import React from "react";
import BoatComponent from "../components/Boat/Boat";
import Addons from "../components/Boat/Addons";
import BoatSpecs from "../components/Boat/BoatSpecs";
import Eligibility from "../components/Boat/Eligibility";
import Coverage from "../components/Boat/Coverage";

const BoatMain = ({
  formNumber,
  boatCount,
  animateX,
  setAnimateX,
  number_of_boats,
  boatNumber,
  addBoat,
  boatFormValues,
  setBoatFormValues,
  errorBoat,
  setErrorBoat,
  coverageFormValues,
  setCoverageFormValues,
  eligiblityFormValues,
  setEligiblityFormValues,
  boatSpecsFormValues,
  setBoatSpecsFormValues,
  addonsFormValues,
  setAddonsFormValues,
  errorCoverage,
  setErrorCoverage,
  errorEligiblity,
  setErrorEligiblity,
  errorBoatSpecs,
  setErrorBoatSpecs,
  errorAddons,
  setErrorAddons,
  index,
  getStartedFormValues,
  addBoatCount,
  quotesRedBoxObj,
  boatRedBoxObj,
  setQuoteRedBoxObj,
  eligiblityRedBoxObj,
  boatSpecsRedBoxObj,
  addonsRedBoxObj,
  setIndex,
  setFormNumber,
  setNumber,
  number,
  setEligibilityRedBoxObj,
  setBoatRedBoxObj,
  setBoatSpecsRedBoxObj,
  setAddonsRedBoxObj,
  boatNames,
  setLeinHolders,
  referralFlag,
  organization,
  brokerReviewFlag,
  btnLoading,
  defaultData,
  setDefaultData,
}) => {
  let which_whole_err =
    formNumber === 1
      ? errorBoat
      : formNumber === 2
      ? errorCoverage
      : formNumber === 3
      ? errorEligiblity
      : formNumber === 4
      ? errorBoatSpecs
      : formNumber === 5 && errorAddons;

  let error =
    formNumber === 1
      ? errorBoat[index - 1]
      : formNumber === 2
      ? errorCoverage[index - 1]
      : formNumber === 3
      ? errorEligiblity[index - 1]
      : formNumber === 4
      ? errorBoatSpecs[index - 1]
      : formNumber === 5 && errorAddons[index - 1];

  let setError =
    formNumber === 1
      ? setErrorBoat
      : formNumber === 2
      ? setErrorCoverage
      : formNumber === 3
      ? setErrorEligiblity
      : formNumber === 4
      ? setErrorBoatSpecs
      : formNumber === 5 && setErrorAddons;

  const which_redbox =
    formNumber === 1
      ? boatRedBoxObj
      : formNumber === 2
      ? quotesRedBoxObj
      : formNumber === 3
      ? eligiblityRedBoxObj
      : formNumber === 4
      ? boatSpecsRedBoxObj
      : formNumber === 5 && addonsRedBoxObj;

  const which_set_redbox =
    formNumber === 1
      ? setBoatRedBoxObj
      : formNumber === 2
      ? setQuoteRedBoxObj
      : formNumber === 3
      ? setEligibilityRedBoxObj
      : formNumber === 4
      ? setBoatSpecsRedBoxObj
      : formNumber === 5 && setAddonsRedBoxObj;

  const handleNext = async () => {
    const allValuesUndefined = Object.values(error).some(
      (value) => value === undefined
    );

    let map = which_redbox.map((m) => {
      return {
        ...m,
        state: false,
      };
    });

    which_set_redbox(map);

    console.log(allValuesUndefined);
    if (allValuesUndefined) {
      const updatedFormData = { ...error };
      for (const key in updatedFormData) {
        if (updatedFormData.hasOwnProperty(key)) {
          if (updatedFormData[key] === undefined) updatedFormData[key] = true;
        }
      }

      let id = index;

      let err = which_whole_err.map((m, index) => {
        return index === id - 1
          ? (which_whole_err[id - 1] = updatedFormData)
          : m;
      });

      let red_box = which_redbox.map((m, index) => {
        if (index === id - 1) {
          return { ...m, state: true };
        } else {
          return m;
        }
      });

      await new Promise((resolve) => setTimeout(resolve, 0));

      which_set_redbox(red_box);

      setError(err);

      return;
    }

    console.log(error);
    const anyValueIsTrue = Object.values(error).some((value) => value === true);

    console.log(anyValueIsTrue);

    if (anyValueIsTrue) {
      let id = index;

      let red_box = which_redbox.map((m, index) => {
        if (index === id - 1) {
          return { ...m, state: true };
        } else {
          return m;
        }
      });

      console.log(red_box);

      await new Promise((resolve) => setTimeout(resolve, 0));

      which_set_redbox(red_box);
      //   setRedBox(true);

      return;
    }
    setAnimateX(50);

    let id = index;

    let red_box = which_redbox.map((m, index) => {
      if (index === id - 1) {
        return { ...m, state: false };
      } else {
        return m;
      }
    });

    which_set_redbox(red_box);

    // nextPage(formNumber + 1);

    if (boatFormValues[index - 1]?.type_of_watercraft === "I'm not too sure") {
      if (formNumber === 1) {
        setFormNumber(3);
        return;
      }
    }

    if (formNumber === 5 && index >= boatCount.length) {
      setNumber(number + 1);
    } else if (formNumber === 5) {
      setFormNumber(1);
      setIndex(index + 1);
    } else {
      setFormNumber(formNumber + 1);
    }

    return true;
  };

  const handleBack = () => {
    setAnimateX(-50);

    if (boatFormValues[index - 1]?.type_of_watercraft === "I'm not too sure") {
      if (formNumber === 3) {
        setFormNumber(1);
        return;
      }
    }

    if (formNumber === 1 && index === 1) {
      return;
    } else if (formNumber === 1 && index !== 1) {
      setFormNumber(5);
      setIndex(index - 1);
    } else {
      setFormNumber(formNumber - 1);
    }
  };

  const progressClick = (page) => {
    return;

    if (formNumber < page) {
      for (let i = formNumber; i < page; i++) {
        let which_whole_err =
          i === 1
            ? errorBoat
            : i === 2
            ? errorCoverage
            : i === 3
            ? errorEligiblity
            : i === 4
            ? errorBoatSpecs
            : i === 5 && errorAddons;

        let error =
          i === 1
            ? errorBoat[index - 1]
            : i === 2
            ? errorCoverage[index - 1]
            : i === 3
            ? errorEligiblity[index - 1]
            : i === 4
            ? errorBoatSpecs[index - 1]
            : i === 5 && errorAddons[index - 1];

        let setError =
          i === 1
            ? setErrorBoat
            : i === 2
            ? setErrorCoverage
            : i === 3
            ? setErrorEligiblity
            : i === 4
            ? setErrorBoatSpecs
            : i === 5 && setErrorAddons;

        const which_redbox =
          i === 1
            ? boatRedBoxObj
            : i === 2
            ? quotesRedBoxObj
            : i === 3
            ? eligiblityRedBoxObj
            : i === 4
            ? boatSpecsRedBoxObj
            : i === 5 && addonsRedBoxObj;

        const which_set_redbox =
          i === 1
            ? setBoatRedBoxObj
            : i === 2
            ? setQuoteRedBoxObj
            : i === 3
            ? setEligibilityRedBoxObj
            : i === 4
            ? setBoatSpecsRedBoxObj
            : i === 5 && setAddonsRedBoxObj;

        const allValuesUndefined = Object.values(error).some(
          (value) => value === undefined
        );

        console.log(allValuesUndefined);
        if (allValuesUndefined) {
          const updatedFormData = { ...error };
          for (const key in updatedFormData) {
            if (updatedFormData.hasOwnProperty(key)) {
              if (updatedFormData[key] === undefined)
                updatedFormData[key] = true;
            }
          }

          let id = index;

          let err = which_whole_err.map((m, index) => {
            return index === id - 1
              ? (which_whole_err[id - 1] = updatedFormData)
              : m;
          });

          let red_box = which_redbox.map((m, index) => {
            if (index === id - 1) {
              return { ...m, state: true };
            } else {
              return m;
            }
          });

          console.log(red_box);

          which_set_redbox(red_box);

          console.log(err);

          setError(err);

          return;
        }

        console.log(error);
        const anyValueIsTrue = Object.values(error).some(
          (value) => value === true
        );

        console.log(anyValueIsTrue);

        if (anyValueIsTrue) {
          let id = index;

          let red_box = which_redbox.map((m, index) => {
            if (index === id - 1) {
              return { ...m, state: true };
            } else {
              return m;
            }
          });

          console.log(red_box);

          which_set_redbox(red_box);
          //   setRedBox(true);

          return;
        }
        setAnimateX(50);

        let id = index;

        let red_box = which_redbox.map((m, index) => {
          if (index === id - 1) {
            return { ...m, state: false };
          } else {
            return m;
          }
        });

        which_set_redbox(red_box);
      }
    }
    setFormNumber(page);
  };

  return (
    <div>
      <div
        style={{
          padding: "10px",
          width: "100%",
          border: "1px solid rgb(166, 166, 166)",
          borderRadius: "5px",
        }}
      >
        <div
          className="inner_progress_bar"
          style={{ justifyContent: "center" }}
        >
          <div className="bar" onClick={() => progressClick(1)}>
            <div
              className={formNumber >= 1 ? `bar_line` : "bar_line_unselects"}
            ></div>
            <div
              className={
                formNumber >= 1 && formNumber === 1
                  ? `bar_content_selects`
                  : formNumber >= 1
                  ? "bar_content"
                  : "bar_content_unselects"
              }
            >
              BOAT DETAILS
            </div>
          </div>
          <div className="bar" onClick={() => progressClick(2)}>
            <div
              className={formNumber >= 2 ? `bar_line` : "bar_line_unselects"}
            ></div>
            <div
              className={
                formNumber >= 2 && formNumber === 2
                  ? `bar_content_selects`
                  : formNumber >= 2
                  ? "bar_content"
                  : "bar_content_unselects"
              }
            >
              QUOTES & COVERAGES
            </div>
          </div>
          <div className="bar" onClick={() => progressClick(3)}>
            <div
              className={formNumber >= 3 ? `bar_line` : "bar_line_unselects"}
            ></div>
            <div
              className={
                formNumber >= 3 && formNumber === 3
                  ? `bar_content_selects`
                  : formNumber >= 3
                  ? "bar_content"
                  : "bar_content_unselects"
              }
            >
              ELIGIBILITY
            </div>
          </div>
          <div className="bar" onClick={() => progressClick(4)}>
            <div
              className={formNumber >= 4 ? `bar_line` : "bar_line_unselects"}
            ></div>
            <div
              className={
                formNumber >= 4 && formNumber === 4
                  ? `bar_content_selects`
                  : formNumber >= 4
                  ? "bar_content"
                  : "bar_content_unselects"
              }
            >
              BOAT SPECS
            </div>
          </div>
          <div className="bar" onClick={() => progressClick(5)}>
            <div
              className={formNumber >= 5 ? `bar_line` : "bar_line_unselects"}
            ></div>
            <div
              className={
                formNumber >= 5 && formNumber === 5
                  ? `bar_content_selects`
                  : formNumber >= 5
                  ? "bar_content"
                  : "bar_content_unselects"
              }
            >
              ADDONS
            </div>
          </div>
        </div>
        <div
          className="progress_line"
          style={{
            width: `${(formNumber / 5) * 100 - 0.75}%`,
            marginTop: "20px",
          }}
        ></div>
        {formNumber === 1 ? (
          <BoatComponent
            animateX={animateX}
            setAnimateX={setAnimateX}
            value={boatFormValues[index - 1]}
            setValue={setBoatFormValues}
            error={errorBoat[index - 1]}
            setError={setErrorBoat}
            getStarted={getStartedFormValues}
            boatCount={addBoatCount}
            id={index - 1}
            number_of_boats={number_of_boats}
            boatNumber={boatNumber}
            addBoat={addBoat}
            wholevalue={boatFormValues}
            wholeError={errorBoat}
            coverageFormValues={coverageFormValues}
            setCoverageFormValues={setCoverageFormValues}
            eligiblityFormValues={eligiblityFormValues}
            setEligiblityFormValues={setEligiblityFormValues}
            boatSpecsFormValues={boatSpecsFormValues}
            setBoatSpecsFormValues={setBoatSpecsFormValues}
            addonsFormValues={addonsFormValues}
            setAddonsFormValues={setAddonsFormValues}
            errorCoverage={errorCoverage}
            setErrorCoverage={setErrorCoverage}
            errorEligiblity={errorEligiblity}
            setErrorEligiblity={setErrorEligiblity}
            errorBoatSpecs={errorBoatSpecs}
            setErrorBoatSpecs={setErrorBoatSpecs}
            errorAddons={errorAddons}
            setErrorAddons={setErrorAddons}
            redBoxObj={boatRedBoxObj[index - 1]}
            boatNames={boatNames[index - 1]}
            wholeBoatNames={boatNames}
            referralFlag={referralFlag}
            brokerReviewFlag={brokerReviewFlag}
          />
        ) : formNumber === 2 ? (
          <Coverage
            formNumber={formNumber}
            animateX={animateX}
            setAnimateX={setAnimateX}
            value={coverageFormValues[index - 1]}
            setValue={setCoverageFormValues}
            error={errorCoverage[index - 1]}
            setError={setErrorCoverage}
            boatCount={addBoatCount}
            wholevalue={coverageFormValues}
            wholeError={errorCoverage}
            id={index - 1}
            redBoxObj={quotesRedBoxObj[index - 1]}
            setRedBoxObj={setQuoteRedBoxObj}
            wholeRedBox={quotesRedBoxObj[index - 1]}
            boatNames={boatNames[index - 1]}
            index={index}
            which_whole_err={which_whole_err}
            which_redbox={which_redbox}
            which_set_redbox={which_set_redbox}
            setNumber={setNumber}
            number={number}
            setFormNumber={setFormNumber}
            setIndex={setIndex}
            wholeBoatNames={boatNames}
            boatFormValues={boatFormValues[index - 1]}
            referralFlag={referralFlag}
            brokerReviewFlag={brokerReviewFlag}
            defaultData={defaultData}
            setDefaultData={setDefaultData}
          />
        ) : formNumber === 3 ? (
          <Eligibility
            formNumber={formNumber}
            animateX={animateX}
            setAnimateX={setAnimateX}
            value={eligiblityFormValues[index - 1]}
            setValue={setEligiblityFormValues}
            error={errorEligiblity[index - 1]}
            setError={setErrorEligiblity}
            id={index}
            boatCount={addBoatCount}
            wholeError={errorEligiblity}
            wholevalue={eligiblityFormValues}
            redBoxObj={eligiblityRedBoxObj[index - 1]}
            boatNames={boatNames[index - 1]}
            wholeBoatNames={boatNames}
            referralFlag={referralFlag}
            brokerReviewFlag={brokerReviewFlag}
          />
        ) : formNumber === 4 ? (
          <BoatSpecs
            formNumber={formNumber}
            animateX={animateX}
            setAnimateX={setAnimateX}
            value={boatSpecsFormValues[index - 1]}
            setValue={setBoatSpecsFormValues}
            error={errorBoatSpecs[index - 1]}
            setError={setErrorBoatSpecs}
            id={index}
            boatCount={addBoatCount}
            wholeError={errorBoatSpecs}
            wholevalue={boatSpecsFormValues}
            redBoxObj={boatSpecsRedBoxObj[index - 1]}
            boatForms={boatFormValues[index - 1]}
            boatNames={boatNames[index - 1]}
            wholeBoatNames={boatNames}
            referralFlag={referralFlag}
            brokerReviewFlag={brokerReviewFlag}
          />
        ) : formNumber === 5 ? (
          <Addons
            formNumber={formNumber}
            animateX={animateX}
            setAnimateX={setAnimateX}
            value={addonsFormValues[index - 1]}
            setValue={setAddonsFormValues}
            error={errorAddons[index - 1]}
            setError={setErrorAddons}
            id={index}
            boatCount={addBoatCount}
            wholeError={errorAddons}
            wholevalue={addonsFormValues}
            redBoxObj={addonsRedBoxObj[index - 1]}
            boatNames={boatNames[index - 1]}
            wholeBoatNames={boatNames}
            setLeinHolders={setLeinHolders}
            referralFlag={referralFlag}
            organization={organization}
            brokerReviewFlag={brokerReviewFlag}
            btnLoading={btnLoading}
          />
        ) : (
          ""
        )}
        <div className="inner_btns">
          {!(formNumber === 1 && index === 1) && (
            <button
              className="inner_back_btn"
              onClick={handleBack}
              disabled={btnLoading}
            >
              Back
            </button>
          )}
          {formNumber === 2 && (referralFlag?.state || brokerReviewFlag) && (
            <button className="inner_next_btn" onClick={handleNext}>
              Next
            </button>
          )}
          {!(
            (formNumber === 5 && index === addBoatCount.length) ||
            formNumber === 2
          ) && (
            <button className="inner_next_btn" onClick={handleNext}>
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BoatMain;
