import React, { useEffect, useRef, useState } from "react";
import Select from "../Select";
import { motion } from "framer-motion";
import "../../styles/Trailer/Quote.css";
import logo from "../../assets/RecLogo.png";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "../../api";
import { formatCurrency } from "../../utils";
import SplTextField from "../SplTextField";

const Coverages = ({
  formNumber,
  nextPage,
  animateX,
  setAnimateX,
  value,
  setValue,
  error,
  setError,
  id,
  wholevalue,
  wholeError,
  redBoxObj,
  trailer,
  setRedBoxObj,
  wholeRedBox,
  trailerNames,
  index,
  which_whole_err,
  which_redbox,
  which_set_redbox,
  setFormNumber,
  addTrailerCount,
  setIndex,
  wholetrailerNames,
  referralFlag,
  setReferralFlag,
  brokerReviewFlag,
  defaultData,
  setDefaultData,
}) => {
  const deductible = [
    { value: 500, label: "$500" },
    { value: 1000, label: "$1,000" },
  ];

  const [currentValueData, setCurrentValueData] = useState(null);
  const [repValueData, setRepValueData] = useState(null);

  const currentYear = new Date().getFullYear();
  let is_perils;

  if (
    trailer?.type_of_trailer === "Travel Trailer/Fifth Wheel Trailer" &&
    Number(trailer?.trailer_model_year) >= currentYear - 15
  ) {
    is_perils = false; // Insured on an All-Risk policy
  } else if (
    trailer?.type_of_trailer === "Park Model/Destination Trailer" &&
    Number(trailer?.trailer_model_year) >= currentYear - 25
  ) {
    is_perils = false; // Insured on an All-Risk policy
  } else {
    is_perils = true; // Named perils is applicable
  }

  console.log(is_perils, "is_perils");

  let overLand_water_protection =
    !is_perils && trailer?.is_trailer_parked_within_500ft === "No";

  const handleNext = (which_coverage) => {
    // const allValuesUndefined = Object.values(error).some(
    //   (value) => value === undefined
    // );

    // console.log(allValuesUndefined);
    // if (allValuesUndefined) {
    //   const updatedFormData = { ...error };
    //   for (const key in updatedFormData) {
    //     if (updatedFormData.hasOwnProperty(key)) {
    //       if (updatedFormData[key] === undefined) updatedFormData[key] = true;
    //     }
    //   }

    //   let id = index;

    //   let err = which_whole_err.map((m, index) => {
    //     return index === id - 1
    //       ? (which_whole_err[id - 1] = updatedFormData)
    //       : m;
    //   });

    //   let red_box = which_redbox.map((m, index) => {
    //     if (index === id - 1) {
    //       return { ...m, state: true };
    //     } else {
    //       return m;
    //     }
    //   });

    //   console.log(red_box);

    //   which_set_redbox(red_box);

    //   console.log(err);

    //   setError(err);

    //   return;
    // }

    // console.log(error);
    // const anyValueIsTrue = Object.values(error).some((value) => value === true);

    // console.log(anyValueIsTrue);

    // if (anyValueIsTrue) {
    //   let id = index;

    //   let red_box = which_redbox.map((m, index) => {
    //     if (index === id - 1) {
    //       return { ...m, state: true };
    //     } else {
    //       return m;
    //     }
    //   });

    //   console.log(red_box);

    //   which_set_redbox(red_box);
    //   //   setRedBox(true);

    //   return;
    // }
    setAnimateX(50);

    let id = index;

    let red_box = which_redbox.map((m, index) => {
      if (index === id - 1) {
        return { ...m, state: false };
      } else {
        return m;
      }
    });

    which_set_redbox(red_box);

    let updated = wholevalue.map((m, index) => {
      if (index === id - 1) {
        return {
          ...m,
          coverage:
            which_coverage === "Replacement Value Coverage"
              ? Number(trailer?.replacement_cost)
              : Number(trailer?.actual_cash_value),
          coverage_name: which_coverage,
          coverage_type: is_perils ? "Named Perils" : "All Risk",
          permises_liability: 2000000,
          deductible:
            which_coverage === "Replacement Value Coverage"
              ? value?.deductible_rc
              : value?.deductible_ac,
          premium_per_year:
            which_coverage === "Replacement Value Coverage"
              ? Number(repValueData?.total_premium).toFixed(2)
              : Number(currentValueData?.total_premium).toFixed(2),
          personal_property_coverage:
            which_coverage === "Replacement Value Coverage"
              ? Number(repValueData?.personal_prop_cov)
              : Number(currentValueData?.personal_prop_cov),
          detached_private_structure_coverage:
            which_coverage === "Replacement Value Coverage"
              ? parseFloat(repValueData?.detached_priv_stru_cov)
              : parseFloat(currentValueData?.detached_priv_stru_cov),
          overland_water_protection: true,
        };
      }

      return m;
    });

    console.log(updated, repValueData?.total_prem);

    let err = which_whole_err.map((m, index) => {
      if (index === id - 1) {
        return {
          ...m,
          coverage_name: false,
        };
      }

      return m;
    });
    setError(err);

    setValue(updated);

    // let coverage =
    //   which_coverage === "Replacement Value Coverage"
    //     ? Number(trailer?.replacement_cost)
    //     : Number(trailer?.actual_cash_value);

    // let is_trailer_over_35years =
    //   Number(trailer?.trailer_model_year) < new Date().getFullYear() - 35;

    // if (
    //   trailer?.type_of_trailer === "Park Model/Destination Trailer" &&
    //   (coverage > 300000 || coverage < 25000)
    // ) {
    //   setReferralFlag({ ...referralFlag, state: true });
    // } else if (
    //   trailer?.type_of_trailer === "Travel Trailer/Fifth Wheel Trailer" &&
    //   (coverage > 175000 || coverage < 10000)
    // ) {
    //   setReferralFlag({ ...referralFlag, state: true });
    // } else if (is_trailer_over_35years) {
    //   setReferralFlag({ ...referralFlag, state: true });
    // } else {
    //   setReferralFlag({ ...referralFlag, state: false });
    // }

    // nextPage(formNumber + 1);

    if (formNumber === 4 && index >= addTrailerCount.length) {
      //   setNumber(number + 1);
    } else if (formNumber === 4) {
      setFormNumber(1);
      setIndex(index + 1);
    } else {
      setFormNumber(formNumber + 1);
    }

    return true;
  };

  const [loading, setIsloading] = useState(true);

  const divRef = useRef(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, [loading]);

  useEffect(() => {
    let isCancelled = false;

    setIsloading(true);

    async function fun() {
      let preferred_park = [];
      console.log(trailer);
      if (trailer?.park_name) {
        await axios
          .get(`${APIURL}/preferred_park/get_preferred_parks`)
          .then((res) => {
            console.log(res);

            let array = res?.data?.data.map((m) => {
              return {
                ...m,
                value: m.name,
                label: m.name,
              };
            });

            preferred_park = array;

            console.log(array);
          });
      }

      let is_preferred_parks = preferred_park.filter(
        (m) => m.name === trailer?.park_name
      );

      let is_trailer_over_25years =
        Number(trailer?.trailer_model_year) < new Date().getFullYear() - 25;

      let body = {
        organization_id: ORGANIZATION_ID,
        is_ol_water_protection: true,
        trailer_type: trailer?.type_of_trailer,
        is_seasonally_parked:
          trailer?.is_trailer_parked === "Yes" &&
          trailer?.is_trailer_removed_from_park === "No",
        trailer_value: parseFloat(trailer?.actual_cash_value),
        deductible_value: Number(value?.deductible_ac),
        liability_value: 2000000,
        is_preferred_parks:
          !value?.overrided_preferred_park_discount && is_preferred_parks.length
            ? true
            : false,
        is_trailer_over_25years,
        is_golf_cart_added: trailer?.insure_golf_cart === "Yes",
        total_golf_cart_value:
          (Number(trailer?.golf_cart_1) ? Number(trailer.golf_cart_1) : 0) +
          (Number(trailer?.golf_cart_2) ? Number(trailer?.golf_cart_2) : 0),
        coverage_type: is_perils ? "Named Perils" : "All Risk",
        opted_snowbird_endorsement: value?.opted_for_snowbird_endorsment
          ? value?.opted_for_snowbird_endorsment
          : false,
      };

      console.log(body, is_perils);

      await axios
        .post(`${APIURL}/trailer/send_trailer_coverage_details`, body)
        .then((res) => {
          console.log(res);
          setCurrentValueData(res?.data?.data);

          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (!isCancelled) {
      fun();
    }

    return () => (isCancelled = true);
  }, [value?.deductible_ac]);

  useEffect(() => {
    let isCancelled = false;

    setIsloading(true);

    async function fun() {
      if (trailer?.replacement_cost) {
        let preferred_park = [];
        if (trailer?.park_name) {
          await axios
            .get(`${APIURL}/preferred_park/get_preferred_parks`)
            .then((res) => {
              console.log(res);

              let array = res?.data?.data.map((m) => {
                return {
                  ...m,
                  value: m.name,
                  label: m.name,
                };
              });

              preferred_park = array;

              console.log(array);
            });
        }

        let is_preferred_parks = preferred_park.filter(
          (m) => m.name === trailer?.park_name
        );

        let is_trailer_over_25years =
          Number(trailer?.trailer_model_year) < new Date().getFullYear() - 25;

        let body = {
          organization_id: ORGANIZATION_ID,
          is_ol_water_protection: true,
          trailer_type: trailer?.type_of_trailer,
          is_seasonally_parked:
            trailer?.is_trailer_parked === "Yes" &&
            trailer?.is_trailer_removed_from_park === "No",
          trailer_value: parseFloat(trailer?.replacement_cost),
          deductible_value: Number(value?.deductible_rc),
          liability_value: 2000000,
          is_preferred_parks:
            !value?.overrided_preferred_park_discount &&
            is_preferred_parks.length
              ? true
              : false,
          is_trailer_over_25years,
          is_golf_cart_added: trailer?.insure_golf_cart === "Yes",
          total_golf_cart_value:
            (Number(trailer?.golf_cart_1) ? Number(trailer.golf_cart_1) : 0) +
            (Number(trailer?.golf_cart_2) ? Number(trailer?.golf_cart_2) : 0),
          coverage_type: is_perils ? "Named Perils" : "All Risk",
          opted_snowbird_endorsement: value?.opted_for_snowbird_endorsment
            ? value?.opted_for_snowbird_endorsment
            : false,
        };

        console.log(body);

        await axios
          .post(`${APIURL}/trailer/send_trailer_coverage_details`, body)
          .then((res) => {
            console.log(res);
            setRepValueData(res?.data?.data);
            setIsloading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    if (!isCancelled) {
      fun();
    }

    return () => (isCancelled = true);
  }, [value?.deductible_rc]);

  console.log(value, "value");

  useEffect(() => {
    if (defaultData?.coverage_gtag_sent === false) {
      window.gtag("event", "conversion", {
        send_to: "AW-1006867181/m33LCMnp3aIDEO2ljuAD",
      });

      setDefaultData({ ...defaultData, coverage_gtag_sent: true });
    }
  }, []);

  return (
    <motion.div
      initial={{ x: animateX, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {loading ? (
        <div
          style={{
            height: "85Vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="loader"></div>
        </div>
      ) : currentValueData !== null ? (
        <div ref={divRef}>
          <div
            className={`box ${redBoxObj?.state && "redbox"}`}
            style={{ marginBottom: "2rem" }}
          >
            <div className="trailer_coverage">
              <header>
                <div className="side_line"></div>
                <div>
                  <h3>Coverages</h3>
                  <h6 className="trailer_name">
                    {" "}
                    {wholetrailerNames.length > 1 && `(${trailerNames?.name})`}
                  </h6>
                </div>
              </header>
              <div className="quote_container">
                <h1 style={{ color: error?.coverage_name && "red" }}>
                  Select the required coverage
                </h1>
                <div className="trailer_quote">
                  {trailer?.replacement_cost &&
                  (!(referralFlag?.state || brokerReviewFlag) ||
                    ((referralFlag?.state || brokerReviewFlag) &&
                      value?.coverage_name ===
                        "Replacement Value Coverage")) ? (
                    <div
                      className="box"
                      style={{
                        margin:
                          referralFlag?.state || brokerReviewFlag ? "" : "0",
                      }}
                    >
                      <div className="quote_box">
                        <h2>Replacement Value Coverage</h2>
                        <img src={logo} alt="logo" loading="lazy" />
                        <div className="price">
                          <p
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <p
                              style={{
                                display: "flex",
                                gap: "4px",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              ${Number(repValueData?.total_premium).toFixed(2)}{" "}
                              /Year{" "}
                              {/* <Tooltip
                                title={
                                  <div style={{ fontSize: "0.75rem" }}>
                                    <p>
                                      Trailer base premium{" "}
                                      <b>
                                        $
                                        {Number(
                                          repValueData?.trailer_base_premium
                                        ).toFixed(2)}
                                      </b>
                                    </p>
                                    <p>
                                      Trailer Liability premium{" "}
                                      <b>
                                        $
                                        {Number(
                                          repValueData?.liability_prem
                                        ).toFixed(2)}
                                      </b>
                                    </p>
                                  </div>
                                }
                              >
                                <QuestionCircleTwoTone
                                  twoToneColor={"#8ed1fc"}
                                />
                              </Tooltip> */}
                            </p>
                            <span style={{ fontSize: "0.75rem" }}>
                              plus applicable taxes/ fees
                            </span>
                          </p>
                        </div>
                        {referralFlag?.state || brokerReviewFlag ? (
                          <div style={{ paddingTop: ".5rem" }}></div>
                        ) : (
                          <button
                            className="next_btn"
                            style={{ margin: "0.5rem auto 1rem" }}
                            onClick={() =>
                              handleNext("Replacement Value Coverage")
                            }
                          >
                            Click here to finish application
                          </button>
                        )}
                        <div
                          style={{
                            margin: "0 10px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <div className="quote_flex">
                            <label>OverLand Water Protection</label>
                            {/* <p> {overLand_water_protection ? "Yes" : "No"} </p> */}
                            <p>Yes</p>
                          </div>
                          <SplTextField
                            label={"Trailer Coverage"}
                            disabled={true}
                            value={formatCurrency(
                              Number(trailer?.replacement_cost)
                            )}
                          />{" "}
                          <div className="quote_flex">
                            <label>Coverage Type</label>
                            <p>{is_perils ? "Named Perils" : "All Risk"}</p>
                          </div>
                          <SplTextField
                            label={
                              "Detached Private Structure Coverage (Sheds etc.)"
                            }
                            value={formatCurrency(
                              Number(repValueData?.detached_priv_stru_cov)
                            )}
                            disabled={true}
                          />
                          <SplTextField
                            label={
                              "Personal Property Coverage (T.V., Furniture etc.)"
                            }
                            value={formatCurrency(
                              Number(repValueData?.personal_prop_cov)
                            )}
                            disabled={true}
                          />
                          <SplTextField
                            label={"Premises Liability"}
                            helperText={
                              "Provides third party bodily injury and property damage coverage when you're parked and using your trailer."
                            }
                            value={value?.premises_liability}
                            name={"premises_liability"}
                            setValue={setValue}
                            error={error}
                            error_state={error?.premises_liability}
                            error_text={"Please select an option"}
                            setError={setError}
                            object={value}
                            id={id}
                            wholevalue={wholevalue}
                            wholeError={wholeError}
                            disabled={true}
                          />
                          <Select
                            label={"Deductible"}
                            options={deductible}
                            helperText={
                              "The amount of money you pay in a claim. We cover anything over the deductible up to the limit on policy. $5,000 Hail Deductible applies for trailers located in AB"
                            }
                            value={value?.deductible_rc}
                            name={"deductible_rc"}
                            setValue={setValue}
                            error={error}
                            error_state={error?.deductible_rc}
                            error_text={"Please select an option"}
                            setError={setError}
                            object={value}
                            id={id}
                            wholevalue={wholevalue}
                            wholeError={wholeError}
                            allowClear={"he"}
                            disabled={referralFlag?.state || brokerReviewFlag}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "1rem",
                            }}
                          >
                            <div className="quote_flex">
                              <label>Golf Cart(s)</label>
                              <p>
                                {" "}
                                {trailer?.insure_golf_cart === "Yes"
                                  ? "Yes"
                                  : "No"}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {!(referralFlag?.state || brokerReviewFlag) ||
                  ((referralFlag?.state || brokerReviewFlag) &&
                    value?.coverage_name ===
                      "Current Market Value Coverage") ? (
                    <div className="box" style={{ marginBottom: "2rem" }}>
                      <div className="quote_box">
                        <h2>Current Market Value Coverage</h2>
                        <img src={logo} alt="logo" loading="lazy" />
                        <div className="price">
                          <p
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <p
                              style={{
                                display: "flex",
                                gap: "4px",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              $
                              {Number(currentValueData?.total_premium).toFixed(
                                2
                              )}{" "}
                              /Year{" "}
                              {/* <Tooltip
                              title={
                                <div style={{ fontSize: "0.75rem" }}>
                                  <p>
                                    Trailer base premium{" "}
                                    <b>
                                      $
                                      {Number(
                                        currentValueData?.trailer_base_premium
                                      ).toFixed(2)}
                                    </b>
                                  </p>
                                  <p>
                                    Trailer Liability premium{" "}
                                    <b>
                                      $
                                      {Number(
                                        currentValueData?.liability_prem
                                      ).toFixed(2)}
                                    </b>
                                  </p>
                                </div>
                              }
                            >
                              <QuestionCircleTwoTone twoToneColor={"#8ed1fc"} />
                            </Tooltip> */}
                            </p>
                            <span style={{ fontSize: "0.75rem" }}>
                              plus applicable taxes/ fees
                            </span>
                          </p>
                        </div>
                        {referralFlag?.state || brokerReviewFlag ? (
                          <div style={{ paddingTop: ".5rem" }}></div>
                        ) : (
                          <button
                            className="next_btn"
                            style={{ margin: "0.5rem auto 1rem" }}
                            onClick={() =>
                              handleNext("Current Market Value Coverage")
                            }
                          >
                            Click here to finish application
                          </button>
                        )}
                        <div
                          style={{
                            margin: "0 10px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <div className="quote_flex">
                            <label>OverLand Water Protection</label>
                            {/* <p> {overLand_water_protection ? "Yes" : "No"} </p> */}
                            <p>Yes</p>
                          </div>
                          <SplTextField
                            label={"Trailer Coverage"}
                            value={formatCurrency(
                              Number(trailer?.actual_cash_value)
                            )}
                            disabled={true}
                          />{" "}
                          <div className="quote_flex">
                            <label>Coverage Type</label>
                            <p>{is_perils ? "Named Perils" : "All Risk"}</p>
                          </div>
                          <SplTextField
                            label={
                              "Detached Private Structure Coverage (Sheds etc.)"
                            }
                            value={formatCurrency(
                              Number(currentValueData?.detached_priv_stru_cov)
                            )}
                            disabled={true}
                          />
                          <SplTextField
                            label={
                              "Personal Property Coverage (T.V., Furniture etc.)"
                            }
                            value={formatCurrency(
                              Number(currentValueData?.personal_prop_cov)
                            )}
                            disabled={true}
                          />
                          <SplTextField
                            label={"Premises Liability"}
                            helperText={
                              "Provides third party bodily injury and property damage coverage when you're parked and using your trailer."
                            }
                            value={value?.premises_liability}
                            name={"premises_liability"}
                            setValue={setValue}
                            error={error}
                            error_state={error?.premises_liability}
                            error_text={"Please select an option"}
                            setError={setError}
                            object={value}
                            id={id}
                            wholevalue={wholevalue}
                            wholeError={wholeError}
                            disabled={true}
                          />
                          <Select
                            label={"Deductible"}
                            options={deductible}
                            helperText={
                              "The amount of money you pay in a claim. We cover anything over the deductible up to the limit on policy. $5,000 Hail Deductible applies for trailers located in AB"
                            }
                            value={value?.deductible_ac}
                            name={"deductible_ac"}
                            setValue={setValue}
                            error={error}
                            error_state={error?.deductible_ac}
                            error_text={"Please select an option"}
                            setError={setError}
                            object={value}
                            id={id}
                            wholevalue={wholevalue}
                            wholeError={wholeError}
                            allowClear={"he"}
                            disabled={referralFlag?.state || brokerReviewFlag}
                          />
                          {/* <TextField
                          label={"Golf Cart(s)"}
                          value={
                            trailer?.insure_golf_cart === "Yes" ? "Yes" : "No"
                          }
                          disabled={true}
                        /> */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "1rem",
                            }}
                          >
                            <div className="quote_flex">
                              <label>Golf Cart(s)</label>
                              <p>
                                {" "}
                                {trailer?.insure_golf_cart === "Yes"
                                  ? "Yes"
                                  : "No"}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </motion.div>
  );
};

export default Coverages;
