import { useEffect, useState } from "react";
import LoginPage from "../zoho_auth/LoginPage";
import "../styles/zoho_auth/App.css";
import { useNavigate } from "react-router-dom";
import Admin from "./AdminPanel/Admin";

function Layout({ authState, setAuthState, userDetails, setUserDetails }) {
  const [isFetching, setIsFetching] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function auth() {
      await window.catalyst.auth
        .isUserAuthenticated()
        .then((result) => {
          console.log(result);
          setUserDetails({
            firstName: result.content.first_name,
            lastName: result.content.last_name,
            mailid: result.content.email_id,
            timeZone: result.content.time_zone,
            createdTime: result.content.created_time,
          });
          setAuthState(true);
          navigate("/admin");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally((res) => {
          console.log(res);
          setIsFetching(false);
        });
    }

    auth();
  }, []);

  return (
    <>
      {isFetching ? (
        <div className="flex_layout">
          <div className="loader_layout"></div>
        </div>
      ) : authState ? (
        <Admin />
      ) : (
        <LoginPage />
      )}
    </>
  );
}
export default Layout;

const Navigation = () => {
  const navigate = useNavigate();
  useEffect(() => {
    console.log("hello");
    navigate("/admin");
  }, []);
  return <div></div>;
};
