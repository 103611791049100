import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "../../../api";
import Nav from "../../../components/AdminPanel/Nav";
import APTextField from "../../../components/AdminPanel/APTextField";
import { PercentageOutlined } from "@ant-design/icons";

const Tax = () => {
  const [loading, setLoading] = useState(true);
  const [editState, setEditState] = useState(false);
  const [cancelState, setCancelState] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    async function fetch() {
      await axios
        .post(`${APIURL}/organization/get_organization`, {
          organization_id: ORGANIZATION_ID,
          required_keys: ["tax"],
        })
        .then((res) => {
          if (!isCancelled) {
            console.log(res);
            setData(res?.data?.data?.organization?.tax);
            setLoading(() => false);
            setCancelState(() => false);
            setEditState(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetch();

    return () => {
      isCancelled = true;
    };
  }, [cancelState]);

  const handleEdit = async () => {
    setLoading(() => true);

    await new Promise((resolve) => setTimeout(resolve, 1500));

    setLoading(() => false);
    setEditState(true);
  };

  const handleCancel = async () => {
    setLoading(() => true);

    setCancelState(true);
  };

  const handleSave = async () => {
    let body = {
      organization_id: ORGANIZATION_ID,
      organization_updates: {
        tax: {
          Alberta: parseFloat(data?.Alberta),
          "British Columbia": parseFloat(data["British Columbia"]),
          Ontario: parseFloat(data?.Ontario),
          Saskatchewan: parseFloat(data?.Saskatchewan),
        },
      },
    };

    console.log(body);

    setLoading(() => true);

    await axios
      .post(`${APIURL}/organization/update_organization`, body)
      .then((res) => {
        console.log(res);

        setCancelState(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  return (
    <div className="layout">
      <Nav
        header={editState ? "Edit Tax details" : "View Tax details"}
        layout={editState ? "save" : "edit"}
        onEditChange={handleEdit}
        onSaveChange={handleSave}
        onCancelChange={handleCancel}
      />
      <div className="layout_container">
        {loading ? (
          <div className="layout_loading">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="layout_main">
            <APTextField
              disabled={!editState && true}
              name={"Alberta"}
              label="Alberta"
              value={data?.Alberta}
              handleChange={handleChange}
              addonAfter={<PercentageOutlined />}
            />
            <APTextField
              name={"British Columbia"}
              label="British Columbia"
              value={data["British Columbia"]}
              handleChange={handleChange}
              disabled={!editState && true}
              addonAfter={<PercentageOutlined />}
            />
            <APTextField
              disabled={!editState && true}
              name={"Ontario"}
              label="Ontario"
              value={data?.Ontario}
              handleChange={handleChange}
              addonAfter={<PercentageOutlined />}
            />
            <APTextField
              disabled={!editState && true}
              name={"Saskatchewan"}
              label="Saskatchewan"
              value={data?.Saskatchewan}
              handleChange={handleChange}
              addonAfter={<PercentageOutlined />}
            />{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default Tax;
