import { FormHelperText } from "@mui/material";
import React from "react";
import { ConfigProvider, Input } from "antd";
import {
  ERROR_HELPERTEXT_STYLES,
  PRIMARY_COLOR,
  WARNING_COLOR,
} from "../utils";

const numberRegex = /^\d+$/;

const SplTextField = ({
  value,
  label,
  setValue,
  object,
  name,
  key,
  helperText,
  error,
  setError,
  error_state,
  error_text,
  wholeError,
  wholevalue,
  id,
  disabled,
  isNumber,
  percent,
  width,
}) => {
  const handleChange = (e) => {
    if (
      name === "replacement_cost" ||
      name === "aux_engine_value" ||
      name === "tender_value" ||
      name === "trailer_value" ||
      name === "golf_cart_2" ||
      name === "golf_cart_1" ||
      name === "actual_cash_value"
    ) {
      if (value === undefined && !numberRegex.test(e.target.value)) {
        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: "",
            };
          }

          return m;
        });

        setValue(updated);
        return;
      }
      if (e.target.value === "" || parseFloat(e.target.value) === 0) {
        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: e.target.value === "" ? "" : parseFloat(e.target.value),
            };
          }

          return m;
        });

        setValue(updated);

        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);

        return;
      }

      // if (e.target.value === "0") {
      //   let updated = wholevalue.map((m, index) => {
      //     if (index === id - 1) {
      //       return { ...m, [name]: e.target.value };
      //     }

      //     return m;
      //   });

      //   setValue(updated);

      //   let updated_error = wholeError.map((m, index) => {
      //     if (index === id - 1) {
      //       return { ...m, [name]: true };
      //     }

      //     return m;
      //   });
      //   setError(updated_error);

      //   return;
      // }

      if (numberRegex.test(e.target.value)) {
        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: parseFloat(e.target.value) };
          }

          return m;
        });
        setValue(updated);
      } else {
        // let updated_error = wholeError.map((m, index) => {
        //   if (index === id - 1) {
        //     return { ...m, [name]: true };
        //   }

        //   return m;
        // });
        // setError(updated_error);

        // let updated = wholevalue.map((m, index) => {
        //   if (index === id - 1) {
        //     return { ...m, [name]: "" };
        //   }

        //   return m;
        // });
        // setValue(updated);

        return;
      }

      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false };
        }

        return m;
      });
      setError(updated_error);

      return;
    }

    if (name === "actual_cash_value") {
      if (e.target.value === "") {
        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: e.target.value };
          }

          return m;
        });
        setValue(updated);
      }
      if (numberRegex.test(e.target.value)) {
        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: e.target.value };
          }

          return m;
        });
        setValue(updated);
      } else {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: "" };
          }

          return m;
        });
        setValue(updated);

        return;
      }

      if (e.target.value === "") {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);
      } else {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false };
          }

          return m;
        });
        setError(updated_error);
      }

      return;
    }

    if (e.target.value === "") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: true };
        }

        return m;
      });
      setError(updated_error);
    } else {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false };
        }

        return m;
      });
      setError(updated_error);
    }
    let updated = wholevalue.map((m, index) => {
      if (index === id - 1) {
        return { ...m, [name]: e.target.value };
      }

      return m;
    });

    setValue(updated);
  };

  return (
    <div
      className={`spl_input`}
      style={{
        alignItems: helperText ? "flex-start" : "center",
      }}
    >
      <label
        style={{
          marginBottom: error_state && "0.75rem",
          marginTop: helperText && ".75rem",
          width: width && "55%",
        }}
        htmlFor={label}
      >
        {label}
      </label>
      <div className="input_container">
        <ConfigProvider
          theme={{
            components: {
              Input: {
                activeBorderColor: PRIMARY_COLOR,
                hoverBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                errorActiveShadow: "none",
              },
            },
            token: {
              colorErrorOutline: "none",
              colorError: WARNING_COLOR,
              colorErrorBorderHover: "none",
              fontSize: 12,
            },
          }}
        >
          <Input
            size="large"
            addonBefore={
              <p style={{ fontSize: ".875rem", opacity: "0.5" }}>
                {percent ? "%" : "$"}
              </p>
            }
            id={label}
            status={error_state && "error"}
            onChange={handleChange}
            value={value}
            readOnly={disabled}
          />
        </ConfigProvider>
        {error_state ? (
          <FormHelperText
            sx={ERROR_HELPERTEXT_STYLES}
            style={{ padding: "3px 0" }}
          >
            {error_text}
          </FormHelperText>
        ) : (
          ""
        )}
        <FormHelperText
          sx={{
            fontStyle: "italic",
            fontSize: "0.75rem",
            color: "#a0aec0",
            margin: "8px 0",
            lineHeight: "1rem",
            letterSpacing: "0.7px",
            fontFamily: "Montserrat",
          }}
        >
          {helperText}
        </FormHelperText>
      </div>
    </div>
  );
};

export default SplTextField;
