import { ConfigProvider, Select } from "antd";
import React from "react";
import { PRIMARY_COLOR, WARNING_COLOR } from "../../utils";

const APSelect = ({
  name,
  label,
  disabled,
  value,
  handleChange,
  placeholder,
  options = [],
}) => {
  return (
    <div className={`textfield`}>
      <label htmlFor={name}>{label}</label>
      <div>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                activeBorderColor: PRIMARY_COLOR,
                hoverBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                errorActiveShadow: "none",
              },
            },
            token: {
              colorErrorOutline: "none",
              colorError: WARNING_COLOR,
              colorErrorBorderHover: "none",
            },
          }}
        >
          <Select
            id={name}
            className="input"
            style={{ width: "100%" }}
            disabled={disabled}
            size="large"
            name={name}
            value={value}
            onChange={(e) => handleChange(e)}
            placeholder={placeholder}
            options={options}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default APSelect;
