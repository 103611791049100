import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Checkbox from "../Checkbox";
import SplTextField from "../SplTextField";
import Select from "../Select";
import TextField from "../TextField";
import FeetInput from "../FeetInput";
import { COMPONENT_ERROR_TEXT, FIELD_ERROR_TEXT } from "../../utils";
import axios from "axios";
import { APIURL } from "../../api";
import Autocomplete from "../AutoComplete";
import AddressAutoComplete from "../AddressAutoComplete";

const canadaPostalRegex =
  /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ] ?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i;

const Addons = ({
  animateX,
  value,
  setValue,
  error,
  setError,
  id,
  wholevalue,
  wholeError,
  redBoxObj,
  boatNames,
  wholeBoatNames,
  setLeinHolders,
  referralFlag,
  organization,
  brokerReviewFlag,
  btnLoading,
}) => {
  const years = [];
  for (
    let year = new Date().getFullYear() + 1;
    year >= new Date().getFullYear() - 30;
    year--
  ) {
    years.push({ value: year, label: year });
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  const [common_lienholders, setCommonLeinholders] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    axios.get(`${APIURL}/financier/get_financiers`).then((res) => {
      if (!isCancelled) {
        console.log(res);

        let array = res?.data?.data.map((m) => {
          return {
            ...m,
            value: m.name,
            label: m.name,
          };
        });

        console.log(array);
        setCommonLeinholders(array);
        setLeinHolders(array);
      }
    });

    return () => {
      isCancelled = true;
    };
  }, []);

  const [selectedFinancier, setSelectedFinancier] = useState(false);
  useEffect(() => {
    let options = [...common_lienholders];

    console.log(options, "leinholders");

    let filter = options.filter((m) => m.name === value?.boat_lienholder);

    if (filter.length) {
      setSelectedFinancier(true);
    } else {
      setSelectedFinancier(false);
    }
  }, [value?.boat_lienholder]);

  console.log(value);

  return (
    <motion.div
      initial={{ x: animateX, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div>
        <div className={`box ${redBoxObj?.state && "redbox"}`}>
          <div className="boat_form">
            <header>
              <div className="side_line"></div>
              <div>
                <h3 className="error_h3">
                  Addons{" "}
                  {redBoxObj?.state ? (
                    <p className="component_error_text">
                      {COMPONENT_ERROR_TEXT}
                    </p>
                  ) : (
                    ""
                  )}{" "}
                </h3>{" "}
                <h6 className="boat_name">
                  {" "}
                  {wholeBoatNames.length > 1 && `(${boatNames?.name})`}
                </h6>
              </div>
            </header>
            <div className="container">
              <Checkbox
                label={"Add a trailer"}
                value={value?.add_trailer}
                firstoption={"Yes"}
                secondoption={"No"}
                setValue={setValue}
                name={"add_trailer"}
                error={error}
                object={value}
                setError={setError}
                error_state={error?.add_trailer}
                error_text={FIELD_ERROR_TEXT}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag || btnLoading}
              />
              <Checkbox
                label={"Add a tender"}
                value={value?.add_tender}
                firstoption={"Yes"}
                secondoption={"No"}
                setValue={setValue}
                name={"add_tender"}
                error={error}
                object={value}
                setError={setError}
                error_state={error?.add_tender}
                error_text={FIELD_ERROR_TEXT}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag || btnLoading}
              />
              <Checkbox
                label={"Add an auxiliary motor"}
                value={value?.add_auxillary_motor}
                firstoption={"Yes"}
                secondoption={"No"}
                setValue={setValue}
                name={"add_auxillary_motor"}
                error={error}
                object={value}
                setError={setError}
                error_state={error?.add_auxillary_motor}
                error_text={FIELD_ERROR_TEXT}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag || btnLoading}
              />
            </div>
          </div>
        </div>
        {value?.add_trailer === "Yes" && (
          <div
            className={`box ${redBoxObj?.state && "redbox"}`}
            style={{ marginTop: "2rem" }}
          >
            <div className="boat_form">
              <header>
                <div className="side_line"></div>
                <h3>Trailer Details</h3>
              </header>
              <div className="container">
                <SplTextField
                  label={"Trailer Value"}
                  value={value?.trailer_value}
                  name={"trailer_value"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.trailer_value}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                  isNumber={true}
                />
                <Select
                  label={"Trailer Model Year"}
                  options={years}
                  value={value?.trailer_model_year}
                  name={"trailer_model_year"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.trailer_model_year}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                />
                <TextField
                  label={"Trailer Manufacturer"}
                  value={value?.trailer_manufacturer}
                  name={"trailer_manufacturer"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.trailer_manufacturer}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                />
                <TextField
                  label={"Trailer VIN"}
                  value={value?.trailer_serial}
                  name={"trailer_serial"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.trailer_serial}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                  alphanumeric={true}
                  vin={true}
                />
                <FeetInput
                  label={"Trailer Length"}
                  value={value?.trailer_length}
                  name={"trailer_length"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.trailer_length}
                  error_text={FIELD_ERROR_TEXT}
                  inputEnd={"ft."}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                  isDecimal={true}
                />
              </div>
            </div>
          </div>
        )}
        {value?.add_tender === "Yes" && (
          <div
            className={`box ${redBoxObj?.state && "redbox"}`}
            style={{ marginTop: "2rem" }}
          >
            <div className="boat_form">
              <header>
                <div className="side_line"></div>
                <h3>Tender Details</h3>
              </header>
              <div className="container">
                <SplTextField
                  label={"Tender Value"}
                  value={value?.tender_value}
                  name={"tender_value"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.tender_value}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                  isNumber={true}
                />
                <Select
                  label={"Tender Model Year"}
                  options={years}
                  value={value?.tender_model_year}
                  name={"tender_model_year"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.tender_model_year}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                />
                <TextField
                  label={"Tender Manufacturer"}
                  value={value?.tender_manufacturer}
                  name={"tender_manufacturer"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.tender_manufacturer}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                />
                <TextField
                  label={"Tender Serial #"}
                  value={value?.tender_serial}
                  name={"tender_serial"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.tender_serial}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                  alphanumeric={true}
                />
                <FeetInput
                  label={"Tender Length"}
                  value={value?.tender_length}
                  name={"tender_length"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.tender_length}
                  error_text={FIELD_ERROR_TEXT}
                  inputEnd={"ft."}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                  isDecimal={true}
                />
              </div>
            </div>
          </div>
        )}
        {value?.add_auxillary_motor === "Yes" && (
          <div
            className={`box ${redBoxObj?.state && "redbox"}`}
            style={{ marginTop: "2rem" }}
          >
            <div className="boat_form">
              <header>
                <div className="side_line"></div>
                <h3>Auxiliary Engine Details</h3>
              </header>
              <div className="container">
                <SplTextField
                  label={"Aux Engine Value"}
                  value={value?.aux_engine_value}
                  name={"aux_engine_value"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.aux_engine_value}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                  isNumber={true}
                />
                <Select
                  label={"Aux Engine Model Year"}
                  options={years}
                  value={value?.aux_engine_model_year}
                  name={"aux_engine_model_year"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.aux_engine_model_year}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                />
                <TextField
                  label={"Aux Engine Manufacturer"}
                  value={value?.aux_engine_manufacturer}
                  name={"aux_engine_manufacturer"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.aux_engine_manufacturer}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                />
                <TextField
                  label={"Aux Engine Serial #"}
                  value={value?.aux_engine_serial}
                  name={"aux_engine_serial"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.aux_engine_serial}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                  alphanumeric={true}
                />
                <FeetInput
                  label={"Aux Engine HP/Thrust"}
                  value={value?.aux_engine_hp}
                  name={"aux_engine_hp"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.aux_engine_hp}
                  error_text={FIELD_ERROR_TEXT}
                  inputEnd={"HP"}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                  isDecimal={true}
                />
              </div>
            </div>
          </div>
        )}
        <div
          className={`box multiple_box ${redBoxObj?.state && "redbox"}`}
          style={{ marginTop: "2rem" }}
        >
          <div className="trailer_policy_details">
            <header>
              <div className="side_line"></div>
              <h3>Lienholder</h3>
            </header>
            <div className="container">
              <Checkbox
                label={"Is your boat financed?"}
                value={value?.boat_financed}
                object={value}
                name={"boat_financed"}
                setValue={setValue}
                required={true}
                firstoption="Yes"
                secondoption={"No"}
                error_state={error?.boat_financed}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag || btnLoading}
              />
              {value?.boat_financed === "Yes" ? (
                <Autocomplete
                  options={common_lienholders}
                  label={"Leinholder"}
                  value={value?.boat_lienholder}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  name={"boat_lienholder"}
                  object={value}
                  error_state={error?.boat_lienholder}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                />
              ) : (
                ""
              )}

              {value?.boat_lienholder && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  {/* <AddressAutoComplete
                    label={"Address Line 1"}
                    value={value?.street_leinholder}
                    name={"street_leinholder"}
                    setValue={setValue}
                    object={value}
                    error_state={error?.street_leinholder}
                    error_text={FIELD_ERROR_TEXT}
                    error={error}
                    setError={setError}
                    id={id}
                    wholevalue={wholevalue}
                    wholeError={wholeError}
                    disabled={
                      referralFlag?.state ||
                      selectedFinancier ||
                      brokerReviewFlag ||
                      btnLoading
                    }
                    POSTGRID_API_KEY={organization?.post_grid_api_key}
                  /> */}
                  <TextField
                    label={"Address Line 1"}
                    value={value?.street_leinholder}
                    name={"street_leinholder"}
                    setValue={setValue}
                    object={value}
                    error_state={error?.street_leinholder}
                    error_text={FIELD_ERROR_TEXT}
                    error={error}
                    setError={setError}
                    id={id}
                    wholevalue={wholevalue}
                    wholeError={wholeError}
                    disabled={
                      referralFlag?.state ||
                      selectedFinancier ||
                      brokerReviewFlag ||
                      btnLoading
                    }
                  />
                  <TextField
                    label={"Suite/Unit Number"}
                    value={value?.line2_leinholder}
                    name={"line2_leinholder"}
                    setValue={setValue}
                    object={value}
                    error_state={error?.line2_leinholder}
                    error_text={FIELD_ERROR_TEXT}
                    error={error}
                    setError={setError}
                    id={id}
                    wholevalue={wholevalue}
                    // disabled={true}
                    wholeError={wholeError}
                    disabled={
                      referralFlag?.state ||
                      selectedFinancier ||
                      brokerReviewFlag ||
                      btnLoading
                    }
                  />
                  <TextField
                    label={"City"}
                    value={value?.city_leinholder}
                    name={"city_leinholder"}
                    setValue={setValue}
                    object={value}
                    error_state={error?.city_leinholder}
                    error_text={FIELD_ERROR_TEXT}
                    error={error}
                    setError={setError}
                    id={id}
                    wholevalue={wholevalue}
                    disabled={
                      referralFlag?.state ||
                      selectedFinancier ||
                      brokerReviewFlag ||
                      btnLoading
                    }
                    wholeError={wholeError}
                  />
                  <TextField
                    label={"Province"}
                    value={value?.province_leinholder}
                    name={"province_leinholder"}
                    setValue={setValue}
                    object={value}
                    error_state={error?.province_leinholder}
                    error_text={FIELD_ERROR_TEXT}
                    error={error}
                    setError={setError}
                    id={id}
                    wholevalue={wholevalue}
                    // disabled={true}
                    disabled={
                      referralFlag?.state ||
                      selectedFinancier ||
                      brokerReviewFlag ||
                      btnLoading
                    }
                    wholeError={wholeError}
                  />
                  <TextField
                    label={"Postal Code"}
                    value={value?.pc_leinholder}
                    name={"pc_leinholder"}
                    setValue={setValue}
                    object={value}
                    error_state={error?.pc_leinholder}
                    // error_text={FIELD_ERROR_TEXT}
                    error={error}
                    setError={setError}
                    id={id}
                    wholevalue={wholevalue}
                    // disabled={true}
                    disabled={
                      referralFlag?.state ||
                      selectedFinancier ||
                      brokerReviewFlag ||
                      btnLoading
                    }
                    wholeError={wholeError}
                    error_text={
                      value?.pc_leinholder === ""
                        ? FIELD_ERROR_TEXT
                        : !canadaPostalRegex.test(value.pc_leinholder)
                        ? "Please enter valid postal code"
                        : ""
                    }
                  />
                  <TextField
                    label={"Country"}
                    value={value?.country_leinholder}
                    name={"country_leinholder"}
                    setValue={setValue}
                    object={value}
                    error_state={error?.country_leinholder}
                    error_text={FIELD_ERROR_TEXT}
                    error={error}
                    setError={setError}
                    id={id}
                    wholevalue={wholevalue}
                    // disabled={true}
                    disabled={
                      referralFlag?.state ||
                      selectedFinancier ||
                      brokerReviewFlag ||
                      btnLoading
                    }
                    wholeError={wholeError}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Addons;
