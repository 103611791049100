import { QuestionCircleTwoTone } from "@ant-design/icons";
import { Button, ConfigProvider, Input, Modal, Tooltip, message } from "antd";
import React, { useState } from "react";
import { PRIMARY_COLOR } from "../utils";
import dayjs from "dayjs";
import { APIURL, ORGANIZATION_ID } from "../api";
import axios from "axios";

export const Footer = ({
  brokerReviewFlag,
  setBrokerReviewFlag,
  type,
  addBoatCount,
  boatFormValues,
  coverageFormValues,
  eligiblityFormValues,
  boatSpecsFormValues,
  addonsFormValues,
  globalData,
  addTrailerCount,
  trailerFormValues,
  additionalInfoFormValues,
  trailerSpecsFormValues,
  getStartedFormValues,
  referralFlag,
  formNumber,
  policyDetailFormValues,
  signatureObj,
  boatNames,
  trailerNames,
}) => {
  const [later, setLater] = useState(false);
  const [brokerReview, setBrokerReview] = useState(false);

  const [laterLoading, setLaterLoading] = useState(false);
  const [brokerLoading, setBrokerLoading] = useState(false);

  const [comments, setComments] = useState("");

  const continueLater = async () => {
    setLaterLoading(() => true);

    let body_data = {
      phone: getStartedFormValues[0]?.phone,
      dob: getStartedFormValues[0]?.date_of_birth
        ? dayjs(getStartedFormValues[0]?.date_of_birth).format("DD/MM/YYYY")
        : undefined,
      where_you_find_us: getStartedFormValues[0]?.find_us,
      friend_option_name:
        getStartedFormValues[0]?.find_us === "Friend or Family"
          ? getStartedFormValues[0]?.find_us_value
          : undefined,
      dealership_option_name:
        getStartedFormValues[0]?.find_us === "Dealership"
          ? getStartedFormValues[0]?.find_us_value
          : undefined,
      campground_option_name:
        getStartedFormValues[0]?.find_us === "Campground"
          ? getStartedFormValues[0]?.find_us_value
          : undefined,
      marina_option_name:
        getStartedFormValues[0]?.find_us === "Marina"
          ? getStartedFormValues[0]?.find_us_value
          : undefined,
      others_option_name:
        getStartedFormValues[0]?.find_us === "Other"
          ? getStartedFormValues[0]?.find_us_value
          : undefined,
      mailing_address_line1: policyDetailFormValues[0]?.mailing_address,
      mailing_address_line2: policyDetailFormValues[0]?.mailing_suite,
      mailing_city: policyDetailFormValues[0]?.mailing_city,
      mailing_province: policyDetailFormValues[0]?.mailing_province,
      mailing_postalcode: policyDetailFormValues[0]?.mailing_postal_code,
      mailing_country: policyDetailFormValues[0]?.mailing_country,
    };

    await axios
      .post(`${APIURL}/customer/update_customer`, {
        customer_id: globalData.customer_id,
        customer_updates: body_data,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    if (type === "Boat") {
      let boat_data_obj = [];

      for (let i = 0; i < addBoatCount.length; i++) {
        boat_data_obj.push({});
      }

      for (let i = 0; i < addBoatCount.length; i++) {
        boat_data_obj[i].type = boatFormValues[i]?.type_of_watercraft;
        boat_data_obj[i].year = boatFormValues[i]?.boat_model_year;
        boat_data_obj[i].manufacturer = boatFormValues[i].boat_manufacturer;
        boat_data_obj[i].model = boatFormValues[i].boat_model;
        boat_data_obj[i].replacement_cost = boatFormValues[i].replacement_cost;
        boat_data_obj[i].actual_cost_value =
          boatFormValues[i].actual_cash_value;
        boat_data_obj[i].is_main_operator_same_as_owner =
          boatFormValues[i].is_operator_same_as_owner === "Yes"
            ? true
            : boatFormValues[i].is_operator_same_as_owner === "No"
            ? false
            : undefined;
        boat_data_obj[i].main_operator_first_name =
          boatFormValues[i].org_first_name;
        boat_data_obj[i].main_operator_last_name =
          boatFormValues[i].org_last_name;
        boat_data_obj[i].main_operator_dob = boatFormValues[i].date_of_birth
          ? dayjs(boatFormValues[i].date_of_birth).format("DD/MM/YYYY")
          : undefined;
        boat_data_obj[i].main_operator_boating_experience =
          boatFormValues[i].boating_experience;
        boat_data_obj[i].main_operator_hold_pcoc_card =
          boatFormValues[i].operator_hold_pcoc === "Yes"
            ? true
            : boatFormValues[i].operator_hold_pcoc === "No"
            ? false
            : null;
        boat_data_obj[i].deductible =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].deductible;
        boat_data_obj[i].personal_effects_coverage =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].personal_effects_coverage;
        boat_data_obj[i].navigational_equipment_coverage =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].navigational_equipment_coverage;
        boat_data_obj[i].premium_per_year =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].premium_per_year;
        boat_data_obj[i].coverage =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].coverage;
        boat_data_obj[i].coverage_name =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].coverage_name;
        boat_data_obj[i].liability_coverage =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].liability_coverage;
        boat_data_obj[i].salvage =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].salvage;
        boat_data_obj[i].pollution =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].pollution;
        boat_data_obj[i].removal_of_wreckage =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].removal_of_wreckage;
        boat_data_obj[i].emergency_towing_limit =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].emergency_towing_limit;
        boat_data_obj[i].uninsured_underinsured_boater_endorsement =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].uninsured_underinsured_boater_endorsement;
        boat_data_obj[i].loss_of_use_limit =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].loss_of_use_limit;
        boat_data_obj[i].will_boat_be_used_in_atlantic_pacific_questions =
          eligiblityFormValues[i].watercraft_used_in_atlantic === "Yes"
            ? true
            : eligiblityFormValues[i].watercraft_used_in_atlantic === "No"
            ? false
            : undefined;
        boat_data_obj[
          i
        ].is_boat_rented_or_carry_passengers_or_existing_damages_questions =
          eligiblityFormValues[i].first_apply_to_watercraft === "Yes"
            ? true
            : eligiblityFormValues[i].first_apply_to_watercraft === "No"
            ? false
            : undefined;
        boat_data_obj[i].is_boat_layered_up_and_safety_questions =
          eligiblityFormValues[i].second_apply_to_watercraft === "Yes"
            ? true
            : eligiblityFormValues[i].second_apply_to_watercraft === "No"
            ? false
            : undefined;
        boat_data_obj[i].is_owner_employees_or_lives_on =
          eligiblityFormValues[i].apply_to_applicant === "Yes"
            ? true
            : eligiblityFormValues[i].apply_to_applicant === "No"
            ? false
            : undefined;
        boat_data_obj[i].operator_have_claim_in_last_5_years =
          eligiblityFormValues[i].claims_in_last_five_years === "Yes"
            ? true
            : eligiblityFormValues[i].claims_in_last_five_years === "No"
            ? false
            : undefined;
        boat_data_obj[i].how_many_claims =
          eligiblityFormValues[i].no_of_claims_in_last_five_years;
        boat_data_obj[i].operator_have_motor_convictions_in_last_3_years =
          eligiblityFormValues[i].claims_in_last_three_years === "Yes"
            ? true
            : eligiblityFormValues[i].claims_in_last_three_years === "No"
            ? false
            : undefined;
        boat_data_obj[i].how_many_convictions =
          eligiblityFormValues[i].no_of_claims_in_last_three_years;
        boat_data_obj[i].operator_had_suspended_driver_license =
          eligiblityFormValues[i].driver_license_suspended === "Yes"
            ? true
            : eligiblityFormValues[i].driver_license_suspended === "No"
            ? false
            : undefined;
        boat_data_obj[i].when_suspended =
          eligiblityFormValues[i].when_license_suspended;
        boat_data_obj[i].length_in_feet = boatSpecsFormValues[i].boat_length;
        boat_data_obj[i].hull_serial_id = boatSpecsFormValues[i].hull_id;
        boat_data_obj[i].hull_type = boatSpecsFormValues[i].hull_type;
        boat_data_obj[i].hull_type_for_others =
          boatSpecsFormValues[i].other_hull_type;
        boat_data_obj[i].max_est_speed = boatSpecsFormValues[i].est_max_speed;
        boat_data_obj[i].engine1_type =
          boatSpecsFormValues[i].main1_engine_type;
        boat_data_obj[i].engine1_others_type =
          boatSpecsFormValues[i].main1_other_engine_type;
        boat_data_obj[i].engine1_fuel_type =
          boatSpecsFormValues[i].main1_fuel_type;
        boat_data_obj[i].engine1_model_year =
          boatSpecsFormValues[i].main1_boat_engine_model_year;
        boat_data_obj[i].engine1_manufacturer =
          boatSpecsFormValues[i].main1_boat_engine_manufacturer;
        boat_data_obj[i].engine1_serial_number =
          boatSpecsFormValues[i].main1_boat_engine_serail;
        boat_data_obj[i].engine1_horse_power =
          boatSpecsFormValues[i].main1_boat_engine_horsepower;
        boat_data_obj[i].engine2_type =
          boatSpecsFormValues[i].main2_engine_type;
        boat_data_obj[i].engine2_others_type =
          boatSpecsFormValues[i].main2_other_engine_type;
        boat_data_obj[i].engine2_fuel_type =
          boatSpecsFormValues[i].main2_fuel_type;
        boat_data_obj[i].engine2_model_year =
          boatSpecsFormValues[i].main2_boat_engine_model_year;
        boat_data_obj[i].engine2_manufacturer =
          boatSpecsFormValues[i].main2_boat_engine_manufacturer;
        boat_data_obj[i].engine2_serial_number =
          boatSpecsFormValues[i].main2_boat_engine_serail;
        boat_data_obj[i].engine2_horse_power =
          boatSpecsFormValues[i].main2_boat_engine_horsepower;
        boat_data_obj[i].add_a_trailer =
          addonsFormValues[i].add_trailer === "Yes"
            ? true
            : addonsFormValues[i].add_trailer === "No"
            ? false
            : undefined;
        boat_data_obj[i].trailer_value = addonsFormValues[i].trailer_value;
        boat_data_obj[i].trailer_model_year =
          addonsFormValues[i].trailer_model_year;
        boat_data_obj[i].trailer_manufacturer =
          addonsFormValues[i].trailer_manufacturer;
        boat_data_obj[i].trailer_vin = addonsFormValues[i].trailer_serial;
        boat_data_obj[i].trailer_length = addonsFormValues[i].trailer_length;
        boat_data_obj[i].add_a_tender =
          addonsFormValues[i].add_tender === "Yes"
            ? true
            : addonsFormValues[i].add_tender === "No"
            ? false
            : undefined;
        boat_data_obj[i].tender_value = addonsFormValues[i].tender_value;
        boat_data_obj[i].tender_model_year =
          addonsFormValues[i].tender_model_year;
        boat_data_obj[i].tender_manufacturer =
          addonsFormValues[i].tender_manufacturer;
        boat_data_obj[i].tender_vin = addonsFormValues[i].tender_serial;
        boat_data_obj[i].tender_length = addonsFormValues[i].tender_length;
        boat_data_obj[i].add_auxiliary_motor =
          addonsFormValues[i].add_auxillary_motor === "Yes"
            ? true
            : addonsFormValues[i].add_auxillary_motor === "No"
            ? false
            : undefined;
        boat_data_obj[i].aux_value = addonsFormValues[i].aux_engine_value;
        boat_data_obj[i].aux_model_year =
          addonsFormValues[i].aux_engine_model_year;
        boat_data_obj[i].aux_manufacturer =
          addonsFormValues[i].aux_engine_manufacturer;
        boat_data_obj[i].aux_vin = addonsFormValues[i].aux_engine_serial;
        boat_data_obj[i].aux_length = addonsFormValues[i].aux_engine_hp;
        boat_data_obj[i].is_financed =
          addonsFormValues[i].boat_financed === "Yes"
            ? true
            : addonsFormValues[i].boat_financed === "No"
            ? false
            : undefined;
        boat_data_obj[i].select_a_financier =
          addonsFormValues[i].boat_lienholder;
        boat_data_obj[i].financier_name = addonsFormValues[i].boat_lienholder;
        boat_data_obj[i].financier_address_line1 =
          addonsFormValues[i].street_leinholder;
        boat_data_obj[i].financier_address_line2 =
          addonsFormValues[i].line2_leinholder;
        boat_data_obj[i].financier_city = addonsFormValues[i].city_leinholder;
        boat_data_obj[i].financier_province =
          addonsFormValues[i].province_leinholder;
        boat_data_obj[i].financier_country =
          addonsFormValues[i].country_leinholder;
        boat_data_obj[i].financier_postal_code =
          addonsFormValues[i].pc_leinholder;
      }

      let boat_quote_updates = {
        boat_quote_record_id: globalData.quote_record_id,
        quote_status: "Saved",
        boats: boat_data_obj,
        quote_id: globalData?.quote_id,
        organization_id: ORGANIZATION_ID,
        inception_date: policyDetailFormValues[0]?.inception_date
          ? dayjs(policyDetailFormValues[0]?.inception_date).format(
              "DD/MM/YYYY"
            )
          : undefined,
        expiry_date: policyDetailFormValues[0]?.expiry_date
          ? dayjs(policyDetailFormValues[0]?.expiry_date).format("DD/MM/YYYY")
          : undefined,
        signature_accept_text: signatureObj?.text
          ? signatureObj?.text
          : undefined,
        customer_id: globalData?.customer_id,
        located_province: getStartedFormValues[0]?.province,
        any_additional_names:
          getStartedFormValues[0]?.additional_name === "Yes",
        number_of_boats: boatNames.length,
      };

      let body = {
        boat_quote_record_id: globalData.quote_record_id,
        boat_quote_updates,
      };

      console.log(boat_data_obj, body);

      await axios
        .post(`${APIURL}/boat/update_boat_quote`, body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          setLaterLoading(() => false);
        });
    }

    if (type === "Trailer") {
      let trailer_data_obj = [];

      for (let i = 0; i < addTrailerCount.length; i++) {
        trailer_data_obj.push({});
      }

      for (let i = 0; i < addTrailerCount.length; i++) {
        trailer_data_obj[i].type = trailerFormValues[i].type_of_trailer;
        trailer_data_obj[i].year = trailerFormValues[i].trailer_model_year;
        trailer_data_obj[i].original_owner =
          trailerFormValues[i].are_you_org_owner === "Yes"
            ? true
            : trailerFormValues[i].are_you_org_owner === "No"
            ? false
            : undefined;
        trailer_data_obj[i].replacement_cost =
          trailerFormValues[i].replacement_cost;
        trailer_data_obj[i].actual_cost_value =
          trailerFormValues[i].actual_cash_value;
        trailer_data_obj[i].parked_within_500feet_of_waterbody =
          trailerFormValues[i].is_trailer_parked_within_500ft === "Yes"
            ? true
            : trailerFormValues[i].is_trailer_parked_within_500ft === "No"
            ? false
            : undefined;
        trailer_data_obj[i].permanently_or_seasonally_parked =
          trailerFormValues[i].is_trailer_parked === "Yes"
            ? true
            : trailerFormValues[i].is_trailer_parked === "No"
            ? false
            : undefined;
        trailer_data_obj[i].stored_location_while_not_in_use =
          trailerFormValues[i].trailer_stored;
        trailer_data_obj[i].taken_to_us_gt_180_days =
          trailerFormValues[i].trailer_taken_into_usa === "Yes"
            ? true
            : trailerFormValues[i].trailer_taken_into_usa === "No"
            ? false
            : undefined;
        trailer_data_obj[i].how_long_owned_trailer =
          trailerFormValues[i].how_long_owned;
        trailer_data_obj[i].removed_in_off_season =
          trailerFormValues[i].is_trailer_removed_from_park === "Yes"
            ? true
            : trailerFormValues[i].is_trailer_removed_from_park === "No"
            ? false
            : undefined;
        trailer_data_obj[i].location_moved_to_in_off_season =
          trailerFormValues[i].trailer_removed_address;
        trailer_data_obj[i].have_deck =
          trailerFormValues[i].have_deck === "Yes"
            ? true
            : trailerFormValues[i].have_deck === "No"
            ? false
            : undefined;
        trailer_data_obj[i].have_screened_or_florida_room =
          trailerFormValues[i].have_screened_in_room === "Yes"
            ? true
            : trailerFormValues[i].have_screened_in_room === "No"
            ? false
            : undefined;
        trailer_data_obj[i].have_hard_awning =
          trailerFormValues[i].have_hard_awning === "Yes"
            ? true
            : trailerFormValues[i].have_hard_awning === "No"
            ? false
            : undefined;
        trailer_data_obj[i].like_to_insure_golf_cart =
          trailerFormValues[i].insure_golf_cart === "Yes"
            ? true
            : trailerFormValues[i].insure_golf_cart === "No"
            ? false
            : undefined;
        trailer_data_obj[i].number_of_golf_cart =
          trailerFormValues[i].no_of_golf_carts;
        trailer_data_obj[i].golf_cart_value1 = trailerFormValues[i].golf_cart_1;
        trailer_data_obj[i].golf_cart_value2 = trailerFormValues[i].golf_cart_2;
        trailer_data_obj[i].park_name_or_location =
          trailerFormValues[i].park_name;
        trailer_data_obj[i].park_address = trailerFormValues[i].parking_address;
        trailer_data_obj[i].park_city = trailerFormValues[i].parking_city;
        trailer_data_obj[i].park_province =
          trailerFormValues[i].parking_province;
        trailer_data_obj[i].park_postalcode =
          trailerFormValues[i].parking_postal_code;
        trailer_data_obj[i].park_site_number =
          trailerFormValues[i].parking_site_number;

        trailer_data_obj[i].coverage_name = coverageFormValues[i].coverage_name;
        trailer_data_obj[i].premium_per_year =
          coverageFormValues[i].premium_per_year;
        trailer_data_obj[i].coverage = coverageFormValues[i].coverage;
        trailer_data_obj[i].coverage_type = coverageFormValues[i].coverage_type;
        trailer_data_obj[i].detached_private_structure_coverage =
          coverageFormValues[i].detached_private_structure_coverage;
        trailer_data_obj[i].personal_property_coverage =
          coverageFormValues[i].personal_property_coverage;
        trailer_data_obj[i].permises_liability =
          coverageFormValues[i].permises_liability;
        trailer_data_obj[i].deductible = coverageFormValues[i].deductible;
        trailer_data_obj[i].overland_water_protection =
          coverageFormValues[i].overland_water_protection;

        trailer_data_obj[i].is_principal_residence =
          additionalInfoFormValues[i].live_in_trailer_full_time === "Yes"
            ? true
            : additionalInfoFormValues[i].live_in_trailer_full_time === "No"
            ? false
            : undefined;
        trailer_data_obj[i].have_rental_or_business_use =
          additionalInfoFormValues[i].is_unit_strictly === "Yes"
            ? true
            : additionalInfoFormValues[i].is_unit_strictly === "No"
            ? false
            : undefined;
        trailer_data_obj[i].have_heating_that_was_not_factory_installed =
          additionalInfoFormValues[i].is_any_heating === "Yes"
            ? true
            : additionalInfoFormValues[i].is_any_heating === "No"
            ? false
            : undefined;
        trailer_data_obj[i].non_factory_heating_type =
          additionalInfoFormValues[i].heating_type;
        trailer_data_obj[i].is_dual_purpose =
          additionalInfoFormValues[i].is_dual_purpose_trailer === "Yes"
            ? true
            : additionalInfoFormValues[i].is_dual_purpose_trailer === "No"
            ? false
            : undefined;
        trailer_data_obj[i].purpose_type =
          additionalInfoFormValues[i].hauler_selection;
        trailer_data_obj[i].items_of_purpose =
          additionalInfoFormValues[i].hauler_selection === "Toy Hauler"
            ? Object.entries(additionalInfoFormValues[i].toy_hauler_dropdown)
                .filter(([key, value]) => value === true)
                .map(([key]) => key)
            : undefined;
        trailer_data_obj[i].have_modifications =
          additionalInfoFormValues[i].any_mod_to_trailer === "Yes"
            ? true
            : additionalInfoFormValues[i].any_mod_to_trailer === "No"
            ? false
            : undefined;
        trailer_data_obj[i].modification_type =
          additionalInfoFormValues[i].mod_options;
        trailer_data_obj[i].is_solar_panels_factory_or_dealer_installed =
          additionalInfoFormValues[i].solar_panels_installed === "Yes"
            ? true
            : additionalInfoFormValues[i].solar_panels_installed === "No"
            ? false
            : undefined;
        trailer_data_obj[i].not_cover_aftermarket_solarpanels_installation =
          additionalInfoFormValues[i].use_of_solar_on_trailer === "Yes"
            ? true
            : additionalInfoFormValues[i].use_of_solar_on_trailer === "No"
            ? false
            : undefined;
        trailer_data_obj[i].interior_upgrades_comments =
          additionalInfoFormValues[i].interior_upgrades;
        trailer_data_obj[i].any_pre_existing_damage =
          additionalInfoFormValues[i].is_pre_existing_damage === "Yes"
            ? true
            : additionalInfoFormValues[i].is_pre_existing_damage === "No"
            ? false
            : undefined;
        trailer_data_obj[i].describe_damage =
          additionalInfoFormValues[i].damage_description;
        trailer_data_obj[i].is_motorized =
          additionalInfoFormValues[i].is_trailer_motorized === "Yes"
            ? true
            : additionalInfoFormValues[i].is_trailer_motorized === "No"
            ? false
            : undefined;
        trailer_data_obj[i].manufacturer =
          trailerSpecsFormValues[i].trailer_manufacturer;
        trailer_data_obj[i].model = trailerSpecsFormValues[i].trailer_model;
        trailer_data_obj[i].length_in_feet =
          trailerSpecsFormValues[i].trailer_length;
        trailer_data_obj[i].width_in_feet =
          trailerSpecsFormValues[i].trailer_width;
        trailer_data_obj[i].VIN = trailerSpecsFormValues[i].vin;
        trailer_data_obj[i].deck_length_ft =
          trailerSpecsFormValues[i].deck_length;
        trailer_data_obj[i].deck_width_ft =
          trailerSpecsFormValues[i].deck_width;
        trailer_data_obj[i].screened_or_florida_room_length_ft =
          trailerSpecsFormValues[i].florida_room_length;
        trailer_data_obj[i].screened_or_florida_room_width_ft =
          trailerSpecsFormValues[i].florida_room_width;
        trailer_data_obj[i].hard_awning_length =
          trailerSpecsFormValues[i].awning_length;
        trailer_data_obj[i].hard_awning_width =
          trailerSpecsFormValues[i].awning_width;
        trailer_data_obj[i].is_financed =
          additionalInfoFormValues[i].trailer_financed === "Yes"
            ? true
            : additionalInfoFormValues[i].trailer_financed === "No"
            ? false
            : undefined;
        trailer_data_obj[i].select_a_financier =
          additionalInfoFormValues[i].trailer_lienholder;
        trailer_data_obj[i].financier_name =
          additionalInfoFormValues[i].trailer_lienholder;

        trailer_data_obj[i].financier_address_line1 =
          additionalInfoFormValues[i].street_leinholder;
        trailer_data_obj[i].financier_address_line2 =
          additionalInfoFormValues[i].line2_leinholder;
        trailer_data_obj[i].financier_city =
          additionalInfoFormValues[i].city_leinholder;
        trailer_data_obj[i].financier_province =
          additionalInfoFormValues[i].province_leinholder;
        trailer_data_obj[i].financier_postalcode =
          additionalInfoFormValues[i].pc_leinholder;
        trailer_data_obj[i].financier_country =
          additionalInfoFormValues[i].country_leinholder;
      }

      let trailer_quote_updates = {
        trailer_quote_record_id: globalData?.quote_record_id,
        quote_status: "Saved",
        trailers: trailer_data_obj,
        organization_id: ORGANIZATION_ID,
        inception_date: policyDetailFormValues[0]?.inception_date
          ? dayjs(policyDetailFormValues[0]?.inception_date).format(
              "DD/MM/YYYY"
            )
          : undefined,
        expiry_date: policyDetailFormValues[0]?.expiry_date
          ? dayjs(policyDetailFormValues[0]?.expiry_date).format("DD/MM/YYYY")
          : undefined,
        signature_accept_text: signatureObj?.text
          ? signatureObj?.text
          : undefined,
        located_province: getStartedFormValues[0]?.province,
        any_additional_names:
          getStartedFormValues[0]?.additional_name === "Yes",
        number_of_trailers: trailerNames.length,
      };

      let body = {
        trailer_quote_record_id: globalData.quote_record_id,
        trailer_quote_updates,
      };

      console.log(trailer_data_obj);

      await axios
        .post(`${APIURL}/trailer/update_trailer_quote`, body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          setLaterLoading(() => false);
        });
    }

    let return_to_quote =
      type === "Boat"
        ? `${window.location.origin}/#boat?quote_record_id=${globalData?.quote_record_id}`
        : type === "Trailer"
        ? `${window.location.origin}/#trailer?quote_record_id=${globalData?.quote_record_id}`
        : "";

    let email_body = {
      return_to_quote,
      quote_id: globalData?.quote_id,
      first_name: getStartedFormValues[0]?.insured_first_name,
      email: getStartedFormValues[0]?.email,
      organization_id: ORGANIZATION_ID,
    };

    await axios
      .post(
        `${APIURL}/customer/send_save_and_continue_email_to_customer`,
        email_body
      )
      .then((res) => {
        console.log(res);
        message.success("Email sent successfully!!");
      })
      .catch((err) => {
        console.log(err);
        setLaterLoading(() => false);
        message.error("Error occured");
      });

    // setLater(false);
    setLaterLoading(() => false);
  };

  const sendToBroker = async () => {
    setBrokerLoading(() => true);

    let body_data = {
      phone: getStartedFormValues[0]?.phone,
      dob: getStartedFormValues[0]?.date_of_birth
        ? dayjs(getStartedFormValues[0]?.date_of_birth).format("DD/MM/YYYY")
        : undefined,
      where_you_find_us: getStartedFormValues[0]?.find_us,
      friend_option_name:
        getStartedFormValues[0]?.find_us === "Friend or Family"
          ? getStartedFormValues[0]?.find_us_value
          : undefined,
      dealership_option_name:
        getStartedFormValues[0]?.find_us === "Dealership"
          ? getStartedFormValues[0]?.find_us_value
          : undefined,
      campground_option_name:
        getStartedFormValues[0]?.find_us === "Campground"
          ? getStartedFormValues[0]?.find_us_value
          : undefined,
      marina_option_name:
        getStartedFormValues[0]?.find_us === "Marina"
          ? getStartedFormValues[0]?.find_us_value
          : undefined,
      others_option_name:
        getStartedFormValues[0]?.find_us === "Other"
          ? getStartedFormValues[0]?.find_us_value
          : undefined,
      mailing_address_line1: policyDetailFormValues[0]?.mailing_address,
      mailing_address_line2: policyDetailFormValues[0]?.mailing_suite,
      mailing_city: policyDetailFormValues[0]?.mailing_city,
      mailing_province: policyDetailFormValues[0]?.mailing_province,
      mailing_postalcode: policyDetailFormValues[0]?.mailing_postal_code,
      mailing_country: policyDetailFormValues[0]?.mailing_country,
    };

    await axios
      .post(`${APIURL}/customer/update_customer`, {
        customer_id: globalData.customer_id,
        customer_updates: body_data,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    if (type === "Boat") {
      let boat_data_obj = [];

      for (let i = 0; i < addBoatCount.length; i++) {
        boat_data_obj.push({});
      }

      for (let i = 0; i < addBoatCount.length; i++) {
        boat_data_obj[i].type = boatFormValues[i]?.type_of_watercraft;
        boat_data_obj[i].year = boatFormValues[i]?.boat_model_year;
        boat_data_obj[i].manufacturer = boatFormValues[i].boat_manufacturer;
        boat_data_obj[i].model = boatFormValues[i].boat_model;
        boat_data_obj[i].replacement_cost = boatFormValues[i].replacement_cost;
        boat_data_obj[i].actual_cost_value =
          boatFormValues[i].actual_cash_value;
        boat_data_obj[i].is_main_operator_same_as_owner =
          boatFormValues[i].is_operator_same_as_owner === "Yes"
            ? true
            : boatFormValues[i].is_operator_same_as_owner === "No"
            ? false
            : undefined;
        boat_data_obj[i].main_operator_first_name =
          boatFormValues[i].org_first_name;
        boat_data_obj[i].main_operator_last_name =
          boatFormValues[i].org_last_name;
        boat_data_obj[i].main_operator_dob = boatFormValues[i].date_of_birth
          ? dayjs(boatFormValues[i].date_of_birth).format("DD/MM/YYYY")
          : undefined;
        boat_data_obj[i].main_operator_boating_experience =
          boatFormValues[i].boating_experience;
        boat_data_obj[i].main_operator_hold_pcoc_card =
          boatFormValues[i].operator_hold_pcoc === "Yes"
            ? true
            : boatFormValues[i].operator_hold_pcoc === "No"
            ? false
            : null;
        boat_data_obj[i].deductible =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].deductible;
        boat_data_obj[i].personal_effects_coverage =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].personal_effects_coverage;
        boat_data_obj[i].navigational_equipment_coverage =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].navigational_equipment_coverage;
        boat_data_obj[i].premium_per_year =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].premium_per_year;
        boat_data_obj[i].coverage =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].coverage;
        boat_data_obj[i].coverage_name =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].coverage_name;
        boat_data_obj[i].liability_coverage =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].liability_coverage;
        boat_data_obj[i].salvage =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].salvage;
        boat_data_obj[i].pollution =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].pollution;
        boat_data_obj[i].removal_of_wreckage =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].removal_of_wreckage;
        boat_data_obj[i].emergency_towing_limit =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].emergency_towing_limit;
        boat_data_obj[i].uninsured_underinsured_boater_endorsement =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].uninsured_underinsured_boater_endorsement;
        boat_data_obj[i].loss_of_use_limit =
          boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
            ? null
            : coverageFormValues[i].loss_of_use_limit;
        boat_data_obj[i].will_boat_be_used_in_atlantic_pacific_questions =
          eligiblityFormValues[i].watercraft_used_in_atlantic === "Yes"
            ? true
            : eligiblityFormValues[i].watercraft_used_in_atlantic === "No"
            ? false
            : undefined;
        boat_data_obj[
          i
        ].is_boat_rented_or_carry_passengers_or_existing_damages_questions =
          eligiblityFormValues[i].first_apply_to_watercraft === "Yes"
            ? true
            : eligiblityFormValues[i].first_apply_to_watercraft === "No"
            ? false
            : undefined;
        boat_data_obj[i].is_boat_layered_up_and_safety_questions =
          eligiblityFormValues[i].second_apply_to_watercraft === "Yes"
            ? true
            : eligiblityFormValues[i].second_apply_to_watercraft === "No"
            ? false
            : undefined;
        boat_data_obj[i].is_owner_employees_or_lives_on =
          eligiblityFormValues[i].apply_to_applicant === "Yes"
            ? true
            : eligiblityFormValues[i].apply_to_applicant === "No"
            ? false
            : undefined;
        boat_data_obj[i].operator_have_claim_in_last_5_years =
          eligiblityFormValues[i].claims_in_last_five_years === "Yes"
            ? true
            : eligiblityFormValues[i].claims_in_last_five_years === "No"
            ? false
            : undefined;
        boat_data_obj[i].how_many_claims =
          eligiblityFormValues[i].no_of_claims_in_last_five_years;
        boat_data_obj[i].operator_have_motor_convictions_in_last_3_years =
          eligiblityFormValues[i].claims_in_last_three_years === "Yes"
            ? true
            : eligiblityFormValues[i].claims_in_last_three_years === "No"
            ? false
            : undefined;
        boat_data_obj[i].how_many_convictions =
          eligiblityFormValues[i].no_of_claims_in_last_three_years;
        boat_data_obj[i].operator_had_suspended_driver_license =
          eligiblityFormValues[i].driver_license_suspended === "Yes"
            ? true
            : eligiblityFormValues[i].driver_license_suspended === "No"
            ? false
            : undefined;
        boat_data_obj[i].when_suspended =
          eligiblityFormValues[i].when_license_suspended;
        boat_data_obj[i].length_in_feet = boatSpecsFormValues[i].boat_length;
        boat_data_obj[i].hull_serial_id = boatSpecsFormValues[i].hull_id;
        boat_data_obj[i].hull_type = boatSpecsFormValues[i].hull_type;
        boat_data_obj[i].hull_type_for_others =
          boatSpecsFormValues[i].other_hull_type;
        boat_data_obj[i].max_est_speed = boatSpecsFormValues[i].est_max_speed;
        boat_data_obj[i].engine1_type =
          boatSpecsFormValues[i].main1_engine_type;
        boat_data_obj[i].engine1_others_type =
          boatSpecsFormValues[i].main1_other_engine_type;
        boat_data_obj[i].engine1_fuel_type =
          boatSpecsFormValues[i].main1_fuel_type;
        boat_data_obj[i].engine1_model_year =
          boatSpecsFormValues[i].main1_boat_engine_model_year;
        boat_data_obj[i].engine1_manufacturer =
          boatSpecsFormValues[i].main1_boat_engine_manufacturer;
        boat_data_obj[i].engine1_serial_number =
          boatSpecsFormValues[i].main1_boat_engine_serail;
        boat_data_obj[i].engine1_horse_power =
          boatSpecsFormValues[i].main1_boat_engine_horsepower;
        boat_data_obj[i].engine2_type =
          boatSpecsFormValues[i].main2_engine_type;
        boat_data_obj[i].engine2_others_type =
          boatSpecsFormValues[i].main2_other_engine_type;
        boat_data_obj[i].engine2_fuel_type =
          boatSpecsFormValues[i].main2_fuel_type;
        boat_data_obj[i].engine2_model_year =
          boatSpecsFormValues[i].main2_boat_engine_model_year;
        boat_data_obj[i].engine2_manufacturer =
          boatSpecsFormValues[i].main2_boat_engine_manufacturer;
        boat_data_obj[i].engine2_serial_number =
          boatSpecsFormValues[i].main2_boat_engine_serail;
        boat_data_obj[i].engine2_horse_power =
          boatSpecsFormValues[i].main2_boat_engine_horsepower;
        boat_data_obj[i].add_a_trailer =
          addonsFormValues[i].add_trailer === "Yes"
            ? true
            : addonsFormValues[i].add_trailer === "No"
            ? false
            : undefined;
        boat_data_obj[i].trailer_value = addonsFormValues[i].trailer_value;
        boat_data_obj[i].trailer_model_year =
          addonsFormValues[i].trailer_model_year;
        boat_data_obj[i].trailer_manufacturer =
          addonsFormValues[i].trailer_manufacturer;
        boat_data_obj[i].trailer_vin = addonsFormValues[i].trailer_serial;
        boat_data_obj[i].trailer_length = addonsFormValues[i].trailer_length;
        boat_data_obj[i].add_a_tender =
          addonsFormValues[i].add_tender === "Yes"
            ? true
            : addonsFormValues[i].add_tender === "No"
            ? false
            : undefined;
        boat_data_obj[i].tender_value = addonsFormValues[i].tender_value;
        boat_data_obj[i].tender_model_year =
          addonsFormValues[i].tender_model_year;
        boat_data_obj[i].tender_manufacturer =
          addonsFormValues[i].tender_manufacturer;
        boat_data_obj[i].tender_vin = addonsFormValues[i].tender_serial;
        boat_data_obj[i].tender_length = addonsFormValues[i].tender_length;
        boat_data_obj[i].add_auxiliary_motor =
          addonsFormValues[i].add_auxillary_motor === "Yes"
            ? true
            : addonsFormValues[i].add_auxillary_motor === "No"
            ? false
            : undefined;
        boat_data_obj[i].aux_value = addonsFormValues[i].aux_engine_value;
        boat_data_obj[i].aux_model_year =
          addonsFormValues[i].aux_engine_model_year;
        boat_data_obj[i].aux_manufacturer =
          addonsFormValues[i].aux_engine_manufacturer;
        boat_data_obj[i].aux_vin = addonsFormValues[i].aux_engine_serial;
        boat_data_obj[i].aux_length = addonsFormValues[i].aux_engine_hp;
        boat_data_obj[i].is_financed =
          addonsFormValues[i].boat_financed === "Yes"
            ? true
            : addonsFormValues[i].boat_financed === "No"
            ? false
            : undefined;
        boat_data_obj[i].select_a_financier =
          addonsFormValues[i].boat_lienholder;
        boat_data_obj[i].financier_name = addonsFormValues[i].boat_lienholder;
        boat_data_obj[i].financier_address_line1 =
          addonsFormValues[i].street_leinholder;
        boat_data_obj[i].financier_address_line2 =
          addonsFormValues[i].line2_leinholder;
        boat_data_obj[i].financier_city = addonsFormValues[i].city_leinholder;
        boat_data_obj[i].financier_province =
          addonsFormValues[i].province_leinholder;
        boat_data_obj[i].financier_country =
          addonsFormValues[i].country_leinholder;
        boat_data_obj[i].financier_postal_code =
          addonsFormValues[i].pc_leinholder;
      }

      let boat_quote_updates = {
        boat_quote_record_id: globalData.quote_record_id,
        quote_status: "Return To Broker",
        boats: boat_data_obj,
        quote_id: globalData?.quote_id,
        organization_id: ORGANIZATION_ID,
        inception_date: policyDetailFormValues[0]?.inception_date
          ? dayjs(policyDetailFormValues[0]?.inception_date).format(
              "DD/MM/YYYY"
            )
          : undefined,
        expiry_date: policyDetailFormValues[0]?.expiry_date
          ? dayjs(policyDetailFormValues[0]?.expiry_date).format("DD/MM/YYYY")
          : undefined,
        signature_accept_text: signatureObj?.text
          ? signatureObj?.text
          : undefined,
        located_province: getStartedFormValues[0]?.province,
        any_additional_names:
          getStartedFormValues[0]?.additional_name === "Yes",
        number_of_boats: boatNames.length,
      };

      let body = {
        boat_quote_record_id: globalData.quote_record_id,
        boat_quote_updates,
      };

      console.log(boat_data_obj, body);

      await axios
        .post(`${APIURL}/boat/update_boat_quote`, body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          setLaterLoading(() => false);
        });
    }

    if (type === "Trailer") {
      let trailer_data_obj = [];

      for (let i = 0; i < addTrailerCount.length; i++) {
        trailer_data_obj.push({});
      }

      for (let i = 0; i < addTrailerCount.length; i++) {
        trailer_data_obj[i].type = trailerFormValues[i].type_of_trailer;
        trailer_data_obj[i].year = trailerFormValues[i].trailer_model_year;
        trailer_data_obj[i].original_owner =
          trailerFormValues[i].are_you_org_owner === "Yes"
            ? true
            : trailerFormValues[i].are_you_org_owner === "No"
            ? false
            : undefined;
        trailer_data_obj[i].replacement_cost =
          trailerFormValues[i].replacement_cost;
        trailer_data_obj[i].actual_cost_value =
          trailerFormValues[i].actual_cash_value;
        trailer_data_obj[i].parked_within_500feet_of_waterbody =
          trailerFormValues[i].is_trailer_parked_within_500ft === "Yes"
            ? true
            : trailerFormValues[i].is_trailer_parked_within_500ft === "No"
            ? false
            : undefined;
        trailer_data_obj[i].permanently_or_seasonally_parked =
          trailerFormValues[i].is_trailer_parked === "Yes"
            ? true
            : trailerFormValues[i].is_trailer_parked === "No"
            ? false
            : undefined;
        trailer_data_obj[i].stored_location_while_not_in_use =
          trailerFormValues[i].trailer_stored;
        trailer_data_obj[i].taken_to_us_gt_180_days =
          trailerFormValues[i].trailer_taken_into_usa === "Yes"
            ? true
            : trailerFormValues[i].trailer_taken_into_usa === "No"
            ? false
            : undefined;
        trailer_data_obj[i].how_long_owned_trailer =
          trailerFormValues[i].how_long_owned;
        trailer_data_obj[i].removed_in_off_season =
          trailerFormValues[i].is_trailer_removed_from_park === "Yes"
            ? true
            : trailerFormValues[i].is_trailer_removed_from_park === "No"
            ? false
            : undefined;
        trailer_data_obj[i].location_moved_to_in_off_season =
          trailerFormValues[i].trailer_removed_address;
        trailer_data_obj[i].have_deck =
          trailerFormValues[i].have_deck === "Yes"
            ? true
            : trailerFormValues[i].have_deck === "No"
            ? false
            : undefined;
        trailer_data_obj[i].have_screened_or_florida_room =
          trailerFormValues[i].have_screened_in_room === "Yes"
            ? true
            : trailerFormValues[i].have_screened_in_room === "No"
            ? false
            : undefined;
        trailer_data_obj[i].have_hard_awning =
          trailerFormValues[i].have_hard_awning === "Yes"
            ? true
            : trailerFormValues[i].have_hard_awning === "No"
            ? false
            : undefined;
        trailer_data_obj[i].like_to_insure_golf_cart =
          trailerFormValues[i].insure_golf_cart === "Yes"
            ? true
            : trailerFormValues[i].insure_golf_cart === "No"
            ? false
            : undefined;
        trailer_data_obj[i].number_of_golf_cart =
          trailerFormValues[i].no_of_golf_carts;
        trailer_data_obj[i].golf_cart_value1 = trailerFormValues[i].golf_cart_1;
        trailer_data_obj[i].golf_cart_value2 = trailerFormValues[i].golf_cart_2;
        trailer_data_obj[i].park_name_or_location =
          trailerFormValues[i].park_name;
        trailer_data_obj[i].park_address = trailerFormValues[i].parking_address;
        trailer_data_obj[i].park_city = trailerFormValues[i].parking_city;
        trailer_data_obj[i].park_province =
          trailerFormValues[i].parking_province;
        trailer_data_obj[i].park_postalcode =
          trailerFormValues[i].parking_postal_code;
        trailer_data_obj[i].park_site_number =
          trailerFormValues[i].parking_site_number;

        trailer_data_obj[i].coverage_name = coverageFormValues[i].coverage_name;
        trailer_data_obj[i].premium_per_year =
          coverageFormValues[i].premium_per_year;
        trailer_data_obj[i].coverage = coverageFormValues[i].coverage;
        trailer_data_obj[i].coverage_type = coverageFormValues[i].coverage_type;
        trailer_data_obj[i].detached_private_structure_coverage =
          coverageFormValues[i].detached_private_structure_coverage;
        trailer_data_obj[i].personal_property_coverage =
          coverageFormValues[i].personal_property_coverage;
        trailer_data_obj[i].permises_liability =
          coverageFormValues[i].permises_liability;
        trailer_data_obj[i].deductible = coverageFormValues[i].deductible;
        trailer_data_obj[i].overland_water_protection =
          coverageFormValues[i].overland_water_protection;

        trailer_data_obj[i].is_principal_residence =
          additionalInfoFormValues[i].live_in_trailer_full_time === "Yes"
            ? true
            : additionalInfoFormValues[i].live_in_trailer_full_time === "No"
            ? false
            : undefined;
        trailer_data_obj[i].have_rental_or_business_use =
          additionalInfoFormValues[i].is_unit_strictly === "Yes"
            ? true
            : additionalInfoFormValues[i].is_unit_strictly === "No"
            ? false
            : undefined;
        trailer_data_obj[i].have_heating_that_was_not_factory_installed =
          additionalInfoFormValues[i].is_any_heating === "Yes"
            ? true
            : additionalInfoFormValues[i].is_any_heating === "No"
            ? false
            : undefined;
        trailer_data_obj[i].non_factory_heating_type =
          additionalInfoFormValues[i].heating_type;
        trailer_data_obj[i].is_dual_purpose =
          additionalInfoFormValues[i].is_dual_purpose_trailer === "Yes"
            ? true
            : additionalInfoFormValues[i].is_dual_purpose_trailer === "No"
            ? false
            : undefined;
        trailer_data_obj[i].purpose_type =
          additionalInfoFormValues[i].hauler_selection;
        trailer_data_obj[i].items_of_purpose =
          additionalInfoFormValues[i].hauler_selection === "Toy Hauler"
            ? Object.entries(additionalInfoFormValues[i].toy_hauler_dropdown)
                .filter(([key, value]) => value === true)
                .map(([key]) => key)
            : undefined;
        trailer_data_obj[i].have_modifications =
          additionalInfoFormValues[i].any_mod_to_trailer === "Yes"
            ? true
            : additionalInfoFormValues[i].any_mod_to_trailer === "No"
            ? false
            : undefined;
        trailer_data_obj[i].modification_type =
          additionalInfoFormValues[i].mod_options;
        trailer_data_obj[i].is_solar_panels_factory_or_dealer_installed =
          additionalInfoFormValues[i].solar_panels_installed === "Yes"
            ? true
            : additionalInfoFormValues[i].solar_panels_installed === "No"
            ? false
            : undefined;
        trailer_data_obj[i].not_cover_aftermarket_solarpanels_installation =
          additionalInfoFormValues[i].use_of_solar_on_trailer === "Yes"
            ? true
            : additionalInfoFormValues[i].use_of_solar_on_trailer === "No"
            ? false
            : undefined;
        trailer_data_obj[i].interior_upgrades_comments =
          additionalInfoFormValues[i].interior_upgrades;
        trailer_data_obj[i].any_pre_existing_damage =
          additionalInfoFormValues[i].is_pre_existing_damage === "Yes"
            ? true
            : additionalInfoFormValues[i].is_pre_existing_damage === "No"
            ? false
            : undefined;
        trailer_data_obj[i].describe_damage =
          additionalInfoFormValues[i].damage_description;
        trailer_data_obj[i].is_motorized =
          additionalInfoFormValues[i].is_trailer_motorized === "Yes"
            ? true
            : additionalInfoFormValues[i].is_trailer_motorized === "No"
            ? false
            : undefined;
        trailer_data_obj[i].manufacturer =
          trailerSpecsFormValues[i].trailer_manufacturer;
        trailer_data_obj[i].model = trailerSpecsFormValues[i].trailer_model;
        trailer_data_obj[i].length_in_feet =
          trailerSpecsFormValues[i].trailer_length;
        trailer_data_obj[i].width_in_feet =
          trailerSpecsFormValues[i].trailer_width;
        trailer_data_obj[i].VIN = trailerSpecsFormValues[i].vin;
        trailer_data_obj[i].deck_length_ft =
          trailerSpecsFormValues[i].deck_length;
        trailer_data_obj[i].deck_width_ft =
          trailerSpecsFormValues[i].deck_width;
        trailer_data_obj[i].screened_or_florida_room_length_ft =
          trailerSpecsFormValues[i].florida_room_length;
        trailer_data_obj[i].screened_or_florida_room_width_ft =
          trailerSpecsFormValues[i].florida_room_width;
        trailer_data_obj[i].hard_awning_length =
          trailerSpecsFormValues[i].awning_length;
        trailer_data_obj[i].hard_awning_width =
          trailerSpecsFormValues[i].awning_width;
        trailer_data_obj[i].is_financed =
          additionalInfoFormValues[i].trailer_financed === "Yes"
            ? true
            : additionalInfoFormValues[i].trailer_financed === "No"
            ? false
            : undefined;
        trailer_data_obj[i].select_a_financier =
          additionalInfoFormValues[i].trailer_lienholder;
        trailer_data_obj[i].financier_name =
          additionalInfoFormValues[i].trailer_lienholder;

        trailer_data_obj[i].financier_address_line1 =
          additionalInfoFormValues[i].street_leinholder;
        trailer_data_obj[i].financier_address_line2 =
          additionalInfoFormValues[i].line2_leinholder;
        trailer_data_obj[i].financier_city =
          additionalInfoFormValues[i].city_leinholder;
        trailer_data_obj[i].financier_province =
          additionalInfoFormValues[i].province_leinholder;
        trailer_data_obj[i].financier_postalcode =
          additionalInfoFormValues[i].pc_leinholder;
        trailer_data_obj[i].financier_country =
          additionalInfoFormValues[i].country_leinholder;
      }

      let trailer_quote_updates = {
        trailer_quote_record_id: globalData?.quote_record_id,
        quote_status: "Return To Broker",
        trailers: trailer_data_obj,
        organization_id: ORGANIZATION_ID,
        inception_date: policyDetailFormValues[0]?.inception_date
          ? dayjs(policyDetailFormValues[0]?.inception_date).format(
              "DD/MM/YYYY"
            )
          : undefined,
        expiry_date: policyDetailFormValues[0]?.expiry_date
          ? dayjs(policyDetailFormValues[0]?.expiry_date).format("DD/MM/YYYY")
          : undefined,
        signature_accept_text: signatureObj?.text
          ? signatureObj?.text
          : undefined,
        located_province: getStartedFormValues[0]?.province,
        any_additional_names:
          getStartedFormValues[0]?.additional_name === "Yes",
        number_of_trailers: trailerNames.length,
      };

      let body = {
        trailer_quote_record_id: globalData.quote_record_id,
        trailer_quote_updates,
      };

      await axios
        .post(`${APIURL}/trailer/update_trailer_quote`, body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          setLaterLoading(() => false);
        });
    }

    let return_to_quote =
      type === "Boat"
        ? `${window.location.origin}/#boat?quote_record_id=${globalData?.quote_record_id}`
        : type === "Trailer"
        ? `${window.location.origin}/#trailer?quote_record_id=${globalData?.quote_record_id}`
        : "";

    let review_customer = {
      return_to_quote,
      quote_id: globalData?.quote_id,
      first_name: getStartedFormValues[0]?.insured_first_name,
      email: getStartedFormValues[0]?.email,
      customer_comments: comments,
      organization_id: ORGANIZATION_ID,
    };

    await axios
      .post(
        `${APIURL}/customer/send_send_for_review_email_to_customer`,
        review_customer
      )
      .then((res) => {
        console.log(res);
        message.success("Email sent successfully!!");
      })
      .catch((err) => {
        console.log(err);
        message.error("Error occured");
      });

    await axios
      .post(`${APIURL}/customer/send_send_for_review_email_to_recprotect`, {
        customer_comments: comments,
        organization_id: ORGANIZATION_ID,
        quote_id: globalData?.quote_id,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    setBrokerLoading(() => false);

    // setBrokerReview(false);

    setBrokerReviewFlag(true);
  };

  console.log(formNumber);

  return (
    <div className="footer">
      <Modal
        open={later}
        footer={false}
        onCancel={() => {
          setLater(false);
        }}
        title="Save and Continue Later"
        centered
        closable={!laterLoading}
        maskClosable={false}
      >
        <div className="later_modal">
          {laterLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "20dvh",
              }}
            >
              <div className="loader"></div>
            </div>
          ) : (
            <div>
              <p>You have successfully saved your application.</p>
              <p>
                If you have any questions about your application you can always
                email us{" "}
                <strong>
                  <a
                    href="mailto:sales@recprotect.ca"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    sales@recprotect.ca
                  </a>
                </strong>{" "}
                or give us a call{" "}
                <strong>
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    href="tel:8888870881"
                  >
                    888-887-0881
                  </a>
                </strong>
              </p>
            </div>
          )}

          <div style={{ textAlign: "center" }}>
            {/* <ConfigProvider
              theme={{
                token: { colorPrimary: PRIMARY_COLOR },
              }}
            >
              <Button
                type="primary"
                onClick={continueLater}
                loading={laterLoading}
              >
                Submit
              </Button>
            </ConfigProvider> */}
          </div>
        </div>
      </Modal>{" "}
      <Modal
        open={brokerReview}
        footer={false}
        onCancel={() => {
          setBrokerReview(false);
        }}
        title="Broker Review"
        centered
        closable={!brokerLoading}
        maskClosable={false}
      >
        <div className="later_modal">
          {brokerLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "20dvh",
              }}
            >
              <div className="loader"></div>
            </div>
          ) : (
            <div>
              <p>Your application has been sent for Broker Review!</p>
              <p>We will reach out to you soon!</p>
            </div>
          )}
          {/* <ConfigProvider
            theme={{
              token: { colorPrimary: PRIMARY_COLOR },
            }}
          >
            <Input.TextArea
              placeholder="Please provide your comments"
              rows={5}
              value={comments}
              onChange={(e) => {
                setComments(e.target.value);
              }}
            />
          </ConfigProvider> */}
          {/* <div style={{ textAlign: "center", marginTop: "1rem" }}>
            <ConfigProvider
              theme={{
                token: { colorPrimary: PRIMARY_COLOR },
              }}
            >
              <Button
                type="primary"
                onClick={sendToBroker}
                loading={brokerLoading}
              >
                Submit
              </Button>
            </ConfigProvider>
          </div> */}
        </div>
      </Modal>
      {brokerReviewFlag || referralFlag?.state ? (
        ""
      ) : (
        <footer>
          <div
            onClick={() => {
              setLater(true);
              continueLater();
            }}
          >
            Save & Continue Later
          </div>
          <div>
            <p
              onClick={() => {
                setBrokerReview(true);
                sendToBroker();
              }}
            >
              {" "}
              Send to broker for Review{" "}
            </p>
            <Tooltip
              title={
                <p style={{ fontSize: ".75rem" }}>
                  If you need some additional help in determining the right
                  coverage for you, simply click
                  <strong> Send to broker for review </strong> link and we will
                  review your application and get back to you within 2 business
                  days.
                </p>
              }
            >
              <QuestionCircleTwoTone twoToneColor={"#8ed1fc"} />
            </Tooltip>{" "}
          </div>
        </footer>
      )}
    </div>
  );
};
