import React from "react";
import { FormHelperText } from "@mui/material";
import { ConfigProvider, Input } from "antd";
import {
  ERROR_HELPERTEXT_STYLES,
  PRIMARY_COLOR,
  WARNING_COLOR,
} from "../utils";
import canadaFlag from "../assets/CanadaFlag.jpg";

const numberRegex = /^[0-9]*$/;

const CustomPhoneInput = ({
  value,
  label,
  setValue,
  object,
  name,
  key,
  helperText,
  error,
  setError,
  error_state,
  error_text,
  wholeError,
  wholevalue,
  id,
  disabled,
  placeholder,
}) => {
  const handleChange = (e) => {
    if (value === undefined && !numberRegex.test(e.target.value)) {
      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: "",
          };
        }

        return m;
      });

      setValue(updated);
      return;
    }
    if (numberRegex.test(e.target.value)) {
      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.value };
        }

        return m;
      });
      setValue(updated);
    } else {
      // let updated_error = wholeError.map((m, index) => {
      //   if (index === id - 1) {
      //     return { ...m, [name]: true };
      //   }

      //   return m;
      // });
      // setError(updated_error);

      // let updated = wholevalue.map((m, index) => {
      //   if (index === id - 1) {
      //     return { ...m, [name]: "" };
      //   }

      //   return m;
      // });
      // setValue(updated);

      return;
    }

    console.log(e.target.value.length);

    if (e.target.value === "" || e.target.value.length !== 10) {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: true };
        }

        return m;
      });
      setError(updated_error);
    } else {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false };
        }

        return m;
      });
      setError(updated_error);
    }

    return;
  };

  return (
    <div
      className={`spl_input`}
      style={{
        alignItems: helperText ? "flex-start" : "center",
      }}
    >
      <label
        style={{
          marginBottom: error_state && "0.75rem",
          marginTop: helperText && ".75rem",
        }}
        htmlFor={label}
      >
        {label}
      </label>
      <div className="input_container">
        <ConfigProvider
          theme={{
            components: {
              Input: {
                activeBorderColor: PRIMARY_COLOR,
                hoverBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                errorActiveShadow: "none",
              },
            },
            token: {
              colorErrorOutline: "none",
              colorError: WARNING_COLOR,
              colorErrorBorderHover: "none",
              fontSize: 12,
            },
          }}
        >
          <Input
            size="large"
            addonBefore={
              <div
                style={{ display: "flex", alignItems: "center", gap: "2px" }}
              >
                <img
                  style={{ height: "0.75rem", opacity: "0.7" }}
                  src={canadaFlag}
                  alt=""
                />
                {/* <p style={{ fontWeight: "500" }}>+1</p> */}
              </div>
            }
            id={label}
            status={error_state && "error"}
            onChange={handleChange}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onPaste={(e) => {
              e.preventDefault();
            }}
          />
        </ConfigProvider>
        {error_state ? (
          <FormHelperText
            sx={ERROR_HELPERTEXT_STYLES}
            style={{ padding: "3px 0" }}
          >
            {error_text}
          </FormHelperText>
        ) : (
          ""
        )}
        <FormHelperText
          sx={{
            fontStyle: "italic",
            fontSize: "0.75rem",
            color: "#a0aec0",
            margin: "8px 0",
            lineHeight: "1rem",
            letterSpacing: "0.7px",
          }}
        >
          {helperText}
        </FormHelperText>
      </div>
    </div>
  );
};

export default CustomPhoneInput;
