import styled from "@emotion/styled";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
} from "@mui/material";
import React from "react";
import { ERROR_HELPERTEXT_STYLES, WARNING_COLOR } from "../utils";

const CustomRadioGroup = styled(RadioGroup)({
  "&.MuiFormGroup-root": {
    flexDirection: "row",
    gap: "10px",
    width: "100%",
    flexWrap: "nowrap",
  },

  "@media screen and (max-width: 767px)": {
    "&.MuiFormGroup-root": {
      flexWrap: "wrap",
    },
  },
});

const CustomFormControlLabel = styled(FormControlLabel)({
  "&.MuiFormControlLabel-root": {
    paddingRight: "10px",
    padding: ".3rem 0",
    borderRadius: "5px",
    marginRight: "0",
    marginLeft: "0",
    width: "100%",
    // maxWidth: "100%",
  },

  ".MuiTypography-root": {
    fontFamily: "Montserrat",
    fontSize: "15px",
  },

  //   "&.MuiFormControlLabel-root:focus-within": {
  //     color: "rgb(0, 109, 168)",
  //     border: "1px solid rgb(0, 109, 168)",
  //   },
});

const Checkbox = ({
  value,
  label,
  setValue,
  object,
  name,
  firstoption,
  secondoption,
  helperText,
  error_text,
  error_state,
  error,
  setError,
  trailerSpecsFormValues,
  setTrailerSpecsFormValues,
  errorTrailerSpecs,
  setErrorTrailerSpecs,
  getStarted,
  wholevalue,
  wholeError,
  id,
  wholeTrailerSpecs,
  wholeErrorTrailerSpecs,
  disabled,
  setErrAddInfo,
  errAddinfo,
}) => {
  const handleChangeFirst = (e) => {
    if (name === "is_trailer_parked_within_500ft") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && firstoption,
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "have_deck") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.checked && firstoption };
        }

        return m;
      });

      setValue(updated);

      let updated_error_trailer_specs = wholeErrorTrailerSpecs.map(
        (m, index) => {
          if (index === id - 1) {
            return { ...m, deck_length: undefined, deck_width: undefined };
          }

          return m;
        }
      );

      setErrorTrailerSpecs(updated_error_trailer_specs);

      return;
    }

    if (name === "have_screened_in_room") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.checked && firstoption };
        }

        return m;
      });

      setValue(updated);

      let updated_error_trailer_specs = wholeErrorTrailerSpecs.map(
        (m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              florida_room_length: undefined,
              florida_room_width: undefined,
            };
          }

          return m;
        }
      );

      setErrorTrailerSpecs(updated_error_trailer_specs);

      return;
    }

    if (name === "have_hard_awning") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.checked && firstoption };
        }

        return m;
      });

      setValue(updated);

      let updated_error_trailer_specs = wholeErrorTrailerSpecs.map(
        (m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              awning_length: undefined,
              awning_width: undefined,
            };
          }

          return m;
        }
      );

      setErrorTrailerSpecs(updated_error_trailer_specs);

      return;
    }

    if (name === "type_of_trailer") {
      let is_trailer_parked = wholevalue[id - 1].is_trailer_parked === "Yes";

      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            have_deck: false,
            have_hard_awning: false,
            have_screened_in_room: false,
            insure_golf_cart: false,
            is_trailer_parked: true,
            golf_cart_1: false,
            golf_cart_2: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && firstoption,
            have_deck: undefined,
            have_hard_awning: undefined,
            have_screened_in_room: undefined,
            insure_golf_cart: undefined,
            golf_cart_1: undefined,
            golf_cart_2: undefined,
          };
        }

        return m;
      });

      setValue(updated);

      let updated_error_trailer_specs = wholeErrorTrailerSpecs.map(
        (m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              vin: undefined,
              trailer_width: false,
            };
          }

          return m;
        }
      );

      setErrorTrailerSpecs(updated_error_trailer_specs);

      let updated_add_info = errAddinfo.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            is_dual_purpose_trailer: undefined,
          };
        }

        return m;
      });

      setErrAddInfo(updated_add_info);

      return;
    }

    if (name === "is_any_heating") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false, heating_type: true };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && firstoption,
            heating_type: "",
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "is_dual_purpose_trailer") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            hauler_selection: true,
            toy_hauler_dropdown: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && firstoption,
            hauler_selection: "",
            toy_hauler_dropdown: {
              "Pedal Bike": false,
              ATV: false,
              Motorcycle: false,
              "Dirt Bike": false,
              "Cars/Trucks/SUV": false,
            },
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "any_mod_to_trailer") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            any_mod_to_trailer: false,
            mod_options: true,
            solar_panels_installed: false,
            interior_upgrades: false,
            use_of_solar_on_trailer: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && firstoption,
            mod_options: "",
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "add_trailer") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            trailer_value: true,
            trailer_model_year: true,
            trailer_manufacturer: true,
            trailer_serial: true,
            trailer_length: true,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.checked && firstoption };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "add_tender") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            tender_value: true,
            tender_model_year: true,
            tender_manufacturer: true,
            tender_serial: true,
            tender_length: true,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.checked && firstoption };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "add_auxillary_motor") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            aux_engine_value: true,
            aux_engine_model_year: true,
            aux_engine_manufacturer: true,
            aux_engine_serial: true,
            aux_engine_hp: true,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.checked && firstoption };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "is_operator_same_as_owner") {
      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            org_first_name: getStarted[0]?.insured_first_name,
            org_last_name: getStarted[0]?.insured_last_name,
            date_of_birth: getStarted[0]?.date_of_birth,
            [name]: e.target.checked && firstoption,
          };
        }

        return m;
      });

      setValue(updated);

      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            org_first_name: false,
            org_last_name: false,
            date_of_birth: false,
          };
        }

        return m;
      });
      setError(updated_error);

      return;
    }

    if (name === "claims_in_last_five_years") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false, no_of_claims_in_last_five_years: true };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.checked && firstoption };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "claims_in_last_three_years") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            no_of_claims_in_last_three_years: true,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.checked && firstoption };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "is_pre_existing_damage") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            damage_description: true,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.checked && firstoption };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "driver_license_suspended") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            when_license_suspended: true,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.checked && firstoption };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "boat_financed") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            boat_lienholder: true,
            other_lienholder: false,
            street_leinholder: false,
            city_leinholder: false,
            province_leinholder: false,
            pc_leinholder: false,
            country_leinholder: false,
            line2_leinholder: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.checked && firstoption };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "trailer_financed") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            trailer_lienholder: true,
            other_trailer_lienholder: false,
            street_leinholder: false,
            city_leinholder: false,
            province_leinholder: false,
            pc_leinholder: false,
            country_leinholder: false,
            line2_leinholder: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.checked && firstoption };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "is_trailer_removed_from_park") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false, trailer_removed_address: true };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.checked && firstoption };
        }

        return m;
      });

      setValue(updated);
      return;
    }

    if (name === "solar_panels_installed") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false, use_of_solar_on_trailer: false };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && firstoption,
            use_of_solar_on_trailer: undefined,
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    let updated_error = wholeError.map((m, index) => {
      if (index === id - 1) {
        return { ...m, [name]: false };
      }

      return m;
    });
    setError(updated_error);

    let updated = wholevalue.map((m, index) => {
      if (index === id - 1) {
        return { ...m, [name]: e.target.checked && firstoption };
      }

      return m;
    });

    setValue(updated);
  };

  const handleChangeSecond = (e) => {
    if (name === "is_trailer_parked_within_500ft") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && secondoption,
          };
        }

        return m;
      });

      setValue(updated);

      let updated_error_trailer_specs = wholeErrorTrailerSpecs.map(
        (m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              deck_length: false,
              deck_width: false,
              awning_length: false,
              awning_width: false,
              florida_room_length: false,
              florida_room_width: false,
            };
          }

          return m;
        }
      );

      setErrorTrailerSpecs(updated_error_trailer_specs);

      return;
    }

    if (name === "have_deck") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.checked && secondoption };
        }

        return m;
      });

      setValue(updated);

      let updated_error_trailer_specs = wholeErrorTrailerSpecs.map(
        (m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              deck_length: false,
              deck_width: false,
            };
          }

          return m;
        }
      );

      setErrorTrailerSpecs(updated_error_trailer_specs);

      let updated_trailer_specs = wholeTrailerSpecs.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            deck_length: undefined,
            deck_width: undefined,
          };
        }

        return m;
      });

      setTrailerSpecsFormValues(updated_trailer_specs);

      return;
    }

    if (name === "have_screened_in_room") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.checked && secondoption };
        }

        return m;
      });

      setValue(updated);
      let updated_error_trailer_specs = wholeErrorTrailerSpecs.map(
        (m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              florida_room_length: false,
              florida_room_width: false,
            };
          }

          return m;
        }
      );

      setErrorTrailerSpecs(updated_error_trailer_specs);

      let updated_trailer_specs = wholeTrailerSpecs.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            florida_room_length: undefined,
            florida_room_width: undefined,
          };
        }

        return m;
      });

      setTrailerSpecsFormValues(updated_trailer_specs);

      return;
    }

    if (name === "have_hard_awning") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.checked && secondoption };
        }

        return m;
      });

      setValue(updated);

      let updated_error_trailer_specs = wholeErrorTrailerSpecs.map(
        (m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              awning_length: false,
              awning_width: false,
            };
          }

          return m;
        }
      );

      setErrorTrailerSpecs(updated_error_trailer_specs);

      let updated_trailer_specs = wholeTrailerSpecs.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            awning_length: undefined,
            awning_width: undefined,
          };
        }

        return m;
      });

      setTrailerSpecsFormValues(updated_trailer_specs);

      return;
    }

    if (name === "type_of_trailer") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            is_trailer_removed_from_park: false,
            is_trailer_parked: false,
            trailer_stored: false,
            trailer_taken_into_usa: false,
            have_deck: true,
            have_hard_awning: true,
            have_screened_in_room: true,
            insure_golf_cart: true,
            trailer_removed_address: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && secondoption,
            is_trailer_parked: undefined,
            trailer_stored: undefined,
            trailer_taken_into_usa: undefined,
            have_deck: undefined,
            have_hard_awning: undefined,
            have_screened_in_room: undefined,
            insure_golf_cart: undefined,
            golf_cart_1: undefined,
            golf_cart_2: undefined,
            trailer_removed_address: undefined,
            is_trailer_removed_from_park: undefined,
          };
        }

        return m;
      });

      setValue(updated);

      let updated_error_trailer_specs = wholeErrorTrailerSpecs.map(
        (m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              vin: false,
              trailer_width: undefined,
            };
          }

          return m;
        }
      );

      setErrorTrailerSpecs(updated_error_trailer_specs);

      let updated_add_info = errAddinfo.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            is_dual_purpose_trailer: false,
            hauler_selection: false,
            toy_hauler_dropdown: false,
          };
        }

        return m;
      });

      setErrAddInfo(updated_add_info);

      return;
    }

    if (name === "is_any_heating") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false, heating_type: false };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && secondoption,
            heating_type: undefined,
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "is_dual_purpose_trailer") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            hauler_selection: false,
            toy_hauler_dropdown: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && secondoption,
            hauler_selection: "",
            toy_hauler_dropdown: {
              "Pedal Bike": false,
              ATV: false,
              Motorcycle: false,
              "Dirt Bike": false,
              "Cars/Trucks/SUV": false,
            },
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "any_mod_to_trailer") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            any_mod_to_trailer: false,
            mod_options: false,
            solar_panels_installed: false,
            interior_upgrades: false,
            use_of_solar_on_trailer: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && secondoption,
            mod_options: "",
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "add_trailer") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            trailer_value: false,
            trailer_model_year: false,
            trailer_manufacturer: false,
            trailer_serial: false,
            trailer_length: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && secondoption,
            trailer_value: undefined,
            trailer_model_year: undefined,
            trailer_manufacturer: undefined,
            trailer_serial: undefined,
            trailer_length: undefined,
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "add_tender") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            tender_value: false,
            tender_model_year: false,
            tender_manufacturer: false,
            tender_serial: false,
            tender_length: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && secondoption,
            tender_value: undefined,
            tender_model_year: undefined,
            tender_manufacturer: undefined,
            tender_serial: undefined,
            tender_length: undefined,
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "add_auxillary_motor") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            aux_engine_value: false,
            aux_engine_model_year: false,
            aux_engine_manufacturer: false,
            aux_engine_serial: false,
            aux_engine_hp: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && secondoption,
            aux_engine_value: undefined,
            aux_engine_model_year: undefined,
            aux_engine_manufacturer: undefined,
            aux_engine_serial: undefined,
            aux_engine_hp: undefined,
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "is_operator_same_as_owner") {
      console.log(value);
      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && secondoption,
            org_first_name: "",
            org_last_name: "",
            date_of_birth: "",
          };
        }

        return m;
      });

      setValue(updated);

      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            org_first_name: true,
            org_last_name: true,
            date_of_birth: true,
          };
        }

        return m;
      });
      setError(updated_error);

      return;
    }

    if (name === "claims_in_last_five_years") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            no_of_claims_in_last_five_years: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && secondoption,
            no_of_claims_in_last_five_years: undefined,
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "is_pre_existing_damage") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            damage_description: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && secondoption,
            damage_description: undefined,
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "claims_in_last_three_years") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            no_of_claims_in_last_three_years: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && secondoption,
            no_of_claims_in_last_three_years: undefined,
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "driver_license_suspended") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            when_license_suspended: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && secondoption,
            when_license_suspended: undefined,
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "boat_financed") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            boat_lienholder: false,
            other_lienholder: false,
            street_leinholder: false,
            city_leinholder: false,
            province_leinholder: false,
            pc_leinholder: false,
            country_leinholder: false,
            line2_leinholder: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && secondoption,
            boat_lienholder: undefined,
            other_lienholder: undefined,
            street_leinholder: undefined,
            line2_leinholder: undefined,
            city_leinholder: undefined,
            province_leinholder: undefined,
            pc_leinholder: undefined,
            country_leinholder: undefined,
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "trailer_financed") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            trailer_lienholder: false,
            other_trailer_lienholder: false,
            street_leinholder: false,
            city_leinholder: false,
            province_leinholder: false,
            pc_leinholder: false,
            country_leinholder: false,
            line2_leinholder: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && secondoption,
            trailer_lienholder: undefined,
            other_trailer_lienholder: undefined,
            street_leinholder: undefined,
            line2_leinholder: undefined,
            city_leinholder: undefined,
            province_leinholder: undefined,
            pc_leinholder: undefined,
            country_leinholder: undefined,
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "is_trailer_removed_from_park") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false, trailer_removed_address: false };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && secondoption,
            trailer_removed_address: undefined,
          };
        }

        return m;
      });

      setValue(updated);
      return;
    }

    if (name === "solar_panels_installed") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false, use_of_solar_on_trailer: true };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: e.target.checked && secondoption,
            use_of_solar_on_trailer: undefined,
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    let updated_error = wholeError.map((m, index) => {
      if (index === id - 1) {
        return { ...m, [name]: false };
      }

      return m;
    });
    setError(updated_error);

    let updated = wholevalue.map((m, index) => {
      if (index === id - 1) {
        return { ...m, [name]: e.target.checked && secondoption };
      }

      return m;
    });

    setValue(updated);
  };

  return (
    <div className="checkbox">
      <label style={{ marginBottom: helperText && "0.75rem" }}>{label}</label>
      <div className="input_checkbox">
        <CustomRadioGroup>
          <CustomFormControlLabel
            sx={{
              "&.MuiFormControlLabel-root": {
                border: error_state
                  ? `1px solid ${WARNING_COLOR}`
                  : value === firstoption
                  ? "1px solid #8ed1fc"
                  : "1px solid #d9d9d9",
                "&:hover": {
                  border: "1px solid #8ed1fc",
                },
              },
            }}
            value={firstoption}
            control={
              <Radio
                disableRipple
                disableFocusRipple
                disableTouchRipple
                disabled={disabled}
                sx={{
                  "&.Mui-checked": {
                    color: "#8ed1fc",
                  },
                  color: "#d9d9d9",
                  ".MuiSvgIcon-root": { height: "1.25rem" },
                }}
                onChange={handleChangeFirst}
                checked={value === firstoption}
              />
            }
            label={firstoption}
          />
          <CustomFormControlLabel
            value={secondoption}
            sx={{
              "&.MuiFormControlLabel-root": {
                border: error_state
                  ? `1px solid ${WARNING_COLOR}`
                  : value === secondoption
                  ? "1px solid #8ed1fc"
                  : "1px solid #d9d9d9",
                "&:hover": {
                  border: "1px solid #8ed1fc",
                },
              },
            }}
            control={
              <Radio
                disableRipple
                disableFocusRipple
                disableTouchRipple
                disabled={disabled}
                sx={{
                  "&.Mui-checked": {
                    color: "#8ed1fc",
                  },
                  color: "#d9d9d9",
                  ".MuiSvgIcon-root": { height: "1.25rem" },
                }}
                onChange={handleChangeSecond}
                checked={value === secondoption}
              />
            }
            label={secondoption}
          />
        </CustomRadioGroup>
        {error_state && (
          <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
            {error_text}
          </FormHelperText>
        )}
        {helperText && (
          <FormHelperText
            sx={{
              fontStyle: "italic",
              fontSize: "0.75rem",
              color: "#a0aec0",
              margin: "8px 0",
              lineHeight: "1rem",
              letterSpacing: "0.7px",
              fontFamily: "Montserrat",
            }}
          >
            {helperText}
          </FormHelperText>
        )}
      </div>
    </div>
  );
};

export default Checkbox;
