import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Select from "../Select";
import logo from "../../assets/RecLogo.png";
import { APIURL, ORGANIZATION_ID } from "../../api";
import axios from "axios";
import dayjs from "dayjs";
import { formatCurrency } from "../../utils";
import SplTextField from "../SplTextField";

const Coverage = ({
  formNumber,
  animateX,
  setAnimateX,
  value,
  setValue,
  error,
  setError,
  id,
  boatCount,
  wholevalue,
  wholeError,
  redBoxObj,
  boatNames,
  index,
  which_whole_err,
  which_redbox,
  which_set_redbox,
  setNumber,
  number,
  setFormNumber,
  setIndex,
  wholeBoatNames,
  boatFormValues,
  referralFlag,
  brokerReviewFlag,
  defaultData,
  setDefaultData,
}) => {
  const deductible = [
    { value: 500, label: "$500" },
    { value: 1000, label: "$1,000" },
  ];

  const personal_effects = [
    { value: 5000, label: "$5,000" },
    { value: 7500, label: "$7,500" },
    { value: 10000, label: "$10,000" },
  ];

  const nav_equipments = [
    { value: 2500, label: "$2,500" },
    { value: 5000, label: "$5,000" },
    { value: 10000, label: "$10,000" },
  ];

  const [loading, setIsloading] = useState(true);
  const [currentValueData, setCurrentValueData] = useState(null);
  const [repValueData, setRepValueData] = useState(null);

  console.log(value, wholeError);

  const handleNext = (which_coverage) => {
    // const allValuesUndefined = Object.values(error).some(
    //   (value) => value === undefined
    // );

    // console.log(allValuesUndefined);
    // if (allValuesUndefined) {
    //   const updatedFormData = { ...error };
    //   for (const key in updatedFormData) {
    //     if (updatedFormData.hasOwnProperty(key)) {
    //       if (updatedFormData[key] === undefined) updatedFormData[key] = true;
    //     }
    //   }

    //   let id = index;

    //   let err = which_whole_err.map((m, index) => {
    //     return index === id - 1
    //       ? (which_whole_err[id - 1] = updatedFormData)
    //       : m;
    //   });

    //   let red_box = which_redbox.map((m, index) => {
    //     if (index === id - 1) {
    //       return { ...m, state: true };
    //     } else {
    //       return m;
    //     }
    //   });

    //   console.log(red_box);

    //   which_set_redbox(red_box);

    //   console.log(err);

    //   setError(err);

    //   return;
    // }

    // console.log(error);
    // const anyValueIsTrue = Object.values(error).some((value) => value === true);

    // console.log(anyValueIsTrue);

    // if (anyValueIsTrue) {
    //   let id = index;

    //   let red_box = which_redbox.map((m, index) => {
    //     if (index === id - 1) {
    //       return { ...m, state: true };
    //     } else {
    //       return m;
    //     }
    //   });

    //   which_set_redbox(red_box);
    //   //   setRedBox(true);

    //   return;
    // }
    setAnimateX(50);

    let id = index;

    let red_box = which_redbox.map((m, index) => {
      if (index === id - 1) {
        return { ...m, state: false };
      } else {
        return m;
      }
    });

    let updated = wholevalue.map((m, index) => {
      if (index === id - 1) {
        return {
          ...m,
          coverage:
            which_coverage === "Replacement Value Coverage"
              ? Number(boatFormValues?.replacement_cost)
              : Number(boatFormValues?.actual_cash_value),
          coverage_name: which_coverage,
          liability_coverage: 2000000,
          deductible:
            which_coverage === "Replacement Value Coverage"
              ? value?.deductible_rc
              : value?.deductible_ac,
          personal_effects_coverage:
            which_coverage === "Replacement Value Coverage"
              ? value?.personal_effects_coverage_rc
              : value?.personal_effects_coverage_ac,
          navigational_equipment_coverage:
            which_coverage === "Replacement Value Coverage"
              ? value?.nav_coverage_rc
              : value?.nav_coverage_ac,
          salvage:
            which_coverage === "Replacement Value Coverage"
              ? Number(boatFormValues?.replacement_cost)
              : Number(boatFormValues?.actual_cash_value),
          pollution: 2000000,
          removal_of_wreckage: 5000,
          emergency_towing_limit: 2500,
          uninsured_underinsured_boater_endorsement: 2000000,
          loss_of_use_limit: 1500,
          premium_per_year:
            which_coverage === "Replacement Value Coverage"
              ? Number(repValueData?.total_prem).toFixed(2)
              : Number(currentValueData?.total_prem).toFixed(2),
        };
      }

      return m;
    });

    let err = which_whole_err.map((m, index) => {
      if (index === id - 1) {
        return {
          ...m,
          coverage_name: false,
        };
      }

      return m;
    });
    setError(err);
    setValue(updated);

    which_set_redbox(red_box);

    // nextPage(formNumber + 1);

    if (formNumber === 5 && index >= boatCount.length) {
      setNumber(number + 1);
    } else if (formNumber === 5) {
      setFormNumber(1);
      setIndex(index + 1);
    } else {
      setFormNumber(formNumber + 1);
    }

    return true;
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });

    if (defaultData?.coverage_gtag_sent === false) {
      window.gtag("event", "conversion", {
        send_to: "AW-1006867181/m33LCMnp3aIDEO2ljuAD",
      });
      
      setDefaultData({ ...defaultData, coverage_gtag_sent: true });
    }
  }, []);

  useEffect(() => {
    let isCancelled = false;

    setIsloading(true);

    async function fun() {
      let is_boat_age_gt_15 =
        Number(boatFormValues?.boat_model_year) <=
        new Date().getFullYear() - 15;

      let boat_model_year = Number(boatFormValues?.boat_model_year);
      let current_year = new Date().getFullYear();

      let is_boat_age_10_to_15 =
        boat_model_year >= current_year - 15 &&
        boat_model_year <= current_year - 10;

      console.log(is_boat_age_10_to_15);

      const parsedDate = dayjs(boatFormValues?.date_of_birth);

      const currentDate = dayjs();

      const twentyYearsAgo = currentDate.subtract(20, "year");

      const twentyFiveYearsAgo = currentDate.subtract(25, "year");

      // console.log(twentyFiveYearsAgo, twentyYearsAgo);

      // Check if the parsed date is between 20 and 25 years ago
      const isBetween20And25YearsAgo =
        parsedDate.isAfter(twentyFiveYearsAgo) &&
        parsedDate.isBefore(twentyYearsAgo);

      let is_boat_operator_age_20_25 = isBetween20And25YearsAgo;

      let is_boat_operator_gt_25_lt_3years_exp = Boolean(
        parsedDate.isBefore(twentyFiveYearsAgo) &&
          boatFormValues?.boating_experience === "0-3 years"
      );

      let body = {
        organization_id: ORGANIZATION_ID,
        boat_value: Number(boatFormValues?.actual_cash_value),
        boat_type: boatFormValues?.type_of_watercraft,
        deductible_value: Number(value?.deductible_ac),
        is_boat_age_gt_15,
        is_boat_operator_age_20_25,
        is_boat_operator_gt_25_lt_3years_exp,
        navi_eqip_value: Number(value?.nav_coverage_ac),
        pers_eff_value: Number(value?.personal_effects_coverage_ac),
        is_boat_liability_rate_2M: true,
        coverage_type: "ACV",
        is_boat_age_10_to_15: is_boat_age_10_to_15,
      };

      console.log(body, "ACV");

      // console.log(body);//replacement_cost

      await axios
        .post(`${APIURL}/boat/send_boat_coverage_details`, body)
        .then((res) => {
          console.log(res);
          setCurrentValueData(res?.data?.data);

          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (!isCancelled) {
      fun();
    }

    return () => (isCancelled = true);
  }, [
    value?.deductible_ac,
    value?.nav_coverage_ac,
    value?.personal_effects_coverage_ac,
  ]);

  useEffect(() => {
    let isCancelled = false;

    setIsloading(true);
    async function fun() {
      if (boatFormValues?.replacement_cost) {
        let is_boat_age_gt_15 =
          Number(boatFormValues?.boat_model_year) <=
          new Date().getFullYear() - 15;

        let boat_model_year = Number(boatFormValues?.boat_model_year);
        let current_year = new Date().getFullYear();

        let is_boat_age_10_to_15 =
          boat_model_year >= current_year - 15 &&
          boat_model_year <= current_year - 10;

        const parsedDate = dayjs(boatFormValues?.date_of_birth);

        const currentDate = dayjs();

        const twentyYearsAgo = currentDate.subtract(20, "year");

        const twentyFiveYearsAgo = currentDate.subtract(25, "year");

        // Check if the parsed date is between 20 and 25 years ago
        const isBetween20And25YearsAgo =
          parsedDate.isAfter(twentyFiveYearsAgo) &&
          parsedDate.isBefore(twentyYearsAgo);

        let is_boat_operator_age_20_25 = isBetween20And25YearsAgo;

        let is_boat_operator_gt_25_lt_3years_exp =
          parsedDate.isBefore(twentyFiveYearsAgo) &&
          boatFormValues?.boating_experience === "0-3 years";

        let body_data = {
          organization_id: ORGANIZATION_ID,
          boat_value: Number(boatFormValues?.replacement_cost),
          boat_type: boatFormValues?.type_of_watercraft,
          deductible_value: Number(value?.deductible_rc),
          is_boat_age_gt_15,
          is_boat_operator_age_20_25,
          is_boat_operator_gt_25_lt_3years_exp,
          navi_eqip_value: Number(value?.nav_coverage_rc),
          pers_eff_value: Number(value?.personal_effects_coverage_rc),
          is_boat_liability_rate_2M: true,
          coverage_type: "RCV",
          is_boat_age_10_to_15: is_boat_age_10_to_15,
        };

        console.log(body_data, "RCV");

        await axios
          .post(`${APIURL}/boat/send_boat_coverage_details`, body_data)
          .then((res) => {
            console.log(res);
            setRepValueData(res?.data?.data);
            setIsloading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    if (!isCancelled) {
      fun();
    }

    return () => (isCancelled = true);
  }, [
    value?.deductible_rc,
    value?.nav_coverage_rc,
    value?.personal_effects_coverage_rc,
  ]);

  useEffect(() => {
    // if (boatFormValues?.type_of_watercraft === "I'm not too sure") {
    //   setNumber(5);
    // }
  }, []);

  return (
    <motion.div
      initial={{ x: animateX, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {loading ? (
        <div
          style={{
            height: "85Vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="loader"></div>
        </div>
      ) : currentValueData !== null ? (
        <div>
          <div className={`box ${redBoxObj?.state && "redbox"}`}>
            <div className="boat_form">
              <header>
                <div className="side_line"></div>
                <div>
                  <h3>Quotes and Coverages</h3>
                  <h6 className="boat_name">
                    {" "}
                    {wholeBoatNames.length > 1 && `(${boatNames?.name})`}
                  </h6>
                </div>
              </header>
              <div className="quote_container">
                <h1 style={{ color: error?.coverage_name && "red" }}>
                  Select the required coverage
                </h1>
                <div className="trailer_quote">
                  {boatFormValues?.replacement_cost &&
                  (!(referralFlag?.state || brokerReviewFlag) ||
                    ((referralFlag?.state || brokerReviewFlag) &&
                      value?.coverage_name ===
                        "Replacement Value Coverage")) ? (
                    <div
                      className="box"
                      style={{
                        margin:
                          referralFlag?.state || brokerReviewFlag ? "" : "0",
                      }}
                    >
                      <div className="quote_box">
                        <h2>Replacement Value Coverage</h2>
                        <img src={logo} alt="logo" loading="lazy" />
                        <div className="price">
                          <p
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <p
                              style={{
                                display: "flex",
                                gap: "4px",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              ${Number(repValueData?.total_prem).toFixed(2)}{" "}
                              /Year{" "}
                              {/* <Tooltip
                                title={
                                  <div style={{ fontSize: "0.75rem" }}>
                                    <p>
                                      Base premium{" "}
                                      <b>
                                        $
                                        {Number(
                                          repValueData?.boat_base_premium
                                        ).toFixed(2)}
                                      </b>
                                    </p>
                                    <p>
                                      Boat Liability premium{" "}
                                      <b>
                                        $
                                        {Number(
                                          repValueData?.boat_liability_prem
                                        ).toFixed(2)}
                                      </b>
                                    </p>
                                  </div>
                                }
                              >
                                <QuestionCircleTwoTone
                                  twoToneColor={"#8ed1fc"}
                                />
                              </Tooltip> */}
                            </p>

                            <span style={{ fontSize: "0.75rem" }}>
                              {" "}
                              plus applicable taxes/ fees
                            </span>
                          </p>
                        </div>
                        {referralFlag?.state || brokerReviewFlag ? (
                          <div style={{ paddingTop: ".5rem" }}></div>
                        ) : (
                          <button
                            className="next_btn"
                            onClick={() =>
                              handleNext("Replacement Value Coverage")
                            }
                            style={{ margin: "0.5rem auto 1rem" }}
                          >
                            Click here to finish application
                          </button>
                        )}

                        <div
                          style={{
                            margin: "0 10px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <SplTextField
                            label={"Boat Coverage"}
                            disabled={true}
                            value={formatCurrency(
                              Number(boatFormValues?.replacement_cost)
                            )}
                            width="50"
                          />{" "}
                          {/* <div className="quote_flex">
                            <label>Liability coverage</label>
                            <p>$2,000,000</p>
                          </div> */}
                          <SplTextField
                            label="Liability coverage"
                            value={"2,000,000"}
                            disabled={true}
                            width="50"
                          />
                          <Select
                            label={"Deductible"}
                            options={deductible}
                            value={value?.deductible_rc}
                            name={"deductible_rc"}
                            setValue={setValue}
                            error={error}
                            setError={setError}
                            object={value}
                            error_state={error?.deductible_rc}
                            error_text={""}
                            id={id + 1}
                            wholevalue={wholevalue}
                            wholeError={wholeError}
                            allowClear={"he"}
                            disabled={referralFlag?.state || brokerReviewFlag}
                            width="50"
                          />
                          <Select
                            label={"Personal Effects Coverage"}
                            options={personal_effects}
                            value={value?.personal_effects_coverage_rc}
                            name={"personal_effects_coverage_rc"}
                            setValue={setValue}
                            error={error}
                            setError={setError}
                            object={value}
                            error_state={error?.personal_effects_coverage_rc}
                            error_text={""}
                            id={id + 1}
                            wholevalue={wholevalue}
                            wholeError={wholeError}
                            allowClear={"he"}
                            disabled={referralFlag?.state || brokerReviewFlag}
                            width="50"
                          />{" "}
                          <Select
                            label={"Navigational Equipment Coverage"}
                            options={nav_equipments}
                            value={value?.nav_coverage_rc}
                            name={"nav_coverage_rc"}
                            setValue={setValue}
                            error={error}
                            setError={setError}
                            object={value}
                            error_state={error?.nav_coverage_rc}
                            error_text={""}
                            id={id + 1}
                            wholevalue={wholevalue}
                            wholeError={wholeError}
                            allowClear={"he"}
                            // helperText={
                            //   "Overland flood damage from rising rivers/lakes, spring snow melt and flash rains."
                            // }
                            disabled={referralFlag?.state || brokerReviewFlag}
                            width="50"
                          />
                          <SplTextField
                            label={"Salvage"}
                            disabled={true}
                            value={formatCurrency(
                              Number(boatFormValues?.replacement_cost)
                            )}
                            width="50"
                          />
                          <SplTextField
                            label={"Pollution"}
                            disabled={true}
                            value="2,000,000"
                            width="50"
                          />
                          {/* <div className="quote_flex">
                          <label>Removal of Wreckage</label>
                          <p>$5,000</p>
                        </div> */}
                          <SplTextField
                            label={"Removal of Wreckage"}
                            disabled={true}
                            value={"5,000"}
                            width="50"
                          />
                          {/* <div className="quote_flex">
                          <label>Emergency Towing Limit</label>
                          <p>$2,500</p>
                        </div> */}
                          <SplTextField
                            label={"Emergency Towing Limit"}
                            disabled={true}
                            value={"2,500"}
                            width="50"
                          />
                          {/* <div className="quote_flex">
                          <label>
                            Uninsured/ Underinsured Boater Endorsement
                          </label>
                          <p>$2,000,000</p>
                        </div>
                        <div className="quote_flex">
                          <label>Loss of Use Limit</label>
                          <p>$1,500</p>
                        </div> */}
                          <SplTextField
                            label={"Uninsured/ Underinsured Boater Endorsement"}
                            value={"2,000,000"}
                            disabled={true}
                            width="50"
                          />
                          <SplTextField
                            label={"Loss of Use Limit"}
                            value={"1,500"}
                            disabled={true}
                            width="50"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {!(referralFlag?.state || brokerReviewFlag) ||
                  ((referralFlag?.state || brokerReviewFlag) &&
                    value?.coverage_name ===
                      "Current Market Value Coverage") ? (
                    <div className="box" style={{ marginBottom: "2rem" }}>
                      <div className="quote_box">
                        <h2>Current Market Value Coverage</h2>
                        <img src={logo} alt="logo" loading="lazy" />
                        <div className="price">
                          <p
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <p
                              style={{
                                display: "flex",
                                gap: "4px",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              ${Number(currentValueData?.total_prem).toFixed(2)}{" "}
                              /Year
                              {/* <Tooltip
                              title={
                                <div style={{ fontSize: "0.75rem" }}>
                                  <p>
                                    Base premium{" "}
                                    <b>
                                      $
                                      {Number(
                                        currentValueData?.boat_base_premium
                                      ).toFixed(2)}
                                    </b>
                                  </p>
                                  <p>
                                    Boat Liability premium{" "}
                                    <b>
                                      $
                                      {Number(
                                        currentValueData?.boat_liability_prem
                                      ).toFixed(2)}
                                    </b>
                                  </p>
                                </div>
                              }
                            >
                              <QuestionCircleTwoTone twoToneColor={"#8ed1fc"} />
                            </Tooltip> */}
                            </p>
                            <span style={{ fontSize: "0.75rem" }}>
                              plus applicable taxes/ fees
                            </span>
                          </p>
                        </div>
                        {referralFlag?.state || brokerReviewFlag ? (
                          <div style={{ paddingTop: ".5rem" }}></div>
                        ) : (
                          <button
                            className="next_btn"
                            onClick={() =>
                              handleNext("Current Market Value Coverage")
                            }
                            style={{ margin: "0.5rem auto 1rem" }}
                          >
                            Click here to finish application
                          </button>
                        )}
                        <div
                          style={{
                            margin: "0 10px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <SplTextField
                            label={"Boat Coverage"}
                            disabled={true}
                            value={formatCurrency(
                              Number(boatFormValues?.actual_cash_value)
                            )}
                            width="50"
                          />{" "}
                          {/* <div className="quote_flex">
                          <label>Liability coverage</label> */}
                          {/* <p>$2,000,000</p> */}
                          <SplTextField
                            label="Liability coverage"
                            value={"2,000,000"}
                            disabled={true}
                            width="50"
                          />
                          {/* </div> */}
                          <Select
                            label={"Deductible"}
                            options={deductible}
                            value={value?.deductible_ac}
                            name={"deductible_ac"}
                            setValue={setValue}
                            error={error}
                            setError={setError}
                            object={value}
                            error_state={error?.deductible_ac}
                            error_text={""}
                            id={id + 1}
                            wholevalue={wholevalue}
                            wholeError={wholeError}
                            allowClear={"he"}
                            disabled={referralFlag?.state || brokerReviewFlag}
                            width="50"
                          />
                          <Select
                            label={"Personal Effects Coverage"}
                            options={personal_effects}
                            value={value?.personal_effects_coverage_ac}
                            name={"personal_effects_coverage_ac"}
                            setValue={setValue}
                            error={error}
                            setError={setError}
                            object={value}
                            error_state={error?.personal_effects_coverage_ac}
                            error_text={"Please select type of watercraft"}
                            id={id + 1}
                            wholevalue={wholevalue}
                            wholeError={wholeError}
                            allowClear={"he"}
                            disabled={referralFlag?.state || brokerReviewFlag}
                            width="50"
                          />{" "}
                          <Select
                            label={"Navigational Equipment Coverage"}
                            options={nav_equipments}
                            value={value?.nav_coverage_ac}
                            name={"nav_coverage_ac"}
                            setValue={setValue}
                            error={error}
                            setError={setError}
                            object={value}
                            error_state={error?.nav_coverage_ac}
                            error_text={"Please select type of watercraft"}
                            id={id + 1}
                            wholevalue={wholevalue}
                            wholeError={wholeError}
                            allowClear={"he"}
                            width="50"
                            // helperText={
                            //   "Overland flood damage from rising rivers/lakes, spring snow melt and flash rains."
                            // }
                            disabled={referralFlag?.state || brokerReviewFlag}
                          />
                          <SplTextField
                            label={"Salvage"}
                            disabled={true}
                            value={formatCurrency(
                              Number(boatFormValues?.actual_cash_value)
                            )}
                            width="50"
                          />
                          {/* <div className="quote_flex">
                          <label>Pollution</label>
                          <p>$2,000,000</p>
                        </div> */}
                          <SplTextField
                            label={"Pollution"}
                            disabled={true}
                            value="2,000,000"
                            width="50"
                          />
                          {/* <div className="quote_flex">
                          <label>Removal of Wreckage</label>
                          <p>$5,000</p>
                        </div> */}
                          <SplTextField
                            label={"Removal of Wreckage"}
                            disabled={true}
                            value={"5,000"}
                            width="50"
                          />
                          {/* <div className="quote_flex">
                          <label>Emergency Towing Limit</label>
                          <p>$2,500</p>
                        </div> */}
                          <SplTextField
                            label={"Emergency Towing Limit"}
                            disabled={true}
                            value={"2,500"}
                            width="50"
                          />
                          {/* <div className="quote_flex">
                          <label>
                            Uninsured/ Underinsured Boater Endorsement
                          </label>
                          <p>$2,000,000</p>
                        </div>
                        <div className="quote_flex">
                          <label>Loss of Use Limit</label>
                          <p>$1,500</p>
                        </div> */}
                          <SplTextField
                            label={"Uninsured/ Underinsured Boater Endorsement"}
                            value={"2,000,000"}
                            disabled={true}
                            width="50"
                          />
                          <SplTextField
                            label={"Loss of Use Limit"}
                            value={"1,500"}
                            disabled={true}
                            width="50"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </motion.div>
  );
};

export default Coverage;
