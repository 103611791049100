import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import { APIURL } from "../../api";
import { ERROR_HELPERTEXT_STYLES, FIELD_ERROR_TEXT } from "../../utils";

const Terms = ({
  formNumber,
  nextPage,
  backPage,
  animateX,
  setAnimateX,
  value,
  setValue,
  error,
  setError,
  globalData,
  type,
  referralFlag,
  id,
  wholeError,
  wholevalue,
  brokerReviewFlag,
  defaultData,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [redbox, setRedbox] = useState(false);

  console.log(wholevalue);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <motion.div
      initial={{ x: animateX, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div>
        <div className={`box ${redbox && "redbox"}`}>
          <div className="terms">
            <header>
              <div className="side_line"></div>
              <h3>Terms</h3>
            </header>
            <div className="container terms_container">
              <p>
                I understand and agree that any failure or omission to disclose
                any fact, circumstance, situation, or claim known to me may void
                the policy. Furthermore, any claim or action arising out of any
                fact, situation, or claim known to me not disclosed herein will
                not be covered under this policy.
              </p>
              <p>
                I understand at the time of a claim proof of ownership via a
                bill of sale and/or registration will need to be provided prior
                to payment.
              </p>
              <p>
                I understand and agree that if the policy is registered in two
                names, via Procuration I have the express authority to sign the
                policy on behalf of both named insureds in order to bind and
                issue the policy subject to all terms, conditions, coverages and
                limits on policy.
              </p>
              <p>
                I declare that after proper enquiry the statements and
                particulars given in the application including are true and that
                I have not misstated or suppressed any material facts.
              </p>
              <p>
                I declare the email provided for signing authority is my valid
                email I consent to using electronic signatures to bind the
                policy. I agree to be bound by the terms of the agreement.
              </p>
              <p>
                I agree to inform the insurer immediately of any change to the
                information provided in this application which occurs between
                the date of the application and the effective date of the
                policy.
              </p>
              <p>
                In the event of a claim, I agree to authorize the insurer to
                provide the information contained in this application to loss
                adjusters, lawyers or other service providers for the purpose of
                investigating, defending, negotiating or settling any claims as
                required.
              </p>
              <p>
                I confirm that all individuals whose personal information is
                contained in this document have authorized that I agree to the
                above on their behalf.
              </p>
              <p>
                I understand that this authorization may be cancelled by me/us
                upon written notice, subject to a period which shall not exceed
                30 days. I/We may obtain a sample cancellation form, or further
                information on my/our right to cancel a payment authorization
                agreement.
              </p>
              <p>
                I agree that, if there is a change in premium due to a change in
                coverage, rate, or upon renewal, the amount of the will
                automatically be changed.
              </p>
              <p>
                I waive the right to obtain written notice of at least 10 days
                from Insurer or broker of the amount to be charged on credit
                card.
              </p>
              <p>
                I understand that this authorization is continuous and will
                automatically apply to the renewal terms, unless instructed
                differently.
              </p>
              <p>
                I authorize the Insurer and broker to disclose any personal
                information contained in this authorization form to its
                financial institution or lienholder institution to the extent
                disclosure is directly related to and necessary for the proper
                execution of the payment authorization or lending. I understand
                as part of the renewal insurance coverage(s), I authorize
                RecProtect to collect, use and disclose personal information of
                such insured individuals as required and as permitted pursuant
                to relevant privacy laws or other laws.
              </p>
              <p style={{ marginBottom: "0" }}>
                I understand that I am able to contact the Broker Privacy
                Officer on the following:
              </p>
              <li>
                review personal information maintained by RecProtect pertaining
                to the Client’s application, policy or policies;
              </li>
              <li>
                obtain copies of the Brokerages privacy policies or standards;
                or
              </li>
              <li style={{ marginBottom: "0.5rem" }}>
                make other enquiries to express concerns,
              </li>
              <p>
                These Terms and Conditions govern the use and storage of credit
                card information by RecProtect Insurance, a division of Coburn
                Insurance Brokers Ltd. (“Company") for customers in Canada. By
                linking your credit card to your policy and account, you consent
                to these terms.
              </p>
              <p>
                You authorize the Company to store your credit card information
                and to automatically charge your card for any services provided
                by the Company, including but not limited to policy premiums,
                fees, and charges related to the insurance services offered.
                This is a standing authorization for all necessary payments.
              </p>
              <p>
                Your credit card information will be used solely for the purpose
                of processing payments for the services you engage in. We may
                employ reputable third-party payment processors to facilitate
                these transactions, and your credit card information will be
                shared with them as required.
              </p>
              <p>
                Adhering to Canadian data protection laws, including PIPEDA, the
                Company implements robust security measures to safeguard your
                credit card information against unauthorized access, use, or
                disclosure. While we strive for complete security, please be
                aware that no method of electronic storage or transmission is
                infallible.
              </p>

              <p>
                In compliance with Canadian privacy laws, you have the right to
                access and request correction of your personal information held
                by us. For any queries or concerns regarding your data, please
                contact
                <strong>
                  <a
                    style={{
                      color: "#454545",
                      marginLeft: "0.3rem",
                      textDecoration: "none",
                      opacity: "1",
                    }}
                    href={`mailto:service@recprotect.ca`}
                  >
                    service@recprotect.ca
                  </a>{" "}
                </strong>
              </p>

              <p>
                It is your responsibility to ensure that your credit card
                details are current and accurate. Please inform us promptly of
                any changes to your credit card information.
              </p>

              <p>
                Cancellation of services and requests for refunds will be
                handled as per our Cancellation and Refund Policy. In case of
                disputes related to credit card charges, contact our customer
                service immediately.
              </p>

              <p>
                These Terms and Conditions may be updated periodically to
                reflect changes in our practices or relevant laws. Your
                continued use of our services after such changes constitutes
                acceptance of the new terms.
              </p>

              <p>
                These Terms and Conditions are governed by and construed in
                accordance with the laws of Canada and the province in which you
                reside.
              </p>

              <p>
                For any questions or concerns regarding these Terms and
                Conditions, please contact our customer service team at
                <a
                  style={{
                    color: "#454545",
                    marginLeft: "0.3rem",
                    textDecoration: "none",
                  }}
                  href={`mailto:service@recprotect.ca`}
                >
                  service@recprotect.ca
                </a>
              </p>

              <p>
                I understand, acknowledge and agree that the security measures
                instituted by RecProtect Insurance a division of Coburn
                Insurance Brokers Ltd. to prevent unauthorized access to the
                confidential information are reasonable and adequate. I also
                understand and acknowledge that notwithstanding the use of
                security measures, RecProtect Insurance a division of Coburn
                Insurance Brokers Ltd. cannot guarantee the security of
                information that is transmitted over the Internet and
                unauthorized access to the Information may occur.
              </p>
            </div>
          </div>
        </div>
        <FormGroup>
          <FormControlLabel
            sx={{
              "&.MuiFormControlLabel-root": {
                padding: ".5rem 0 0",
                borderRadius: "5px",
              },

              ".MuiTypography-root": {
                fontFamily: "Montserrat",
                fontSize: "0.95rem",
              },
            }}
            required
            control={
              <Checkbox
                disableFocusRipple
                disableRipple
                disableTouchRipple
                disabled={
                  defaultData?.quote_status !== "Return To Payment" &&
                  (referralFlag?.state || brokerReviewFlag || btnLoading)
                }
                size="small"
                sx={{
                  "&.Mui-checked": {
                    color: "#8ed1fc",
                  },
                  color: "#d9d9d9",
                }}
                checked={value?.terms_checked}
                onChange={(e) => {
                  if (e.target.checked) {
                    let updated_error = wholeError.map((m, index) => {
                      if (index === id - 1) {
                        return {
                          ...m,
                          terms_checked: false,
                        };
                      }

                      return m;
                    });
                    setError(updated_error);

                    let updated = wholevalue.map((m, index) => {
                      if (index === id - 1) {
                        return { ...m, terms_checked: true };
                      }

                      return m;
                    });

                    setValue(updated);
                  } else {
                    let updated_error = wholeError.map((m, index) => {
                      if (index === id - 1) {
                        return {
                          ...m,
                          terms_checked: true,
                        };
                      }

                      return m;
                    });
                    setError(updated_error);

                    let updated = wholevalue.map((m, index) => {
                      if (index === id - 1) {
                        return { ...m, terms_checked: false };
                      }

                      return m;
                    });

                    setValue(updated);
                  }
                }}
              />
            }
            label="Agree to terms and conditions"
          />
          {error?.terms_checked && (
            <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
              {FIELD_ERROR_TEXT}
            </FormHelperText>
          )}
        </FormGroup>
        <div className="trailer_btns">
          <button
            className={`next_btn ${btnLoading ? "loading" : ""}`}
            disabled={btnLoading}
            onClick={async () => {
              if (
                defaultData?.quote_status !== "Return To Payment" &&
                defaultData?.quote_status !== "Payment Pending" &&
                (referralFlag?.state || brokerReviewFlag)
              ) {
                setAnimateX(100);
                nextPage(formNumber + 1);

                return;
              }

              setBtnLoading(() => true);
              setRedbox(() => false);

              const allValuesUndefined = Object.values(error).some(
                (value) => value === undefined
              );

              let updatedArray = [...wholeError];

              if (allValuesUndefined) {
                updatedArray = wholeError.map((obj) => {
                  const updatedObj = { ...obj };

                  for (const key in updatedObj) {
                    if (updatedObj[key] === undefined) {
                      updatedObj[key] = true;
                    }
                  }

                  console.log(updatedObj);

                  return updatedObj;
                });

                setError(updatedArray);
              }

              console.log(updatedArray);

              const anyValueIsTrue = updatedArray.some((obj) => {
                for (const key in obj) {
                  if (obj[key] === true) {
                    return true;
                  }
                }
                return false;
              });

              console.log(anyValueIsTrue);

              if (anyValueIsTrue) {
                await new Promise((resolve) => setTimeout(resolve, 0));

                setBtnLoading(() => false);
                setRedbox(true);
                return;
              }

              let body =
                type === "Trailer"
                  ? {
                      trailer_quote_record_id: globalData.quote_record_id,
                      trailer_quote_updates: {
                        agreed_to_tnc: value?.terms_checked,
                      },
                    }
                  : {
                      boat_quote_record_id: globalData.quote_record_id,
                      boat_quote_updates: {
                        agreed_to_tnc: value?.terms_checked,
                      },
                    };

              let url =
                type === "Boat"
                  ? "/boat/update_boat_quote"
                  : "/trailer/update_trailer_quote";

              setAnimateX(100);
              nextPage(formNumber + 1);
              setBtnLoading(() => false);

              await axios
                .post(`${APIURL}${url}`, body)
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          >
            Next {btnLoading && <div className="btn_loading"></div>}
          </button>
          {formNumber === 1 ? (
            ""
          ) : (
            <button
              className="back_btn"
              onClick={() => {
                setAnimateX(-100);

                backPage(formNumber - 1);
              }}
              disabled={btnLoading}
            >
              Back
            </button>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default Terms;
