import React, { useState } from "react";
import APTextField from "../components/AdminPanel/APTextField";
import { Card, message } from "antd";
import APSelect from "../components/AdminPanel/APSelect";
import axios from "axios";
import { APIURL } from "../api";

const SyncQuote = () => {
  const [data, setData] = useState({
    quote_record_id: "",
    type: "",
    btn_loading: false,
  });

  const handleSubmit = async () => {
    setData({
      ...data,
      btn_loading: true,
    });

    if (data.type === "Trailer") {
      let body = {
        trailer_quote_record_id: data.quote_record_id,
        trailer_quote_updates: {},
      };

      await axios
        .post(`${APIURL}/trailer/update_trailer_quote`, body)
        .then((res) => {
          if (res.data?.success === true)
            message.success("Synced successfully !!");
          else message.error(res.data?.message);
        })
        .catch((err) => {
          message.error("Sync failed");
        })
        .finally(() => {
          setData({
            ...data,
            quote_record_id: "",
            btn_loading: false,
            type: "",
          });
        });
    } else if (data.type === "Boat") {
      let body = {
        boat_quote_record_id: data.quote_record_id,
        boat_quote_updates: {},
      };

      await axios
        .post(`${APIURL}/boat/update_boat_quote`, body)
        .then((res) => {
          if (res.data?.success === true)
            message.success("Synced successfully !!");
          else message.error(res.data?.message);
        })
        .catch((err) => {
          message.error("Sync failed");
        })
        .finally(() => {
          setData({
            ...data,
            quote_record_id: "",
            btn_loading: false,
            type: "",
          });
        });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100dvh",
        minWidth: "100dvw",
        flexDirection: "column",
        gap: "1.5rem",
      }}
    >
      <h2 style={{ color: "#353535" }}>Sync Quote</h2>
      <Card
        style={{ minHeight: "30dvh", minWidth: "550px", cursor: "default" }}
        hoverable
      >
        <APTextField
          label={"Quote Record ID"}
          handleChange={(e) => {
            setData({
              ...data,
              quote_record_id: e.target.value,
              type: "",
            });
          }}
          value={data?.quote_record_id}
          name={"quote_record_id"}
        />
        <div style={{ paddingBottom: "1rem" }}></div>
        <APSelect
          label={"Select the type"}
          options={[
            { label: "Boat", value: "Boat" },
            { label: "Trailer", value: "Trailer" },
          ]}
          name={"type"}
          value={data?.type}
          handleChange={(value) => {
            setData({
              ...data,
              type: value,
            });
          }}
        />

        {data?.quote_record_id && data?.type ? (
          <button
            style={{
              padding: ".5rem",
              fontSize: ".75rem",
              margin: "2rem auto",
            }}
            className={`next_btn ${data?.btn_loading ? "loading" : ""}`}
            onClick={handleSubmit}
          >
            Sync {data?.btn_loading && <div className="btn_loading"></div>}
          </button>
        ) : (
          ""
        )}
      </Card>
    </div>
  );
};

export default SyncQuote;
