import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import CheckboxComponent from "../Checkbox";
import Select from "../Select";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import TextFieldComponent from "../TextField";
import {
  COMPONENT_ERROR_TEXT,
  ERROR_HELPERTEXT_STYLES,
  FIELD_ERROR_TEXT,
  PRIMARY_COLOR,
  WARNING_COLOR,
} from "../../utils";
import axios from "axios";
import { APIURL } from "../../api";
import Autocomplete from "../AutoComplete";
import AddressAutoComplete from "../AddressAutoComplete";
import { ConfigProvider, Input } from "antd";

const canadaPostalRegex =
  /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ] ?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i;

const AdditionalInfo = ({
  animateX,
  value,
  setValue,
  error,
  setError,
  id,
  wholevalue,
  wholeError,
  redBoxObj,
  trailerNames,
  wholetrailerNames,
  referralFlag,
  organization,
  trailer,
  brokerReviewFlag,
}) => {
  let heating_type = [
    { value: "Wood stove", label: "Wood stove" },
    { value: "Pellet stove", label: "Pellet stove" },
    { value: "Electric space heater", label: "Electric space heater" },
    { value: "Other", label: "Other" },
  ];

  const hauler_array = [
    { value: "Animal Hauler", label: "Animal Hauler" },
    { value: "Toy Hauler", label: "Toy Hauler" },
  ];

  const handleToyHaulerChange = (e) => {
    let val = e.target.checked;
    let name = e.target.name;

    console.log(wholevalue);

    let arr = [...wholevalue];

    const updatedArray = arr.map((item, index) => {
      if (index === id - 1) {
        const updatedData = { ...item.toy_hauler_dropdown, [name]: val };

        return { ...item, toy_hauler_dropdown: updatedData };
      }
      return item;
    });

    console.log(updatedArray);

    setValue(updatedArray);

    let check_all_false = Object.values(
      updatedArray[id - 1].toy_hauler_dropdown
    ).some((m) => m === true);

    console.log(check_all_false);

    if (check_all_false || arr[id - 1].toy_hauler_dropdown === undefined) {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, toy_hauler_dropdown: false };
        }

        return m;
      });

      console.log(updated_error);

      setError(updated_error);
    } else {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, toy_hauler_dropdown: true };
        }

        return m;
      });

      console.log(updated_error);
      setError(updated_error);
    }
  };

  console.log(value, error);

  const modifications_option = [
    {
      value: "Solar panels",
      label: "Solar panels",
    },
    {
      value: "Interior upgrades",
      label: "Interior upgrades",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  const [common_lienholders, setCommonLienholders] = useState([]);

  useEffect(() => {
    async function fetch() {
      await axios.get(`${APIURL}/financier/get_financiers`).then((res) => {
        console.log(res);
        let array = res?.data?.data.map((m) => {
          return {
            ...m,
            value: m.name,
            label: m.name,
          };
        });

        console.log(array);
        setCommonLienholders(array);
      });
    }

    fetch();
  }, []);

  const [selectedFinancier, setSelectedFinancier] = useState(false);

  useEffect(() => {
    let options = [...common_lienholders];

    let filter = options.filter((m) => m.name === value?.trailer_lienholder);

    if (filter.length) {
      setSelectedFinancier(true);
    } else {
      setSelectedFinancier(false);
    }
  }, [value?.trailer_lienholder]);

  return (
    <motion.div
      initial={{ x: animateX, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div>
        <div className={`box ${redBoxObj?.state && "redbox"}`}>
          <div className="trailer_add_info">
            <header>
              <div className="side_line"></div>
              <div>
                <h3 className="error_h3">
                  Additional Details{" "}
                  {redBoxObj?.state ? (
                    <p className="component_error_text">
                      {COMPONENT_ERROR_TEXT}
                    </p>
                  ) : (
                    ""
                  )}{" "}
                </h3>{" "}
                <h6 className="trailer_name">
                  {" "}
                  {wholetrailerNames.length > 1 && `(${trailerNames?.name})`}
                </h6>
              </div>
            </header>
            <div className="container">
              <CheckboxComponent
                label={
                  "Do you live in the trailer full time, all year round as a principal residence?"
                }
                firstoption="Yes"
                secondoption="No"
                error={error}
                setError={setError}
                value={value?.live_in_trailer_full_time}
                setValue={setValue}
                object={value}
                error_state={error?.live_in_trailer_full_time}
                error_text={FIELD_ERROR_TEXT}
                name={"live_in_trailer_full_time"}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              <CheckboxComponent
                label={
                  "Is the unit used strictly for pleasure purposes with no rental or business use of any kind?"
                }
                firstoption="Yes"
                secondoption="No"
                error={error}
                setError={setError}
                value={value?.is_unit_strictly}
                setValue={setValue}
                object={value}
                error_state={error?.is_unit_strictly}
                error_text={FIELD_ERROR_TEXT}
                name={"is_unit_strictly"}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              <CheckboxComponent
                label={
                  "Is there any heating that wasn't factory installed? (wood stove, space heater, etc.)                "
                }
                firstoption="Yes"
                secondoption="No"
                error={error}
                setError={setError}
                value={value?.is_any_heating}
                setValue={setValue}
                object={value}
                error_state={error?.is_any_heating}
                error_text={FIELD_ERROR_TEXT}
                name={"is_any_heating"}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              {value?.is_any_heating === "Yes" && (
                <Select
                  options={heating_type}
                  name="heating_type"
                  label="select the heating type"
                  value={value?.heating_type}
                  setValue={setValue}
                  error={error}
                  object={value}
                  setError={setError}
                  error_state={error?.heating_type}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />
              )}
              {trailer?.type_of_trailer ===
              "Travel Trailer/Fifth Wheel Trailer" ? (
                <CheckboxComponent
                  label={
                    "Is this a dual purpose trailer? (toy hauler/horse trailer)"
                  }
                  firstoption="Yes"
                  secondoption="No"
                  error={error}
                  setError={setError}
                  value={value?.is_dual_purpose_trailer}
                  setValue={setValue}
                  object={value}
                  error_state={error?.is_dual_purpose_trailer}
                  error_text={FIELD_ERROR_TEXT}
                  name={"is_dual_purpose_trailer"}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />
              ) : (
                ""
              )}
              {value?.is_dual_purpose_trailer === "Yes" && (
                <Select
                  options={hauler_array}
                  name="hauler_selection"
                  label={"Select the hauler type"}
                  value={value?.hauler_selection}
                  setValue={setValue}
                  error={error}
                  object={value}
                  setError={setError}
                  error_state={error?.hauler_selection}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />
              )}
              {value?.hauler_selection === "Toy Hauler" && (
                <div className="find_us_container">
                  <label>
                    Please select which items are being hauled{" "}
                    {/* <Tooltip
                      title={
                        <div style={{ fontSize: "0.75rem" }}>
                          This policy will not provide coverage for ATV's,
                          Motorcycles, and Dirt bikes that are stored within
                          your toy hauler
                        </div>
                      }
                    >
                      <QuestionCircleTwoTone twoToneColor={"#8ed1fc"} />
                    </Tooltip>{" "} */}
                  </label>
                  <FormGroup sx={{ width: "64%" }}>
                    <FormControlLabel
                      sx={{
                        ".MuiTypography-root": {
                          fontFamily: "Montserrat",
                          fontSize: "0.875rem",
                        },
                      }}
                      control={
                        <Checkbox
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled={referralFlag?.state || brokerReviewFlag}
                          size="small"
                          sx={{
                            "&.Mui-checked": {
                              color: "#8ed1fc",
                            },
                            color: "#d9d9d9",
                          }}
                          name="Pedal Bike"
                          onChange={handleToyHaulerChange}
                          checked={value?.toy_hauler_dropdown["Pedal Bike"]}
                        />
                      }
                      label="Pedal Bike"
                    />
                    <FormControlLabel
                      sx={{
                        ".MuiTypography-root": {
                          fontFamily: "Montserrat",
                          fontSize: "0.875rem",
                        },
                      }}
                      control={
                        <Checkbox
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled={referralFlag?.state || brokerReviewFlag}
                          size="small"
                          sx={{
                            "&.Mui-checked": {
                              color: "#8ed1fc",
                            },
                            color: "#d9d9d9",
                          }}
                          name="ATV"
                          onChange={handleToyHaulerChange}
                          checked={value?.toy_hauler_dropdown?.ATV}
                        />
                      }
                      label="ATV"
                    />
                    <FormControlLabel
                      sx={{
                        ".MuiTypography-root": {
                          fontFamily: "Montserrat",
                          fontSize: "0.875rem",
                        },
                      }}
                      control={
                        <Checkbox
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled={referralFlag?.state || brokerReviewFlag}
                          size="small"
                          sx={{
                            "&.Mui-checked": {
                              color: "#8ed1fc",
                            },
                            color: "#d9d9d9",
                          }}
                          name="Motorcycle"
                          onChange={handleToyHaulerChange}
                          checked={value?.toy_hauler_dropdown?.Motorcycle}
                        />
                      }
                      label="Motorcycle"
                    />
                    <FormControlLabel
                      sx={{
                        ".MuiTypography-root": {
                          fontFamily: "Montserrat",
                          fontSize: "0.875rem",
                        },
                      }}
                      control={
                        <Checkbox
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled={referralFlag?.state || brokerReviewFlag}
                          size="small"
                          sx={{
                            "&.Mui-checked": {
                              color: "#8ed1fc",
                            },
                            color: "#d9d9d9",
                          }}
                          name="Dirt Bike"
                          onChange={handleToyHaulerChange}
                          checked={value?.toy_hauler_dropdown["Dirt Bike"]}
                        />
                      }
                      label="Dirt Bike"
                    />
                    <FormControlLabel
                      sx={{
                        ".MuiTypography-root": {
                          fontFamily: "Montserrat",
                          fontSize: "0.875rem",
                        },
                      }}
                      control={
                        <Checkbox
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled={referralFlag?.state || brokerReviewFlag}
                          size="small"
                          sx={{
                            "&.Mui-checked": {
                              color: "#8ed1fc",
                            },
                            color: "#d9d9d9",
                          }}
                          name="Cars/Trucks/SUV"
                          onChange={handleToyHaulerChange}
                          checked={
                            value?.toy_hauler_dropdown["Cars/Trucks/SUV"]
                          }
                        />
                      }
                      label="Cars/Trucks/SUV"
                    />
                    {error?.toy_hauler_dropdown && (
                      <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                        {FIELD_ERROR_TEXT}
                      </FormHelperText>
                    )}
                    <FormHelperText
                      sx={{
                        fontStyle: "italic",
                        fontSize: "0.75rem",
                        color: "#a0aec0",
                        margin: "8px 0",
                        lineHeight: "1rem",
                        letterSpacing: "0.7px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      This policy will not provide coverage for ATV's,
                      Motorcycles, and Dirt bikes that are stored within your
                      toy hauler.
                    </FormHelperText>
                  </FormGroup>
                </div>
              )}
              <CheckboxComponent
                label={
                  "Are there any modifications to the trailer? (ie. Addition of permanently installed solar panels)"
                }
                firstoption="Yes"
                secondoption="No"
                error={error}
                setError={setError}
                value={value?.any_mod_to_trailer}
                setValue={setValue}
                object={value}
                error_state={error?.any_mod_to_trailer}
                error_text={FIELD_ERROR_TEXT}
                name={"any_mod_to_trailer"}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              {value?.any_mod_to_trailer === "Yes" && (
                <Select
                  options={modifications_option}
                  name="mod_options"
                  value={value?.mod_options}
                  setValue={setValue}
                  error={error}
                  object={value}
                  setError={setError}
                  error_state={error?.mod_options}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  label={"Select an option"}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />
              )}
              {value?.mod_options === "Solar panels" && (
                <CheckboxComponent
                  label={"Are the solar panels factory/dealer installed"}
                  firstoption="Yes"
                  secondoption="No"
                  error={error}
                  setError={setError}
                  value={value?.solar_panels_installed}
                  setValue={setValue}
                  object={value}
                  error_state={error?.solar_panels_installed}
                  error_text={FIELD_ERROR_TEXT}
                  name={"solar_panels_installed"}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />
              )}
              {value?.solar_panels_installed === "No" && (
                <CheckboxComponent
                  label={
                    "This insurance policy does not cover aftermarket solar panels or any damage arising from the installation or use of such solar panels on the trailer. Would you like to proceed?"
                  }
                  firstoption="Yes"
                  secondoption="No"
                  error={error}
                  setError={setError}
                  value={value?.use_of_solar_on_trailer}
                  setValue={setValue}
                  object={value}
                  error_state={error?.use_of_solar_on_trailer}
                  error_text={FIELD_ERROR_TEXT}
                  name={"use_of_solar_on_trailer"}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />
              )}
              {value?.mod_options === "Interior upgrades" && (
                <div
                  className={`interior_input ${
                    error?.interior_upgrades && "error_field"
                  }`}
                >
                  <label>Please add other details</label>
                  <ConfigProvider
                    theme={{
                      components: {
                        Input: {
                          activeBorderColor: PRIMARY_COLOR,
                          hoverBorderColor: PRIMARY_COLOR,
                          activeShadow: "none",
                          errorActiveShadow: "none",
                        },
                      },
                      token: {
                        colorErrorOutline: "none",
                        colorError: WARNING_COLOR,
                        colorErrorBorderHover: "none",
                      },
                    }}
                  >
                    <Input.TextArea
                      type="text"
                      value={value?.interior_upgrades}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          let updated_error = wholeError.map((m, index) => {
                            if (index === id - 1) {
                              return { ...m, interior_upgrades: true };
                            }

                            return m;
                          });
                          setError(updated_error);
                        } else {
                          let updated_error = wholeError.map((m, index) => {
                            if (index === id - 1) {
                              return { ...m, interior_upgrades: false };
                            }

                            return m;
                          });
                          setError(updated_error);
                        }
                        let updated = wholevalue.map((m, index) => {
                          if (index === id - 1) {
                            return {
                              ...m,
                              interior_upgrades: e.target.value,
                            };
                          }

                          return m;
                        });

                        setValue(updated);
                      }}
                      status={error?.interior_upgrades && "error"}
                      disabled={referralFlag?.state || brokerReviewFlag}
                    />
                  </ConfigProvider>
                  {error?.interior_upgrades && (
                    <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                      {FIELD_ERROR_TEXT}
                    </FormHelperText>
                  )}
                </div>
              )}
              <CheckboxComponent
                label={
                  "Is there any pre-existing damage on the trailer, property/liability claims in the last 5 years or open claims?"
                }
                firstoption="Yes"
                secondoption="No"
                error={error}
                setError={setError}
                value={value?.is_pre_existing_damage}
                setValue={setValue}
                object={value}
                error_state={error?.is_pre_existing_damage}
                error_text={FIELD_ERROR_TEXT}
                name={"is_pre_existing_damage"}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />

              {value?.is_pre_existing_damage === "Yes" ? (
                <div
                  className={`interior_input ${
                    error?.damage_description && "error_field"
                  }`}
                >
                  <label>Describe Damage</label>
                  <ConfigProvider
                    theme={{
                      components: {
                        Input: {
                          activeBorderColor: PRIMARY_COLOR,
                          hoverBorderColor: PRIMARY_COLOR,
                          activeShadow: "none",
                          errorActiveShadow: "none",
                        },
                      },
                      token: {
                        colorErrorOutline: "none",
                        colorError: WARNING_COLOR,
                        colorErrorBorderHover: "none",
                      },
                    }}
                  >
                    <Input.TextArea
                      type="text"
                      value={value?.damage_description}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          let updated_error = wholeError.map((m, index) => {
                            if (index === id - 1) {
                              return { ...m, damage_description: true };
                            }

                            return m;
                          });
                          setError(updated_error);
                        } else {
                          let updated_error = wholeError.map((m, index) => {
                            if (index === id - 1) {
                              return { ...m, damage_description: false };
                            }

                            return m;
                          });
                          setError(updated_error);
                        }
                        let updated = wholevalue.map((m, index) => {
                          if (index === id - 1) {
                            return {
                              ...m,
                              damage_description: e.target.value,
                            };
                          }

                          return m;
                        });

                        setValue(updated);
                      }}
                      disabled={referralFlag?.state || brokerReviewFlag}
                      status={error?.damage_description && "error"}
                    />
                  </ConfigProvider>
                  {error?.damage_description && (
                    <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                      {FIELD_ERROR_TEXT}
                    </FormHelperText>
                  )}
                </div>
              ) : (
                ""
              )}

              <CheckboxComponent
                label={
                  "Is the trailer motorized and able to travel on its own without a towing vehicle"
                }
                firstoption="Yes"
                secondoption="No"
                error={error}
                setError={setError}
                value={value?.is_trailer_motorized}
                setValue={setValue}
                object={value}
                error_state={error?.is_trailer_motorized}
                error_text={FIELD_ERROR_TEXT}
                name={"is_trailer_motorized"}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
            </div>
          </div>
        </div>

        <div
          className={`box multiple_box ${redBoxObj?.state && "redbox"}`}
          style={{ marginTop: "2rem" }}
        >
          <div className="trailer_policy_details">
            <header>
              <div className="side_line"></div>
              <h3>Lienholder</h3>
            </header>
            <div className="container">
              <CheckboxComponent
                label={"Is your trailer financed?"}
                value={value?.trailer_financed}
                object={value}
                name={"trailer_financed"}
                setValue={setValue}
                required={true}
                firstoption="Yes"
                secondoption={"No"}
                error_state={error?.trailer_financed}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              {value?.trailer_financed === "Yes" && (
                <Autocomplete
                  options={common_lienholders}
                  label={"Leinholder"}
                  value={value?.trailer_lienholder}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  name={"trailer_lienholder"}
                  object={value}
                  error_state={error?.trailer_lienholder}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />
              )}

              {value?.trailer_lienholder && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  {/* <AddressAutoComplete
                    label={"Address Line 1"}
                    value={value?.street_leinholder}
                    name={"street_leinholder"}
                    setValue={setValue}
                    object={value}
                    error_state={error?.street_leinholder}
                    error_text={FIELD_ERROR_TEXT}
                    error={error}
                    setError={setError}
                    id={id}
                    wholevalue={wholevalue}
                    // disabled={selectedFinancier}
                    wholeError={wholeError}
                    disabled={
                      referralFlag?.state ||
                      selectedFinancier ||
                      brokerReviewFlag
                    }
                    POSTGRID_API_KEY={organization?.post_grid_api_key}
                  /> */}
                  <TextFieldComponent
                    label={"Address Line 1"}
                    value={value?.street_leinholder}
                    name={"street_leinholder"}
                    setValue={setValue}
                    object={value}
                    error_state={error?.street_leinholder}
                    error_text={FIELD_ERROR_TEXT}
                    error={error}
                    setError={setError}
                    id={id}
                    wholevalue={wholevalue}
                    // disabled={selectedFinancier}
                    wholeError={wholeError}
                    disabled={
                      referralFlag?.state ||
                      selectedFinancier ||
                      brokerReviewFlag
                    }
                  />
                  <TextFieldComponent
                    label={"Suite/Unit Number"}
                    value={value?.line2_leinholder}
                    name={"line2_leinholder"}
                    setValue={setValue}
                    object={value}
                    error_state={error?.line2_leinholder}
                    error_text={FIELD_ERROR_TEXT}
                    error={error}
                    setError={setError}
                    id={id}
                    wholevalue={wholevalue}
                    wholeError={wholeError}
                    disabled={
                      referralFlag?.state ||
                      selectedFinancier ||
                      brokerReviewFlag
                    }
                  />
                  <TextFieldComponent
                    label={"City"}
                    value={value?.city_leinholder}
                    name={"city_leinholder"}
                    setValue={setValue}
                    object={value}
                    error_state={error?.city_leinholder}
                    error_text={FIELD_ERROR_TEXT}
                    error={error}
                    setError={setError}
                    id={id}
                    wholevalue={wholevalue}
                    disabled={
                      referralFlag?.state ||
                      selectedFinancier ||
                      brokerReviewFlag
                    }
                    wholeError={wholeError}
                  />
                  <TextFieldComponent
                    label={"Province"}
                    value={value?.province_leinholder}
                    name={"province_leinholder"}
                    setValue={setValue}
                    object={value}
                    error_state={error?.province_leinholder}
                    error_text={FIELD_ERROR_TEXT}
                    error={error}
                    setError={setError}
                    id={id}
                    wholevalue={wholevalue}
                    disabled={
                      referralFlag?.state ||
                      selectedFinancier ||
                      brokerReviewFlag
                    }
                    wholeError={wholeError}
                  />
                  <TextFieldComponent
                    label={"Postal Code"}
                    value={value?.pc_leinholder}
                    name={"pc_leinholder"}
                    setValue={setValue}
                    object={value}
                    error_state={error?.pc_leinholder}
                    // error_text={FIELD_ERROR_TEXT}
                    error_text={
                      value?.pc_leinholder === ""
                        ? FIELD_ERROR_TEXT
                        : !canadaPostalRegex.test(value.pc_leinholder)
                        ? "Please enter valid postal code"
                        : ""
                    }
                    error={error}
                    setError={setError}
                    id={id}
                    wholevalue={wholevalue}
                    disabled={
                      referralFlag?.state ||
                      selectedFinancier ||
                      brokerReviewFlag
                    }
                    wholeError={wholeError}
                  />
                  <TextFieldComponent
                    label={"Country"}
                    value={value?.country_leinholder}
                    name={"country_leinholder"}
                    setValue={setValue}
                    object={value}
                    error_state={error?.country_leinholder}
                    error_text={FIELD_ERROR_TEXT}
                    error={error}
                    setError={setError}
                    id={id}
                    wholevalue={wholevalue}
                    disabled={
                      referralFlag?.state ||
                      selectedFinancier ||
                      brokerReviewFlag
                    }
                    wholeError={wholeError}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AdditionalInfo;
