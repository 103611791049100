import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Select from "../Select";
import TextFieldComponent from "../TextField";
import SplTextField from "../SplTextField";
import Checkbox from "../Checkbox";
import axios from "axios";
import { APIURL } from "../../api";
import DatePickerComponent from "../DatePicker";
import { COMPONENT_ERROR_TEXT, FIELD_ERROR_TEXT } from "../../utils";
import Autocomplete from "../AutoComplete";

const BoatComponent = ({
  animateX,
  value,
  setValue,
  error,
  setError,
  getStarted,
  id,
  wholevalue,
  wholeError,
  redBoxObj,
  boatNames,
  wholeBoatNames,
  referralFlag,
  brokerReviewFlag,
  boatSpecsFormValues,
  setBoatSpecsFormValues,
  errorBoatSpecs,
  setErrorBoatSpecs,
  coverageFormValues,
  setCoverageFormValues,
}) => {
  const years = [];
  for (
    let year = new Date().getFullYear() + 1;
    year >= new Date().getFullYear() - 30;
    year--
  ) {
    years.push({ value: year, label: year });
  }

  const types_of_watercraft = [
    { value: "Runabout/Deck/Bowrider", text: "Runabout/Deck/Bowrider" },
    { value: "Fishing Boat", text: "Fishing Boat" },
    { value: "Bass Boat", text: "Bass Boat" },
    { value: "Pontoon", text: "Pontoon" },
    { value: "Ski/Wake Boat", text: "Ski/Wake Boat" },
    { value: "SeaDoo/JetSki", text: "SeaDoo/JetSki" },
    { value: "Cabin Cruiser", text: "Cabin Cruiser" },
    { value: "I'm not too sure", text: "I'm not too sure" },
  ];

  const years_of_experience = [
    { value: "0-3 years", text: "0-3 years" },
    { value: "3 or more years", text: "3 or more years" },
  ];

  const [manufacturerOptions, setManufacturerOptions] = useState([]);

  useEffect(() => {
    if (!value?.boat_manufacturer) {
      console.log(boatNames);

      let updated = wholevalue.map((m, index) => {
        if (index === id) {
          return { ...m, boat_manufacturer: boatNames?.name };
        }

        return m;
      });

      console.log(updated);

      setValue(updated);

      let updated_error = wholeError.map((m, index) => {
        if (index === id) {
          return { ...m, boat_manufacturer: false };
        }

        return m;
      });

      setError(updated_error);
    }
  }, []);

  useEffect(() => {
    async function fun() {
      await axios
        .get(`${APIURL}/manufacturer/get_manufacturers?type=Boat`)
        .then((res) => {
          console.log(res);
          let arr = res.data?.data
            .map((m) => {
              return {
                value: m.name,
                label: m?.name,
              };
            })
            .sort();

          console.log(arr);
          setManufacturerOptions(arr);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    fun();
  }, []);
  console.log(wholeError, wholevalue);

  const handleChangeManufacturer = async (e, val) => {
    console.log(val);

    if (val === null) {
      let updated_error = wholeError.map((m, index) => {
        if (index === id) {
          return { ...m, boat_manufacturer: true };
        }

        return m;
      });
      setError(updated_error);
    } else {
      let updated_error = wholeError.map((m, index) => {
        if (index === id) {
          return { ...m, boat_manufacturer: false };
        }

        return m;
      });
      setError(updated_error);
    }

    let updated = wholevalue.map((m, index) => {
      if (index === id) {
        return { ...m, boat_manufacturer: val };
      }

      return m;
    });

    console.log(updated);

    setValue(updated);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    if (value?.is_operator_same_as_owner === "Yes") {
      let updated = wholevalue.map((m, index) => {
        if (index === id) {
          return {
            ...m,
            org_first_name: getStarted[0]?.insured_first_name,
            org_last_name: getStarted[0]?.insured_last_name,
            date_of_birth: getStarted[0]?.date_of_birth,
          };
        }

        return m;
      });

      setValue(updated);

      let updated_error = wholeError.map((m, index) => {
        if (index === id) {
          return {
            ...m,
            org_first_name: false,
            org_last_name: false,
            date_of_birth: false,
          };
        }

        return m;
      });

      setError(updated_error);
    }
  }, []);

  console.log(boatSpecsFormValues, errorBoatSpecs, getStarted);

  return (
    <motion.div
      initial={{ x: animateX, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div>
        <div className={`box ${redBoxObj?.state && "redbox"}`}>
          <div className="boat_form">
            <header>
              <div className="side_line"></div>
              <div>
                <h3 className="error_h3">
                  Boat Details{" "}
                  {redBoxObj?.state ? (
                    <p className="component_error_text">
                      {COMPONENT_ERROR_TEXT}
                    </p>
                  ) : (
                    ""
                  )}{" "}
                </h3>
                <h6 className="boat_name">
                  {wholeBoatNames.length > 1 && `(${boatNames?.name})`}
                </h6>
              </div>
            </header>
            <div className="container">
              <Select
                label={"Select the type of watercraft"}
                options={types_of_watercraft}
                value={value?.type_of_watercraft}
                name={"type_of_watercraft"}
                setValue={setValue}
                error={error}
                setError={setError}
                object={value}
                wholevalue={wholevalue}
                wholeError={wholeError}
                error_state={error?.type_of_watercraft}
                error_text={FIELD_ERROR_TEXT}
                id={id + 1}
                disabled={referralFlag?.state || brokerReviewFlag}
                boatSpecsFormValues={boatSpecsFormValues}
                setBoatSpecsFormValues={setBoatSpecsFormValues}
                errorBoatSpecs={errorBoatSpecs}
                setErrorBoatSpecs={setErrorBoatSpecs}
                coverageFormValues={coverageFormValues}
                setCoverageFormValues={setCoverageFormValues}
              />
              <Select
                label={"Boat Model Year"}
                options={years}
                value={value?.boat_model_year}
                name={"boat_model_year"}
                setValue={setValue}
                error={error}
                setError={setError}
                object={value}
                error_state={error?.boat_model_year}
                error_text={FIELD_ERROR_TEXT}
                id={id + 1}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />

              {/* <div className="textfield">
                <label htmlFor="autocomplete">Boat Manufacturer</label>
                <div>
                  <CustomAutoComplete
                    disablePortal
                    id="autocomplete"
                    options={manufacturerOptions}
                    value={value?.boat_manufacturer}
                    onChange={handleChangeManufacturer}
                    renderInput={(params) => (
                      <CustomTextField
                        error={error?.boat_manufacturer}
                        helperText={
                          error?.boat_manufacturer && FIELD_ERROR_TEXT
                        }
                        {...params}
                      />
                    )}
                  />
                </div>
              </div> */}
              <Autocomplete
                label={"Boat Manufacturer"}
                name={"boat_manufacturer"}
                options={manufacturerOptions}
                value={value?.boat_manufacturer}
                setValue={setValue}
                error={error}
                setError={setError}
                error_state={error?.boat_manufacturer}
                error_text={FIELD_ERROR_TEXT}
                id={id + 1}
                object={value}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />

              {/* <TextFieldComponent
                label={"Boat Manufacturer"}
                value={value?.boat_manufacturer}
                name={"boat_manufacturer"}
                setValue={setValue}
                error={error}
                setError={setError}
                object={value}
                error_state={error?.boat_manufacturer}
                error_text={"Please enter manufacturer"}
                id={id + 1}
                wholevalue={wholevalue}
                wholeError={wholeError}
              /> */}
              <TextFieldComponent
                label={"Boat Model"}
                value={value?.boat_model}
                name={"boat_model"}
                setValue={setValue}
                error={error}
                setError={setError}
                object={value}
                error_state={error?.boat_model}
                error_text={FIELD_ERROR_TEXT}
                id={id + 1}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              {Number(value?.boat_model_year) >=
                new Date().getFullYear() - 15 && (
                <SplTextField
                  label={"Replacement Cost"}
                  value={value?.replacement_cost}
                  name={"replacement_cost"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.replacement_cost}
                  error_text={
                    error?.replacement_cost ? FIELD_ERROR_TEXT : ""
                    // value?.replacement_cost === ""
                    //   ? FIELD_ERROR_TEXT
                    //   : parseFloat(value?.replacement_cost) < 10000
                    //   ? "Your unit's value must be atleast $10,000"
                    //   : FIELD_ERROR_TEXT
                  }
                  id={id + 1}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                  helperText={
                    <>
                      <p style={{ marginBottom: "0.7rem" }}>
                        Your vessel's value must be at least $10,000, including
                        motor(s), trailers, and accessories.
                      </p>
                      <p>
                        To qualify for replacement cost, you must insure for the
                        Current List Price New of similar unit with upgrades
                        plus applicable tax
                      </p>
                    </>
                  }
                />
              )}

              <SplTextField
                label={"Actual Cash Value"}
                value={value?.actual_cash_value}
                name={"actual_cash_value"}
                setValue={setValue}
                error={error}
                setError={setError}
                object={value}
                error_state={error?.actual_cash_value}
                disabled={referralFlag?.state || brokerReviewFlag}
                error_text={
                  error?.actual_cash_value ? FIELD_ERROR_TEXT : ""
                  // value?.actual_cash_value === ""
                  //   ? FIELD_ERROR_TEXT
                  //   : parseFloat(value?.actual_cash_value) < 10000
                  //   ? "Your unit's value must be atleast $10,000"
                  //   : FIELD_ERROR_TEXT
                }
                id={id + 1}
                wholevalue={wholevalue}
                wholeError={wholeError}
                helperText={
                  <>
                    <p style={{ marginBottom: "0.7rem" }}>
                      Your vessel's value must be at least $10,000, including
                      motor(s), trailers, and accessories.
                    </p>
                    <p>
                      Actual Cash Value will be the least of fair market value
                      or what you paid for it with upgrades plus applicable tax.
                      We will pay for a similar unit (year, make, model) to the
                      one you just lost including depreciation.
                    </p>
                  </>
                }
              />
            </div>
          </div>
        </div>
        <div
          className={`box ${redBoxObj?.state && "redbox"}`}
          style={{ marginTop: "2rem" }}
        >
          <div className="boat_form">
            <header>
              <div className="side_line"></div>
              <h3>Principal Operator</h3>
            </header>
            <div className="container">
              <Checkbox
                label={
                  "Is the Principal Operator the same as the Applicant/ Owner?"
                }
                value={value?.is_operator_same_as_owner}
                firstoption={"Yes"}
                secondoption={"No"}
                setValue={setValue}
                name={"is_operator_same_as_owner"}
                error={error}
                object={value}
                setError={setError}
                error_state={error?.is_operator_same_as_owner}
                error_text={FIELD_ERROR_TEXT}
                getStarted={getStarted}
                id={id + 1}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              <TextFieldComponent
                label={"First Name"}
                value={value?.org_first_name}
                name={"org_first_name"}
                setValue={setValue}
                error={error}
                setError={setError}
                object={value}
                error_state={error?.org_first_name}
                error_text={FIELD_ERROR_TEXT}
                id={id + 1}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={
                  value?.is_operator_same_as_owner === "Yes" ||
                  referralFlag?.state ||
                  brokerReviewFlag
                }
              />
              <TextFieldComponent
                label={"Last Name"}
                value={value?.org_last_name}
                name={"org_last_name"}
                setValue={setValue}
                error={error}
                setError={setError}
                object={value}
                error_state={error?.org_last_name}
                error_text={FIELD_ERROR_TEXT}
                id={id + 1}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={
                  value?.is_operator_same_as_owner === "Yes" ||
                  referralFlag?.state ||
                  brokerReviewFlag
                }
              />
              <DatePickerComponent
                label={"Date of Birth"}
                value={value?.date_of_birth}
                name={"date_of_birth"}
                setValue={setValue}
                error={error}
                setError={setError}
                object={value}
                error_state={error?.date_of_birth}
                error_text={FIELD_ERROR_TEXT}
                id={id + 1}
                wholevalue={wholevalue}
                wholeError={wholeError}
                placeholder={"DD/MM/YYYY"}
                max={(current) => {
                  return current && current > new Date();
                }}
                disabled={
                  value?.is_operator_same_as_owner === "Yes" ||
                  referralFlag?.state ||
                  brokerReviewFlag
                }
              />
              {/* <TextFieldComponent
                type={"date"}
                label={"Date of Birth"}
                value={value?.date_of_birth}
                name={"date_of_birth"}
                setValue={setValue}
                error={error}
                setError={setError}
                object={value}
                error_state={error?.date_of_birth}
                error_text={"Please select date of birth"}
                id={id + 1}
                wholevalue={wholevalue}
                wholeError={wholeError}
              /> */}
              <Select
                label={
                  "Principal operator's years of boating experience with vessels of a similar size and type."
                }
                options={years_of_experience}
                value={value?.boating_experience}
                name={"boating_experience"}
                setValue={setValue}
                error={error}
                setError={setError}
                object={value}
                error_state={error?.boating_experience}
                error_text={FIELD_ERROR_TEXT}
                id={id + 1}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              <Checkbox
                label={
                  "Does the operator hold a Pleasure Craft Operator's Card (PCOC)?"
                }
                value={value?.operator_hold_pcoc}
                firstoption={"Yes"}
                secondoption={"No"}
                setValue={setValue}
                object={value}
                name={"operator_hold_pcoc"}
                error={error}
                setError={setError}
                error_state={error?.operator_hold_pcoc}
                error_text={FIELD_ERROR_TEXT}
                id={id + 1}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />{" "}
            </div>
          </div>
        </div>
        {/* {id === boatCount.length && (
          <div className="trailer_btns">
            <button
              className="next_btn"
              onClick={() => {
                const allValuesUndefined = Object.values(error).some(
                  (value) => value === undefined
                );

                console.log(allValuesUndefined);
                if (allValuesUndefined) {
                  const updatedFormData = { ...error };
                  for (const key in updatedFormData) {
                    if (updatedFormData.hasOwnProperty(key)) {
                      if (updatedFormData[key] === undefined)
                        updatedFormData[key] = true;
                    }
                  }

                  setError(updatedFormData);
                  setRedBox(true);
                  return;
                }

                console.log(error);
                const anyValueIsTrue = Object.values(error).some(
                  (value) => value === true
                );

                console.log(anyValueIsTrue);

                if (anyValueIsTrue) {
                  setRedBox(true);
                  return;
                }

                setAnimateX(100);

                nextPage(formNumber + 1);
              }}
            >
              Next
            </button>
            {formNumber === 1 ? (
              ""
            ) : (
              <div
                className="back_btn"
                onClick={() => {
                  setAnimateX(-100);

                  backPage(formNumber - 1);
                }}
              >
                Back
              </div>
            )}
          </div>
        )} */}
      </div>
    </motion.div>
  );
};

export default BoatComponent;
