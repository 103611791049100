import React, { useEffect, useState } from "react";
import ProgressBar from "../components/Boat/ProgressBar";
import GetStarted from "../components/Boat/GetStarted";
import PolicyDetails from "../components/Boat/PolicyDetails";
import Terms from "../components/Trailer/Terms";
import BoatMain from "./BoatMain";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "../api";
import dayjs from "dayjs";
import {
  addons,
  boat,
  boat_policy_details,
  boat_policy_details_error,
  boat_specs,
  eligiblity,
  err_boat_specs,
  errorValueCheckFn,
  getStarted,
  terms,
} from "../utils";
import Payment from "../components/Boat/Payment";
import { Footer } from "../components/Footer";
import { useLocation } from "react-router-dom";
import ServerError from "./ServerError";
import { Result } from "antd";

const coverage = {
  deductible_ac: 1000,
  personal_effects_coverage_ac: 5000,
  nav_coverage_ac: 2500,
  deductible_rc: 1000,
  personal_effects_coverage_rc: 5000,
  nav_coverage_rc: 2500,
  coverage_name: undefined,
};

const error_coverage = {
  deductible_ac: false,
  personal_effects_coverage_ac: false,
  nav_coverage_ac: false,
  deductible_rc: false,
  personal_effects_coverage_rc: false,
  nav_coverage_rc: false,
  coverage_name: undefined,
};

const Boat = () => {
  const location = useLocation();

  const [formNumber, setFormNumber] = useState(1);
  const [animateX, setAnimateX] = useState(0);

  const [getStartedFormValues, setGetStartedFormValues] = useState([
    getStarted,
  ]);
  const [errorGetStarted, setErrorGetStarted] = useState([getStarted]);

  const [isAddNamesSent, setIsAddNamesSent] = useState(false);
  const [additionalNames, setAdditionalNames] = useState([
    {
      id: 1,
      add_first_name: "",
      add_last_name: "",
      add_email: "",
      add_phone: "",
      add_dob: "",
    },
  ]);
  const [errorAdditionalNames, setErrorAdditionalNames] = useState(false);

  const [boatFormValues, setBoatFormValues] = useState([boat]);
  const [errorBoat, setErrorBoat] = useState([boat]);

  const [coverageFormValues, setCoverageFormValues] = useState([coverage]);
  const [errorCoverage, setErrorCoverage] = useState([error_coverage]);

  const [eligiblityFormValues, setEligiblityFormValues] = useState([
    eligiblity,
  ]);
  const [errorEligiblity, setErrorEligiblity] = useState([eligiblity]);

  const [boatSpecsFormValues, setBoatSpecsFormValues] = useState([boat_specs]);
  const [errorBoatSpecs, setErrorBoatSpecs] = useState([err_boat_specs]);

  const [addonsFormValues, setAddonsFormValues] = useState([addons]);
  const [errorAddons, setErrorAddons] = useState([addons]);

  const [policyDetailFormValues, setPolicyDetailFormValues] = useState([
    boat_policy_details,
  ]);
  const [errorPolicyDetails, setErrorPolicyDetails] = useState([
    boat_policy_details_error,
  ]);

  const [termsValues, setTermsValues] = useState([terms]);
  const [errorTerms, setErrorTerms] = useState([terms]);

  const [boatNames, setBoatNames] = useState([{ name: undefined }]);
  const [errorBoatNames, setErrorBoatNames] = useState([{ name: undefined }]);

  const nextPage = (page) => {
    setFormNumber(page);
  };

  const backPage = (page) => {
    setFormNumber(page);
  };

  const [addBoatCount, setAddBoatCount] = useState([{ id: 1, name: "boat 1" }]);

  const number_of_boats = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
  ];

  console.log(coverageFormValues, "coverage", errorBoatSpecs);

  const addBoat = (val) => {
    let array = [...addBoatCount];

    let redBoat = [...boatRedBoxObj];
    let redQuote = [...quotesRedBoxObj];
    let redEligibility = [...eligiblityRedBoxObj];
    let redBoatSpecs = [...boatSpecsRedBoxObj];
    let redAddons = [...addonsRedBoxObj];

    console.log(array.length - val);

    if (array.length > val) {
      for (let i = array.length; i > val; i--) {
        array.pop();
        redBoat.pop();
        redQuote.pop();
        redEligibility.pop();
        redBoatSpecs.pop();
        redAddons.pop();
      }
    } else {
      for (let i = array.length + 1; i <= val; i++) {
        array.push({ id: i, name: `boat ${i}` });
        redBoat.push({ id: i, state: false });
        redQuote.push({ id: i, state: false });
        redEligibility.push({ id: i, state: false });
        redBoatSpecs.push({ id: i, state: false });
        redAddons.push({ id: i, state: false });
      }
    }

    console.log(array);
    setAddBoatCount(array);

    setBoatRedBoxObj(redBoat);
    setQuoteRedBoxObj(redQuote);
    setEligibilityRedBoxObj(redEligibility);
    setBoatSpecsRedBoxObj(redBoatSpecs);
    setAddonsRedBoxObj(redAddons);

    setBoatNumber(val);
  };

  const [signatureObj, setSignatureObj] = useState({ text: "", font: "" });

  const [boatNumber, setBoatNumber] = useState(1);

  const [boatRedBoxObj, setBoatRedBoxObj] = useState([{ id: 1, state: false }]);
  const [quotesRedBoxObj, setQuoteRedBoxObj] = useState([
    { id: 1, state: false },
  ]);
  const [eligiblityRedBoxObj, setEligibilityRedBoxObj] = useState([
    { id: 1, state: false },
  ]);
  const [boatSpecsRedBoxObj, setBoatSpecsRedBoxObj] = useState([
    { id: 1, state: false },
  ]);
  const [addonsRedBoxObj, setAddonsRedBoxObj] = useState([
    { id: 1, state: false },
  ]);

  const [boatNumberId, setBoatNumberId] = useState(1);
  const [internalBoatState, setInternalBoatState] = useState(1);

  const [getStartedCount, setGetStartedCount] = useState([1, 2]);
  const [getStartedId, setGetStartedId] = useState(1);
  const [globalData, setGlobalData] = useState({
    quote_id: "",
    customer_id: "",
    quote_record_id: "",
  });

  const [paymentFormValues, setPaymentFormvalues] = useState({
    autoCharge: false,
    payment_mode: "",
  });

  const [paymentId, setPaymentId] = useState(1);

  const [lienHolders, setLeinHolders] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  //saves the response for existing quote
  const [responseData, setResponseData] = useState(null);
  const [defaultData, setDefaultData] = useState({
    quote_status: "",
    coverage_gtag_sent: false,
    referral_gtag_sent: false,
  });

  function ReturnFalseObj(arrayOfObjects) {
    const newArray = arrayOfObjects.map((obj) => {
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        newObj[key] = false;
      });
      return newObj;
    });

    return newArray;
  }

  console.log(boatFormValues);

  useEffect(() => {
    async function fun() {
      await axios
        .post(`${APIURL}/organization/get_organization`, {
          organization_id: ORGANIZATION_ID,
          required_keys: [
            "organization_id",
            "name",
            "email",
            "webhook_url",
            "post_grid_api_key",
            "supported_payment_modes",
            "sales_email",
          ],
        })
        .then((res) => {
          console.log(res);

          setOrganization(res?.data?.data?.organization);
        })
        .catch((err) => {
          console.log(err);
        });

      let sessionQuoteRecordId =
        window.sessionStorage.getItem("boat_record_id");

      let sessionQuoteId = window.sessionStorage.getItem("boat_quote_id");
      let data = { ...globalData };

      const searchParams = new URLSearchParams(location.search);

      let quote_record_id = searchParams.get("quote_record_id");

      //checks quote record id is in session storage
      if (sessionQuoteId || quote_record_id) {
        await axios
          .get(
            `${APIURL}/boat/get_boat_quote?quote_record_id=${
              quote_record_id
                ? quote_record_id
                : sessionQuoteRecordId
                ? sessionQuoteRecordId
                : ""
            }`
          )
          .then(async (res) => {
            console.log(res);

            if (res?.data?.success === false) {
              //if quote record id is incorrect
              if (
                res.data?.message ===
                `BoarQuote Not Found with given id ${quote_record_id}`
              ) {
                setLoading("id_not_found");
                return;
              }

              let data = { ...globalData };

              data.quote_id = res?.data?.data?.quote?.quote_id
                ? res?.data?.data?.quote?.quote_id
                : sessionQuoteId
                ? sessionQuoteId
                : "";
              data.quote_record_id = quote_record_id
                ? quote_record_id
                : sessionQuoteRecordId
                ? sessionQuoteRecordId
                : "";

              console.log(data, "data");
              setGlobalData(data);
              setLoading("success");

              if (!data.quote_id || !data.quote_record_id) {
                setLoading("error");
              }

              return;
            }

            let boat_data_obj = [],
              coverage_data_obj = [],
              eligiblity_data_obj = [],
              boat_specs_data_obj = [],
              addons_data_obj = [],
              error_boat = [],
              error_coverage = [],
              error_eligiblity = [],
              error_boatSpecs = [],
              error_addons = [],
              customer = [{}],
              error_customer = [{}],
              policy_details = [{}],
              error_policy_details = [{}],
              terms = [{}],
              error_terms = [{}],
              payment = {},
              additional_names = [];

            let boat = res?.data?.data?.quote?.boats;
            let customer_data = res?.data?.data?.customer;
            let boat_name_obj = [];
            let add_names_data = res?.data?.data?.additional_names;

            if (boat.length) {
              setResponseData(boat);
            }

            data.quote_id = res?.data?.data?.quote?.quote_id
              ? res?.data?.data?.quote?.quote_id
              : sessionQuoteId
              ? sessionQuoteId
              : "";
            data.quote_record_id = quote_record_id
              ? quote_record_id
              : sessionQuoteRecordId
              ? sessionQuoteRecordId
              : "";

            if (!data.quote_id || !data.quote_record_id) {
              setLoading("error");
            }

            data.customer_id = customer_data?.customer_id;

            customer[0].date_of_birth = customer_data?.dob
              ? dayjs(customer_data?.dob, "DD/MM/YYYY")
              : undefined;
            customer[0].email = customer_data?.email;
            customer[0].insured_first_name = customer_data?.first_name;
            customer[0].insured_last_name = customer_data?.last_name;
            customer[0].phone = customer_data?.phone;
            customer[0].find_us = customer_data?.where_you_find_us;
            customer[0].additional_name = res?.data?.data?.quote
              ?.any_additional_names
              ? "Yes"
              : res?.data?.data?.quote?.any_additional_names === false
              ? "No"
              : undefined;

            customer[0].find_us_value =
              customer_data?.where_you_find_us === "Social Media"
                ? customer_data?.social_media_option_name
                : customer_data?.where_you_find_us === "Friend or Family"
                ? customer_data?.friend_option_name
                : customer_data?.where_you_find_us === "Dealership"
                ? customer_data?.dealership_option_name
                : customer_data?.where_you_find_us === "Campground"
                ? customer_data?.campground_option_name
                : customer_data?.where_you_find_us === "Marina"
                ? customer_data?.marina_option_name
                : customer_data?.where_you_find_us === "Other"
                ? customer_data?.others_option_name
                : undefined;

            customer[0].province = res?.data?.data?.quote?.located_province;

            setGlobalData(data);
            setGetStartedFormValues(customer);

            error_customer[0].insured_first_name = customer_data?.first_name
              ? false
              : undefined;
            error_customer[0].insured_last_name = customer_data?.last_name
              ? false
              : undefined;
            error_customer[0].email = customer_data?.email ? false : undefined;
            error_customer[0].date_of_birth = customer_data?.dob
              ? false
              : undefined;
            error_customer[0].phone = customer_data?.phone ? false : undefined;
            error_customer[0].find_us = customer_data?.where_you_find_us
              ? false
              : undefined;
            error_customer[0].find_us_value =
              customer_data?.where_you_find_us === "Google"
                ? false
                : !customer[0].find_us_value
                ? undefined
                : false;

            error_customer[0].province = res?.data?.data?.quote
              ?.located_province
              ? false
              : undefined;
            error_customer[0].additional_name =
              res?.data?.data?.quote?.any_additional_names ||
              res?.data?.data?.quote?.any_additional_names === false
                ? false
                : undefined;

            // error_customer = ReturnFalseObj(errorGetStarted);

            setErrorGetStarted(error_customer);

            if (add_names_data.length) {
              for (let i = 0; i < add_names_data.length; i++) {
                additional_names.push({
                  id: i + 1,
                  add_first_name: add_names_data[i].first_name,
                  add_last_name: add_names_data[i].last_name,
                  add_email: add_names_data[i].email,
                  add_phone: add_names_data[i].phone,
                  add_dob: dayjs(add_names_data[i].dob, "DD/MM/YYYY"),
                });
              }

              setAdditionalNames(additional_names);

              // setIsAddNamesSent(true);
            }

            //for multiple boats, so we are creating the boats length to the formdata for the respective data
            if (boat.length) {
              for (let i = 0; i < boat.length; i++) {
                boat_data_obj.push({});
                coverage_data_obj.push({});
                eligiblity_data_obj.push({});
                boat_specs_data_obj.push({});
                addons_data_obj.push({});
                error_boat.push({});
                error_coverage.push({});
                error_eligiblity.push({});
                error_boatSpecs.push({});
                error_addons.push({});
                boat_name_obj.push({});
              }
            } else {
              setLoading("success");

              return;
            }

            setLoading("success");

            policy_details[0].mailing_country = customer_data?.mailing_country;
            policy_details[0].mailing_address =
              customer_data?.mailing_address_line1;
            policy_details[0].mailing_suite =
              customer_data?.mailing_address_line2;
            policy_details[0].mailing_city = customer_data?.mailing_city;
            policy_details[0].mailing_province =
              customer_data?.mailing_province;
            policy_details[0].mailing_postal_code =
              customer_data?.mailing_postalcode;
            policy_details[0].inception_date = res?.data?.data?.quote
              ?.inception_date
              ? dayjs(res?.data?.data?.quote?.inception_date, "DD/MM/YYYY")
              : undefined;
            policy_details[0].expiry_date = res?.data?.data?.quote?.expiry_date
              ? dayjs(res?.data?.data?.quote?.expiry_date, "DD/MM/YYYY")
              : undefined;
            policy_details[0].signature = undefined;

            error_policy_details[0].inception_date = res?.data?.data?.quote
              .inception_date
              ? false
              : undefined;
            error_policy_details[0].expiry_date = res?.data?.data?.quote
              .expiry_date
              ? false
              : undefined;
            error_policy_details[0].mailing_country =
              customer_data?.mailing_country ? false : undefined;
            error_policy_details[0].mailing_address =
              customer_data?.mailing_address_line1 ? false : undefined;
            error_policy_details[0].mailing_suite = false;
            error_policy_details[0].mailing_city = customer_data?.mailing_city
              ? false
              : undefined;
            error_policy_details[0].mailing_province =
              customer_data?.mailing_province ? false : undefined;
            error_policy_details[0].mailing_postal_code =
              customer_data?.mailing_postalcode ? false : undefined;
            error_policy_details[0].signature = res?.data?.data?.quote
              .signature_accept_text
              ? false
              : undefined;

            setSignatureObj({
              text: res?.data?.data?.quote.signature_accept_text,
              font: "",
            });

            terms[0].terms_checked = res?.data?.data?.quote?.agreed_to_tnc;
            error_terms[0].terms_checked = res?.data?.data?.quote?.agreed_to_tnc
              ? false
              : undefined;

            payment.autoCharge = res?.data?.data?.quote?.is_auto_charge_enabled
              ? true
              : false;

            setPaymentFormvalues({
              autoCharge: payment.autoCharge,
              payment_mode: "",
            });

            setTermsValues(terms);
            setErrorTerms(error_terms);

            setPolicyDetailFormValues(policy_details);
            setErrorPolicyDetails(error_policy_details);

            setBoatNumber(res?.data?.data?.quote?.number_of_boats);

            if (res?.data?.data?.quote?.number_of_boats > 1) {
              addBoat(res?.data?.data?.quote?.number_of_boats);

              for (let i = 0; i < boat.length; i++) {
                boat_name_obj[i].name = boat[i].manufacturer;
              }

              setErrorBoatNames([{ name: false }, { name: false }]);

              setBoatNames(boat_name_obj);
            }

            for (let i = 0; i < boat.length; i++) {
              boat_data_obj[i].type_of_watercraft = boat[i]?.type;
              boat_data_obj[i].boat_model_year = boat[i]?.year;
              boat_data_obj[i].boat_manufacturer = boat[i]?.manufacturer;
              boat_data_obj[i].boat_model = boat[i]?.model;
              boat_data_obj[i].replacement_cost = boat[i].replacement_cost;
              boat_data_obj[i].actual_cash_value = boat[i].actual_cost_value;
              boat_data_obj[i].is_operator_same_as_owner = boat[i]
                ?.is_main_operator_same_as_owner
                ? "Yes"
                : boat[i]?.is_main_operator_same_as_owner === false
                ? "No"
                : undefined;
              boat_data_obj[i].org_first_name = boat[i]
                ?.is_main_operator_same_as_owner
                ? customer_data?.first_name
                : boat[i].main_operator_first_name;
              boat_data_obj[i].org_last_name = boat[i]
                ?.is_main_operator_same_as_owner
                ? customer_data?.last_name
                : boat[i].main_operator_last_name;
              boat_data_obj[i].date_of_birth = boat[i]
                ?.is_main_operator_same_as_owner
                ? dayjs(customer_data?.dob, "DD/MM/YYYY")
                : boat[i].main_operator_dob
                ? dayjs(boat[i].main_operator_dob, "DD/MM/YYYY")
                : undefined;
              boat_data_obj[i].boating_experience =
                boat[i].main_operator_boating_experience;
              boat_data_obj[i].operator_hold_pcoc = boat[i]
                .main_operator_hold_pcoc_card
                ? "Yes"
                : boat[i].main_operator_hold_pcoc_card === false
                ? "No"
                : undefined;
              coverage_data_obj[i].deductible = boat[i].deductible;
              coverage_data_obj[i].deductible_rc =
                boat[i]?.coverage_name === "Replacement Value Coverage" &&
                boat[i].deductible
                  ? boat[i].deductible
                  : 1000;
              coverage_data_obj[i].deductible_ac =
                boat[i]?.coverage_name === "Current Market Value Coverage" &&
                boat[i].deductible
                  ? boat[i].deductible
                  : 1000;
              coverage_data_obj[i].personal_effects_coverage_ac =
                boat[i]?.coverage_name === "Current Market Value Coverage" &&
                boat[i].personal_effects_coverage
                  ? boat[i].personal_effects_coverage
                  : 5000;
              coverage_data_obj[i].personal_effects_coverage_rc =
                boat[i]?.coverage_name === "Replacement Value Coverage" &&
                boat[i].personal_effects_coverage
                  ? boat[i].personal_effects_coverage
                  : 5000;
              coverage_data_obj[i].nav_coverage_ac =
                boat[i]?.coverage_name === "Current Market Value Coverage" &&
                boat[i].navigational_equipment_coverage
                  ? boat[i].navigational_equipment_coverage
                  : 2500;
              coverage_data_obj[i].nav_coverage_rc =
                boat[i]?.coverage_name === "Replacement Value Coverage" &&
                boat[i]?.navigational_equipment_coverage
                  ? boat[i]?.navigational_equipment_coverage
                  : 2500;

              coverage_data_obj[i].personal_effects_coverage =
                boat[i].personal_effects_coverage;
              coverage_data_obj[i].navigational_equipment_coverage =
                boat[i].navigational_equipment_coverage;
              coverage_data_obj[i].premium_per_year = boat[i].premium_per_year;
              coverage_data_obj[i].coverage = boat[i].coverage;
              coverage_data_obj[i].coverage_name = boat[i].coverage_name;
              coverage_data_obj[i].liability_coverage =
                boat[i].liability_coverage;
              coverage_data_obj[i].salvage = boat[i].salvage;
              coverage_data_obj[i].pollution = boat[i].pollution;
              coverage_data_obj[i].removal_of_wreckage =
                boat[i].removal_of_wreckage;
              coverage_data_obj[i].emergency_towing_limit =
                boat[i].emergency_towing_limit;
              coverage_data_obj[i].uninsured_underinsured_boater_endorsement =
                boat[i].uninsured_underinsured_boater_endorsement;
              coverage_data_obj[i].loss_of_use_limit =
                boat[i].loss_of_use_limit;
              eligiblity_data_obj[i].watercraft_used_in_atlantic = boat[i]
                .will_boat_be_used_in_atlantic_pacific_questions
                ? "Yes"
                : boat[i].will_boat_be_used_in_atlantic_pacific_questions ===
                  false
                ? "No"
                : undefined;
              eligiblity_data_obj[i].first_apply_to_watercraft = boat[i]
                .is_boat_rented_or_carry_passengers_or_existing_damages_questions
                ? "Yes"
                : boat[i]
                    .is_boat_rented_or_carry_passengers_or_existing_damages_questions ===
                  false
                ? "No"
                : undefined;
              eligiblity_data_obj[i].second_apply_to_watercraft = boat[i]
                .is_boat_layered_up_and_safety_questions
                ? "Yes"
                : boat[i].is_boat_layered_up_and_safety_questions === false
                ? "No"
                : undefined;
              eligiblity_data_obj[i].apply_to_applicant = boat[i]
                .is_owner_employees_or_lives_on
                ? "Yes"
                : boat[i].is_owner_employees_or_lives_on === false
                ? "No"
                : undefined;
              eligiblity_data_obj[i].claims_in_last_five_years = boat[i]
                .operator_have_claim_in_last_5_years
                ? "Yes"
                : boat[i].operator_have_claim_in_last_5_years === false
                ? "No"
                : undefined;
              eligiblity_data_obj[i].no_of_claims_in_last_five_years =
                boat[i].how_many_claims;
              eligiblity_data_obj[i].claims_in_last_three_years = boat[i]
                .operator_have_motor_convictions_in_last_3_years
                ? "Yes"
                : boat[i].operator_have_motor_convictions_in_last_3_years ===
                  false
                ? "No"
                : undefined;
              eligiblity_data_obj[i].no_of_claims_in_last_three_years =
                boat[i].how_many_convictions;
              eligiblity_data_obj[i].driver_license_suspended = boat[i]
                .operator_had_suspended_driver_license
                ? "Yes"
                : boat[i].operator_had_suspended_driver_license === false
                ? "No"
                : undefined;
              eligiblity_data_obj[i].when_license_suspended =
                boat[i].when_suspended;
              boat_specs_data_obj[i].boat_length = boat[i].length_in_feet;
              boat_specs_data_obj[i].hull_id = boat[i].hull_serial_id;
              boat_specs_data_obj[i].hull_type = boat[i].hull_type;
              boat_specs_data_obj[i].other_hull_type =
                boat[i].hull_type_for_others;
              boat_specs_data_obj[i].est_max_speed = boat[i].max_est_speed;
              boat_specs_data_obj[i].main1_engine_type = boat[i].engine1_type;
              boat_specs_data_obj[i].main1_other_engine_type =
                boat[i].engine1_others_type;
              boat_specs_data_obj[i].main1_fuel_type =
                boat[i].engine1_fuel_type;
              boat_specs_data_obj[i].main1_boat_engine_model_year =
                boat[i].engine1_model_year;
              boat_specs_data_obj[i].main1_boat_engine_manufacturer =
                boat[i].engine1_manufacturer;
              boat_specs_data_obj[i].main1_boat_engine_serail =
                boat[i].engine1_serial_number;
              boat_specs_data_obj[i].main1_boat_engine_horsepower =
                boat[i].engine1_horse_power;
              boat_specs_data_obj[i].main2_engine_type = boat[i].engine2_type;
              boat_specs_data_obj[i].main2_other_engine_type =
                boat[i].engine2_others_type;
              boat_specs_data_obj[i].main2_fuel_type =
                boat[i].engine2_fuel_type;
              boat_specs_data_obj[i].main2_boat_engine_model_year =
                boat[i].engine2_model_year;
              boat_specs_data_obj[i].main2_boat_engine_manufacturer =
                boat[i].engine2_manufacturer;
              boat_specs_data_obj[i].main2_boat_engine_serail =
                boat[i].engine2_serial_number;
              boat_specs_data_obj[i].main2_boat_engine_horsepower =
                boat[i].engine2_horse_power;

              addons_data_obj[i].add_trailer = boat[i].add_a_trailer
                ? "Yes"
                : boat[i].add_a_trailer === false
                ? "No"
                : undefined;
              addons_data_obj[i].trailer_value = boat[i].trailer_value;
              addons_data_obj[i].trailer_model_year =
                boat[i].trailer_model_year;
              addons_data_obj[i].trailer_manufacturer =
                boat[i].trailer_manufacturer;
              addons_data_obj[i].trailer_serial = boat[i].trailer_vin;
              addons_data_obj[i].trailer_length = boat[i].trailer_length;
              addons_data_obj[i].add_tender = boat[i].add_a_tender
                ? "Yes"
                : boat[i].add_a_tender === false
                ? "No"
                : undefined;
              addons_data_obj[i].tender_value = boat[i].tender_value;
              addons_data_obj[i].tender_model_year = boat[i].tender_model_year;
              addons_data_obj[i].tender_manufacturer =
                boat[i].tender_manufacturer;
              addons_data_obj[i].tender_serial = boat[i].tender_vin;
              addons_data_obj[i].tender_length = boat[i].tender_length;
              addons_data_obj[i].add_auxillary_motor = boat[i]
                .add_auxiliary_motor
                ? "Yes"
                : boat[i].add_auxiliary_motor === false
                ? "No"
                : undefined;
              addons_data_obj[i].aux_engine_value = boat[i].aux_value;
              addons_data_obj[i].aux_engine_model_year = boat[i].aux_model_year;
              addons_data_obj[i].aux_engine_manufacturer =
                boat[i].aux_manufacturer;
              addons_data_obj[i].aux_engine_serial = boat[i].aux_vin;
              addons_data_obj[i].aux_engine_hp = boat[i].aux_length;

              addons_data_obj[i].boat_financed = boat[i].is_financed
                ? "Yes"
                : boat[i].is_financed === false
                ? "No"
                : undefined;
              addons_data_obj[i].boat_lienholder = boat[i].financier_name;
              addons_data_obj[i].other_lienholder = boat[i].financier_name;
              addons_data_obj[i].street_leinholder =
                boat[i].financier_address_line1;
              addons_data_obj[i].line2_leinholder =
                boat[i].financier_address_line2;
              addons_data_obj[i].city_leinholder = boat[i].financier_city;
              addons_data_obj[i].province_leinholder =
                boat[i].financier_province;
              addons_data_obj[i].country_leinholder = boat[i].financier_country;
              addons_data_obj[i].pc_leinholder = boat[i].financier_postal_code;

              error_boat[i].type_of_watercraft = boat[i]?.type
                ? false
                : undefined;
              error_boat[i].boat_model_year = boat[i]?.year ? false : undefined;
              error_boat[i].boat_manufacturer = boat[i]?.manufacturer
                ? false
                : undefined;
              error_boat[i].boat_model = boat[i]?.model ? false : undefined;
              error_boat[i].replacement_cost =
                Number(boat[i]?.year) >= new Date().getFullYear() - 15 &&
                !boat[i].replacement_cost
                  ? undefined
                  : false;
              error_boat[i].actual_cash_value = errorValueCheckFn(
                boat[i].actual_cost_value
              );
              error_boat[i].is_operator_same_as_owner = boat[i]
                ?.is_main_operator_same_as_owner
                ? false
                : boat[i]?.is_main_operator_same_as_owner === false
                ? false
                : undefined;
              error_boat[i].org_first_name = errorValueCheckFn(
                boat[i].main_operator_first_name
              );
              error_boat[i].org_last_name = errorValueCheckFn(
                boat[i].main_operator_last_name
              );
              error_boat[i].date_of_birth = errorValueCheckFn(
                boat[i].main_operator_dob
              );
              error_boat[i].boating_experience = errorValueCheckFn(
                boat[i].main_operator_boating_experience
              );
              error_boat[i].operator_hold_pcoc = boat[i]
                .main_operator_hold_pcoc_card
                ? false
                : boat[i].main_operator_hold_pcoc_card === false
                ? false
                : undefined;
              error_coverage[i].deductible = false;
              error_coverage[i].deductible_rc = false;
              error_coverage[i].deductible_ac = false;
              error_coverage[i].personal_effects_coverage_ac = false;
              error_coverage[i].personal_effects_coverage_rc = false;
              error_coverage[i].nav_coverage_ac = false;
              error_coverage[i].nav_coverage_rc = false;

              error_coverage[i].personal_effects_coverage = false;
              error_coverage[i].navigational_equipment_coverage = false;
              error_coverage[i].premium_per_year = false;
              error_coverage[i].coverage = false;
              error_coverage[i].coverage_name = errorValueCheckFn(
                boat[i]?.coverage_name
              );
              error_coverage[i].liability_coverage = false;
              error_coverage[i].salvage = false;
              error_coverage[i].pollution = false;
              error_coverage[i].removal_of_wreckage = false;
              error_coverage[i].emergency_towing_limit = false;
              error_coverage[
                i
              ].uninsured_underinsured_boater_endorsement = false;
              error_coverage[i].loss_of_use_limit = false;
              error_eligiblity[i].watercraft_used_in_atlantic = boat[i]
                .will_boat_be_used_in_atlantic_pacific_questions
                ? false
                : boat[i].will_boat_be_used_in_atlantic_pacific_questions ===
                  false
                ? false
                : undefined;
              error_eligiblity[i].first_apply_to_watercraft = boat[i]
                .is_boat_rented_or_carry_passengers_or_existing_damages_questions
                ? false
                : boat[i]
                    .is_boat_rented_or_carry_passengers_or_existing_damages_questions ===
                  false
                ? false
                : undefined;
              error_eligiblity[i].second_apply_to_watercraft = boat[i]
                .is_boat_layered_up_and_safety_questions
                ? false
                : boat[i].is_boat_layered_up_and_safety_questions === false
                ? false
                : undefined;
              error_eligiblity[i].apply_to_applicant = boat[i]
                .is_owner_employees_or_lives_on
                ? false
                : boat[i].is_owner_employees_or_lives_on === false
                ? false
                : undefined;
              error_eligiblity[i].claims_in_last_five_years = boat[i]
                .operator_have_claim_in_last_5_years
                ? false
                : boat[i].operator_have_claim_in_last_5_years === false
                ? false
                : undefined;

              error_eligiblity[i].no_of_claims_in_last_five_years =
                boat[i].operator_have_claim_in_last_5_years &&
                boat[i].how_many_claims
                  ? false
                  : boat[i].operator_have_claim_in_last_5_years &&
                    !boat[i].how_many_claims
                  ? undefined
                  : false;
              error_eligiblity[i].claims_in_last_three_years = boat[i]
                .operator_have_motor_convictions_in_last_3_years
                ? false
                : boat[i].operator_have_motor_convictions_in_last_3_years ===
                  false
                ? false
                : undefined;

              error_eligiblity[i].no_of_claims_in_last_three_years =
                boat[i].operator_have_motor_convictions_in_last_3_years &&
                !boat[i].how_many_convictions
                  ? undefined
                  : false;
              error_eligiblity[i].driver_license_suspended = boat[i]
                .operator_had_suspended_driver_license
                ? false
                : boat[i].operator_had_suspended_driver_license === false
                ? false
                : undefined;
              error_eligiblity[i].when_license_suspended =
                boat[i].operator_had_suspended_driver_license &&
                !boat[i].when_suspended
                  ? undefined
                  : false;
              error_boatSpecs[i].boat_length = errorValueCheckFn(
                boat[i].length_in_feet
              );
              error_boatSpecs[i].hull_id = errorValueCheckFn(
                boat[i].hull_serial_id
              );
              error_boatSpecs[i].hull_type = errorValueCheckFn(
                boat[i].hull_type
              );
              error_boatSpecs[i].other_hull_type =
                boat[i].hull_type === "Other" && !boat[i].hull_type_for_others
                  ? undefined
                  : false;

              error_boatSpecs[i].est_max_speed = errorValueCheckFn(
                boat[i].max_est_speed
              );
              error_boatSpecs[i].main1_engine_type = errorValueCheckFn(
                boat[i].engine1_type
              );
              error_boatSpecs[i].main1_other_engine_type =
                boat[i].engine1_type === "Other" && !boat[i].engine1_others_type
                  ? undefined
                  : false;
              error_boatSpecs[i].main1_fuel_type = errorValueCheckFn(
                boat[i].engine1_fuel_type
              );
              error_boatSpecs[i].main1_boat_engine_model_year =
                errorValueCheckFn(boat[i].engine1_model_year);
              error_boatSpecs[i].main1_boat_engine_manufacturer =
                errorValueCheckFn(boat[i].engine1_manufacturer);
              error_boatSpecs[i].main1_boat_engine_serail = errorValueCheckFn(
                boat[i].engine1_serial_number
              );
              error_boatSpecs[i].main1_boat_engine_horsepower =
                errorValueCheckFn(boat[i].engine1_horse_power);
              error_boatSpecs[i].main2_engine_type =
                boat[i]?.type === "Cabin Cruiser" && !boat[i].engine2_type
                  ? undefined
                  : false;
              error_boatSpecs[i].main2_other_engine_type =
                boat[i]?.type === "Cabin Cruiser" &&
                boat[i].engine2_type === "Other" &&
                !boat[i].engine2_others_type
                  ? undefined
                  : false;
              error_boatSpecs[i].main2_fuel_type =
                boat[i]?.type === "Cabin Cruiser" && !boat[i].engine2_fuel_type
                  ? undefined
                  : false;

              error_boatSpecs[i].main2_boat_engine_model_year =
                boat[i]?.type === "Cabin Cruiser" && !boat[i].engine2_model_year
                  ? undefined
                  : false;
              error_boatSpecs[i].main2_boat_engine_manufacturer =
                boat[i]?.type === "Cabin Cruiser" &&
                !boat[i].engine2_manufacturer
                  ? undefined
                  : false;
              error_boatSpecs[i].main2_boat_engine_serail =
                boat[i]?.type === "Cabin Cruiser" &&
                !boat[i].engine2_serial_number
                  ? undefined
                  : false;
              error_boatSpecs[i].main2_boat_engine_horsepower =
                boat[i]?.type === "Cabin Cruiser" &&
                !boat[i].engine2_horse_power
                  ? undefined
                  : false;

              error_addons[i].add_trailer =
                boat[i].add_a_trailer || boat[i].add_a_trailer === false
                  ? false
                  : undefined;
              error_addons[i].trailer_value =
                boat[i].add_a_trailer && !boat[i].trailer_value
                  ? undefined
                  : false;
              error_addons[i].trailer_model_year =
                boat[i].add_a_trailer && !boat[i].trailer_model_year
                  ? undefined
                  : false;
              error_addons[i].trailer_manufacturer =
                boat[i].add_a_trailer && !boat[i].trailer_manufacturer
                  ? undefined
                  : false;
              error_addons[i].trailer_serial =
                boat[i].add_a_trailer && !boat[i].trailer_vin
                  ? undefined
                  : false;
              error_addons[i].trailer_length =
                boat[i].add_a_trailer && !boat[i].trailer_length
                  ? undefined
                  : false;

              error_addons[i].add_tender =
                boat[i].add_a_tender || boat[i].add_a_tender === false
                  ? false
                  : undefined;
              error_addons[i].tender_value =
                boat[i].add_a_tender && !boat[i].tender_value
                  ? undefined
                  : false;
              error_addons[i].tender_model_year =
                boat[i].add_a_tender && !boat[i].tender_model_year
                  ? undefined
                  : false;
              error_addons[i].tender_manufacturer =
                boat[i].add_a_tender && !boat[i].tender_manufacturer
                  ? undefined
                  : false;
              error_addons[i].tender_serial =
                boat[i].add_a_tender && !boat[i].tender_vin ? undefined : false;
              error_addons[i].tender_length =
                boat[i].add_a_tender && !boat[i].tender_length
                  ? undefined
                  : false;

              error_addons[i].add_auxillary_motor =
                boat[i].add_auxiliary_motor ||
                boat[i].add_auxiliary_motor === false
                  ? false
                  : undefined;
              error_addons[i].aux_engine_value =
                boat[i].add_auxiliary_motor && !boat[i].aux_value
                  ? undefined
                  : false;
              error_addons[i].aux_engine_model_year =
                boat[i].add_auxiliary_motor && !boat[i].aux_model_year
                  ? undefined
                  : false;
              error_addons[i].aux_engine_manufacturer =
                boat[i].add_auxiliary_motor && !boat[i].aux_manufacturer
                  ? undefined
                  : false;
              error_addons[i].aux_engine_serial =
                boat[i].add_auxiliary_motor && !boat[i].aux_vin
                  ? undefined
                  : false;
              error_addons[i].aux_engine_hp =
                boat[i].add_auxiliary_motor && !boat[i].aux_length
                  ? undefined
                  : false;

              error_addons[i].boat_financed =
                boat[i].is_financed || boat[i].is_financed === false
                  ? false
                  : undefined;
              error_addons[i].boat_lienholder =
                boat[i].is_financed && !boat[i].financier_name
                  ? undefined
                  : false;
              error_addons[i].other_lienholder = false;

              error_addons[i].street_leinholder =
                boat[i].is_financed && !boat[i].financier_address_line1
                  ? undefined
                  : false;
              error_addons[i].line2_leinholder = false;
              error_addons[i].city_leinholder =
                boat[i].is_financed && !boat[i].financier_city
                  ? undefined
                  : false;
              error_addons[i].province_leinholder =
                boat[i].is_financed && !boat[i].financier_province
                  ? undefined
                  : false;
              error_addons[i].country_leinholder =
                boat[i].is_financed && !boat[i].financier_country
                  ? undefined
                  : false;
              error_addons[i].pc_leinholder =
                boat[i].is_financed && !boat[i].financier_postal_code
                  ? undefined
                  : false;
            }

            setBoatFormValues(boat_data_obj);
            setCoverageFormValues(coverage_data_obj);
            setEligiblityFormValues(eligiblity_data_obj);
            setBoatSpecsFormValues(boat_specs_data_obj);
            setAddonsFormValues(addons_data_obj);
            setErrorAddons(error_addons);
            setErrorBoatSpecs(error_boatSpecs);
            setErrorEligiblity(error_eligiblity);
            setErrorCoverage(error_coverage);
            setErrorBoat(error_boat);

            console.log(customer, "cus");

            setDefaultData({
              ...defaultData,
              quote_status: res?.data?.data?.quote?.quote_status,
            });

            //to block editing after getting the status as referral
            if (res?.data?.data?.quote?.quote_status === "Referral") {
              setReferralFlag({ state: true, reached: true });
            } else if (res?.data?.data?.quote?.quote_status === "Completed") {
              setBrokerReviewFlag(true);
            } else if (
              res?.data?.data?.quote?.quote_status === "Return To Payment"
            ) {
              setBrokerReviewFlag(true);
              setFormNumber(3);
            } else if (
              res?.data?.data?.quote?.quote_status === "Payment Pending"
            ) {
              setFormNumber(3);
            } else if (
              res?.data?.data?.quote?.quote_status === "Return To Broker"
            ) {
              setBrokerReviewFlag(true);
            }

            console.log(res?.data?.data?.quote?.is_uneditable_by_rtp);
            if (res?.data?.data?.quote?.is_uneditable_by_rtp) {
              setBrokerReviewFlag(true);
            }

            console.log(boat_data_obj);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        await axios
          .post(`${APIURL}/boat/create_boat_quote`, {
            organization_id: ORGANIZATION_ID,
            created_source: "WEBAPP",
          })
          .then((res) => {
            console.log(res);
            if (res?.data?.data?.quote.quote_id) {
              setLoading("success");

              let data = { ...globalData };

              data.quote_id = res?.data?.data?.quote.quote_id;
              data.quote_record_id = res?.data?.data?.quote.quote_record_id;

              if (!data.quote_id || !data.quote_record_id) {
                setLoading("error");
              }
              setGlobalData(data);

              window.sessionStorage.setItem(
                "boat_quote_id",
                res?.data?.data?.quote?.quote_id
              );
              window.sessionStorage.setItem(
                "boat_record_id",
                res?.data?.data?.quote?.quote_record_id
              );
            } else {
              setLoading("error");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    fun();
  }, []);

  const [loading, setLoading] = useState("loading");

  const [btnLoading, setBtnLoading] = useState(false);

  const [manufacturerOptions, setManufacturerOptions] = useState([]);

  useEffect(() => {
    async function fun() {
      await axios
        .get(`${APIURL}/manufacturer/get_manufacturers?type=Boat`)
        .then((res) => {
          console.log(res);
          let arr = res.data?.data
            .map((m) => {
              return { value: m.name, label: m.name };
            })
            .sort();

          console.log(arr);
          setManufacturerOptions(() => arr);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    fun();
  }, []);

  const [referralFlag, setReferralFlag] = useState({
    state: false,
    reached: false,
  });

  const [brokerReviewFlag, setBrokerReviewFlag] = useState(false);

  return (
    <div>
      {loading === "loading" ? (
        <div className="flex_layout" style={{ minHeight: "30vh" }}>
          <div className="loader_layout"></div>
        </div>
      ) : loading === "success" ? (
        <div className="boat">
          <h1>Watercraft</h1>
          <h2>Quote # {globalData?.quote_id}</h2>
          <ProgressBar
            formNumber={formNumber}
            nextPage={nextPage}
            boatCount={addBoatCount}
            index={boatNumberId}
            getStartedCount={getStartedCount}
            getStartedId={getStartedId}
            paymentId={paymentId}
            referralFlag={referralFlag}
            getStarted={errorGetStarted}
            setGetStarted={setErrorGetStarted}
            boat={errorAddons}
            setBoat={setErrorAddons}
            PolicyDetails={errorPolicyDetails}
            setPolicyDetails={setErrorPolicyDetails}
            terms={errorTerms}
            setTerms={setErrorTerms}
            brokerReviewFlag={brokerReviewFlag}
            paymentSuccess={paymentSuccess}
            setPaymentSuccess={setPaymentSuccess}
          />
          <div className="boat_forms_container">
            {formNumber === 1 ? (
              <GetStarted
                formNumber={formNumber}
                nextPage={nextPage}
                animateX={animateX}
                setAnimateX={setAnimateX}
                value={getStartedFormValues[0]}
                setValue={setGetStartedFormValues}
                error={errorGetStarted[0]}
                setError={setErrorGetStarted}
                additionalNames={additionalNames}
                setAdditionalNames={setAdditionalNames}
                id={1}
                wholeError={errorGetStarted}
                wholevalue={getStartedFormValues}
                policyDetailFormValues={policyDetailFormValues}
                setPolicyDetailFormValues={setPolicyDetailFormValues}
                errorPolicyDetails={errorPolicyDetails}
                setErrorPolicyDetails={setErrorPolicyDetails}
                number_of_boats={number_of_boats}
                boatNumber={boatNumber}
                addBoat={addBoat}
                boatFormValues={boatFormValues}
                setBoatFormValues={setBoatFormValues}
                setErrorBoat={setErrorBoat}
                errorBoat={errorBoat}
                coverageFormValues={coverageFormValues}
                setCoverageFormValues={setCoverageFormValues}
                eligiblityFormValues={eligiblityFormValues}
                setEligiblityFormValues={setEligiblityFormValues}
                boatSpecsFormValues={boatSpecsFormValues}
                setBoatSpecsFormValues={setBoatSpecsFormValues}
                addonsFormValues={addonsFormValues}
                setAddonsFormValues={setAddonsFormValues}
                errorCoverage={errorCoverage}
                setErrorCoverage={setErrorCoverage}
                errorEligiblity={errorEligiblity}
                setErrorEligiblity={setErrorEligiblity}
                errorBoatSpecs={errorBoatSpecs}
                setErrorBoatSpecs={setErrorBoatSpecs}
                errorAddons={errorAddons}
                setErrorAddons={setErrorAddons}
                boatRedBoxObj={boatRedBoxObj}
                addBoatCount={addBoatCount}
                setBoatRedBoxObj={setBoatRedBoxObj}
                quotesRedBoxObj={quotesRedBoxObj}
                setQuoteRedBoxObj={setQuoteRedBoxObj}
                eligiblityRedBoxObj={eligiblityRedBoxObj}
                boatSpecsRedBoxObj={boatSpecsRedBoxObj}
                addonsRedBoxObj={addonsRedBoxObj}
                setEligibilityRedBoxObj={setEligibilityRedBoxObj}
                setBoatSpecsRedBoxObj={setBoatSpecsRedBoxObj}
                setAddonsRedBoxObj={setAddonsRedBoxObj}
                boatNames={boatNames}
                setBoatNames={setBoatNames}
                errorBoatNames={errorBoatNames}
                setErrorBoatNames={setErrorBoatNames}
                getStartedCount={getStartedCount}
                getStartedId={getStartedId}
                setGetStartedId={setGetStartedId}
                errorAdditionalNames={errorAdditionalNames}
                setErrorAdditionalNames={setErrorAdditionalNames}
                globalData={globalData}
                setGlobalData={setGlobalData}
                isAddNamesSent={isAddNamesSent}
                setIsAddNamesSent={setIsAddNamesSent}
                manufacturerOptions={manufacturerOptions}
                setManufacturerOptions={setManufacturerOptions}
                referralFlag={referralFlag}
                organization={organization}
                brokerReviewFlag={brokerReviewFlag}
              />
            ) : formNumber === 2 ? (
              <div>
                <BoatMain
                  number={formNumber}
                  setNumber={setFormNumber}
                  formNumber={internalBoatState}
                  setFormNumber={setInternalBoatState}
                  animateX={animateX}
                  setAnimateX={setAnimateX}
                  setBoatFormValues={setBoatFormValues}
                  setErrorBoat={setErrorBoat}
                  getStartedFormValues={getStartedFormValues}
                  boatCount={addBoatCount}
                  index={boatNumberId}
                  setIndex={setBoatNumberId}
                  number_of_boats={number_of_boats}
                  boatNumber={boatNumber}
                  addBoat={addBoat}
                  boatFormValues={boatFormValues}
                  errorBoat={errorBoat}
                  coverageFormValues={coverageFormValues}
                  setCoverageFormValues={setCoverageFormValues}
                  eligiblityFormValues={eligiblityFormValues}
                  setEligiblityFormValues={setEligiblityFormValues}
                  boatSpecsFormValues={boatSpecsFormValues}
                  setBoatSpecsFormValues={setBoatSpecsFormValues}
                  addonsFormValues={addonsFormValues}
                  setAddonsFormValues={setAddonsFormValues}
                  errorCoverage={errorCoverage}
                  setErrorCoverage={setErrorCoverage}
                  errorEligiblity={errorEligiblity}
                  setErrorEligiblity={setErrorEligiblity}
                  errorBoatSpecs={errorBoatSpecs}
                  setErrorBoatSpecs={setErrorBoatSpecs}
                  errorAddons={errorAddons}
                  setErrorAddons={setErrorAddons}
                  boatRedBoxObj={boatRedBoxObj}
                  addBoatCount={addBoatCount}
                  setBoatRedBoxObj={setBoatRedBoxObj}
                  quotesRedBoxObj={quotesRedBoxObj}
                  setQuoteRedBoxObj={setQuoteRedBoxObj}
                  eligiblityRedBoxObj={eligiblityRedBoxObj}
                  boatSpecsRedBoxObj={boatSpecsRedBoxObj}
                  addonsRedBoxObj={addonsRedBoxObj}
                  setEligibilityRedBoxObj={setEligibilityRedBoxObj}
                  setBoatSpecsRedBoxObj={setBoatSpecsRedBoxObj}
                  setAddonsRedBoxObj={setAddonsRedBoxObj}
                  boatNames={boatNames}
                  setLeinHolders={setLeinHolders}
                  referralFlag={referralFlag}
                  organization={organization}
                  brokerReviewFlag={brokerReviewFlag}
                  btnLoading={btnLoading}
                  defaultData={defaultData}
                  setDefaultData={setDefaultData}
                />
                <BoatButtons
                  error={errorAddons}
                  setError={setErrorAddons}
                  setAnimateX={setAnimateX}
                  formNumber={formNumber}
                  setRedBoxObj={setAddonsRedBoxObj}
                  redBoxObj={addonsRedBoxObj}
                  nextPage={nextPage}
                  backPage={backPage}
                  internalBoatState={internalBoatState}
                  type="boat"
                  index={boatNumberId}
                  addBoatCount={addBoatCount}
                  boatFormValues={boatFormValues}
                  coverageFormValues={coverageFormValues}
                  eligiblityFormValues={eligiblityFormValues}
                  boatSpecsFormValues={boatSpecsFormValues}
                  addonsFormValues={addonsFormValues}
                  globalData={globalData}
                  lienHolders={lienHolders}
                  btnLoading={btnLoading}
                  setBtnLoading={setBtnLoading}
                  brokerReviewFlag={brokerReviewFlag}
                  referralFlag={referralFlag}
                />
              </div>
            ) : formNumber === 3 ? (
              <PolicyDetails
                formNumber={formNumber}
                nextPage={nextPage}
                backPage={backPage}
                animateX={animateX}
                setAnimateX={setAnimateX}
                value={policyDetailFormValues[0]}
                setValue={setPolicyDetailFormValues}
                error={errorPolicyDetails[0]}
                setError={setErrorPolicyDetails}
                id={1}
                wholeError={errorPolicyDetails}
                wholevalue={policyDetailFormValues}
                signatureObj={signatureObj}
                setSignatureObj={setSignatureObj}
                globalData={globalData}
                referralFlag={referralFlag}
                organization={organization}
                brokerReviewFlag={brokerReviewFlag}
                defaultData={defaultData}
              />
            ) : formNumber === 4 ? (
              <Terms
                formNumber={formNumber}
                nextPage={nextPage}
                backPage={backPage}
                animateX={animateX}
                setAnimateX={setAnimateX}
                value={termsValues[0]}
                setValue={setTermsValues}
                error={errorTerms[0]}
                id={1}
                setError={setErrorTerms}
                globalData={globalData}
                type="Boat"
                referralFlag={referralFlag}
                wholeError={errorTerms}
                wholevalue={termsValues}
                brokerReviewFlag={brokerReviewFlag}
                defaultData={defaultData}
              />
            ) : formNumber === 5 ? (
              <Payment
                referralFlag={referralFlag}
                setReferralFlag={setReferralFlag}
                boat={boatFormValues}
                eligiblity={eligiblityFormValues}
                boatSpecs={boatSpecsFormValues}
                coverage={coverageFormValues}
                addons={addonsFormValues}
                paymentId={paymentId}
                setPaymentId={setPaymentId}
                animateX={animateX}
                setAnimateX={setAnimateX}
                globalData={globalData}
                formNumber={formNumber}
                nextPage={nextPage}
                backPage={backPage}
                organization={organization}
                brokerReviewFlag={brokerReviewFlag}
                getStartedFormValues={getStartedFormValues}
                paymentFormValues={paymentFormValues}
                setPaymentFormvalues={setPaymentFormvalues}
                paymentSuccess={paymentSuccess}
                setPaymentSuccess={setPaymentSuccess}
                responseData={responseData}
                defaultData={defaultData}
                setDefaultData={setDefaultData}
              />
            ) : (
              ""
            )}
          </div>
          {(formNumber === 1 && getStartedId === 1) ||
          (formNumber === 5 && paymentId === 2) ? (
            ""
          ) : (
            <Footer
              brokerReviewFlag={brokerReviewFlag}
              setBrokerReviewFlag={setBrokerReviewFlag}
              type={"Boat"}
              addBoatCount={addBoatCount}
              boatFormValues={boatFormValues}
              coverageFormValues={coverageFormValues}
              eligiblityFormValues={eligiblityFormValues}
              boatSpecsFormValues={boatSpecsFormValues}
              addonsFormValues={addonsFormValues}
              globalData={globalData}
              getStartedFormValues={getStartedFormValues}
              referralFlag={referralFlag}
              formNumber={formNumber}
              policyDetailFormValues={policyDetailFormValues}
              signatureObj={signatureObj}
              boatNames={boatNames}
            />
          )}
          {/* {(formNumber !== 1 && formNumber !== 5) ||
          (getStartedId !== 1 && paymentId !== 2) ? (
            <Footer
              brokerReviewFlag={brokerReviewFlag}
              setBrokerReviewFlag={setBrokerReviewFlag}
              type={"Boat"}
              addBoatCount={addBoatCount}
              boatFormValues={boatFormValues}
              coverageFormValues={coverageFormValues}
              eligiblityFormValues={eligiblityFormValues}
              boatSpecsFormValues={boatSpecsFormValues}
              addonsFormValues={addonsFormValues}
              globalData={globalData}
              getStartedFormValues={getStartedFormValues}
            />
          ) : (
            ""
          )} */}
        </div>
      ) : loading === "error" ? (
        <ServerError />
      ) : loading === "id_not_found" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100dvh",
            minWidth: "100vdw",
          }}
        >
          <Result
            status={"error"}
            title="Invalid Quote"
            subTitle="Please check your url"
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Boat;

const BoatButtons = ({
  error,
  setError,
  setAnimateX,
  formNumber,
  nextPage,
  backPage,
  redBoxObj,
  setRedBoxObj,
  internalBoatState,
  type,
  index,
  addBoatCount,
  boatFormValues,
  coverageFormValues,
  eligiblityFormValues,
  boatSpecsFormValues,
  addonsFormValues,
  globalData,
  lienHolders,
  btnLoading,
  setBtnLoading,
  brokerReviewFlag,
  referralFlag,
}) => {
  return (
    <div className="trailer_btns">
      {internalBoatState === 5 && index === addBoatCount.length && (
        <button
          className={`next_btn ${btnLoading ? "loading" : ""}`}
          disabled={btnLoading}
          onClick={async () => {
            if (referralFlag?.state || brokerReviewFlag) {
              setAnimateX(100);

              nextPage(formNumber + 1);

              return;
            }

            console.log(error);
            setBtnLoading(() => true);

            let obj = [...redBoxObj];

            const map = redBoxObj.map((m) => {
              return {
                ...m,
                state: false,
              };
            });

            setRedBoxObj(() => map);

            const allValuesUndefined = error.some((obj) => {
              for (const key in obj) {
                if (obj[key] === undefined) {
                  return true;
                }
              }
              return false;
            });

            let updatedArray = [...error];

            console.log(allValuesUndefined);
            if (allValuesUndefined) {
              updatedArray = error.map((obj) => {
                const updatedObj = { ...obj };

                for (const key in updatedObj) {
                  if (updatedObj[key] === undefined) {
                    updatedObj[key] = true;
                  }
                }

                return updatedObj;
              });

              for (let i = 0; i < updatedArray.length; i++) {
                const errorItem = updatedArray[i];
                const objItem = obj[i];

                const hasTrueValue = Object.values(errorItem).some(
                  (value) => value === true
                );

                console.log(hasTrueValue);

                if (hasTrueValue) {
                  objItem.state = true;
                } else {
                  objItem.state = false;
                }

                console.log(objItem, "objitem");
              }

              await new Promise((resolve) => setTimeout(resolve, 0));

              setRedBoxObj(obj);
              setBtnLoading(() => false);

              setError(updatedArray);

              return;
            }

            const anyValueIsTrue = updatedArray.some((obj) => {
              for (const key in obj) {
                if (obj[key] === true) {
                  return true;
                }
              }
              return false;
            });

            console.log(updatedArray);

            if (anyValueIsTrue) {
              for (let i = 0; i < updatedArray.length; i++) {
                const errorItem = updatedArray[i];
                const objItem = obj[i];

                const hasTrueValue = Object.values(errorItem).some(
                  (value) => value === true
                );

                console.log(hasTrueValue);

                if (hasTrueValue) {
                  objItem.state = true;
                } else {
                  objItem.state = false;
                }

                console.log(objItem, "obj");
              }
              await new Promise((resolve) => setTimeout(resolve, 0));

              setRedBoxObj(obj);
              setBtnLoading(() => false);

              console.log(obj);
              // setRedBox(true);
              return;
            }

            for (let i = 0; i < updatedArray.length; i++) {
              const objItem = obj[i];

              objItem.state = false;
            }

            setRedBoxObj(obj);

            let boat_data_obj = [];

            for (let i = 0; i < addBoatCount.length; i++) {
              boat_data_obj.push({});
            }

            for (let i = 0; i < addBoatCount.length; i++) {
              boat_data_obj[i].type = boatFormValues[i]?.type_of_watercraft;
              boat_data_obj[i].year = boatFormValues[i]?.boat_model_year;
              boat_data_obj[i].manufacturer =
                boatFormValues[i].boat_manufacturer;
              boat_data_obj[i].model = boatFormValues[i].boat_model;
              boat_data_obj[i].replacement_cost =
                boatFormValues[i].replacement_cost;
              boat_data_obj[i].actual_cost_value =
                boatFormValues[i].actual_cash_value;
              boat_data_obj[i].is_main_operator_same_as_owner =
                boatFormValues[i].is_operator_same_as_owner === "Yes"
                  ? true
                  : boatFormValues[i].is_operator_same_as_owner === "No"
                  ? false
                  : undefined;
              boat_data_obj[i].main_operator_first_name =
                boatFormValues[i].org_first_name;
              boat_data_obj[i].main_operator_last_name =
                boatFormValues[i].org_last_name;
              boat_data_obj[i].main_operator_dob = dayjs(
                boatFormValues[i].date_of_birth
              ).format("DD/MM/YYYY");
              boat_data_obj[i].main_operator_boating_experience =
                boatFormValues[i].boating_experience;
              boat_data_obj[i].main_operator_hold_pcoc_card =
                boatFormValues[i].operator_hold_pcoc === "Yes"
                  ? true
                  : boatFormValues[i].operator_hold_pcoc === "No"
                  ? false
                  : null;
              boat_data_obj[i].deductible =
                boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
                  ? null
                  : coverageFormValues[i].deductible;
              boat_data_obj[i].personal_effects_coverage =
                boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
                  ? null
                  : coverageFormValues[i].personal_effects_coverage;
              boat_data_obj[i].navigational_equipment_coverage =
                boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
                  ? null
                  : coverageFormValues[i].navigational_equipment_coverage;
              boat_data_obj[i].premium_per_year =
                boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
                  ? null
                  : coverageFormValues[i].premium_per_year;
              boat_data_obj[i].coverage =
                boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
                  ? null
                  : coverageFormValues[i].coverage;
              boat_data_obj[i].coverage_name =
                boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
                  ? null
                  : coverageFormValues[i].coverage_name;
              boat_data_obj[i].liability_coverage =
                boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
                  ? null
                  : coverageFormValues[i].liability_coverage;
              boat_data_obj[i].salvage =
                boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
                  ? null
                  : coverageFormValues[i].salvage;
              boat_data_obj[i].pollution =
                boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
                  ? null
                  : coverageFormValues[i].pollution;
              boat_data_obj[i].removal_of_wreckage =
                boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
                  ? null
                  : coverageFormValues[i].removal_of_wreckage;
              boat_data_obj[i].emergency_towing_limit =
                boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
                  ? null
                  : coverageFormValues[i].emergency_towing_limit;
              boat_data_obj[i].uninsured_underinsured_boater_endorsement =
                boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
                  ? null
                  : coverageFormValues[i]
                      .uninsured_underinsured_boater_endorsement;
              boat_data_obj[i].loss_of_use_limit =
                boatFormValues[i]?.type_of_watercraft === "I'm not too sure"
                  ? null
                  : coverageFormValues[i].loss_of_use_limit;
              boat_data_obj[i].will_boat_be_used_in_atlantic_pacific_questions =
                eligiblityFormValues[i].watercraft_used_in_atlantic === "Yes"
                  ? true
                  : eligiblityFormValues[i].watercraft_used_in_atlantic === "No"
                  ? false
                  : undefined;
              boat_data_obj[
                i
              ].is_boat_rented_or_carry_passengers_or_existing_damages_questions =
                eligiblityFormValues[i].first_apply_to_watercraft === "Yes"
                  ? true
                  : eligiblityFormValues[i].first_apply_to_watercraft === "No"
                  ? false
                  : undefined;
              boat_data_obj[i].is_boat_layered_up_and_safety_questions =
                eligiblityFormValues[i].second_apply_to_watercraft === "Yes"
                  ? true
                  : eligiblityFormValues[i].second_apply_to_watercraft === "No"
                  ? false
                  : undefined;
              boat_data_obj[i].is_owner_employees_or_lives_on =
                eligiblityFormValues[i].apply_to_applicant === "Yes"
                  ? true
                  : eligiblityFormValues[i].apply_to_applicant === "No"
                  ? false
                  : undefined;
              boat_data_obj[i].operator_have_claim_in_last_5_years =
                eligiblityFormValues[i].claims_in_last_five_years === "Yes"
                  ? true
                  : eligiblityFormValues[i].claims_in_last_five_years === "No"
                  ? false
                  : undefined;
              boat_data_obj[i].how_many_claims =
                eligiblityFormValues[i].no_of_claims_in_last_five_years;
              boat_data_obj[i].operator_have_motor_convictions_in_last_3_years =
                eligiblityFormValues[i].claims_in_last_three_years === "Yes"
                  ? true
                  : eligiblityFormValues[i].claims_in_last_three_years === "No"
                  ? false
                  : undefined;
              boat_data_obj[i].how_many_convictions =
                eligiblityFormValues[i].no_of_claims_in_last_three_years;
              boat_data_obj[i].operator_had_suspended_driver_license =
                eligiblityFormValues[i].driver_license_suspended === "Yes"
                  ? true
                  : eligiblityFormValues[i].driver_license_suspended === "No"
                  ? false
                  : undefined;
              boat_data_obj[i].when_suspended =
                eligiblityFormValues[i].when_license_suspended;
              boat_data_obj[i].length_in_feet =
                boatSpecsFormValues[i].boat_length;
              boat_data_obj[i].hull_serial_id = boatSpecsFormValues[i].hull_id;
              boat_data_obj[i].hull_type = boatSpecsFormValues[i].hull_type;
              boat_data_obj[i].hull_type_for_others =
                boatSpecsFormValues[i].other_hull_type;
              boat_data_obj[i].max_est_speed =
                boatSpecsFormValues[i].est_max_speed;
              boat_data_obj[i].engine1_type =
                boatSpecsFormValues[i].main1_engine_type;
              boat_data_obj[i].engine1_others_type =
                boatSpecsFormValues[i].main1_other_engine_type;
              boat_data_obj[i].engine1_fuel_type =
                boatSpecsFormValues[i].main1_fuel_type;
              boat_data_obj[i].engine1_model_year =
                boatSpecsFormValues[i].main1_boat_engine_model_year;
              boat_data_obj[i].engine1_manufacturer =
                boatSpecsFormValues[i].main1_boat_engine_manufacturer;
              boat_data_obj[i].engine1_serial_number =
                boatSpecsFormValues[i].main1_boat_engine_serail;
              boat_data_obj[i].engine1_horse_power =
                boatSpecsFormValues[i].main1_boat_engine_horsepower;
              boat_data_obj[i].engine2_type =
                boatSpecsFormValues[i].main2_engine_type;
              boat_data_obj[i].engine2_others_type =
                boatSpecsFormValues[i].main2_other_engine_type;
              boat_data_obj[i].engine2_fuel_type =
                boatSpecsFormValues[i].main2_fuel_type;
              boat_data_obj[i].engine2_model_year =
                boatSpecsFormValues[i].main2_boat_engine_model_year;
              boat_data_obj[i].engine2_manufacturer =
                boatSpecsFormValues[i].main2_boat_engine_manufacturer;
              boat_data_obj[i].engine2_serial_number =
                boatSpecsFormValues[i].main2_boat_engine_serail;
              boat_data_obj[i].engine2_horse_power =
                boatSpecsFormValues[i].main2_boat_engine_horsepower;
              boat_data_obj[i].add_a_trailer =
                addonsFormValues[i].add_trailer === "Yes"
                  ? true
                  : addonsFormValues[i].add_trailer === "No"
                  ? false
                  : undefined;
              boat_data_obj[i].trailer_value =
                addonsFormValues[i].trailer_value;
              boat_data_obj[i].trailer_model_year =
                addonsFormValues[i].trailer_model_year;
              boat_data_obj[i].trailer_manufacturer =
                addonsFormValues[i].trailer_manufacturer;
              boat_data_obj[i].trailer_vin = addonsFormValues[i].trailer_serial;
              boat_data_obj[i].trailer_length =
                addonsFormValues[i].trailer_length;
              boat_data_obj[i].add_a_tender =
                addonsFormValues[i].add_tender === "Yes"
                  ? true
                  : addonsFormValues[i].add_tender === "No"
                  ? false
                  : undefined;
              boat_data_obj[i].tender_value = addonsFormValues[i].tender_value;
              boat_data_obj[i].tender_model_year =
                addonsFormValues[i].tender_model_year;
              boat_data_obj[i].tender_manufacturer =
                addonsFormValues[i].tender_manufacturer;
              boat_data_obj[i].tender_vin = addonsFormValues[i].tender_serial;
              boat_data_obj[i].tender_length =
                addonsFormValues[i].tender_length;
              boat_data_obj[i].add_auxiliary_motor =
                addonsFormValues[i].add_auxillary_motor === "Yes"
                  ? true
                  : addonsFormValues[i].add_auxillary_motor === "No"
                  ? false
                  : undefined;
              boat_data_obj[i].aux_value = addonsFormValues[i].aux_engine_value;
              boat_data_obj[i].aux_model_year =
                addonsFormValues[i].aux_engine_model_year;
              boat_data_obj[i].aux_manufacturer =
                addonsFormValues[i].aux_engine_manufacturer;
              boat_data_obj[i].aux_vin = addonsFormValues[i].aux_engine_serial;
              boat_data_obj[i].aux_length = addonsFormValues[i].aux_engine_hp;
              boat_data_obj[i].is_financed =
                addonsFormValues[i].boat_financed === "Yes"
                  ? true
                  : addonsFormValues[i].boat_financed === "No"
                  ? false
                  : undefined;
              boat_data_obj[i].select_a_financier =
                addonsFormValues[i].boat_lienholder;
              boat_data_obj[i].financier_name =
                addonsFormValues[i].boat_lienholder;
              boat_data_obj[i].financier_address_line1 =
                addonsFormValues[i].street_leinholder;
              boat_data_obj[i].financier_address_line2 =
                addonsFormValues[i].line2_leinholder;
              boat_data_obj[i].financier_city =
                addonsFormValues[i].city_leinholder;
              boat_data_obj[i].financier_province =
                addonsFormValues[i].province_leinholder;
              boat_data_obj[i].financier_country =
                addonsFormValues[i].country_leinholder;
              boat_data_obj[i].financier_postal_code =
                addonsFormValues[i].pc_leinholder;
            }

            let boat_quote_updates = {
              boat_quote_record_id: globalData?.quote_record_id,
              quote_status: "In Progress",
              boats: boat_data_obj,
              organization_id: ORGANIZATION_ID,
            };

            let body = {
              boat_quote_record_id: globalData.quote_record_id,
              boat_quote_updates,
            };

            console.log(boat_data_obj, body);

            setAnimateX(100);

            nextPage(formNumber + 1);
            setBtnLoading(() => false);

            await axios
              .post(`${APIURL}/boat/update_boat_quote`, body)
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                setBtnLoading(() => false);
              });
          }}
        >
          Next {btnLoading && <div className="btn_loading"></div>}
        </button>
      )}
      {formNumber === 1 ? (
        ""
      ) : (
        <button
          className="back_btn"
          onClick={() => {
            setAnimateX(-100);

            backPage(formNumber - 1);
          }}
          disabled={btnLoading}
        >
          Back
        </button>
      )}
    </div>
  );
};
