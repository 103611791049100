import React from "react";
import { useLocation } from "react-router-dom";
import TextField from "../../components/TextField";
import logo from "../../assets/RecLogo.png";
import { Button, ConfigProvider } from "antd";
import { PRIMARY_COLOR } from "../../utils";
import axios from "axios";
import { APIURL } from "../../api";

const QuoteView = () => {
  const location = useLocation();
  let urlparams = new URLSearchParams(location.search);

  const makePayment = async () => {
    await axios
      .post(`${APIURL}/trailer/zero_payment_url`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="signin">
        <header>
          <img src={logo} alt="" loading="lazy" />
        </header>
        <div className="signin_container">
          <form style={{ width: "500px" }}>
            <TextField
              label={"Policy Number"}
              value={urlparams.get("policy_number")}
              disabled={true}
            />
            <TextField
              label={"Email"}
              value={urlparams.get("email")}
              disabled={true}
            />
            <TextField
              label={"First Name"}
              value={urlparams.get("first_name")}
              disabled={true}
            />
            <TextField
              label={"Last Name"}
              value={urlparams.get("last_name")}
              disabled={true}
            />
            <i style={{ color: "rgb(180 180 180)", fontSize: ".875rem" }}>
              By verifying and continue you'll be redirected to payment page
              where you can make a $0 transaction.
            </i>
            <div className="signin_btn" style={{ margin: "auto" }}>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: PRIMARY_COLOR,
                    // colorPrimaryHover: WARNING_COLOR,
                  },
                }}
              >
                <Button type="primary" onClick={makePayment}>
                  Agree and continue
                </Button>
              </ConfigProvider>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default QuoteView;
