import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Checkbox from "../Checkbox";
import { FormHelperText } from "@mui/material";
import {
  COMPONENT_ERROR_TEXT,
  ERROR_HELPERTEXT_STYLES,
  FIELD_ERROR_TEXT,
  PRIMARY_COLOR,
  WARNING_COLOR,
} from "../../utils";
import { ConfigProvider, Input } from "antd";

const Eligibility = ({
  animateX,
  value,
  setValue,
  error,
  setError,
  id,
  wholevalue,
  wholeError,
  redBoxObj,
  boatNames,
  wholeBoatNames,
  referralFlag,
  brokerReviewFlag,
}) => {
  console.log(wholeError, wholevalue);
  const numberPlusDecimalRegex = /^[\d.]+$/;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <motion.div
      initial={{ x: animateX, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div>
        <div className={`box ${redBoxObj?.state && "redbox"}`}>
          <div className="boat_form">
            <header>
              <div className="side_line"></div>
              <div>
                <h3 className="error_h3">
                  Eligibility{" "}
                  {redBoxObj?.state ? (
                    <p className="component_error_text">
                      {COMPONENT_ERROR_TEXT}
                    </p>
                  ) : (
                    ""
                  )}{" "}
                </h3>
                <h6 className="boat_name">
                  {" "}
                  {wholeBoatNames.length > 1 && `(${boatNames?.name})`}
                </h6>
              </div>
            </header>
            <div className="container">
              <div className="container_div">
                <h5>Please review the following:</h5>
                <p>
                  The watercraft will be used in Atlantic/Pacific Coastal waters
                  such and the Atlantic, Pacific or Artic Ocean including
                  Hudson's Bay.
                </p>
              </div>
              <Checkbox
                label={
                  "Do any of the above statements apply to the watercraft?"
                }
                value={value?.watercraft_used_in_atlantic}
                firstoption={"Yes"}
                secondoption={"No"}
                setValue={setValue}
                name={"watercraft_used_in_atlantic"}
                error={error}
                object={value}
                setError={setError}
                error_state={error?.watercraft_used_in_atlantic}
                error_text={FIELD_ERROR_TEXT}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              <div className="container_div">
                <h5>Please review the following:</h5>
                <p>
                  The watercraft is chartered or rented to others (bareboat
                  charters).
                </p>
                <p>
                  The watercraft is used to carry passengers or cargo for
                  compensation, for any other commercial business purpose, or
                  anything other than private pleasure use.
                </p>
                <p>The watercraft has existing/unrepaired damage.</p>
                <p>
                  The watercraft has been modified from the original design or
                  specs.
                </p>
                <p>
                  The watercraft is operated south of the 40th parallel at any
                  time or is primarily moored in the USA.
                </p>
                <p>
                  The watercraft or engine is modified, homemade or its
                  horsepower exceeds the manufacturers specifications.
                </p>
                <p>
                  The watercraft is used for Parasailing / Paraskiing or Racing.
                </p>
                <p>
                  The watercraft has propane or compressed natural gas (CNG)
                  refrigerators.
                </p>
              </div>
              <Checkbox
                label={
                  "Do any of the above statements apply to the watercraft?"
                }
                value={value?.first_apply_to_watercraft}
                firstoption={"Yes"}
                secondoption={"No"}
                setValue={setValue}
                name={"first_apply_to_watercraft"}
                error={error}
                object={value}
                setError={setError}
                error_state={error?.first_apply_to_watercraft}
                error_text={FIELD_ERROR_TEXT}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />{" "}
              <div className="container_div">
                <h5>Please review the following:</h5>
                <p>
                  Is the vessel layed up out of the water from November 30 -
                  April 1 every year?
                </p>
                <p>
                  Have all boat safety requirements been met as required by law
                  (i.e. life jackets, etc.)?
                </p>
              </div>
              <Checkbox
                label={"Do any of the above apply to the watercraft?"}
                value={value?.second_apply_to_watercraft}
                firstoption={"Yes"}
                secondoption={"No"}
                setValue={setValue}
                name={"second_apply_to_watercraft"}
                error={error}
                object={value}
                setError={setError}
                error_state={error?.second_apply_to_watercraft}
                error_text={FIELD_ERROR_TEXT}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />{" "}
              <div className="container_div">
                <h5>Please review the following:</h5>
                <p>The applicant/owner employs paid crew.</p>
                <p>
                  The applicant/owner lives aboard the watercraft, or the
                  watercraft is a primary residence.
                </p>
              </div>
              <Checkbox
                label={"Do any of the above apply to the applicant?"}
                value={value?.apply_to_applicant}
                firstoption={"Yes"}
                secondoption={"No"}
                setValue={setValue}
                name={"apply_to_applicant"}
                error={error}
                object={value}
                setError={setError}
                error_state={error?.apply_to_applicant}
                error_text={FIELD_ERROR_TEXT}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />{" "}
              <div className="container_div">
                <h5>Please review the following:</h5>
              </div>
              <Checkbox
                label={
                  "Has the principal operator had any motor vehicle claims in the last 5 years?"
                }
                value={value?.claims_in_last_five_years}
                firstoption={"Yes"}
                secondoption={"No"}
                setValue={setValue}
                name={"claims_in_last_five_years"}
                error={error}
                object={value}
                setError={setError}
                error_state={error?.claims_in_last_five_years}
                error_text={FIELD_ERROR_TEXT}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              {value?.claims_in_last_five_years === "Yes" && (
                <div
                  className={`interior_input ${
                    error?.no_of_claims_in_last_five_years && "error_field"
                  }`}
                >
                  <label>How many?</label>
                  <input
                    type="text"
                    value={value?.no_of_claims_in_last_five_years}
                    onChange={(e) => {
                      if (
                        e.target.value === "" ||
                        parseFloat(e.target.value) === 0
                      ) {
                        let updated = wholevalue.map((m, index) => {
                          if (index === id - 1) {
                            return {
                              ...m,
                              no_of_claims_in_last_five_years:
                                e.target.value === ""
                                  ? ""
                                  : parseFloat(e.target.value),
                            };
                          }

                          return m;
                        });

                        setValue(updated);

                        let updated_error = wholeError.map((m, index) => {
                          if (index === id - 1) {
                            return {
                              ...m,
                              no_of_claims_in_last_five_years: true,
                            };
                          }

                          return m;
                        });
                        setError(updated_error);

                        return;
                      }

                      if (numberPlusDecimalRegex.test(e.target.value)) {
                        console.log("yes");
                        let updated = wholevalue.map((m, index) => {
                          if (index === id - 1) {
                            return {
                              ...m,
                              no_of_claims_in_last_five_years: parseFloat(
                                e.target.value
                              ),
                            };
                          }

                          return m;
                        });

                        setValue(updated);
                      } else {
                        return;
                      }
                      let updated_error = wholeError.map((m, index) => {
                        if (index === id - 1) {
                          return {
                            ...m,
                            no_of_claims_in_last_five_years: false,
                          };
                        }

                        return m;
                      });
                      setError(updated_error);

                      return;
                    }}
                    disabled={referralFlag?.state || brokerReviewFlag}
                  />
                  {error?.no_of_claims_in_last_five_years && (
                    <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                      {FIELD_ERROR_TEXT}
                    </FormHelperText>
                  )}
                </div>
              )}
              <Checkbox
                label={
                  "Has the principal operator had any motor vehicle convictions in the last 3 years?"
                }
                value={value?.claims_in_last_three_years}
                firstoption={"Yes"}
                secondoption={"No"}
                setValue={setValue}
                name={"claims_in_last_three_years"}
                error={error}
                object={value}
                setError={setError}
                error_state={error?.claims_in_last_three_years}
                error_text={FIELD_ERROR_TEXT}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              {value?.claims_in_last_three_years === "Yes" && (
                <div
                  className={`interior_input ${
                    error?.no_of_claims_in_last_three_years && "error_field"
                  }`}
                >
                  <label>How many?</label>
                  <input
                    type="text"
                    value={value?.no_of_claims_in_last_three_years}
                    onChange={(e) => {
                      if (
                        e.target.value === "" ||
                        parseFloat(e.target.value) === 0
                      ) {
                        let updated = wholevalue.map((m, index) => {
                          if (index === id - 1) {
                            return {
                              ...m,
                              no_of_claims_in_last_three_years:
                                e.target.value === ""
                                  ? ""
                                  : parseFloat(e.target.value),
                            };
                          }

                          return m;
                        });

                        let updated_error = wholeError.map((m, index) => {
                          if (index === id - 1) {
                            return {
                              ...m,
                              no_of_claims_in_last_three_years: true,
                            };
                          }

                          return m;
                        });
                        setError(updated_error);

                        setValue(updated);

                        return;
                      }
                      if (numberPlusDecimalRegex.test(e.target.value)) {
                        console.log("yes");
                        let updated = wholevalue.map((m, index) => {
                          if (index === id - 1) {
                            return {
                              ...m,
                              no_of_claims_in_last_three_years: parseFloat(
                                e.target.value
                              ),
                            };
                          }

                          return m;
                        });

                        setValue(updated);
                      } else {
                        // let updated = wholevalue.map((m, index) => {
                        //   if (index === id - 1) {
                        //     return {
                        //       ...m,
                        //       no_of_claims_in_last_three_years: "",
                        //     };
                        //   }

                        //   return m;
                        // });

                        // setValue(updated);

                        // let updated_error = wholeError.map((m, index) => {
                        //   if (index === id - 1) {
                        //     return {
                        //       ...m,
                        //       no_of_claims_in_last_three_years: true,
                        //     };
                        //   }

                        //   return m;
                        // });
                        // setError(updated_error);

                        return;
                      }

                      let updated_error = wholeError.map((m, index) => {
                        if (index === id - 1) {
                          return {
                            ...m,
                            no_of_claims_in_last_three_years: false,
                          };
                        }

                        return m;
                      });
                      setError(updated_error);
                    }}
                    disabled={referralFlag?.state || brokerReviewFlag}
                  />
                  {error?.no_of_claims_in_last_three_years && (
                    <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                      {FIELD_ERROR_TEXT}
                    </FormHelperText>
                  )}
                </div>
              )}
              <Checkbox
                label={
                  "Has the principal operator had their driver's license suspended?"
                }
                value={value?.driver_license_suspended}
                firstoption={"Yes"}
                secondoption={"No"}
                setValue={setValue}
                name={"driver_license_suspended"}
                error={error}
                object={value}
                setError={setError}
                error_state={error?.driver_license_suspended}
                error_text={FIELD_ERROR_TEXT}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              {value?.driver_license_suspended === "Yes" && (
                <div
                  className={`interior_input ${
                    error?.when_license_suspended && "error_field"
                  }`}
                >
                  <label>Tell us more</label>
                  <ConfigProvider
                    theme={{
                      components: {
                        Input: {
                          activeBorderColor: PRIMARY_COLOR,
                          hoverBorderColor: PRIMARY_COLOR,
                          activeShadow: "none",
                          errorActiveShadow: "none",
                        },
                      },
                      token: {
                        colorErrorOutline: "none",
                        colorError: WARNING_COLOR,
                        colorErrorBorderHover: "none",
                      },
                    }}
                  >
                    <Input.TextArea
                      type="text"
                      value={value?.when_license_suspended}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          let updated_error = wholeError.map((m, index) => {
                            if (index === id - 1) {
                              return {
                                ...m,
                                when_license_suspended: true,
                              };
                            }

                            return m;
                          });
                          setError(updated_error);
                        } else {
                          let updated_error = wholeError.map((m, index) => {
                            if (index === id - 1) {
                              return {
                                ...m,
                                when_license_suspended: false,
                              };
                            }

                            return m;
                          });
                          setError(updated_error);
                        }
                        let updated = wholevalue.map((m, index) => {
                          if (index === id - 1) {
                            return {
                              ...m,
                              when_license_suspended: e.target.value,
                            };
                          }

                          return m;
                        });

                        setValue(updated);
                      }}
                      disabled={referralFlag?.state || brokerReviewFlag}
                      status={error?.when_license_suspended && "error"}
                    />
                  </ConfigProvider>
                  {error?.when_license_suspended && (
                    <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                      {FIELD_ERROR_TEXT}
                    </FormHelperText>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Eligibility;
