import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "../../../api";
import Nav from "../../../components/AdminPanel/Nav";
import { Table } from "antd";
import TableInput from "../../../components/AdminPanel/TableInput";

const TrailerRateTable = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [editState, setEditState] = useState(false);
  const [cancelState, setCancelState] = useState(false);

  useEffect(() => {
    let isCancelled = false;

    async function fetch() {
      await axios
        .post(`${APIURL}/organization/get_organization`, {
          organization_id: ORGANIZATION_ID,
          required_keys: ["trailer_liability_rate"],
        })
        .then((res) => {
          if (!isCancelled) {
            console.log(res);
            let table_data =
              res?.data?.data?.organization?.trailer_liability_rate;
            let mapped_data = Object.entries(table_data).map(([key, value]) => {
              return {
                ...value,
                trailer_liability_rate: key,
                value,
                key,
              };
            });

            console.log(mapped_data);

            setData(mapped_data);
            setLoading(false);
            setCancelState(() => false);
            setEditState(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetch();

    return () => {
      isCancelled = true;
    };
  }, [cancelState]);

  const column = [
    {
      title: "Trailer Liability",
      dataIndex: "trailer_liability_rate",
      key: "trailer_liability_rate",
      fixed: "left",
      width: 150,
    },
    {
      title: "Rate",
      dataIndex: "value",
      key: "value",
      width: 150,
      render: (_, record) => (
        <TableInput
          border={editState}
          disabled={!editState}
          value={record?.value}
          handleChange={(e) => {
            let value = e.target.value;

            let array = [...data];

            let updated = array.map((m) => {
              if (m.trailer_liability_rate === record.trailer_liability_rate) {
                return {
                  ...m,
                  value: Number(value),
                };
              }

              return m;
            });

            setData(updated);
          }}
        />
      ),
    },
  ];

  const handleEdit = async () => {
    setLoading(() => true);

    await new Promise((resolve) => setTimeout(resolve, 1500));

    setLoading(() => false);
    setEditState(true);
  };

  const handleCancel = async () => {
    setLoading(() => true);

    setCancelState(true);
  };

  const handleSave = async () => {
    let trailer_liability_rate = Object.fromEntries(
      data.map((item) => [item.trailer_liability_rate, item.value])
    );

    let body = {
      organization_id: ORGANIZATION_ID,
      organization_updates: {
        trailer_liability_rate,
      },
    };

    console.log(body);

    setLoading(() => true);

    await axios
      .post(`${APIURL}/organization/update_organization`, body)
      .then((res) => {
        console.log(res);

        setCancelState(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="layout">
        <Nav
          header={
            editState
              ? "Edit Trailer Liability Table"
              : "View Trailer Liability Table"
          }
          layout={editState ? "save" : "edit"}
          onEditChange={handleEdit}
          onSaveChange={handleSave}
          onCancelChange={handleCancel}
        />
        <div className="layout_container">
          {loading ? (
            <div className="layout_loading">
              <div className="loader"></div>
            </div>
          ) : (
            <div className="layout_main">
              <Table
                columns={column}
                // scroll={{ x: "auto", y: "auto" }}
                dataSource={data}
                pagination={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrailerRateTable;
