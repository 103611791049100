import { useState, useEffect } from "react";
import { ConfigProvider, Table, Select, Input } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../../api";
import { TablePagination } from "../Quotes/BoatTable";

const LeadTable = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState({ text: "", type: "" });

  async function fetch(page) {
    setLoading(() => true);

    await axios
      .get(`${APIURL}/customer/customers?page=${page}&type=Lead`)
      .then((res) => {
        console.log(res);
        setData(res?.data?.data?.customer_list);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const column = [
    {
      title: "Customer ID",
      dataIndex: "customer_id",
      key: "customer_id",
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 150,
    },
    {
      title: "First name",
      dataIndex: "first_name",
      key: "first_name",
      width: 150,
    },
    {
      title: "Last name",
      dataIndex: "last_name",
      key: "last_name",
      width: 150,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 150,
    },
    {
      title: "Date of birth",
      dataIndex: "dob",
      key: "dob",
      width: 150,
    },
    {
      title: "Creator record id",
      dataIndex: "creator_record_id",
      key: "creator_record_id",
      width: 150,
    },
  ];

  useEffect(() => {
    fetch(1);
  }, []);

  const handleSearch = (value) => {};

  return (
    <div className="layout">
      <div className="nav">
        <p>Lead customers</p>
        <div>
          <Select
            style={{ minWidth: "200px", marginRight: "1rem" }}
            options={[
              { label: "Policy number", value: "policy_number" },
              { label: "Quote id", value: "quote_id" },
              { label: "Quote record id", value: "quote_record_id" },
            ]}
            value={searchData.type}
            onChange={(val) => {
              setSearchData({ ...searchData, type: val });
            }}
          />
          <Input.Search
            enterButton
            style={{ maxWidth: "200px" }}
            value={searchData.text}
            onChange={(e) => {
              setSearchData({ ...searchData, text: e.target.value });
            }}
            onSearch={handleSearch}
          />
        </div>
      </div>

      <div className="layout_main">
        {" "}
        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellPaddingBlock: 8,
                cellPaddingInline: 8,
                // headerBg: "#d7d7d7",
              },
            },
            // token: { colorBgContainer: "red" },
          }}
        >
          <Table
            columns={column}
            virtual
            //   dataSource={[
            //     { quote_record_id: "Q_65eb28cfca8e3927179f5676" },
            //     { quote_record_id: "Q_65dc507087606b1e78d9e2e3" },
            //     { quote_record_id: "Q_65f0afdf13f0cea75454563b" },
            //   ]}
            dataSource={data}
            onRow={(record) => ({
              onClick: () => {
                navigate(`/admin/quotes/trailer/${record.quote_record_id}`);
              },
            })}
            loading={loading}
            scroll={{ y: 630 }}
            pagination={false}
          />{" "}
        </ConfigProvider>
        <TablePagination
          page={page}
          action={fetch}
          data={data}
          setPage={setPage}
        />
      </div>
    </div>
  );
};

export default LeadTable;
