import React, { useEffect, useState } from "react";
import TextField from "../TextField";
import "../../styles/Trailer/GetStarted.css";
import { motion } from "framer-motion";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
} from "@mui/material";
import DatePickerComponent from "../DatePicker";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Flex,
  Input,
  Modal,
  Select,
  message,
} from "antd";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "../../api";
import dayjs from "dayjs";
import {
  COMPONENT_ERROR_TEXT,
  CustomFormControlLabel,
  CustomRadioGroup,
  ERROR_HELPERTEXT_STYLES,
  FIELD_ERROR_TEXT,
  PRIMARY_COLOR,
  Province_Options,
  Social_Media_Options,
  WARNING_COLOR,
  addons,
  boat,
  boat_specs,
  eligiblity,
  err_boat_specs,
  generateTimestamp,
} from "../../utils";
import SelectComponent from "../Select";
import Autocomplete from "../AutoComplete";
import CustomPhoneInput from "../PhoneInput";
import canadaFlag from "../../assets/CanadaFlag.jpg";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const numberRegex = /^[0-9]*$/;

const coverage = {
  deductible_ac: 1000,
  personal_effects_coverage_ac: 5000,
  nav_coverage_ac: 2500,
  deductible_rc: 1000,
  personal_effects_coverage_rc: 5000,
  nav_coverage_rc: 2500,
  coverage_name: undefined,
};

const error_coverage = {
  deductible_ac: false,
  personal_effects_coverage_ac: false,
  nav_coverage_ac: false,
  deductible_rc: false,
  personal_effects_coverage_rc: false,
  nav_coverage_rc: false,
  coverage_name: undefined,
};

const GetStarted = ({
  formNumber,
  nextPage,
  animateX,
  setAnimateX,
  value,
  setValue,
  error,
  setError,
  additionalNames,
  setAdditionalNames,
  id,
  wholeError,
  wholevalue,
  boatFormValues,
  setBoatFormValues,
  errorBoat,
  setErrorBoat,
  coverageFormValues,
  setCoverageFormValues,
  eligiblityFormValues,
  setEligiblityFormValues,
  boatSpecsFormValues,
  setBoatSpecsFormValues,
  addonsFormValues,
  setAddonsFormValues,
  errorCoverage,
  setErrorCoverage,
  errorEligiblity,
  setErrorEligiblity,
  errorBoatSpecs,
  setErrorBoatSpecs,
  errorAddons,
  setErrorAddons,
  number_of_boats,
  boatNumber,
  addBoat,
  setBoatNames,
  boatNames,
  setErrorBoatNames,
  errorBoatNames,
  getStartedId,
  setGetStartedId,
  errorAdditionalNames,
  setErrorAdditionalNames,
  globalData,
  setGlobalData,
  isAddNamesSent,
  setIsAddNamesSent,
  manufacturerOptions,
  referralFlag,
  organization,
  brokerReviewFlag,
  policyDetailFormValues,
  setPolicyDetailFormValues,
  errorPolicyDetails,
  setErrorPolicyDetails,
}) => {
  const [redBox, setRedBox] = useState(false);

  const handleChangeFindUsValue = (e) => {
    if (e.target.value === "") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, find_us_value: true };
        }

        return m;
      });
      setError(updated_error);
    } else {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, find_us_value: false };
        }

        return m;
      });
      setError(updated_error);
    }
    let updated = wholevalue.map((m, index) => {
      if (index === id - 1) {
        return { ...m, find_us_value: e.target.value };
      }

      return m;
    });

    setValue(updated);
  };

  const [modalOpen, setModalOpen] = useState(false);

  const [btnLoading, setBtnLoading] = useState(false);
  const [contactSupport, setContactSupport] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  console.log(redBox);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  const handleNext = async () => {
    if (referralFlag?.state || brokerReviewFlag) {
      setAnimateX(100);
      setGetStartedId(2);

      return;
    }

    setRedBox(() => false);

    setBtnLoading(true);
    let email, first_name, last_name;

    if (!emailRegex.test(value.email)) {
      email = true;
    } else if (value?.email) {
      email = false;
    } else {
      email = true;
    }

    if (value?.insured_first_name) {
      first_name = false;
    } else {
      first_name = true;
    }

    if (value?.insured_last_name) {
      last_name = false;
    } else {
      last_name = true;
    }

    let updated_error = wholeError.map((m, index) => {
      if (index === id - 1) {
        return {
          ...m,
          email,
          insured_first_name: first_name,
          insured_last_name: last_name,
        };
      }

      return m;
    });
    setError(updated_error);

    if (email || first_name || last_name) {
      await new Promise((resolve) => setTimeout(resolve, 0));

      setRedBox(() => true);
      setBtnLoading(false);

      return;
    } else {
      setRedBox(false);

      let body = {
        first_name: value?.insured_first_name.trim(),
        last_name: value?.insured_last_name.trim(),
        email: value?.email.trim(),
        type: "Boat",
        quote_record_id: globalData?.quote_record_id,
      };

      await axios
        .post(`${APIURL}/customer/create_customer`, body)
        .then((res) => {
          console.log(res);

          let cus_response = res?.data?.data?.customer;

          let customer_id = res?.data?.data?.customer?.customer_id;

          if (res?.data?.message === "Invalid Customer details") {
            setModalOpen(true);
            setBtnLoading(false);

            let data = { ...globalData };

            data.customer_id = customer_id;

            console.log(data);
            setGlobalData(data);

            return;
          }

          if (customer_id) {
            let data = { ...globalData };

            data.customer_id = customer_id;

            console.log(data);
            setGlobalData(data);
          }

          if (res.data?.message === "Customer Details") {
            let map = wholevalue.map((m, index) => {
              if (index === 0) {
                return {
                  ...m,
                  find_us: res?.data?.data?.customer?.where_you_find_us,
                  find_us_value:
                    cus_response?.where_you_find_us === "Social Media"
                      ? cus_response?.social_media_option_name
                      : cus_response?.where_you_find_us === "Friend or Family"
                      ? cus_response?.friend_option_name
                      : cus_response?.where_you_find_us === "Dealership"
                      ? cus_response?.dealership_option_name
                      : cus_response?.where_you_find_us === "Campground"
                      ? cus_response?.campground_option_name
                      : cus_response?.where_you_find_us === "Marina"
                      ? cus_response?.marina_option_name
                      : cus_response?.where_you_find_us === "Other"
                      ? cus_response?.others_option_name
                      : "",
                  phone: cus_response?.phone,
                  date_of_birth: cus_response?.dob
                    ? dayjs(cus_response?.dob, "DD/MM/YYYY")
                    : undefined,
                };
              }
            });

            let error_customer = wholeError.map((m, index) => {
              if (index === 0) {
                return {
                  ...m,
                  find_us: cus_response?.where_you_find_us ? false : undefined,
                  find_us_value:
                    cus_response?.where_you_find_us === "Google"
                      ? false
                      : !map[0].find_us_value
                      ? undefined
                      : false,
                  phone: cus_response?.phone ? false : undefined,
                  date_of_birth: cus_response?.dob ? false : undefined,
                };
              }
            });

            setError(error_customer);

            let policy_details_map = policyDetailFormValues.map((m, index) => {
              if (index === 0) {
                return {
                  ...m,
                  mailing_country: cus_response?.mailing_country,
                  mailing_address: cus_response?.mailing_address_line1,
                  mailing_suite: cus_response?.mailing_address_line2,
                  mailing_city: cus_response?.mailing_city,
                  mailing_province: cus_response?.mailing_province,
                  mailing_postal_code: cus_response?.mailing_postalcode,
                };
              }
            });

            let policy_details_error = errorPolicyDetails.map((m, index) => {
              if (index === 0) {
                return {
                  ...m,
                  mailing_country: cus_response?.mailing_country
                    ? false
                    : undefined,
                  mailing_address: cus_response?.mailing_address_line1
                    ? false
                    : undefined,
                  mailing_suite: false,
                  mailing_city: cus_response?.mailing_city ? false : undefined,
                  mailing_province: cus_response?.mailing_province
                    ? false
                    : undefined,
                  mailing_postal_code: cus_response?.mailing_postalcode
                    ? false
                    : undefined,
                };
              }
            });

            setErrorPolicyDetails(policy_details_error);

            setPolicyDetailFormValues(policy_details_map);
            setValue(map);
          }
          setGetStartedId(2);
          setBtnLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleNextPage = async () => {
    if (referralFlag?.state || brokerReviewFlag) {
      setAnimateX(100);
      nextPage(formNumber + 1);

      return;
    }

    setRedBox(() => false);
    setErrorAdditionalNames(() => false);

    setBtnLoading(true);

    const allValuesUndefined = Object.values(error).some(
      (value) => value === undefined
    );

    console.log(errorBoatNames);

    const allNamesUndefined =
      boatNames.length > 1 &&
      errorBoatNames.some((obj) => {
        for (const key in obj) {
          if (obj[key] === undefined) {
            return true;
          }
        }
        return false;
      });

    console.log(allNamesUndefined);
    let updatedArray = [...wholeError];
    let updated = [...errorBoatNames];
    let addArray = [...additionalNames];
    let addNamesCheck = false;

    if (value?.additional_name === "Yes") {
      addNamesCheck = addArray.some(
        (m) =>
          m?.add_first_name === "" ||
          m?.add_first_name === undefined ||
          m?.add_last_name === "" ||
          m?.add_last_name === undefined ||
          m?.add_dob === "" ||
          m?.add_dob === undefined ||
          m?.add_phone === "" ||
          m?.add_phone === undefined ||
          m?.add_email === "" ||
          m?.add_email === undefined ||
          !emailRegex.test(m?.add_email) ||
          m?.add_phone.length !== 10
      );
    }

    if (addNamesCheck) {
      await new Promise((resolve) => setTimeout(resolve, 0));

      setErrorAdditionalNames(true);
    } else {
      setErrorAdditionalNames(false);
    }

    console.log(addNamesCheck);

    console.log(allValuesUndefined);
    if (allValuesUndefined || allNamesUndefined) {
      updatedArray = wholeError.map((obj) => {
        const updatedObj = { ...obj };

        for (const key in updatedObj) {
          if (updatedObj[key] === undefined) {
            updatedObj[key] = true;
          }
        }

        console.log(updatedObj);

        return updatedObj;
      });

      updated =
        boatNames.length > 1 &&
        errorBoatNames.map((obj) => {
          const updatedObj = { ...obj };

          for (const key in updatedObj) {
            if (updatedObj[key] === undefined) {
              updatedObj[key] = true;
            }
          }

          console.log(updatedObj);

          return updatedObj;
        });

      console.log(updated);

      setError(updatedArray);

      if (boatNames.length > 1) setErrorBoatNames(updated);
    }

    console.log(updated);

    console.log(updatedArray);

    const anyValueIsTrue = updatedArray.some((obj) => {
      for (const key in obj) {
        if (obj[key] === true) {
          return true;
        }
      }
      return false;
    });

    const anyBoatNameIsTrue =
      boatNames.length > 1 &&
      updated.some((obj) => {
        for (const key in obj) {
          if (obj[key] === true) {
            return true;
          }
        }
        return false;
      });

    if (anyValueIsTrue || anyBoatNameIsTrue || addNamesCheck) {
      await new Promise((resolve) => setTimeout(resolve, 0));

      setRedBox(true);
      setBtnLoading(false);

      return;
    }

    let error_state = false;

    error_state = additionalNames.length
      ? Boolean(
          additionalNames.filter(
            (m) =>
              m.add_first_name.toLowerCase().trim() ===
                value?.insured_first_name.toLowerCase().trim() &&
              m?.add_last_name.toLowerCase().trim() ===
                value?.insured_last_name.toLowerCase().trim() &&
              m?.add_email.toLowerCase().trim() ===
                value?.email.toLowerCase().trim()
          ).length
        )
      : false;

    if (error_state) {
      message.error({
        duration: 3,
        content: "Additional Insured cannot be same as Insured",
      });

      await new Promise((resolve) => setTimeout(resolve, 0));

      setRedBox(true);
      setBtnLoading(false);

      return;
    }

    let body_data = {
      phone: value?.phone,
      dob: dayjs(value?.date_of_birth).format("DD/MM/YYYY"),
      where_you_find_us: value?.find_us,
      social_media_option_name:
        value?.find_us === "Social Media" ? value?.find_us_value : undefined,
      friend_option_name:
        value?.find_us === "Friend or Family"
          ? value?.find_us_value
          : undefined,
      dealership_option_name:
        value?.find_us === "Dealership" ? value?.find_us_value : undefined,
      campground_option_name:
        value?.find_us === "Campground" ? value?.find_us_value : undefined,
      marina_option_name:
        value?.find_us === "Marina" ? value?.find_us_value : undefined,
      others_option_name:
        value?.find_us === "Other" ? value?.find_us_value : undefined,
    };

    await axios
      .post(`${APIURL}/customer/update_customer`, {
        customer_id: globalData.customer_id,
        customer_updates: body_data,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    if (value?.additional_name === "Yes") {
      let add_names_body = {
        quote_record_id: globalData?.quote_record_id,
        type: "Boat",
        additional_customers: [],
      };

      let additional_customers = [];

      let add_names = [...additionalNames];

      for (let i = 0; i < additionalNames.length; i++) {
        additional_customers.push({
          first_name: add_names[i].add_first_name,
          last_name: add_names[i].add_last_name,
          email: add_names[i].add_email,
          dob: dayjs(add_names[i].add_dob).format("DD/MM/YYYY"),
          phone: add_names[i].add_phone,
        });
      }

      add_names_body.additional_customers = additional_customers;

      await axios
        .post(`${APIURL}/customer/create_additional_customers`, add_names_body)
        .then((res) => {
          console.log(res);
          setIsAddNamesSent(true);
        })
        .catch((err) => console.log(err));
    }

    let update_quote_body = {
      boat_quote_updates: {
        customer_id: globalData?.customer_id,
        located_province: value?.province,
        any_additional_names: value?.additional_name === "Yes",
        number_of_boats: boatNames.length,
        organization_id: ORGANIZATION_ID,
        quote_status: "In Progress",
      },
      boat_quote_record_id: globalData?.quote_record_id,
    };

    setBtnLoading(false);

    setAnimateX(100);
    nextPage(formNumber + 1);

    await axios
      .post(`${APIURL}/boat/update_boat_quote`, update_quote_body)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeAddNames = (e, index) => {
    const { name, value } = e.target;

    if (name === "add_phone") {
      if (numberRegex.test(e.target.value)) {
        let updated = additionalNames.map((m) => {
          if (index === m.id - 1) {
            console.log(index);
            return {
              ...m,
              [name]: value,
            };
          }

          return m;
        });

        setAdditionalNames(updated);
      } else {
        return;
      }

      return;
    }

    let updated = additionalNames.map((m) => {
      if (index === m.id - 1) {
        console.log(index);
        return {
          ...m,
          [name]: value,
        };
      }

      return m;
    });

    setAdditionalNames(updated);
  };

  const addBoatFn = (val) => {
    let array = [...boatFormValues];
    let errorArray = [...errorBoat];
    let coverageArray = [...coverageFormValues];
    let eligiblityAarray = [...eligiblityFormValues];
    let boat_specs_array = [...boatSpecsFormValues];
    let addonsArray = [...addonsFormValues];
    let errCoverage = [...errorCoverage];
    let errEligiblity = [...errorEligiblity];
    let boatSpecsError = [...errorBoatSpecs];
    let errAddons = [...errorAddons];
    let boatName = [...boatNames];
    let err_boat_name = [...errorBoatNames];

    let boat_name_obj = {
      name: undefined,
    };

    if (array.length > val) {
      for (let i = array.length; i > val; i--) {
        array.pop();
        errorArray.pop();
        coverageArray.pop();
        eligiblityAarray.pop();
        boat_specs_array.pop();
        addonsArray.pop();
        errCoverage.pop();
        errEligiblity.pop();
        boatSpecsError.pop();
        errAddons.pop();
        boatName.pop();
        err_boat_name.pop();
      }
    } else {
      for (let i = array.length + 1; i <= val; i++) {
        array.push(boat);
        errorArray.push(boat);
        coverageArray.push(coverage);
        errCoverage.push(error_coverage);
        eligiblityAarray.push(eligiblity);
        errEligiblity.push(eligiblity);
        boat_specs_array.push(boat_specs);
        boatSpecsError.push(err_boat_specs);
        addonsArray.push(addons);
        errAddons.push(addons);
        boatName.push(boat_name_obj);
        err_boat_name.push(boat_name_obj);
      }
    }

    setBoatFormValues(array);
    setErrorBoat(errorArray);
    setCoverageFormValues(coverageArray);
    setEligiblityFormValues(eligiblityAarray);
    setBoatSpecsFormValues(boat_specs_array);
    setAddonsFormValues(addonsArray);
    setErrorCoverage(errCoverage);
    setErrorEligiblity(errEligiblity);
    setErrorBoatSpecs(boatSpecsError);
    setErrorAddons(errAddons);

    setBoatNames(boatName);
    setErrorBoatNames(err_boat_name);

    //add the boat form-> calling in parent
    addBoat(val);
  };

  // useEffect(() => {
  //   let error_state = false;

  //   error_state = additionalNames.length
  //     ? Boolean(
  //         additionalNames.filter(
  //           (m) =>
  //             m.add_first_name === value?.insured_first_name &&
  //             m?.add_last_name === value?.insured_last_name &&
  //             m?.add_email === value?.email
  //         ).length
  //       )
  //     : false;

  //   if (error_state) {
  //     notification.error({
  //       duration: 6,
  //       description: "Additional names cannot be same as customer",
  //     });
  //   }

  //   console.log(error_state, "error in add");
  // }, [additionalNames]);

  console.log(value, additionalNames);

  return (
    <motion.div
      initial={{ x: animateX, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div>
        <ConfigProvider
          theme={{
            components: {
              Modal: {
                titleColor: WARNING_COLOR,
              },
            },
          }}
        >
          <Modal
            title={
              <p className="modal_heading">
                <span>First Name, Last Name and/or Email</span>
                <span> Do Not Match!!</span>
              </p>
            }
            open={modalOpen}
            centered
            footer={null}
            // onCancel={() => {
            //   if (contactSupport) {
            //     setContactSupport(false);
            //     setModalOpen(false);
            //   }
            // }}
            closeIcon={false}
          >
            {sendEmail ? (
              <Flex
                align="center"
                justify="center"
                style={{ marginTop: "1rem" }}
                vertical
                gap={20}
              >
                You will receive an email shortly to registered email.
                <ConfigProvider
                  theme={{
                    token: { colorPrimary: PRIMARY_COLOR },
                  }}
                >
                  <Button
                    onClick={() => {
                      setModalOpen(false);
                      setSendEmail(false);
                    }}
                    style={{ width: "fit-content", margin: "auto" }}
                  >
                    Close
                  </Button>
                </ConfigProvider>
              </Flex>
            ) : contactSupport ? (
              <Flex
                align="center"
                justify="center"
                style={{ marginTop: "1rem" }}
                vertical
                gap={20}
              >
                <p>
                  {" "}
                  Please Mail us at
                  <strong style={{ marginLeft: "5px" }}>
                    {" "}
                    <a
                      style={{ color: "#454545", fontSize: "16px" }}
                      href={`mailto:${organization?.sales_email}`}
                    >
                      {organization?.sales_email}
                      {/* service@recprotect.ca */}
                    </a>
                  </strong>{" "}
                </p>
                <ConfigProvider
                  theme={{
                    token: { colorPrimary: PRIMARY_COLOR },
                  }}
                >
                  <Button
                    onClick={() => {
                      setModalOpen(false);
                      setContactSupport(false);
                    }}
                    style={{ width: "fit-content", margin: "auto" }}
                  >
                    Close
                  </Button>
                </ConfigProvider>
              </Flex>
            ) : (
              <Flex
                vertical
                gap={20}
                style={{ marginTop: "1.5rem" }}
                justify="center"
              >
                <ConfigProvider
                  theme={{
                    token: { colorPrimary: PRIMARY_COLOR },
                  }}
                >
                  <Button
                    type="primary"
                    onClick={
                      async () => {
                        await axios
                          .post(
                            `${APIURL}/customer/send_view_or_edit_customer_info_email`,
                            {
                              email: value?.email,
                              organization_id: ORGANIZATION_ID,
                              reset_link: `${
                                window.location.origin
                              }#/customer/edit?customer_id=${
                                globalData?.customer_id
                              }&timestamp=${generateTimestamp()}`,
                            }
                          )
                          .then((res) => {
                            console.log(res);

                            if (res.data?.success) setSendEmail(true);
                            else message.error("Error in sending email");
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }
                      // sendResetLinkToRegisteredMail(value?.email, setModalOpen)
                    }
                  >
                    Send details and/or reset link to registered email{" "}
                  </Button>
                </ConfigProvider>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: PRIMARY_COLOR,
                      // colorPrimaryHover: WARNING_COLOR,
                    },
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      setContactSupport(true);
                    }}
                  >
                    Contact Support
                  </Button>
                </ConfigProvider>
                <ConfigProvider
                  theme={{
                    token: { colorPrimary: PRIMARY_COLOR },
                  }}
                >
                  <Button
                    onClick={() => {
                      setModalOpen(false);
                    }}
                    style={{ width: "fit-content", margin: "auto" }}
                  >
                    Close
                  </Button>
                </ConfigProvider>
              </Flex>
            )}
          </Modal>
        </ConfigProvider>
        <div className={`box ${redBox && "redbox"}`}>
          <div className="trailer_getstarted">
            <header>
              <div className="side_line"></div>
              <h3>
                {getStartedId === 1 ? "Get Started" : "Additional Details"}
              </h3>
              {redBox ? (
                <p className="component_error_text">{COMPONENT_ERROR_TEXT}</p>
              ) : (
                ""
              )}
            </header>
            {getStartedId === 1 ? (
              <div className="container">
                <TextField
                  label="Insured First Name"
                  value={value?.insured_first_name}
                  name={"insured_first_name"}
                  setValue={setValue}
                  error_state={error?.insured_first_name}
                  error_text={FIELD_ERROR_TEXT}
                  error={error}
                  setError={setError}
                  object={value}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  id={id}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                  placeholder={"John"}
                />
                <TextField
                  label="Insured Last Name"
                  value={value?.insured_last_name}
                  name={"insured_last_name"}
                  setValue={setValue}
                  error_state={error?.insured_last_name}
                  error_text={FIELD_ERROR_TEXT}
                  error={error}
                  setError={setError}
                  object={value}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  id={id}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                  placeholder={"Doe"}
                />
                <TextField
                  label="Email"
                  value={value?.email}
                  name={"email"}
                  setValue={setValue}
                  error_state={error?.email}
                  error_text={
                    value?.email === ""
                      ? FIELD_ERROR_TEXT
                      : !emailRegex.test(value.email)
                      ? "Please enter valid email"
                      : ""
                  }
                  error={error}
                  setError={setError}
                  object={value}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  id={id}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                  placeholder={"example@example.com"}
                />

                <div className="inner_btns" style={{ marginBottom: "0" }}>
                  <button
                    className={`inner_next_btn ${btnLoading ? "loading" : ""}`}
                    onClick={handleNext}
                    disabled={btnLoading}
                  >
                    Next {btnLoading && <div className="btn_loading"></div>}
                  </button>
                </div>
              </div>
            ) : (
              <div className="container">
                <CustomPhoneInput
                  label={"Phone"}
                  value={value?.phone}
                  name={"phone"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.phone}
                  error_text={
                    !value?.phone
                      ? FIELD_ERROR_TEXT
                      : value?.phone.length !== 10
                      ? "Please enter valid phone number"
                      : ""
                  }
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                  placeholder={"1234567890"}
                />
                <DatePickerComponent
                  label={"Date of Birth"}
                  value={value?.date_of_birth}
                  name={"date_of_birth"}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  object={value}
                  error_state={error?.date_of_birth}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  max={(current) => {
                    return current && current > new Date();
                  }}
                  placeholder={"DD/MM/YYYY"}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                />
                <div className="checkbox">
                  <label>
                    Are there any additional names on the boat ownership?
                  </label>
                  <div className="input_checkbox">
                    <CustomRadioGroup>
                      <CustomFormControlLabel
                        value={"Yes"}
                        sx={{
                          "&.MuiFormControlLabel-root": {
                            border: error?.additional_name
                              ? `1px solid ${WARNING_COLOR}`
                              : value?.additional_name === "Yes"
                              ? "1px solid #8ed1fc"
                              : "1px solid #d9d9d9",
                            "&:hover": {
                              border: "1px solid #8ed1fc",
                            },
                          },
                        }}
                        disabled={
                          referralFlag?.state || brokerReviewFlag || btnLoading
                        }
                        control={
                          <Radio
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            sx={{
                              "&.Mui-checked": {
                                color: "#8ed1fc",
                              },
                              color: "#d9d9d9",
                              ".MuiSvgIcon-root": { height: "1.25rem" },
                            }}
                            onChange={(e) => {
                              let updated_error = wholeError.map((m, index) => {
                                if (index === id - 1) {
                                  return { ...m, additional_name: false };
                                }

                                return m;
                              });
                              setError(updated_error);

                              let updated = wholevalue.map((m, index) => {
                                if (index === id - 1) {
                                  return {
                                    ...m,
                                    additional_name: e.target.checked && "Yes",
                                  };
                                }

                                return m;
                              });

                              setValue(updated);
                            }}
                            checked={value?.additional_name === "Yes"}
                          />
                        }
                        label={"Yes"}
                      />
                      <CustomFormControlLabel
                        value={value?.additional_name}
                        sx={{
                          "&.MuiFormControlLabel-root": {
                            border: error?.additional_name
                              ? `1px solid ${WARNING_COLOR}`
                              : value?.additional_name === "No"
                              ? "1px solid #8ed1fc"
                              : "1px solid #d9d9d9",
                            "&:hover": {
                              border: "1px solid #8ed1fc",
                            },
                          },
                        }}
                        disabled={
                          referralFlag?.state || brokerReviewFlag || btnLoading
                        }
                        control={
                          <Radio
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            sx={{
                              "&.Mui-checked": {
                                color: "#8ed1fc",
                              },
                              color: "#d9d9d9",
                              ".MuiSvgIcon-root": { height: "1.25rem" },
                            }}
                            onChange={(e) => {
                              let updated_error = wholeError.map((m, index) => {
                                if (index === id - 1) {
                                  return { ...m, additional_name: false };
                                }

                                return m;
                              });
                              setError(updated_error);

                              let updated = wholevalue.map((m, index) => {
                                if (index === id - 1) {
                                  return {
                                    ...m,
                                    additional_name: e.target.checked && "No",
                                  };
                                }

                                return m;
                              });

                              setValue(updated);

                              setAdditionalNames([
                                {
                                  id: 1,
                                  add_first_name: "",
                                  add_last_name: "",
                                  add_email: "",
                                  add_phone: "",
                                  add_dob: "",
                                },
                              ]);
                            }}
                            checked={value?.additional_name === "No"}
                          />
                        }
                        label={"No"}
                      />
                    </CustomRadioGroup>
                    {error?.additional_name && (
                      <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                        {FIELD_ERROR_TEXT}
                      </FormHelperText>
                    )}
                  </div>
                </div>
                {value?.additional_name === "Yes" && (
                  <div
                    className={`box ${errorAdditionalNames && "redbox"}`}
                    style={{ margin: "0" }}
                  >
                    <div className="trailer_getstarted">
                      <header>
                        <div className="side_line"></div>
                        <h3>Additional Named Insured</h3>
                        <button
                          className="add_name_btn"
                          onClick={() => {
                            if (additionalNames?.length > 1) {
                              message.error("Max 2 Additional Insured allowed");
                              return;
                            }

                            let last_val =
                              additionalNames[additionalNames.length - 1].id;

                            let array = [...additionalNames];

                            array.push({
                              id: last_val + 1,
                              add_first_name: "",
                              add_last_name: "",
                              add_email: "",
                              add_phone: "",
                              add_dob: "",
                            });

                            console.log(array);

                            setAdditionalNames(array);
                          }}
                          disabled={
                            referralFlag?.state ||
                            brokerReviewFlag ||
                            btnLoading
                          } // disabled={additionalNames?.length > 1}
                        >
                          Add name
                        </button>
                        {/* {additionalNames.length > 1 && ( */}
                        <button
                          className="delete_add_btn"
                          onClick={() => {
                            let arr = [...additionalNames];

                            if (arr.length === 1) {
                              setAdditionalNames([
                                {
                                  id: 1,
                                  add_first_name: "",
                                  add_last_name: "",
                                  add_email: "",
                                  add_phone: "",
                                  add_dob: "",
                                },
                              ]);
                              return;
                            }

                            let filter = arr.filter((mm, i) => i === 0);

                            setAdditionalNames(filter);
                          }}
                          disabled={
                            referralFlag?.state ||
                            brokerReviewFlag ||
                            btnLoading
                          }
                        >
                          Delete
                        </button>
                        {/* )} */}
                      </header>
                      <div className="container" style={{ gap: "0rem" }}>
                        <div className="additional_names">
                          <div>
                            <div className="add_name_label">
                              <p>First Name</p>
                            </div>

                            {additionalNames.length &&
                              additionalNames.map((m, index) => {
                                return (
                                  <div
                                    className="add_inputs"
                                    style={{
                                      width:
                                        additionalNames.length > 1
                                          ? "40%"
                                          : "80%",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      key={index}
                                      name="add_first_name"
                                      value={
                                        additionalNames[index]?.add_first_name
                                      }
                                      onChange={(e) =>
                                        handleChangeAddNames(e, index)
                                      }
                                      disabled={
                                        referralFlag?.state ||
                                        brokerReviewFlag ||
                                        btnLoading
                                      }
                                      placeholder="John"
                                      className={
                                        additionalNames[index]
                                          ?.add_first_name === "" ||
                                        Boolean(
                                          additionalNames[index].add_first_name
                                            .toLowerCase()
                                            .trim() ===
                                            value?.insured_first_name
                                              .toLowerCase()
                                              .trim() &&
                                            additionalNames[index].add_last_name
                                              .toLowerCase()
                                              .trim() ===
                                              value?.insured_last_name
                                                .toLowerCase()
                                                .trim() &&
                                            additionalNames[index].add_email
                                              .toLowerCase()
                                              .trim() ===
                                              value?.email.toLowerCase().trim()
                                        )
                                          ? "add_input_err"
                                          : ""
                                      }
                                    />
                                  </div>
                                );
                              })}
                          </div>

                          <div>
                            <div className="add_name_label">
                              <p>Last Name</p>
                            </div>

                            {additionalNames.length &&
                              additionalNames.map((m, index) => {
                                return (
                                  <div
                                    className="add_inputs"
                                    style={{
                                      width:
                                        additionalNames.length > 1
                                          ? "40%"
                                          : "80%",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      value={
                                        additionalNames[index]?.add_last_name
                                      }
                                      name="add_last_name"
                                      onChange={(e) =>
                                        handleChangeAddNames(e, index)
                                      }
                                      disabled={
                                        referralFlag?.state ||
                                        brokerReviewFlag ||
                                        btnLoading
                                      }
                                      placeholder="Doe"
                                      className={
                                        additionalNames[index]
                                          ?.add_last_name === "" ||
                                        Boolean(
                                          additionalNames[index].add_first_name
                                            .toLowerCase()
                                            .trim() ===
                                            value?.insured_first_name
                                              .toLowerCase()
                                              .trim() &&
                                            additionalNames[index].add_last_name
                                              .toLowerCase()
                                              .trim() ===
                                              value?.insured_last_name
                                                .toLowerCase()
                                                .trim() &&
                                            additionalNames[index].add_email
                                              .toLowerCase()
                                              .trim() ===
                                              value?.email.toLowerCase().trim()
                                        )
                                          ? "add_input_err"
                                          : ""
                                      }
                                    />
                                  </div>
                                );
                              })}
                          </div>

                          <div>
                            <div className="add_name_label">
                              <p>Email</p>
                            </div>

                            {additionalNames.length &&
                              additionalNames.map((m, index) => {
                                return (
                                  <div
                                    className="add_inputs"
                                    style={{
                                      width:
                                        additionalNames.length > 1
                                          ? "40%"
                                          : "80%",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      value={additionalNames[index]?.add_email}
                                      name="add_email"
                                      onChange={(e) =>
                                        handleChangeAddNames(e, index)
                                      }
                                      disabled={
                                        referralFlag?.state ||
                                        brokerReviewFlag ||
                                        btnLoading
                                      }
                                      placeholder={"example@example.com"}
                                      className={
                                        additionalNames[index]?.add_email ===
                                          "" ||
                                        (additionalNames[index]?.add_email &&
                                          !emailRegex.test(
                                            additionalNames[index]?.add_email
                                          )) ||
                                        Boolean(
                                          additionalNames[index].add_first_name
                                            .toLowerCase()
                                            .trim() ===
                                            value?.insured_first_name
                                              .toLowerCase()
                                              .trim() &&
                                            additionalNames[index].add_last_name
                                              .toLowerCase()
                                              .trim() ===
                                              value?.insured_last_name
                                                .toLowerCase()
                                                .trim() &&
                                            additionalNames[index].add_email
                                              .toLowerCase()
                                              .trim() ===
                                              value?.email.toLowerCase().trim()
                                        )
                                          ? "add_input_err"
                                          : ""
                                      }
                                    />
                                  </div>
                                );
                              })}
                          </div>

                          <div>
                            <div className="add_name_label">
                              <p>Phone</p>
                            </div>

                            {additionalNames.length &&
                              additionalNames.map((m, index) => {
                                return (
                                  <div
                                    className="add_inputs"
                                    style={{
                                      width:
                                        additionalNames.length > 1
                                          ? "40%"
                                          : "80%",
                                    }}
                                  >
                                    <ConfigProvider
                                      theme={{
                                        components: {
                                          Input: {
                                            activeBorderColor: PRIMARY_COLOR,
                                            hoverBorderColor: PRIMARY_COLOR,
                                            activeShadow: "none",
                                            errorActiveShadow: "none",
                                          },
                                        },
                                        token: {
                                          colorErrorOutline: "none",
                                          colorError: WARNING_COLOR,
                                          colorErrorBorderHover: "none",
                                          fontSize: 12,
                                        },
                                      }}
                                    >
                                      <Input
                                        size="medium"
                                        addonBefore={
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "2px",
                                            }}
                                          >
                                            <img
                                              style={{
                                                height: "0.65rem",
                                                opacity: "0.7",
                                              }}
                                              src={canadaFlag}
                                              alt=""
                                            />
                                            {/* <p style={{ fontWeight: "500" }}>+1</p> */}
                                          </div>
                                        }
                                        id={""}
                                        status={
                                          additionalNames[index]?.add_phone ===
                                            "" ||
                                          (additionalNames[index]?.add_phone &&
                                            additionalNames[index]?.add_phone
                                              .length !== 10)
                                            ? "error"
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleChangeAddNames(e, index)
                                        }
                                        name="add_phone"
                                        value={
                                          additionalNames[index]?.add_phone
                                        }
                                        disabled={
                                          referralFlag?.state ||
                                          brokerReviewFlag ||
                                          btnLoading
                                        }
                                        placeholder="1234567890"
                                      />
                                    </ConfigProvider>
                                  </div>
                                );
                              })}
                          </div>

                          <div>
                            <div className="add_name_label">
                              <p>Date of Birth</p>
                            </div>

                            {additionalNames.length &&
                              additionalNames.map((m, index) => {
                                return (
                                  <div
                                    className="add_inputs"
                                    style={{
                                      width:
                                        additionalNames.length > 1
                                          ? "40%"
                                          : "80%",
                                    }}
                                  >
                                    <ConfigProvider
                                      theme={{
                                        components: {
                                          DatePicker: {
                                            activeBorderColor: "#8ed1fc",
                                            cellHoverWithRangeBg: "#fff",
                                            hoverBorderColor: "#8ed1fc",
                                            cellRangeBorderColor: "#fff",
                                            cellActiveWithRangeBg: "#fff",
                                            activeShadow: "0 0 0 0",
                                          },
                                        },
                                        token: {
                                          colorPrimary: "#8ed1fc",
                                        },
                                      }}
                                    >
                                      <DatePicker
                                        size="medium"
                                        style={{
                                          width: "100%",
                                          padding: "2.5px 7.5px",
                                        }}
                                        allowClear={false}
                                        value={additionalNames[index]?.add_dob}
                                        disabledDate={(current) => {
                                          return (
                                            current && current > new Date()
                                          );
                                        }}
                                        onChange={(date, dateString) => {
                                          console.log(date);

                                          let updated = additionalNames.map(
                                            (m) => {
                                              if (index === m.id - 1) {
                                                console.log(index);
                                                return {
                                                  ...m,
                                                  add_dob: date,
                                                };
                                              }

                                              return m;
                                            }
                                          );

                                          setAdditionalNames(updated);
                                        }}
                                        format={"DD/MM/YYYY"}
                                        placeholder={"DD/MM/YYYY"}
                                        disabled={
                                          referralFlag?.state ||
                                          brokerReviewFlag ||
                                          btnLoading
                                        }
                                        status={
                                          additionalNames[index]?.add_dob ===
                                            "" && "error"
                                        }
                                      />
                                    </ConfigProvider>
                                  </div>
                                );
                              })}
                          </div>

                          {/* <div className="add_name_label">
                            <p>First Name</p>
                            <p>Last Name</p>
                            <p>Email</p>
                            <p>Phone</p>
                            <p>Date of Birth</p>
                          </div> */}

                          {additionalNames.length &&
                            additionalNames.map((m, index) => {
                              return (
                                // <div
                                //   className="add_inputs"
                                //   style={{
                                //     width:
                                //       additionalNames.length > 1
                                //         ? "40%"
                                //         : "80%",
                                //   }}
                                // >
                                //   <input
                                //     type="text"
                                //     key={index}
                                //     name="add_first_name"
                                //     value={
                                //       additionalNames[index]?.add_first_name
                                //     }
                                //     onChange={(e) =>
                                //       handleChangeAddNames(e, index)
                                //     }
                                //     disabled={
                                //       referralFlag?.state || brokerReviewFlag || btnLoading
                                //     }
                                //     placeholder="John"
                                //     className={
                                //       additionalNames[index]?.add_first_name ===
                                //       ""
                                //         ? "add_input_err"
                                //         : ""
                                //     }
                                //   />
                                //   <input
                                //     type="text"
                                //     value={
                                //       additionalNames[index]?.add_last_name
                                //     }
                                //     name="add_last_name"
                                //     onChange={(e) =>
                                //       handleChangeAddNames(e, index)
                                //     }
                                //     disabled={
                                //       referralFlag?.state || brokerReviewFlag || btnLoading
                                //     }
                                //     placeholder="Doe"
                                //     className={
                                //       additionalNames[index]?.add_last_name ===
                                //       ""
                                //         ? "add_input_err"
                                //         : ""
                                //     }
                                //   />
                                //   <input
                                //     type="text"
                                //     value={additionalNames[index]?.add_email}
                                //     name="add_email"
                                //     onChange={(e) =>
                                //       handleChangeAddNames(e, index)
                                //     }
                                //     disabled={
                                //       referralFlag?.state || brokerReviewFlag || btnLoading
                                //     }
                                //     placeholder={"example@example.com"}
                                //     className={
                                //       additionalNames[index]?.add_email ===
                                //         "" ||
                                //       (additionalNames[index]?.add_email &&
                                //         !emailRegex.test(
                                //           additionalNames[index]?.add_email
                                //         ))
                                //         ? "add_input_err"
                                //         : ""
                                //     }
                                //   />

                                //   <ConfigProvider
                                //     theme={{
                                //       components: {
                                //         Input: {
                                //           activeBorderColor: PRIMARY_COLOR,
                                //           hoverBorderColor: PRIMARY_COLOR,
                                //           activeShadow: "none",
                                //           errorActiveShadow: "none",
                                //         },
                                //       },
                                //       token: {
                                //         colorErrorOutline: "none",
                                //         colorError: WARNING_COLOR,
                                //         colorErrorBorderHover: "none",
                                //         fontSize: 12,
                                //       },
                                //     }}
                                //   >
                                //     <Input
                                //       size="medium"
                                //       addonBefore={
                                //         <div
                                //           style={{
                                //             display: "flex",
                                //             alignItems: "center",
                                //             gap: "2px",
                                //           }}
                                //         >
                                //           <img
                                //             style={{
                                //               height: "0.65rem",
                                //               opacity: "0.7",
                                //             }}
                                //             src={canadaFlag}
                                //             alt=""
                                //           />
                                //           {/* <p style={{ fontWeight: "500" }}>+1</p> */}
                                //         </div>
                                //       }
                                //       id={""}
                                //       status={
                                //         additionalNames[index]?.add_phone ===
                                //           "" ||
                                //         (additionalNames[index]?.add_phone &&
                                //           additionalNames[index]?.add_phone
                                //             .length !== 10)
                                //           ? "error"
                                //           : ""
                                //       }
                                //       onChange={(e) =>
                                //         handleChangeAddNames(e, index)
                                //       }
                                //       name="add_phone"
                                //       value={additionalNames[index]?.add_phone}
                                //       disabled={
                                //         referralFlag?.state ||
                                //         brokerReviewFlag ||
                                //         btnLoading
                                //       }
                                //       placeholder="1234567890"
                                //     />
                                //   </ConfigProvider>

                                //   <ConfigProvider
                                //     theme={{
                                //       components: {
                                //         DatePicker: {
                                //           activeBorderColor: "#8ed1fc",
                                //           cellHoverWithRangeBg: "#fff",
                                //           hoverBorderColor: "#8ed1fc",
                                //           cellRangeBorderColor: "#fff",
                                //           cellActiveWithRangeBg: "#fff",
                                //           activeShadow: "0 0 0 0",
                                //         },
                                //       },
                                //       token: {
                                //         colorPrimary: "#8ed1fc",
                                //       },
                                //     }}
                                //   >
                                //     <DatePicker
                                //       size="medium"
                                //       style={{
                                //         width: "100%",
                                //         padding: "2.5px 7.5px",
                                //       }}
                                //       allowClear={false}
                                //       value={additionalNames[index]?.add_dob}
                                //       disabledDate={(current) => {
                                //         return current && current > new Date();
                                //       }}
                                //       onChange={(date, dateString) => {
                                //         console.log(date);

                                //         let updated = additionalNames.map(
                                //           (m) => {
                                //             if (index === m.id - 1) {
                                //               console.log(index);
                                //               return {
                                //                 ...m,
                                //                 add_dob: date,
                                //               };
                                //             }

                                //             return m;
                                //           }
                                //         );

                                //         setAdditionalNames(updated);
                                //       }}
                                //       format={"DD/MM/YYYY"}
                                //       placeholder={"DD/MM/YYYY"}
                                //       disabled={
                                //         referralFlag?.state ||
                                //         brokerReviewFlag ||
                                //         btnLoading
                                //       }
                                //       status={
                                //         additionalNames[index]?.add_dob ===
                                //           "" && "error"
                                //       }
                                //     />
                                //   </ConfigProvider>
                                // </div>

                                <></>
                              );
                            })}
                        </div>
                        {Boolean(
                          additionalNames.filter(
                            (m) =>
                              m?.add_first_name.toLowerCase().trim() ===
                                value?.insured_first_name
                                  .toLowerCase()
                                  .trim() &&
                              m?.add_last_name.toLowerCase().trim() ===
                                value?.insured_last_name.toLowerCase().trim() &&
                              m?.add_email.toLowerCase().trim() ===
                                value?.email.toLowerCase().trim()
                          ).length
                        ) ? (
                          <FormHelperText
                            style={ERROR_HELPERTEXT_STYLES}
                            sx={{ margin: "0", paddingTop: "1rem" }}
                          >
                            Additional Insured cannot be same as Insured{" "}
                          </FormHelperText>
                        ) : (
                          errorAdditionalNames && (
                            <FormHelperText
                              style={ERROR_HELPERTEXT_STYLES}
                              sx={{ margin: "0", paddingTop: "10px" }}
                            >
                              These fields are required
                            </FormHelperText>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="find_us_container">
                  <label>Where did you find us?</label>
                  <RadioGroup
                    onChange={(e) => {
                      let val = e.target.value;

                      console.log(e.target.value);

                      if (
                        val === "Social Media" ||
                        val === "Friend or Family" ||
                        val === "Dealership" ||
                        val === "Campground" ||
                        val === "Marina" ||
                        val === "Other"
                      ) {
                        let updated = wholevalue.map((m, index) => {
                          if (index === id - 1) {
                            return {
                              ...m,
                              find_us: val,
                              find_us_value: "",
                            };
                          }

                          return m;
                        });

                        setValue(updated);

                        let updated_error = wholeError.map((m, index) => {
                          if (index === id - 1) {
                            return {
                              ...m,
                              find_us_value: true,
                              find_us: false,
                            };
                          }

                          return m;
                        });
                        setError(updated_error);

                        return;
                      } else {
                        let updated_error = wholeError.map((m, index) => {
                          if (index === id - 1) {
                            return {
                              ...m,
                              find_us_value: false,
                              find_us: false,
                            };
                          }

                          return m;
                        });
                        setError(updated_error);
                      }

                      let updated = wholevalue.map((m, index) => {
                        if (index === id - 1) {
                          return {
                            ...m,
                            find_us: val,
                          };
                        }

                        return m;
                      });

                      setValue(updated);
                    }}
                  >
                    <FormControlLabel
                      value={"Google"}
                      sx={{
                        ".MuiTypography-root": {
                          fontFamily: "Montserrat",
                          fontSize: "0.875rem",
                        },
                      }}
                      control={
                        <Radio
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled={
                            referralFlag?.state ||
                            brokerReviewFlag ||
                            btnLoading
                          }
                          size="small"
                          sx={{
                            "&.Mui-checked": {
                              color: "#8ed1fc",
                            },
                            color: "#d9d9d9",
                            ".MuiSvgIcon-root": { height: "1.125rem" },
                          }}
                        />
                      }
                      label="Google"
                      checked={value?.find_us === "Google"}
                    />
                    <FormControlLabel
                      value="Social Media"
                      sx={{
                        ".MuiTypography-root": {
                          fontFamily: "Montserrat",
                          fontSize: "0.875rem",
                        },
                      }}
                      control={
                        <Radio
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled={
                            referralFlag?.state ||
                            brokerReviewFlag ||
                            btnLoading
                          }
                          size="small"
                          sx={{
                            "&.Mui-checked": {
                              color: "#8ed1fc",
                            },
                            color: "#d9d9d9",
                            ".MuiSvgIcon-root": { height: "1.125rem" },
                          }}
                        />
                      }
                      label="Social Media"
                      checked={value?.find_us === "Social Media"}
                    />
                    {value?.find_us === "Social Media" && (
                      <div className="find_us_value_container">
                        <label>Choose one from the following:</label>
                        <ConfigProvider
                          theme={{
                            token: {
                              colorPrimary: PRIMARY_COLOR,
                              colorPrimaryHover: PRIMARY_COLOR,
                              colorErrorOutline: "none",
                              colorError: WARNING_COLOR,
                              colorErrorBorderHover: "none",
                              controlOutline: "none",
                              fontSize: 12,
                            },
                          }}
                        >
                          <Select
                            options={Social_Media_Options}
                            style={{ width: "100%", textAlign: "left" }}
                            showSearch
                            value={value?.find_us_value}
                            size="small"
                            placeholder={"Select"}
                            disabled={
                              referralFlag?.state ||
                              brokerReviewFlag ||
                              btnLoading
                            }
                            onChange={(value) => {
                              if (value === "") {
                                let updated_error = wholeError.map(
                                  (m, index) => {
                                    if (index === id - 1) {
                                      return { ...m, find_us_value: true };
                                    }

                                    return m;
                                  }
                                );
                                setError(updated_error);
                              } else {
                                let updated_error = wholeError.map(
                                  (m, index) => {
                                    if (index === id - 1) {
                                      return { ...m, find_us_value: false };
                                    }

                                    return m;
                                  }
                                );
                                setError(updated_error);
                              }
                              let updated = wholevalue.map((m, index) => {
                                if (index === id - 1) {
                                  return {
                                    ...m,
                                    find_us_value: value,
                                  };
                                }

                                return m;
                              });

                              setValue(updated);
                            }}
                          ></Select>
                        </ConfigProvider>
                        {/* <input
                          type="text"
                          value={value?.find_us_value}
                          onChange={handleChangeFindUsValue}
                          disabled={
                            referralFlag?.state ||
                            brokerReviewFlag ||
                            btnLoading
                          }
                          placeholder="John Doe"
                        /> */}
                        {error?.find_us_value && (
                          <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                            {FIELD_ERROR_TEXT}
                          </FormHelperText>
                        )}{" "}
                      </div>
                    )}
                    <FormControlLabel
                      value="Friend or Family"
                      sx={{
                        ".MuiTypography-root": {
                          fontFamily: "Montserrat",
                          fontSize: "0.875rem",
                        },
                      }}
                      control={
                        <Radio
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled={
                            referralFlag?.state ||
                            brokerReviewFlag ||
                            btnLoading
                          }
                          size="small"
                          sx={{
                            "&.Mui-checked": {
                              color: "#8ed1fc",
                            },
                            color: "#d9d9d9",
                            ".MuiSvgIcon-root": { height: "1.125rem" },
                          }}
                        />
                      }
                      label="Friend or Family"
                      checked={value?.find_us === "Friend or Family"}
                    />
                    {value?.find_us === "Friend or Family" && (
                      <div className="find_us_value_container">
                        <label>Name of Friend/Family:</label>
                        <input
                          type="text"
                          value={value?.find_us_value}
                          onChange={handleChangeFindUsValue}
                          disabled={
                            referralFlag?.state ||
                            brokerReviewFlag ||
                            btnLoading
                          }
                          placeholder="John Doe"
                        />
                        {error?.find_us_value && (
                          <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                            {FIELD_ERROR_TEXT}
                          </FormHelperText>
                        )}{" "}
                      </div>
                    )}
                    <FormControlLabel
                      value="Dealership"
                      sx={{
                        ".MuiTypography-root": {
                          fontFamily: "Montserrat",
                          fontSize: "0.875rem",
                        },
                        ".MuiSvgIcon-root": { height: "1.125rem" },
                      }}
                      control={
                        <Radio
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled={
                            referralFlag?.state ||
                            brokerReviewFlag ||
                            btnLoading
                          }
                          size="small"
                          sx={{
                            "&.Mui-checked": {
                              color: "#8ed1fc",
                            },
                            color: "#d9d9d9",
                          }}
                        />
                      }
                      label="Dealership"
                      checked={value?.find_us === "Dealership"}
                    />
                    {value?.find_us === "Dealership" && (
                      <div className="find_us_value_container">
                        <label>Name of Dealership:</label>
                        <input
                          type="text"
                          value={value?.find_us_value}
                          onChange={handleChangeFindUsValue}
                          disabled={
                            referralFlag?.state ||
                            brokerReviewFlag ||
                            btnLoading
                          }
                          placeholder="Carlson's dealership"
                        />
                        {error?.find_us_value && (
                          <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                            {FIELD_ERROR_TEXT}
                          </FormHelperText>
                        )}
                      </div>
                    )}
                    <FormControlLabel
                      value="Campground"
                      sx={{
                        ".MuiTypography-root": {
                          fontFamily: "Montserrat",
                          fontSize: "0.875rem",
                        },
                        ".MuiSvgIcon-root": { height: "1.125rem" },
                      }}
                      control={
                        <Radio
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled={
                            referralFlag?.state ||
                            brokerReviewFlag ||
                            btnLoading
                          }
                          size="small"
                          sx={{
                            "&.Mui-checked": {
                              color: "#8ed1fc",
                            },
                            color: "#d9d9d9",
                          }}
                        />
                      }
                      label="Campground"
                      checked={value?.find_us === "Campground"}
                    />
                    {value?.find_us === "Campground" && (
                      <div className="find_us_value_container">
                        <label>Name of Campground:</label>
                        <input
                          type="text"
                          value={value?.find_us_value}
                          onChange={handleChangeFindUsValue}
                          disabled={
                            referralFlag?.state ||
                            brokerReviewFlag ||
                            btnLoading
                          }
                          placeholder="example Campground"
                        />
                        {error?.find_us_value && (
                          <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                            {FIELD_ERROR_TEXT}
                          </FormHelperText>
                        )}
                      </div>
                    )}
                    <FormControlLabel
                      value="Marina"
                      sx={{
                        ".MuiTypography-root": {
                          fontFamily: "Montserrat",
                          fontSize: "0.875rem",
                        },
                        ".MuiSvgIcon-root": { height: "1.125rem" },
                      }}
                      control={
                        <Radio
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled={
                            referralFlag?.state ||
                            brokerReviewFlag ||
                            btnLoading
                          }
                          size="small"
                          sx={{
                            "&.Mui-checked": {
                              color: "#8ed1fc",
                            },
                            color: "#d9d9d9",
                          }}
                        />
                      }
                      label="Marina"
                      checked={value?.find_us === "Marina"}
                    />
                    {value?.find_us === "Marina" && (
                      <div className="find_us_value_container">
                        <label>Name of Marina:</label>
                        <input
                          type="text"
                          value={value?.find_us_value}
                          onChange={handleChangeFindUsValue}
                          disabled={
                            referralFlag?.state ||
                            brokerReviewFlag ||
                            btnLoading
                          }
                          placeholder="example Marina"
                        />
                        {error?.find_us_value && (
                          <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                            {FIELD_ERROR_TEXT}
                          </FormHelperText>
                        )}
                      </div>
                    )}
                    <FormControlLabel
                      value="Other"
                      sx={{
                        ".MuiTypography-root": {
                          fontFamily: "Montserrat",
                          fontSize: "0.875rem",
                        },
                        ".MuiSvgIcon-root": { height: "1.125rem" },
                      }}
                      control={
                        <Radio
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled={
                            referralFlag?.state ||
                            brokerReviewFlag ||
                            btnLoading
                          }
                          size="small"
                          sx={{
                            "&.Mui-checked": {
                              color: "#8ed1fc",
                            },
                            color: "#d9d9d9",
                          }}
                        />
                      }
                      label="Other"
                      checked={value?.find_us === "Other"}
                    />
                    {value?.find_us === "Other" && (
                      <div className="find_us_value_container">
                        <label>Tell us more:</label>
                        <ConfigProvider
                          theme={{
                            components: {
                              Input: {
                                activeBorderColor: PRIMARY_COLOR,
                                hoverBorderColor: PRIMARY_COLOR,
                                activeShadow: "none",
                                errorActiveShadow: "none",
                              },
                            },
                            token: {
                              colorErrorOutline: "none",
                              colorError: WARNING_COLOR,
                              colorErrorBorderHover: "none",
                            },
                          }}
                        >
                          <Input.TextArea
                            status={error?.find_us_value && "error"}
                            minLength={2}
                            type="text"
                            value={value?.find_us_value}
                            onChange={handleChangeFindUsValue}
                            disabled={
                              referralFlag?.state ||
                              brokerReviewFlag ||
                              btnLoading
                            }
                            placeholder="Your words.."
                          />
                        </ConfigProvider>
                        {error?.find_us_value && (
                          <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                            {FIELD_ERROR_TEXT}
                          </FormHelperText>
                        )}
                      </div>
                    )}
                    {error?.find_us && (
                      <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                        {FIELD_ERROR_TEXT}
                      </FormHelperText>
                    )}
                  </RadioGroup>
                </div>
                <SelectComponent
                  options={Province_Options}
                  name="province"
                  label="Please select the province your watercraft is located in"
                  value={value?.province}
                  setValue={setValue}
                  error={error}
                  object={value}
                  setError={setError}
                  error_state={error?.province}
                  error_text={FIELD_ERROR_TEXT}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  id={id}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                  placeholder=""
                />
                <div className="select">
                  <label htmlFor={"number"}>
                    How many boats would you like to insure?
                  </label>
                  <div>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: PRIMARY_COLOR,
                          colorPrimaryHover: PRIMARY_COLOR,
                          colorErrorOutline: "none",
                          colorError: WARNING_COLOR,
                          colorErrorBorderHover: "none",
                          controlOutline: "none",
                          fontSize: 12,
                        },
                      }}
                    >
                      <Select
                        id={"number"}
                        size="large"
                        value={boatNumber}
                        style={{ width: "100%" }}
                        onChange={(val) => addBoatFn(val)}
                        disabled={
                          referralFlag?.state || brokerReviewFlag || btnLoading
                        }
                      >
                        {number_of_boats.length &&
                          number_of_boats.map((m) => {
                            return <option value={m.value}>{m.text}</option>;
                          })}
                      </Select>
                    </ConfigProvider>
                  </div>
                </div>
                {boatNames.length > 1 &&
                  boatNames.map((m, index) => {
                    return (
                      <Autocomplete
                        label={`Manufacturer for ${
                          index === 0
                            ? "first"
                            : index === 1
                            ? "second"
                            : index === 2
                            ? "third"
                            : index === 3
                            ? "fourth"
                            : index === 4
                            ? "fifth"
                            : ""
                        } boat`}
                        name={"name"}
                        options={manufacturerOptions}
                        value={boatNames[index].name}
                        setValue={setBoatNames}
                        error={errorBoatNames}
                        setError={setErrorBoatNames}
                        error_state={errorBoatNames[index]?.name}
                        error_text={FIELD_ERROR_TEXT}
                        id={index + 1}
                        object={value}
                        wholevalue={boatNames}
                        wholeError={errorBoatNames}
                        disabled={
                          referralFlag?.state || brokerReviewFlag || btnLoading
                        }
                      />
                    );
                  })}
                <div className="inner_btns">
                  <button
                    className="inner_back_btn"
                    onClick={() => {
                      setGetStartedId(1);
                      setRedBox(() => false);
                    }}
                    disabled={btnLoading}
                  >
                    Back
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {getStartedId === 2 && (
          <div className="trailer_btns">
            <button
              className={`next_btn ${btnLoading ? "loading" : ""}`}
              onClick={handleNextPage}
              disabled={btnLoading}
            >
              Next {btnLoading && <div className="btn_loading"></div>}
            </button>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default GetStarted;
