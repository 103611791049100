import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import TextFieldComponent from "../TextField";
import Select from "../Select";
import { FormHelperText } from "@mui/material";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "../../api";
import TextToImageConverter from "./TextToImage";
import DatePickerComponent from "../DatePicker";
import dayjs from "dayjs";
import {
  COMPONENT_ERROR_TEXT,
  ERROR_HELPERTEXT_STYLES,
  FIELD_ERROR_TEXT,
  Province_Options,
} from "../../utils";
import AddressAutoComplete from "../AddressAutoComplete";

const usaZipRegex = /^\d{5}(?:-\d{4})?$/;
const canadaPostalRegex =
  /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ] ?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i;

const PolicyDetails = ({
  formNumber,
  nextPage,
  backPage,
  animateX,
  setAnimateX,
  value,
  setValue,
  error,
  setError,
  id,
  wholevalue,
  wholeError,
  signatureObj,
  setSignatureObj,
  getStartedFormValues,
  globalData,
  referralFlag,
  organization,
  brokerReviewFlag,
  defaultData,
}) => {
  const country = [
    { value: "Canada", label: "Canada" },
    { value: "US", label: "US" },
  ];

  const divRef = useRef();

  const [redBox, setRedBox] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  console.log(wholevalue);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    if (
      defaultData?.quote_status !== "Completed" &&
      defaultData?.quote_status !== "" &&
      dayjs(value?.inception_date).isValid() &&
      value?.inception_date !== undefined
    ) {
      if (dayjs(value?.inception_date).isBefore(dayjs(), "day")) {
        let expiry_date = dayjs().add(1, "year");

        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, inception_date: false, expiry_date: false };
          }

          return m;
        });

        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              inception_date: dayjs(),
              expiry_date: dayjs(expiry_date),
            };
          }

          return m;
        });

        setValue(updated);
      }

      console.log(defaultData?.quote_status);
    }
  }, []);

  return (
    <motion.div
      initial={{ x: animateX, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div>
        <div className={`box multiple_box ${redBox && "redbox"}`}>
          <div className="trailer_policy_details">
            <header>
              <div className="side_line"></div>
              <h3 className="error_h3">
                Policy Details{" "}
                {redBox ? (
                  <p className="component_error_text">{COMPONENT_ERROR_TEXT}</p>
                ) : (
                  ""
                )}{" "}
              </h3>
            </header>
            <div className="container">
              <DatePickerComponent
                label={"Inception Date"}
                value={value?.inception_date}
                name={"inception_date"}
                setValue={setValue}
                object={value}
                error_state={error?.inception_date}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                info={true}
                disabled={
                  defaultData?.quote_status !== "Return To Payment" &&
                  (referralFlag?.state || brokerReviewFlag || btnLoading)
                }
                placeholder={"Select the date"}
                max={(current) => {
                  const currentDate = dayjs();
                  const endDate = currentDate.add(29, "days");

                  return (
                    current &&
                    (current < currentDate.startOf("day") ||
                      current > endDate.endOf("day"))
                  );
                }}
              />
              <DatePickerComponent
                label={"Expiry Date"}
                disabled={true}
                value={value?.expiry_date}
                name={"expiry_date"}
                placeholder={""}
              />
            </div>
          </div>
        </div>
        <div className={`box multiple_box ${redBox && "redbox"}`}>
          <div className="trailer_policy_details">
            <header>
              <div className="side_line"></div>
              <h3>Mailing Address</h3>
            </header>
            <div className="container">
              <Select
                label={"Country"}
                options={country}
                value={value?.mailing_country}
                name={"mailing_country"}
                setValue={setValue}
                object={value}
                error_state={error?.mailing_country}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag || btnLoading}
              />

              {/* <AddressAutoComplete
                label={"Address"}
                value={value?.mailing_address}
                name={"mailing_address"}
                setValue={setValue}
                object={value}
                error_state={error?.mailing_address}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag || btnLoading}
                POSTGRID_API_KEY={organization?.post_grid_api_key}
              /> */}

              <TextFieldComponent
                label={"Address"}
                value={value?.mailing_address}
                name={"mailing_address"}
                setValue={setValue}
                object={value}
                error_state={error?.mailing_address}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag || btnLoading}
              />

              <TextFieldComponent
                label={"Suite/Apt #"}
                value={value?.mailing_suite}
                name={"mailing_suite"}
                setValue={setValue}
                object={value}
                error_state={error?.mailing_suite}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag || btnLoading}

                // disabled={true}
              />
              <TextFieldComponent
                label={"City"}
                value={value?.mailing_city}
                name={"mailing_city"}
                setValue={setValue}
                object={value}
                error_state={error?.mailing_city}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                // disabled={true}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag || btnLoading}
              />
              <TextFieldComponent
                label={"Province"}
                options={Province_Options}
                value={value?.mailing_province}
                name={"mailing_province"}
                setValue={setValue}
                object={value}
                error_state={error?.mailing_province}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                // disabled={true}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag || btnLoading}
              />

              <TextFieldComponent
                label={"Postal Code"}
                value={value?.mailing_postal_code}
                name={"mailing_postal_code"}
                setValue={setValue}
                object={value}
                error_state={error?.mailing_postal_code}
                // error_text={FIELD_ERROR_TEXT}
                error_text={
                  value?.mailing_postal_code === ""
                    ? FIELD_ERROR_TEXT
                    : value?.mailing_country === "US" &&
                      !usaZipRegex.test(value.mailing_postal_code)
                    ? "Please enter valid postal code"
                    : value?.mailing_country === "Canada" &&
                      !canadaPostalRegex.test(value.mailing_postal_code)
                    ? "Please enter valid postal code"
                    : ""
                }
                error={error}
                // disabled={true}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag || btnLoading}
              />
            </div>
          </div>
        </div>

        <div className={`box multiple_box ${redBox && "redbox"}`}>
          <div className="trailer_policy_details" ref={divRef}>
            <header>
              <div className="side_line"></div>
              <h3>Signature</h3>
            </header>
            <div className="text_to_img" style={{ paddingBottom: "1rem" }}>
              <label>
                Your signature is required to bind this insurance policy. Please
                type your legal name in the box below and click accept once
                completed.
              </label>
              <div className="canva_container">
                <TextFieldComponent
                  label={"Enter your name:"}
                  name={"signature_text"}
                  value={signatureObj?.text}
                  wholevalue={signatureObj}
                  setValue={setSignatureObj}
                  disabled={
                    defaultData?.quote_status !== "Return To Payment" &&
                    (referralFlag?.state || brokerReviewFlag || btnLoading)
                  }
                  error={wholeError}
                  setError={setError}
                />

                {signatureObj?.text && (
                  <TextToImageConverter
                    text={signatureObj?.text}
                    font={"WindSong"}
                    value={wholevalue}
                    setValue={setValue}
                    error={wholeError}
                    setError={setError}
                    setText={setSignatureObj}
                    object={signatureObj}
                    disabled={
                      defaultData?.quote_status !== "Return To Payment" &&
                      (referralFlag?.state || brokerReviewFlag || btnLoading)
                    }
                    top={divRef}
                  />
                )}
                {error?.signature && (
                  <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                    {FIELD_ERROR_TEXT}
                  </FormHelperText>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="trailer_btns">
          <button
            className={`next_btn ${btnLoading ? "loading" : ""}`}
            disabled={btnLoading}
            onClick={async () => {
              if (
                defaultData?.quote_status !== "Return To Payment" &&
                defaultData?.quote_status !== "Payment Pending" &&
                (referralFlag?.state || brokerReviewFlag)
              ) {
                setAnimateX(100);
                nextPage(formNumber + 1);

                return;
              }

              setBtnLoading(() => true);
              setRedBox(() => false);

              const allValuesUndefined = Object.values(error).some(
                (value) => value === undefined
              );

              console.log(allValuesUndefined);
              let updatedArray = [...wholeError];

              if (!signatureObj?.text) {
                let updated_error = wholeError.map((m) => {
                  if (1) {
                    return {
                      ...m,
                      signature: true,
                    };
                  }

                  return m;
                });

                updatedArray = updated_error;

                setError(updated_error);
              } else {
                let updated_error = wholeError.map((m) => {
                  if (1) {
                    return {
                      ...m,
                      signature: false,
                    };
                  }

                  return m;
                });

                updatedArray = updated_error;

                setError(updated_error);
              }

              console.log(allValuesUndefined);
              if (allValuesUndefined) {
                updatedArray = wholeError.map((obj) => {
                  const updatedObj = { ...obj };

                  for (const key in updatedObj) {
                    if (updatedObj[key] === undefined) {
                      updatedObj[key] = true;
                    }
                  }

                  console.log(updatedObj);

                  return updatedObj;
                });

                setError(updatedArray);
              }

              console.log(updatedArray);

              const anyValueIsTrue = updatedArray.some((obj) => {
                for (const key in obj) {
                  if (obj[key] === true) {
                    return true;
                  }
                }
                return false;
              });

              console.log(anyValueIsTrue);

              if (anyValueIsTrue || !signatureObj?.text) {
                await new Promise((resolve) => setTimeout(resolve, 0));

                setBtnLoading(() => false);
                setRedBox(true);
                return;
              }

              let customer_body = {
                customer_id: globalData?.customer_id,
                customer_updates: {
                  mailing_address_line1: value?.mailing_address,
                  mailing_address_line2: value?.mailing_suite,
                  mailing_city: value?.mailing_city,
                  mailing_province: value?.mailing_province,
                  mailing_postalcode: value?.mailing_postal_code,
                  mailing_country: value?.mailing_country,
                },
              };

              await axios
                .post(`${APIURL}/customer/update_customer`, customer_body)
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                });

              let signature_body = new FormData();
              signature_body.append(
                "quote_record_id",
                globalData.quote_record_id
              );

              await fetch(value?.signature)
                .then((res) => res.blob())
                .then((blob) => {
                  const file = new File([blob], "signature", {
                    type: "image/png",
                  });
                  signature_body.append("signature", file);
                });

              await axios
                .post(
                  `${APIURL}/trailer/send_quote_signature`,
                  signature_body,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                });

              setAnimateX(100);
              nextPage(formNumber + 1);
              setBtnLoading(() => false);

              let trailer_quote_updates = {
                organization_id: ORGANIZATION_ID,
                inception_date: dayjs(value?.inception_date).format(
                  "DD/MM/YYYY"
                ),
                expiry_date: dayjs(value?.expiry_date).format("DD/MM/YYYY"),
                signature_accept_text: signatureObj?.text,
              };

              console.log(globalData);

              let body = {
                trailer_quote_record_id: globalData.quote_record_id,
                trailer_quote_updates,
              };

              await axios
                .post(`${APIURL}/trailer/update_trailer_quote`, body)
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          >
            Next {btnLoading && <div className="btn_loading"></div>}
          </button>
          {formNumber === 1 ? (
            ""
          ) : (
            <button
              className="back_btn"
              onClick={() => {
                setAnimateX(-100);

                backPage(formNumber - 1);
              }}
              disabled={btnLoading}
            >
              Back
            </button>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default PolicyDetails;
