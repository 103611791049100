import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "../../../api";
import Nav from "../../../components/AdminPanel/Nav";
import APTextField from "../../../components/AdminPanel/APTextField";

const ZeptoMail = () => {
  const [loading, setLoading] = useState(true);
  const [editState, setEditState] = useState(false);
  const [cancelState, setCancelState] = useState(false);

  const [data, setData] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    async function fetch() {
      await axios
        .post(`${APIURL}/organization/get_organization`, {
          organization_id: ORGANIZATION_ID,
          required_keys: [
            "zeptomail_sender_email",
            "zeptomail_sender_name",
            "zeptomail_api_key",
          ],
        })
        .then((res) => {
          if (!isCancelled) {
            console.log(res);
            setData(res?.data?.data?.organization);
            setLoading(() => false);
            setCancelState(() => false);
            setEditState(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetch();

    return () => {
      isCancelled = true;
    };
  }, [cancelState]);

  const handleEdit = async () => {
    setLoading(() => true);

    await new Promise((resolve) => setTimeout(resolve, 1500));

    setLoading(() => false);
    setEditState(true);
  };

  const handleCancel = async () => {
    setLoading(() => true);

    setCancelState(true);
  };

  const handleSave = async () => {
    let body = {
      organization_id: ORGANIZATION_ID,
      organization_updates: {
        zeptomail_sender_email: data?.zeptomail_sender_email,
        zeptomail_sender_name: data?.zeptomail_sender_name,
        zeptomail_api_key: data?.zeptomail_api_key,
      },
    };

    console.log(body);

    setLoading(() => true);

    await axios
      .post(`${APIURL}/organization/update_organization`, body)
      .then((res) => {
        console.log(res);

        setCancelState(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  return (
    <div>
      <div className="layout">
        <Nav
          header={"View Zepto Mail details"}
          layout={editState ? "save" : "edit"}
          onEditChange={handleEdit}
          onSaveChange={handleSave}
          onCancelChange={handleCancel}
        />
        <div className="layout_container">
          {loading ? (
            <div className="layout_loading">
              <div className="loader"></div>
            </div>
          ) : (
            <div className="layout_main">
              {" "}
              <APTextField
                handleChange={handleChange}
                disabled={!editState && true}
                name={"zeptomail_sender_email"}
                label="Sender Email"
                value={data?.zeptomail_sender_email}
              />
              <APTextField
                name={"zeptomail_sender_name"}
                label="Sender Name"
                value={data?.zeptomail_sender_name}
                handleChange={handleChange}
                disabled={!editState && true}
              />
              <APTextField
                name={"zeptomail_api_key"}
                label="Zeptomail Apikey"
                value={data?.zeptomail_api_key}
                type={editState ? "text" : "password"}
                handleChange={handleChange}
                disabled={!editState && true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ZeptoMail;
