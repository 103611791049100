import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIURL, ORGANIZATION_ID } from "../../../api";
import TableInput from "../../../components/AdminPanel/TableInput";
import { Modal, Table, message } from "antd";
import Nav from "../../../components/AdminPanel/Nav";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import APTextField from "../../../components/AdminPanel/APTextField";
import { HoverCell } from "../Boat/BoatManufacturer";

const TrailerManufacturer = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [editState, setEditState] = useState(false);
  const [cancelState, setCancelState] = useState(false);
  const [manufacturerName, setManufacturerName] = useState("");

  useEffect(() => {
    let isCancelled = false;

    async function fetch() {
      await axios
        .get(`${APIURL}/manufacturer/get_manufacturers?type=Trailer`)
        .then((res) => {
          if (!isCancelled) {
            console.log(res);

            let mapped_array = res?.data?.data.map((m, index) => {
              return { ...m, key: index + 1 };
            });

            setData(mapped_array);
            setLoading(false);
            setCancelState(() => false);
            setEditState(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetch();

    return () => {
      isCancelled = true;
    };
  }, [cancelState]);

  const column = [
    {
      title: "S.No",
      dataIndex: "key",
      key: "key",
      fixed: "left",
      width: 100,
    },
    {
      title: "Manufacturer Name",
      dataIndex: "manufacturer_name",
      key: "manufacturer_name",
      width: 200,
      render: (_, record) => (
        <HoverCell data={data} setData={setData} record={record} />
      ),
    },
  ];

  const handleEdit = async () => {
    // setLoading(() => true);

    // await new Promise((resolve) => setTimeout(resolve, 1500));

    // setLoading(() => false);
    setEditState(true);
  };

  const handleCancel = async () => {
    setLoading(() => true);

    setCancelState(true);
  };

  const handleSave = async () => {
    let boat_personal_effect_cov = Object.fromEntries(
      data.map((item) => [item.boat_personal_effect_cov, item.value])
    );

    let body = {
      organization_id: ORGANIZATION_ID,
      organization_updates: {
        boat_personal_effect_cov,
      },
    };

    console.log(body);

    setLoading(() => true);

    await axios
      .post(`${APIURL}/organization/update_organization`, body)
      .then((res) => {
        console.log(res);

        setCancelState(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCreateManufacturer = async () => {
    await axios
      .post(`${APIURL}/manufacturer/create_manufacturer`, {
        manufacturer_name: manufacturerName,
        type: "Trailer",
      })
      .then((res) => {
        console.log(res);
        setManufacturerName("");
        message.success("Manufacturer created");
        setEditState(false);
        setCancelState(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="layout">
      <Modal
        open={editState}
        onCancel={() => {
          setEditState(false);
        }}
        okText={"Create"}
        title="Create Trailer Manufacturer"
        onOk={handleCreateManufacturer}
      >
        <div>
          <APTextField
            size={"medium"}
            label={"Manufacturer Name"}
            value={manufacturerName}
            handleChange={(e) => {
              setManufacturerName(e.target.value);
            }}
          />
        </div>
      </Modal>
      <Nav
        header={
          editState
            ? "Create Trailer Manufacturer"
            : "View Trailer Manufacturers"
        }
        layout={editState ? "save" : "edit"}
        onEditChange={handleEdit}
        onSaveChange={handleSave}
        onCancelChange={handleCancel}
        editText={"Create"}
      />
      <div className="layout_container">
        {loading ? (
          <div className="layout_loading">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="layout_main">
            <Table
              size="small"
              columns={column}
              scroll={{ y: 630 }}
              dataSource={data}
              pagination={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TrailerManufacturer;
