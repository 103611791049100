import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "../../../api";
import Nav from "../../../components/AdminPanel/Nav";
import { Table } from "antd";
import TableInput from "../../../components/AdminPanel/TableInput";

export const BoatNavEquip = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [editState, setEditState] = useState(false);
  const [cancelState, setCancelState] = useState(false);

  useEffect(() => {
    let isCancelled = false;

    async function fetch() {
      await axios
        .post(`${APIURL}/organization/get_organization`, {
          organization_id: ORGANIZATION_ID,
          required_keys: ["boat_nav_equip"],
        })
        .then((res) => {
          if (!isCancelled) {
            console.log(res);
            let table_data = res?.data?.data?.organization?.boat_nav_equip;
            let mapped_data = Object.entries(table_data).map(([key, value]) => {
              return {
                ...value,
                boat_nav_equip: key,
                value,
                key,
              };
            });

            console.log(mapped_data);

            setData(mapped_data);
            setLoading(false);
            setCancelState(() => false);
            setEditState(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetch();

    return () => {
      isCancelled = true;
    };
  }, [cancelState]);

  const column = [
    {
      title: "Boat Navigational Equipment",
      dataIndex: "boat_nav_equip",
      key: "boat_nav_equip",
      fixed: "left",
      width: 200,
    },
    {
      title: "Rate",
      dataIndex: "value",
      key: "value",
      width: 200,
      render: (_, record) => (
        <TableInput
          border={editState}
          disabled={!editState}
          value={record?.value}
          handleChange={(e) => {
            let value = e.target.value;

            let array = [...data];

            let updated = array.map((m) => {
              if (m.boat_nav_equip === record.boat_nav_equip) {
                return {
                  ...m,
                  value: Number(value),
                };
              }

              return m;
            });

            setData(updated);
          }}
        />
      ),
    },
  ];

  const handleEdit = async () => {
    setLoading(() => true);

    await new Promise((resolve) => setTimeout(resolve, 1500));

    setLoading(() => false);
    setEditState(true);
  };

  const handleCancel = async () => {
    setLoading(() => true);

    setCancelState(true);
  };

  const handleSave = async () => {
    let boat_nav_equip = Object.fromEntries(
      data.map((item) => [item.boat_nav_equip, item.value])
    );

    let body = {
      organization_id: ORGANIZATION_ID,
      organization_updates: {
        boat_nav_equip,
      },
    };

    console.log(body);

    setLoading(() => true);

    await axios
      .post(`${APIURL}/organization/update_organization`, body)
      .then((res) => {
        console.log(res);

        setCancelState(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="layout">
      <Nav
        header={
          editState
            ? "Edit Boat Navigational Equipment Table"
            : "View Boat Navigational Equipment Table"
        }
        layout={editState ? "save" : "edit"}
        onEditChange={handleEdit}
        onSaveChange={handleSave}
        onCancelChange={handleCancel}
      />
      <div className="layout_container">
        {loading ? (
          <div className="layout_loading">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="layout_main">
            <Table
              columns={column}
              // scroll={{ x: "auto", y: "auto" }}
              dataSource={data}
              pagination={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};
