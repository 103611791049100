import { FormHelperText } from "@mui/material";
import React, { useState } from "react";
import {
  ERROR_HELPERTEXT_STYLES,
  PRIMARY_COLOR,
  WARNING_COLOR,
} from "../utils";
import { ConfigProvider, Select } from "antd";

const SelectComponent = ({
  label,
  options,
  value,
  setValue,
  helperText,
  next,
  error,
  setError,
  error_state,
  error_text,
  object,
  name,
  id,
  wholevalue,
  wholeError,
  allowClear,
  disabled,
  width,
  boatSpecsFormValues,
  setBoatSpecsFormValues,
  errorBoatSpecs,
  setErrorBoatSpecs,
  coverageFormValues,
  setCoverageFormValues,
}) => {
  const [open, setOpen] = useState(false);

  const handleChange = (val) => {
    let updated_error;

    console.log(val);

    if (name === "payment_mode") {
      setValue({ ...object, [name]: val });
      return;
    }

    if (name === "no_of_golf_carts") {
      let cart1, cart2, map;

      map = wholevalue.map((m, index) => {
        if (index === id - 1) {
          if (m.golf_cart_1) {
            cart1 = false;
          } else {
            cart1 = true;
          }

          if (m.golf_cart_2) {
            cart2 = false;
          } else {
            cart2 = true;
          }
        }
      });

      if (!val) {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
              golf_cart_1: undefined,
              golf_cart_2: undefined,
            };
          }

          return m;
        });

        setValue(updated);
      } else if (val === "1") {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: false,
              golf_cart_1: cart1,
              golf_cart_2: false,
            };
          }

          return m;
        });
        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: val, golf_cart_2: undefined };
          }

          return m;
        });

        setValue(updated);
      } else if (val === "2") {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: false,
              golf_cart_1: cart1,
              golf_cart_2: cart2,
            };
          }

          return m;
        });
        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: val };
          }

          return m;
        });

        setValue(updated);
      }

      return;
    }

    if (name === "hauler_selection") {
      if (val === "Toy Hauler") {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, hauler_selection: false, toy_hauler_dropdown: true };
          }

          return m;
        });

        setError(updated_error);
      } else if (!val) {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true, toy_hauler_dropdown: false };
          }

          return m;
        });

        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
            };
          }

          return m;
        });

        setValue(updated);
      } else {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false, toy_hauler_dropdown: false };
          }

          return m;
        });
        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
              toy_hauler_dropdown: {
                "Pedal Bike": false,
                ATV: false,
                Motorcycle: false,
                "Dirt Bike": false,
                "Cars/Trucks/SUV": false,
              },
            };
          }

          return m;
        });

        console.log(updated);

        setValue(updated);

        return;
      }

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: val };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "mod_options") {
      if (val === "Solar panels") {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              mod_options: false,
              solar_panels_installed: true,
              interior_upgrades: false,
              use_of_solar_on_trailer: false,
            };
          }

          return m;
        });
        setError(updated_error);
      } else if (!val) {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: true,
              solar_panels_installed: false,
              interior_upgrades: false,
              use_of_solar_on_trailer: false,
            };
          }

          return m;
        });
        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
            };
          }

          return m;
        });

        setValue(updated);
      } else {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: false,
              solar_panels_installed: false,
              interior_upgrades: true,
            };
          }

          return m;
        });
        setError(updated_error);
      }

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: val,
            solar_panels_installed: undefined,
            interior_upgrades: undefined,
            use_of_solar_on_trailer: undefined,
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "boat_lienholder") {
      if (!val) {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true, other_lienholder: false };
          }

          return m;
        });
        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
            };
          }

          return m;
        });

        setValue(updated);
      } else if (val === "Others") {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false, other_lienholder: true };
          }

          return m;
        });

        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: val };
          }

          return m;
        });

        setValue(updated);
      } else {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false, other_lienholder: false };
          }

          return m;
        });

        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
              other_lienholder: undefined,
            };
          }

          return m;
        });

        setValue(updated);
      }

      return;
    }

    if (name === "trailer_lienholder") {
      if (!val) {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true, other_trailer_lienholder: false };
          }

          return m;
        });
        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
            };
          }

          return m;
        });

        setValue(updated);
      } else if (val === "Others") {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false, other_trailer_lienholder: true };
          }

          return m;
        });

        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: val };
          }

          return m;
        });

        setValue(updated);
      } else {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false, other_trailer_lienholder: false };
          }

          return m;
        });

        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
              other_trailer_lienholder: undefined,
            };
          }

          return m;
        });

        setValue(updated);
      }

      return;
    }

    if (name === "hull_type") {
      if (!val) {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true, other_hull_type: false };
          }

          return m;
        });
        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
            };
          }

          return m;
        });

        setValue(updated);
      } else if (val === "Other") {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false, other_hull_type: true };
          }

          return m;
        });

        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: val };
          }

          return m;
        });

        setValue(updated);
      } else {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false, other_hull_type: false };
          }

          return m;
        });

        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: val, other_hull_type: undefined };
          }

          return m;
        });

        setValue(updated);
      }

      return;
    }

    if (name === "main1_engine_type") {
      if (!val) {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true, main1_other_engine_type: false };
          }

          return m;
        });
        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
            };
          }

          return m;
        });

        setValue(updated);
      } else if (val === "Other") {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false, main1_other_engine_type: true };
          }

          return m;
        });

        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: val };
          }

          return m;
        });

        setValue(updated);
      } else if (val === "Inboard" || val === "In/Outboard") {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: false,
              main1_other_engine_type: false,
              main1_boat_engine_serail: false,
            };
          }

          return m;
        });

        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
              main1_other_engine_type: undefined,
              main1_boat_engine_serail: m?.hull_id,
            };
          }

          return m;
        });

        setValue(updated);
      } else {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false, main1_other_engine_type: false };
          }

          return m;
        });

        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
              main1_other_engine_type: undefined,
            };
          }

          return m;
        });

        setValue(updated);
      }

      return;
    }

    if (name === "main2_engine_type") {
      if (!val) {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true, main2_other_engine_type: false };
          }

          return m;
        });
        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
            };
          }

          return m;
        });

        setValue(updated);
      } else if (val === "Other") {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false, main2_other_engine_type: true };
          }

          return m;
        });

        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: val };
          }

          return m;
        });

        setValue(updated);
      } else {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false, main2_other_engine_type: false };
          }

          return m;
        });

        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
              main2_other_engine_type: undefined,
            };
          }

          return m;
        });

        setValue(updated);
      }

      return;
    }

    if (name === "boat_model_year") {
      if (!val) {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
            };
          }

          return m;
        });

        setValue(updated);
      } else if (Number(val) >= new Date().getFullYear() - 15) {
        let replacement_val;
        let replace_val = wholevalue.map((m, index) => {
          if (index === id - 1) {
            if (m.replacement_cost) {
              replacement_val = false;
            } else {
              replacement_val = true;
            }
          }
        });

        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: false,
              replacement_cost: replacement_val,
            };
          }

          return m;
        });

        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
            };
          }

          return m;
        });

        setValue(updated);

        return;
      } else {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false, replacement_cost: false };
          }

          return m;
        });

        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
              replacement_cost: undefined,
            };
          }

          return m;
        });

        setValue(updated);

        return;
      }

      return;
    }

    if (name === "signature_font") {
      setValue({ ...wholevalue, font: val });

      return;
    }

    if (name === "mailing_country") {
      if (!val) {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);
      } else {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: false,
              mailing_address: undefined,
              mailing_suite: false,
              mailing_city: undefined,
              mailing_province: undefined,
              mailing_postal_code: undefined,
            };
          }

          return m;
        });

        setError(updated_error);
      }

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: val,
            mailing_address: undefined,
            mailing_suite: undefined,
            mailing_city: undefined,
            mailing_province: undefined,
            mailing_postal_code: undefined,
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "type_of_watercraft") {
      if (!val) {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);
      } else if (val === "Cabin Cruiser") {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false };
          }

          return m;
        });

        let coverage = coverageFormValues.map((m, index) => {
          if (index === id - 1) {
            return {
              deductible_ac: 1000,
              personal_effects_coverage_ac: 5000,
              nav_coverage_ac: 2500,
              deductible_rc: 1000,
              personal_effects_coverage_rc: 5000,
              nav_coverage_rc: 2500,
              coverage_name: undefined,
            };
          }

          return m;
        });

        setCoverageFormValues(coverage);

        let boat_specs = boatSpecsFormValues.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              main2_engine_type: undefined,
              main2_other_engine_type: undefined,
              main2_fuel_type: undefined,
              main2_boat_engine_model_year: undefined,
              main2_boat_engine_manufacturer: undefined,
              main2_boat_engine_serail: undefined,
              main2_boat_engine_horsepower: undefined,
            };
          }

          return m;
        });

        let boat_specs_error = errorBoatSpecs.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              main2_engine_type: undefined,
              main2_other_engine_type: undefined,
              main2_fuel_type: undefined,
              main2_boat_engine_model_year: undefined,
              main2_boat_engine_manufacturer: undefined,
              main2_boat_engine_serail: undefined,
              main2_boat_engine_horsepower: undefined,
            };
          }

          return m;
        });

        setBoatSpecsFormValues(boat_specs);
        setErrorBoatSpecs(boat_specs_error);
      } else if (val === "I'm not too sure") {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false };
          }

          return m;
        });

        setError(updated_error);

        let coverage = coverageFormValues.map((m, index) => {
          if (index === id - 1) {
            return {
              deductible_ac: undefined,
              personal_effects_coverage_ac: undefined,
              nav_coverage_ac: undefined,
              deductible_rc: undefined,
              personal_effects_coverage_rc: undefined,
              nav_coverage_rc: undefined,
              coverage_name: undefined,
            };
          }

          return m;
        });

        setCoverageFormValues(coverage);

        let boat_specs = boatSpecsFormValues.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              main2_engine_type: undefined,
              main2_other_engine_type: undefined,
              main2_fuel_type: undefined,
              main2_boat_engine_model_year: undefined,
              main2_boat_engine_manufacturer: undefined,
              main2_boat_engine_serail: undefined,
              main2_boat_engine_horsepower: undefined,
            };
          }

          return m;
        });

        let boat_specs_error = errorBoatSpecs.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              main2_engine_type: false,
              main2_other_engine_type: false,
              main2_fuel_type: false,
              main2_boat_engine_model_year: false,
              main2_boat_engine_manufacturer: false,
              main2_boat_engine_serail: false,
              main2_boat_engine_horsepower: false,
            };
          }

          return m;
        });

        setBoatSpecsFormValues(boat_specs);
        setErrorBoatSpecs(boat_specs_error);
      } else {
        updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false };
          }

          return m;
        });

        setError(updated_error);

        let coverage = coverageFormValues.map((m, index) => {
          if (index === id - 1) {
            return {
              deductible_ac: 1000,
              personal_effects_coverage_ac: 5000,
              nav_coverage_ac: 2500,
              deductible_rc: 1000,
              personal_effects_coverage_rc: 5000,
              nav_coverage_rc: 2500,
              coverage_name: undefined,
            };
          }

          return m;
        });

        setCoverageFormValues(coverage);

        let boat_specs = boatSpecsFormValues.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              main2_engine_type: undefined,
              main2_other_engine_type: undefined,
              main2_fuel_type: undefined,
              main2_boat_engine_model_year: undefined,
              main2_boat_engine_manufacturer: undefined,
              main2_boat_engine_serail: undefined,
              main2_boat_engine_horsepower: undefined,
            };
          }

          return m;
        });

        let boat_specs_error = errorBoatSpecs.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              main2_engine_type: false,
              main2_other_engine_type: false,
              main2_fuel_type: false,
              main2_boat_engine_model_year: false,
              main2_boat_engine_manufacturer: false,
              main2_boat_engine_serail: false,
              main2_boat_engine_horsepower: false,
            };
          }

          return m;
        });

        setBoatSpecsFormValues(boat_specs);
        setErrorBoatSpecs(boat_specs_error);
      }

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: val };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (!val) {
      updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: true };
        }

        return m;
      });
      setError(updated_error);
    } else {
      updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false };
        }

        return m;
      });

      setError(updated_error);
    }

    let updated = wholevalue.map((m, index) => {
      if (index === id - 1) {
        return { ...m, [name]: val };
      }

      return m;
    });

    setValue(updated);
  };

  return (
    <div
      className={`select ${error_state && "error_select"}`}
      style={{ alignItems: helperText ? "flex-start" : "center" }}
    >
      <label
        htmlFor={name}
        style={{
          marginTop: helperText && "0.75rem",
          width: width && "55%",
          marginBottom: error_state && "1.3rem",
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {label}
      </label>
      <div>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: PRIMARY_COLOR,
              colorPrimaryHover: PRIMARY_COLOR,
              colorErrorOutline: "none",
              colorError: WARNING_COLOR,
              colorErrorBorderHover: "none",
              controlOutline: "none",
              fontSize: 12,
            },
          }}
        >
          <Select
            id={name}
            onChange={handleChange}
            options={options}
            style={{ width: "100%", textAlign: "left" }}
            showSearch
            value={value}
            size="large"
            placeholder={"Select"}
            status={error_state && "error"}
            allowClear={allowClear ? false : true}
            disabled={disabled}
          >
            {/* {options.length &&
            options.map((m) => {
              return <option value={m.value}>{m.text}</option>;
            })} */}
          </Select>
        </ConfigProvider>
        {error_state && (
          <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
            {error_text}
          </FormHelperText>
        )}
        <FormHelperText
          sx={{
            fontStyle: "italic",
            fontSize: "0.75rem",
            color: "#a0aec0",
            margin: "8px 0",
            lineHeight: "1rem",
            letterSpacing: "0.7px",
            fontFamily: "Montserrat",
          }}
        >
          {helperText}
        </FormHelperText>
      </div>
    </div>
  );
};

export default SelectComponent;
