import { Flex, Result } from "antd";
import React, { useEffect } from "react";

const PaymentSuccessPage = () => {
  useEffect(() => {
    window.gtag("event", "conversion", {
      send_to: "AW-1006867181/G5WSCPX5oasDEO2ljuAD",

      transaction_id: window.sessionStorage.getItem("boat_record_id")
        ? window.sessionStorage.getItem("boat_record_id")
        : window.sessionStorage.getItem("trailer_record_id")
        ? window.sessionStorage.getItem("trailer_record_id")
        : "",
    });
  }, []);

  return (
    <Flex
      vertical
      gap={4}
      align="center"
      justify="center"
      style={{ minHeight: "100dvh", fontSize: "1.25rem" }}
    >
      <Result
        status="success"
        // icon={<CheckCircleTwoTone twoToneColor={PRIMARY_COLOR} />}
        title="Your payment has successfully been processed!"
        subTitle="You will receive a confirmation email and policy documents shortly"
      />
    </Flex>
  );
};

export default PaymentSuccessPage;
