import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "../../../api";
import Nav from "../../../components/AdminPanel/Nav";
import { Table } from "antd";
import DollarInput from "../../../components/AdminPanel/DollarInput";
import TableInput from "../../../components/AdminPanel/TableInput";

const GolfCart = () => {
  const [loading, setLoading] = useState(true);
  const [editState, setEditState] = useState(false);
  const [cancelState, setCancelState] = useState(false);
  const [data, setData] = useState(null);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    let isCancelled = false;

    async function fetch() {
      await axios
        .post(`${APIURL}/organization/get_organization`, {
          organization_id: ORGANIZATION_ID,
          required_keys: ["trailer_golf_cart_charges"],
        })
        .then((res) => {
          if (!isCancelled) {
            console.log(res);
            let table_data =
              res?.data?.data?.organization?.trailer_golf_cart_charges;
            let mapped_data = Object.entries(table_data).map(([key, value]) => {
              if (key !== "base_charge") {
                return {
                  ...value,
                  trailer_golf_cart_charges: key,
                  key,
                };
              } else if (key === "base_charge") {
                setData({ base_charge: value });
              }
            });

            let filtered_array = mapped_data.filter((m) => m);

            console.log(filtered_array);

            setRows(filtered_array);
            setLoading(false);
            setCancelState(() => false);
            setEditState(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetch();

    return () => {
      isCancelled = true;
    };
  }, [cancelState]);

  const column = [
    {
      title: "Coverage Type",
      dataIndex: "trailer_golf_cart_charges",
      key: "trailer_golf_cart_charges",
      width: 150,
      fixed: "left",
    },
    {
      title: "Charge",
      dataIndex: "charge",
      key: "charge",
      width: 150,
      render: (_, record) => (
        <TableInput
          border={editState}
          disabled={!editState}
          value={record?.charge}
          handleChange={(e) => {
            let value = e.target.value;

            let array = [...rows];

            let updated = array.map((m) => {
              if (
                m.trailer_golf_cart_charges === record.trailer_golf_cart_charges
              ) {
                return {
                  ...m,
                  charge: Number(value),
                };
              }

              return m;
            });

            setRows(updated);
          }}
        />
      ),
    },
    {
      title: "Limit",
      dataIndex: "limit",
      key: "limit",
      width: 150,
      render: (_, record) => (
        <TableInput
          border={editState}
          disabled={!editState}
          value={record?.limit}
          handleChange={(e) => {
            let value = e.target.value;

            let array = [...rows];

            let updated = array.map((m) => {
              if (
                m.trailer_golf_cart_charges === record.trailer_golf_cart_charges
              ) {
                return {
                  ...m,
                  limit: Number(value),
                };
              }

              return m;
            });

            setRows(updated);
          }}
        />
      ),
    },
    {
      title: "Per Amount",
      dataIndex: "per_amt",
      key: "per_amt",
      width: 150,
      render: (_, record) => (
        <TableInput
          border={editState}
          disabled={!editState}
          value={record?.per_amt}
          handleChange={(e) => {
            let value = e.target.value;

            let array = [...rows];

            let updated = array.map((m) => {
              if (
                m.trailer_golf_cart_charges === record.trailer_golf_cart_charges
              ) {
                return {
                  ...m,
                  per_amt: Number(value),
                };
              }

              return m;
            });

            setRows(updated);
          }}
        />
      ),
    },
  ];

  const handleEdit = async () => {
    setLoading(() => true);

    await new Promise((resolve) => setTimeout(resolve, 1500));

    setLoading(() => false);
    setEditState(true);
  };

  const handleCancel = async () => {
    setLoading(() => true);

    setCancelState(true);
  };

  const handleSave = async () => {
    let trailer_golf_cart_charges = Object.fromEntries(
      rows.map((item) => [
        item.trailer_golf_cart_charges,
        {
          limit: item.limit,
          charge: item.charge,
          per_amt: item.per_amt,
        },
      ])
    );

    trailer_golf_cart_charges.base_charge = data?.base_charge;

    let body = {
      organization_id: ORGANIZATION_ID,
      organization_updates: {
        trailer_golf_cart_charges,
      },
    };

    console.log(body);

    setLoading(() => true);

    await axios
      .post(`${APIURL}/organization/update_organization`, body)
      .then((res) => {
        console.log(res);

        setCancelState(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(data, rows);

  return (
    <div className="layout">
      <Nav
        header={
          editState
            ? "Edit Boat Deductible Table"
            : "View Boat Deductible Table"
        }
        layout={editState ? "save" : "edit"}
        onEditChange={handleEdit}
        onSaveChange={handleSave}
        onCancelChange={handleCancel}
      />
      <div className="layout_container">
        {loading ? (
          <div className="layout_loading">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="layout_main">
            <DollarInput
              label={"Base Charge"}
              value={data?.base_charge}
              handleChange={(e) => {
                setData({ base_charge: Number(e.target.value) });
              }}
              disabled={!editState && true}
              name={"trailer_detached_priv_stru_cov"}
            />
            <Table
              columns={column}
              // scroll={{ x: "auto", y: "auto" }}
              dataSource={rows}
              pagination={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GolfCart;
