import { ConfigProvider, Menu, Popconfirm, message } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PRIMARY_COLOR } from "../../utils";
import LogoutIcon from "@mui/icons-material/Logout";
import { QuestionCircleOutlined } from "@ant-design/icons";
import logo from "../../assets/RecLogo.png";

function getItem(label, key, children, type) {
  return {
    key,
    children,
    label,
    type,
  };
}

const items = [
  getItem("Policies", "policies", [
    getItem(
      <Link to="/admin/policies/boat" key={"boat1"}>
        Boat
      </Link>,
      "boat1Policies"
    ),
    getItem(
      <Link to="/admin/policies/trailer" key={"trailer2"}>
        Trailer
      </Link>,
      "trailer2Policies"
    ),
  ]),
  getItem("Quotes", "quotes", [
    getItem(
      <Link to="/admin/quotes/boat" key={"boat1"}>
        Boat
      </Link>,
      "boat1"
    ),
    getItem(
      <Link to="/admin/quotes/trailer" key={"trailer2"}>
        Trailer
      </Link>,
      "trailer2"
    ),
  ]),
  getItem("Customers", "customers", [
    getItem(
      <Link to="/admin/customers/lead" key={"lead"}>
        Lead
      </Link>,
      "lead"
    ),
    getItem(
      <Link to="/admin/customers/contact" key={"contact"}>
        Contact
      </Link>,
      "contact"
    ),
  ]),
  getItem("Boat", "boat", [
    getItem(
      <Link to="/admin/boat/rate_table" key={"1"}>
        Rate Table
      </Link>,
      "1"
    ),
    getItem(
      <Link to="/admin/boat/deductible" key={"2"}>
        Deductible
      </Link>,
      "2"
    ),
    getItem(
      <Link to={"/admin/boat/nav_equip"} key={"3"}>
        Navigational Equipment
      </Link>,
      "3"
    ),
    getItem(
      <Link to={"/admin/boat/personal_effects"} key={"4"}>
        Personal Effects Coverage
      </Link>,
      "4"
    ),
    getItem(
      <Link to={"/admin/boat/manufacturer"} key={"4"}>
        Manufacturer
      </Link>,
      "6"
    ),
    getItem(
      <Link to={"/admin/boat/general"} key={"5"}>
        General Settings
      </Link>,
      "5"
    ),
  ]),
  getItem("Trailer", "trailer", [
    getItem(
      <Link to={"/admin/trailer/liability"} key={"11"}>
        Liability Rate
      </Link>,
      "11"
    ),
    getItem(
      <Link to={"/admin/trailer/deductible"} key={"12"}>
        Deductible Rate
      </Link>,
      "12"
    ),
    getItem(
      <Link to={"/admin/trailer/golf_cart"} key={"12"}>
        Golf Cart Charges
      </Link>,
      "18"
    ),
    getItem(
      <Link to={"/admin/trailer/manufacturer"} key={"4"}>
        Manufacturer
      </Link>,
      "16"
    ),
    getItem(
      <Link to={"/admin/trailer/parks"} key={"4"}>
        Preferred Parks
      </Link>,
      "17"
    ),
    getItem(
      <Link to={"/admin/trailer/general"} key={"13"}>
        General Settings
      </Link>,
      "13"
    ),
  ]),
  getItem("Organization", "org", [
    getItem(
      <Link to="/admin/org/nuvei">Nuvei Payment</Link>,
      "/admin/org/nuvei"
    ),
    getItem(<Link to={"/admin/org/zeptomail"}>Zepto Mail</Link>, "10"),
    getItem(
      <Link to={"/admin/org/financiers"} key={"5"}>
        Financiers
      </Link>,
      "28"
    ),
    getItem(
      <Link to={"/admin/org/general"} key={"5"}>
        General Settings
      </Link>,
      "2985"
    ),
    getItem(
      <Link to={"/admin/org/premium_retained_table"} key={"215"}>
        Premium retained table
      </Link>,
      "25"
    ),
    getItem(
      <Link to={"/admin/org/tax"} key={"tax"}>
        Tax
      </Link>,
      "25315"
    ),
    getItem(
      <Link
        to={"/admin/org/supported_payment_modes"}
        key={"supported_payment_modes"}
      >
        Supported payment modes
      </Link>,
      "544925"
    ),
  ]),
];

// submenu keys of first level
const rootSubmenuKeys = [
  "policies",
  "quotes",
  "customers",
  "boat",
  "trailer",
  "org",
];

const Sidebar = ({ authState, setAuthState, userDetails }) => {
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      console.log(keys);
      setOpenKeys(keys);
    } else {
      console.log(keys);
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    let url = location.pathname;

    const split = url.split("/admin/");
    let key;
    if (split.length === 2) {
      key = split[1].split("/")[0];
    }

    setOpenKeys([key]);
  }, []);

  const signOutFn = async () => {
    await window.catalyst.auth.signOut("/#signin");
    await new Promise((resolve) => setTimeout(resolve, 3500));

    setAuthState(false);

    message.success("Successfully Logged out");
  };

  return (
    <div className="sidebar">
      <header>
        <img src={logo} alt="logo" />
      </header>
      <ConfigProvider
        theme={{
          token: {},
          components: {
            Menu: {
              subMenuItemBg: "rgb(142,209,252,0.2)",
              itemSelectedColor: PRIMARY_COLOR,
              itemPaddingInline: 0,
            },
          },
        }}
      >
        <Menu
          mode="inline"
          openKeys={openKeys}
          // selectedKeys={selectedKeys}
          onOpenChange={onOpenChange}
          style={{
            height: "85dvh",
            borderInlineEnd: `1px solid rgb(142,209,252,0.3)`,
            backgroundColor: "rgb(142,209,252,0.1)",
          }}
          items={items}
        />
      </ConfigProvider>
      <div className="sidebar_name">
        <p>{userDetails?.first_name}</p>

        <Popconfirm
          title="Logout ?"
          description="Are you sure to log out?"
          okText={
            <p style={{ fontSize: "0.75rem" }} onClick={signOutFn}>
              Logout
            </p>
          }
          cancelText={<p style={{ fontSize: "0.75rem" }}>Cancel</p>}
          icon={
            <QuestionCircleOutlined
              style={{
                color: "red",
              }}
            />
          }
          okButtonProps={{
            style: { background: PRIMARY_COLOR, borderColor: PRIMARY_COLOR },
          }}
          cancelButtonProps={{
            style: { borderColor: PRIMARY_COLOR, color: PRIMARY_COLOR },
          }}
        >
          <LogoutIcon style={{ fontSize: "18px", cursor: "pointer" }} />
        </Popconfirm>
      </div>
    </div>
  );
};

export default Sidebar;
