import React, { useEffect } from "react";
import { motion } from "framer-motion";
import TextFieldComponent from "../TextField";
import FeetInput from "../FeetInput";
import { COMPONENT_ERROR_TEXT, FIELD_ERROR_TEXT } from "../../utils";
import Autocomplete from "../AutoComplete";

const TrailerSpecs = ({
  animateX,
  value,
  setValue,
  error,
  setError,
  trailerFormValues,
  id,
  wholevalue,
  wholeError,
  redBoxObj,
  trailerNames,
  wholetrailerNames,
  manufacturerOptions,
  setManufacturerOptions,
  referralFlag,
  brokerReviewFlag,
  setBtnLoading,
  btnLoading,
}) => {
  console.log(value, error);

  useEffect(() => {
    console.log(id);
    if (!value?.trailer_manufacturer) {
      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, trailer_manufacturer: trailerNames?.name };
        }

        return m;
      });

      console.log(updated);

      setValue(updated);

      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, trailer_manufacturer: false };
        }

        return m;
      });

      setError(updated_error);
    }
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <motion.div
      initial={{ x: animateX, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div>
        <div className={`box ${redBoxObj?.state && "redbox"}`}>
          <div className="trailer_specs">
            <header>
              <div className="side_line"></div>
              <div>
                <h3 className="error_h3">
                  Trailer Specs
                  {redBoxObj?.state ? (
                    <p className="component_error_text">
                      {COMPONENT_ERROR_TEXT}
                    </p>
                  ) : (
                    ""
                  )}{" "}
                </h3>
                <h6 className="trailer_name">
                  {" "}
                  {wholetrailerNames.length > 1 && `(${trailerNames?.name})`}
                </h6>
              </div>
            </header>
            <div className="container">
              <Autocomplete
                label={`Trailer Manufacturer`}
                name={"trailer_manufacturer"}
                options={manufacturerOptions}
                value={value?.trailer_manufacturer}
                error_state={error?.trailer_manufacturer}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                setValue={setValue}
                object={value}
                disabled={referralFlag?.state || brokerReviewFlag || btnLoading}
              />
              <TextFieldComponent
                label={"Trailer Model"}
                value={value?.trailer_model}
                name={"trailer_model"}
                setValue={setValue}
                object={value}
                error_state={error?.trailer_model}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag || btnLoading}
              />
              <FeetInput
                label={"Trailer Length"}
                value={value?.trailer_length}
                name={"trailer_length"}
                setValue={setValue}
                object={value}
                error_state={error?.trailer_length}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                inputEnd={"ft."}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                isDecimal={true}
                disabled={referralFlag?.state || brokerReviewFlag || btnLoading}
              />
              {trailerFormValues?.type_of_trailer ===
                "Park Model/Destination Trailer" && (
                <FeetInput
                  label={"Trailer Width"}
                  value={value?.trailer_width}
                  name={"trailer_width"}
                  setValue={setValue}
                  object={value}
                  error_state={error?.trailer_width}
                  error_text={FIELD_ERROR_TEXT}
                  error={error}
                  setError={setError}
                  inputEnd={"ft."}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                  isDecimal={true}
                />
              )}
              {trailerFormValues?.type_of_trailer ===
                "Travel Trailer/Fifth Wheel Trailer" && (
                <TextFieldComponent
                  label={"VIN"}
                  value={value?.vin}
                  name={"vin"}
                  setValue={setValue}
                  object={value}
                  error_state={error?.vin}
                  error_text={FIELD_ERROR_TEXT}
                  error={error}
                  setError={setError}
                  id={id}
                  alphanumeric={true}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                  vin={true}
                />
              )}
              {trailerFormValues?.have_deck === "Yes" && (
                <FeetInput
                  label={"Deck Length"}
                  value={value?.deck_length}
                  name={"deck_length"}
                  setValue={setValue}
                  object={value}
                  error_state={error?.deck_length}
                  error_text={FIELD_ERROR_TEXT}
                  error={error}
                  setError={setError}
                  inputEnd={"ft."}
                  id={id}
                  isDecimal={true}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                />
              )}
              {trailerFormValues?.have_deck === "Yes" && (
                <FeetInput
                  label={"Deck Width"}
                  value={value?.deck_width}
                  name={"deck_width"}
                  setValue={setValue}
                  object={value}
                  error_state={error?.deck_width}
                  error_text={FIELD_ERROR_TEXT}
                  error={error}
                  setError={setError}
                  inputEnd={"ft."}
                  id={id}
                  isDecimal={true}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                />
              )}
              {trailerFormValues?.have_screened_in_room === "Yes" && (
                <FeetInput
                  label={"Screened in room or Florida room Length"}
                  value={value?.florida_room_length}
                  name={"florida_room_length"}
                  setValue={setValue}
                  object={value}
                  error_state={error?.florida_room_length}
                  error_text={FIELD_ERROR_TEXT}
                  error={error}
                  setError={setError}
                  inputEnd={"ft."}
                  id={id}
                  isDecimal={true}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                />
              )}
              {trailerFormValues?.have_screened_in_room === "Yes" && (
                <FeetInput
                  label={"Screened in room or Florida room Width"}
                  value={value?.florida_room_width}
                  name={"florida_room_width"}
                  setValue={setValue}
                  object={value}
                  error_state={error?.florida_room_width}
                  error_text={FIELD_ERROR_TEXT}
                  error={error}
                  setError={setError}
                  inputEnd={"ft."}
                  isDecimal={true}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                />
              )}
              {trailerFormValues?.have_hard_awning === "Yes" && (
                <FeetInput
                  label={"Hard Awning Length"}
                  value={value?.awning_length}
                  name={"awning_length"}
                  setValue={setValue}
                  object={value}
                  error_state={error?.awning_length}
                  error_text={FIELD_ERROR_TEXT}
                  error={error}
                  setError={setError}
                  isDecimal={true}
                  inputEnd={"ft."}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                />
              )}
              {trailerFormValues?.have_hard_awning === "Yes" && (
                <FeetInput
                  label={"Hard Awning Width"}
                  value={value?.awning_width}
                  name={"awning_width"}
                  setValue={setValue}
                  object={value}
                  error_state={error?.awning_width}
                  error_text={FIELD_ERROR_TEXT}
                  error={error}
                  setError={setError}
                  isDecimal={true}
                  inputEnd={"ft."}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    referralFlag?.state || brokerReviewFlag || btnLoading
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default TrailerSpecs;
