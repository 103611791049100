import { Card, Flex, message } from "antd";
import React, { useRef, useState } from "react";
import APTextField from "../components/AdminPanel/APTextField";
import axios from "axios";
import { APIURL } from "../api";
import html2canvas from "html2canvas";
import APSelect from "../components/AdminPanel/APSelect";

const BoatSignature = () => {
  const imageRef = useRef();

  const [data, setData] = useState({
    creator_record_id: "",
    signature: "",
    signature_text: "",
    search_loading: false,
    quote_record_id: "",
    submit_loading: false,
    type: "",
  });

  const handleSearchRecord = async () => {
    if (!data?.creator_record_id) {
      message.error("Please enter the creator record id");

      return;
    } else if (!data?.type) {
      message.error("Please select the type");

      return;
    }

    setData({
      ...data,
      search_loading: true,
    });

    await axios
      .get(
        `${APIURL}/boat/get_quote_by_creator_record_id?creator_record_id=${data?.creator_record_id}&type=${data?.type}`
      )
      .then((res) => {
        console.log(res);

        if (res.data?.success === false) {
          message.error(res.data?.message);

          setData({
            ...data,
            search_loading: false,
          });
          return;
        }

        setData({
          ...data,
          signature_text: res.data?.data?.signature_accept_text,
          search_loading: false,
          quote_record_id: res.data?.data?.quote_record_id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async () => {
    let signature;

    setData({
      ...data,
      submit_loading: true,
    });

    if (data?.signature_text) {
      let width = window.innerWidth > 767;

      console.log(width);

      await html2canvas(imageRef.current, {
        logging: false,
        useCORS: true,
        width: !width ? 300 : 450,
        height: 150,
      }).then(async (canvas) => {
        signature = canvas.toDataURL("image/png");
      });
    } else {
      message.error("No signature text");
      return;
    }

    console.log(signature);

    let signature_body = new FormData();
    signature_body.append("quote_record_id", data.quote_record_id);

    await fetch(signature)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "signature", {
          type: "image/png",
        });
        signature_body.append("signature", file);
      });

    let type = data.type.toLowerCase();

    await axios
      .post(`${APIURL}/${type}/send_quote_signature`, signature_body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);

        if (res.data?.success === true) {
          message.success("Signature updated successfully!!");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setData({
          ...data,
          creator_record_id: "",
          signature: "",
          signature_text: "",
          search_loading: false,
          quote_record_id: "",
          submit_loading: false,
          type: "",
        });
      });
  };

  console.log(data);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100dvh",
        minWidth: "100dvw",
        flexDirection: "column",
        gap: "1.5rem",
      }}
    >
      <h2 style={{ color: "#353535" }}>Update Signature </h2>
      <Card
        style={{ minHeight: "40dvh", minWidth: "550px", cursor: "default" }}
        hoverable
      >
        <APTextField
          label={"Creator record ID"}
          handleChange={(e) => {
            setData({
              ...data,
              signature: "",
              signature_text: "",
              search_loading: false,
              creator_record_id: e.target.value,
            });
          }}
          value={data?.creator_record_id}
          name={"creator_record_id"}
        />
        <div style={{ paddingBottom: "1rem" }}></div>
        <APSelect
          label={"Select the type"}
          options={[
            { label: "Boat", value: "Boat" },
            { label: "Trailer", value: "Trailer" },
          ]}
          name={"type"}
          value={data?.type}
          handleChange={(value) => {
            setData({
              ...data,
              type: value,
            });
          }}
        />
        {!data?.signature_text ? (
          <Flex align="center" justify="center" style={{ marginTop: "1rem" }}>
            <button
              style={{ padding: ".5rem", fontSize: ".75rem" }}
              className={`next_btn ${data?.search_loading ? "loading" : ""}`}
              onClick={handleSearchRecord}
            >
              Search{" "}
              {data?.search_loading && <div className="btn_loading"></div>}
            </button>{" "}
          </Flex>
        ) : (
          ""
        )}

        {data?.signature_text ? (
          <div style={{ marginTop: "1rem" }}>
            <APTextField
              value={data?.signature_text}
              disabled={true}
              label={"Signature Text"}
              name={"signature_text"}
            />

            <div
              className="text_container"
              style={{
                minHeight: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: `WindSong, sans-serif`,
              }}
              ref={imageRef}
            >
              <p
                style={{
                  textAlign: "center",
                  fontFamily: `WindSong, sans-serif`,
                }}
              >
                {data?.signature_text}
              </p>
            </div>

            <Flex align="center" justify="center" style={{ marginTop: "1rem" }}>
              <button
                style={{ padding: ".5rem", fontSize: ".75rem" }}
                className={`next_btn ${data?.submit_loading ? "loading" : ""}`}
                onClick={handleSubmit}
              >
                Upload{" "}
                {data?.submit_loading && <div className="btn_loading"></div>}
              </button>{" "}
            </Flex>
          </div>
        ) : (
          ""
        )}
      </Card>
    </div>
  );
};

export default BoatSignature;
