import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "../../../api";
import Nav from "../../../components/AdminPanel/Nav";
import DollarInput from "../../../components/AdminPanel/DollarInput";
import APTextField from "../../../components/AdminPanel/APTextField";
import { PercentageOutlined } from "@ant-design/icons";

const BoatGeneral = () => {
  const [loading, setLoading] = useState(true);
  const [editState, setEditState] = useState(false);
  const [cancelState, setCancelState] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    async function fetch() {
      await axios
        .post(`${APIURL}/organization/get_organization`, {
          organization_id: ORGANIZATION_ID,
          required_keys: [
            "boat_endorsement_prem",
            "boat_age_gt_15",
            "boat_liability_rate_2M",
            "boat_min_prem",
            "boat_min_retn_prem",
            "boat_operator_age_20_25",
            "boat_operator_gt_25_lt_3years_exp",
          ],
        })
        .then((res) => {
          if (!isCancelled) {
            console.log(res);
            let response = res?.data?.data?.organization;

            setData(response);
            setLoading(() => false);
            setCancelState(() => false);
            setEditState(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetch();

    return () => {
      isCancelled = true;
    };
  }, [cancelState]);

  const handleEdit = async () => {
    setLoading(() => true);

    await new Promise((resolve) => setTimeout(resolve, 1500));

    setLoading(() => false);
    setEditState(true);
  };

  const handleCancel = async () => {
    setLoading(() => true);

    setCancelState(true);
  };

  const handleSave = async () => {
    let body = {
      organization_id: ORGANIZATION_ID,
      organization_updates: {
        boat_age_gt_15: parseFloat(data?.boat_age_gt_15),
        boat_endorsement_prem: parseFloat(data?.boat_endorsement_prem),
        boat_liability_rate_2M: parseFloat(data?.boat_liability_rate_2M),
        boat_min_prem: parseFloat(data?.boat_min_prem),
        boat_min_retn_prem: parseFloat(data?.boat_min_retn_prem),
        boat_operator_age_20_25: parseFloat(data?.boat_operator_age_20_25),
        boat_operator_gt_25_lt_3years_exp: parseFloat(
          data?.boat_operator_gt_25_lt_3years_exp
        ),
      },
    };

    console.log(body);

    setLoading(() => true);

    await axios
      .post(`${APIURL}/organization/update_organization`, body)
      .then((res) => {
        console.log(res);

        setCancelState(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(name);

    setData({ ...data, [name]: value });
  };

  return (
    <div className="layout">
      <Nav
        header={editState ? "Edit Boat Settings" : "View Boat Settings"}
        layout={editState ? "save" : "edit"}
        onEditChange={handleEdit}
        onSaveChange={handleSave}
        onCancelChange={handleCancel}
      />
      <div className="layout_container">
        {loading ? (
          <div className="layout_loading">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="layout_main">
            <APTextField
              addonAfter={<PercentageOutlined />}
              handleChange={handleChange}
              disabled={!editState && true}
              name={"boat_age_gt_15"}
              label="Boat age greater 15"
              value={data?.boat_age_gt_15}
            />
            <DollarInput
              handleChange={handleChange}
              disabled={!editState && true}
              name={"boat_endorsement_prem"}
              label="Boat Endorsement Premium"
              value={data?.boat_endorsement_prem}
            />
            <DollarInput
              handleChange={handleChange}
              disabled={!editState && true}
              name={"boat_liability_rate_2M"}
              label="Boat Liability Rate 2M"
              value={data?.boat_liability_rate_2M}
            />
            <DollarInput
              handleChange={handleChange}
              disabled={!editState && true}
              name={"boat_min_prem"}
              label="Boat minimum Premium"
              value={data?.boat_min_prem}
            />
            <DollarInput
              handleChange={handleChange}
              disabled={!editState && true}
              name={"boat_min_retn_prem"}
              label="Boat Min.retn Premium"
              value={data?.boat_min_retn_prem}
            />
            <APTextField
              addonAfter={<PercentageOutlined />}
              handleChange={handleChange}
              disabled={!editState && true}
              name={"boat_operator_age_20_25"}
              label="Boat Operator Age 20 to 25"
              value={data?.boat_operator_age_20_25}
            />
            <APTextField
              addonAfter={<PercentageOutlined />}
              handleChange={handleChange}
              disabled={!editState && true}
              name={"boat_operator_gt_25_lt_3years_exp"}
              label="Boat Operator Greater than 25 & lesser than 3years exp"
              value={data?.boat_operator_gt_25_lt_3years_exp}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BoatGeneral;
