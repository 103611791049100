import React, { useState } from "react";
import "../styles/Template.css";
import Logo from "../assets/RecWordmark.png";
import Cib from "../assets/CIB_LOGO-SM.png";

import PhoneIcon from "@mui/icons-material/Phone";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MenuIcon from "@mui/icons-material/Menu";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Divider, Drawer } from "antd";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const Recprotect = ({ children, type }) => {
  return (
    <div>
      <Topbar />

      <div className="template_cont">
        <h2>Get a {type} Insurance Quote</h2>
        <h4>
          Determine Your {type} Insurance Costs with Our Click Quote and Bind
          Solution
        </h4>

        <>{children}</>
      </div>

      <Footer />
    </div>
  );
};

export default Recprotect;

const Topbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <header className="template_top">
      <Drawer
        open={open}
        key={"test1"}
        onClose={() => {
          setOpen(false);
        }}
        width={500}
      >
        <div className="template_drawer_cont">
          <MenuDropdown
            items={[
              {
                label: "Trailer Insurance",
                link: "https://recprotect.ca/coverage/trailer-insurance/",
              },
              {
                label: "Park Model",
                link: "https://recprotect.ca/coverage/park-model-insurance/",
              },
              {
                label: "Recreational Trailer",
                link: "https://recprotect.ca/coverage/recreational-trailer-insurance/",
              },
              {
                label: "Fifth Wheel Trailer",
                link: "https://recprotect.ca/coverage/fifth-wheel-trailer-insurance/",
              },
              {
                label: "Boat Insurance",
                link: "https://recprotect.ca/coverage/boat-insurance/",
              },
            ]}
            parent={{
              label: "COVERAGE",
              link: "https://recprotect.ca/coverage/",
            }}
          />

          <Divider />

          <div className="menu_dropdown_menus_li">
            <a
              href="https://recprotect.ca/claims/"
              style={{ paddingLeft: "0" }}
            >
              CLAIMS
            </a>
          </div>

          <Divider />

          <div className="menu_dropdown_menus_li">
            <a href="https://recprotect.ca/blog/" style={{ paddingLeft: "0" }}>
              BLOG
            </a>
          </div>
          <Divider />

          <MenuDropdown
            items={[
              {
                label: "FAQ",
                link: "https://recprotect.ca/about-us/faq/",
              },
              {
                label: "Contact Us",
                link: "https://recprotect.ca/about-us/contact-us/",
              },
            ]}
            parent={{ label: "ABOUT", link: "https://recprotect.ca/about-us/" }}
          />

          <Divider />

          <a href="tel:8888870881" className="top_phone">
            <PhoneIcon sx={{ fontSize: "1.15rem" }} />
            <span>888-887-0881</span>
          </a>

          <div className="drawer_quote_btn">
            <a href="https://recprotect.ca/get-a-quote/#/">GET A QUOTE</a>
          </div>
        </div>
      </Drawer>

      <div className="top_img">
        <a href="https://recprotect.ca">
          <img src={Logo} alt="" />
        </a>
      </div>
      <div className="top_menus">
        <ul>
          <Dropdown
            items={[
              {
                label: "Trailer Insurance",
                link: "https://recprotect.ca/coverage/trailer-insurance/",
              },
              {
                label: "Park Model",
                link: "https://recprotect.ca/coverage/park-model-insurance/",
              },
              {
                label: "Recreational Trailer",
                link: "https://recprotect.ca/coverage/recreational-trailer-insurance/",
              },
              {
                label: "Fifth Wheel Trailer",
                link: "https://recprotect.ca/coverage/fifth-wheel-trailer-insurance/",
              },
              {
                label: "Boat Insurance",
                link: "https://recprotect.ca/coverage/boat-insurance/",
              },
            ]}
            parent={{
              label: "COVERAGE",
              link: "https://recprotect.ca/coverage/",
            }}
          />
          <li className="top_li">
            <a href="https://recprotect.ca/claims/">CLAIMS</a>
          </li>
          <li className="top_li">
            <a href="https://recprotect.ca/blog/">BLOG</a>
          </li>
          <Dropdown
            items={[
              {
                label: "FAQ",
                link: "https://recprotect.ca/about-us/faq/",
              },
              {
                label: "Contact Us",
                link: "https://recprotect.ca/about-us/contact-us/",
              },
            ]}
            parent={{ label: "ABOUT", link: "https://recprotect.ca/about-us/" }}
          />
        </ul>
      </div>
      <a href="tel:8888870881" className="top_phone">
        <PhoneIcon sx={{ fontSize: "1.15rem" }} />
        <span>888-887-0881</span>
      </a>

      <div className="top_get_quote">
        <a href="https://recprotect.ca/get-a-quote/">GET A QUOTE</a>
      </div>

      <div className="topbar_menu">
        <MenuIcon
          sx={{ fontSize: "1.5rem", cursor: "pointer" }}
          onClick={() => {
            setOpen(true);
          }}
        />
      </div>

      <div className="top_mob_menus">
        <a href="tel:8888870881" className="top_mob_phone">
          <PhoneIcon sx={{ fontSize: "1.15rem" }} />
        </a>
        <a
          href="https://recprotect.ca/get-a-quote/#/"
          className="top_mob_phone"
        >
          <InsertDriveFileIcon sx={{ fontSize: "1.15rem" }} />
        </a>
        <div className="top_mob_phone">
          <MenuIcon
            sx={{ fontSize: "1.15rem" }}
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
      </div>
    </header>
  );
};

const Dropdown = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <li
      onMouseEnter={() => {
        setOpen(true);
      }}
      onMouseLeave={() => {
        setOpen(false);
      }}
      className="top_li"
    >
      <a
        href={props?.parent?.link}
        style={{ display: "flex", alignItems: "center" }}
      >
        {props?.parent?.label} <ArrowDropDownIcon />{" "}
      </a>
      <div className="dropdown_menus">
        {open
          ? props.items?.map((m) => {
              return (
                <li key={m?.link} className="top_li dropdown_menus_li">
                  <a href={m?.link}>{m.label}</a>
                </li>
              );
            })
          : ""}
      </div>
    </li>
  );
};

const MenuDropdown = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="menu_top_li">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          paddingBottom: open && "1rem",
        }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {props?.parent?.label}{" "}
        {!open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{" "}
      </div>
      <div className="menu_dropdown_menus">
        {open
          ? props.items?.map((m) => {
              return (
                <li key={m?.link} className="menu_dropdown_menus_li">
                  <a href={m?.link}>{m.label}</a>
                </li>
              );
            })
          : ""}
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="template_footer">
      <div className="template_footer_cont">
        <div className="footer_component">
          <h6>COVERAGE</h6>
          <div>
            <a href="https://recprotect.ca/coverage/">Overview</a>
            <a href="https://recprotect.ca/coverage/trailer-insurance/">
              Trailer Insurance
            </a>
            <a href="https://recprotect.ca/coverage/park-model-insurance/">
              Park Model
            </a>
            <a href="https://recprotect.ca/coverage/recreational-trailer-insurance/">
              Recreational Trailer
            </a>
            <a href="https://recprotect.ca/coverage/fifth-wheel-trailer-insurance/">
              Fifth Wheel Trailer
            </a>
            <a href="https://recprotect.ca/coverage/boat-insurance/">
              Boat Insurance
            </a>
          </div>
        </div>

        <div className="footer_component">
          <h6>COMPANY</h6>

          <div>
            <a href="https://recprotect.ca/claims/">Claims</a>
            <a href="https://recprotect.ca/blog/">Trailer Insurance Blog</a>
            <a href="https://recprotect.ca/self-serve-portal/">
              Self Serve Portal
            </a>
            <a href="https://recprotect.ca/refer-a-friend/">Refer a Friend</a>
            <a href="https://recprotect.ca/privacy-policy/">Privacy Policy</a>
          </div>
        </div>

        <div className="footer_component">
          <h6>ABOUT</h6>

          <div>
            <a href="https://recprotect.ca/about-us/">About RecProtect</a>
            <a href="https://recprotect.ca/about-us/faq/">FAQ</a>
            <a href="https://recprotect.ca/about-us/contact-us/">Contact Us</a>

            <div className="footer_component_icons">
              <a href="https://www.facebook.com/RecProtect/">
                <FacebookRoundedIcon
                  sx={{ fontSize: "1.15rem", cursor: "pointer" }}
                />
              </a>
              <a href="https://www.instagram.com/recprotect/">
                <InstagramIcon
                  sx={{ fontSize: "1.15rem", cursor: "pointer" }}
                />
              </a>
              <a href="https://twitter.com/RecProtect/">
                <TwitterIcon sx={{ fontSize: "1.15rem", cursor: "pointer" }} />
              </a>
            </div>
          </div>
        </div>

        <div className="footer_component">
          <h6>CONTACT</h6>

          <div>
            <a href="mailto:service@recprotect.ca">service@recprotect.ca</a>
            <a href="mailto:sales@recprotect.ca">sales@recprotect.ca</a>
            <a href="tel:8888870881">888-887-0881</a>
            <p>Hours: Monday to Friday 9am-5pm</p>
            <p>114 Main Street South, Mount Forest, Ontario, NOG 2LO</p>
          </div>
        </div>
      </div>

      <div className="footer_divider">
        <div></div>
      </div>

      <div className="footer_copy">
        <p>© 2024 - RecProtect | All rights reserved.</p>

        <div>
          <img src={Cib} alt="" />
        </div>
      </div>
    </footer>
  );
};
