import React, { useRef } from "react";
import html2canvas from "html2canvas";
import { message } from "antd";

const TextToImageConverter = ({
  text,
  font,
  value,
  setValue,
  error,
  setError,
  setText,
  object,
  disabled,
  top,
}) => {
  const imageRef = useRef();

  const convertToImage = () => {
    if (disabled) {
      return;
    }
    let width = window.innerWidth > 767;

    console.log(width);

    html2canvas(imageRef.current, {
      logging: false,
      useCORS: true,
      width: !width ? 300 : 450,
      height: 150,
    }).then(async (canvas) => {
      const imgData = canvas.toDataURL("image/png");

      console.log(imgData);

      let updated = value.map((m, index) => {
        if (1) {
          return {
            ...m,
            signature: imgData,
          };
        }

        return m;
      });

      setValue(updated);

      let updated_error = error.map((m) => {
        if (1) {
          return {
            ...m,
            signature: false,
          };
        }

        return m;
      });

      setError(updated_error);

      // await new Promise((resolve) => setTimeout(resolve, 1500));

      message.success({
        content: "Signature Accepted",
        style: {
          marginTop: top.current.getBoundingClientRect().top,
        },
        duration: 1.5,
        key: 11,
      });
    });
  };

  const handleClear = () => {
    if (disabled) {
      return;
    }
    setText({ ...object, text: "" });

    let updated_error = error.map((m) => {
      if (1) {
        return {
          ...m,
          signature: undefined,
        };
      }

      return m;
    });

    setError(updated_error);
  };

  console.log(error);

  return (
    <div>
      <div
        className="text_container"
        style={{
          minHeight: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: `WindSong, sans-serif`,
        }}
        ref={imageRef}
      >
        <p
          style={{
            textAlign: "center",
            fontFamily: `WindSong, sans-serif`,
          }}
        >
          {text}
        </p>
      </div>
      <div className="sign_btn">
        <button className="clear_btn" onClick={handleClear}>
          Clear
        </button>
        <button
          className={"accept_btn"}
          style={{ opacity: error[0]?.signature === false && 0.3 }}
          onClick={convertToImage}
          disabled={error[0]?.signature === false}
        >
          Accept
        </button>
      </div>
    </div>
  );
};

export default TextToImageConverter;
