import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import APTextField from "./AdminPanel/APTextField";
import { Button, ConfigProvider, message } from "antd";
import { PRIMARY_COLOR, getTimestamp } from "../utils";
import dayjs from "dayjs";
import axios from "axios";
import { APIURL } from "../api";

const EditCustomer = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [valid, setValid] = useState(false);
  const [customer, setCustomer] = useState({
    first_name: "",
    last_name: "",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // console.log(
    //   dayjs(getTimestamp(searchParams.get("timestamp"))).add(15, "minutes")
    // );

    // console.log(searchParams.get("timestamp"));

    // console.log(
    //   dayjs(getTimestamp("20240103133820440")).add(15, "minutes") > dayjs()
    // );

    if (
      dayjs(getTimestamp(searchParams.get("timestamp"))).add(15, "minutes") <
      dayjs()
    ) {
      message.error("URL expired.");
    }

    setValid(
      dayjs(getTimestamp(searchParams.get("timestamp"))).add(15, "minutes") <
        dayjs()
    );
  }, []);

  const handleSave = async (e) => {
    e.preventDefault();
    if (customer.first_name === "" || customer.last_name === "") {
      message.error("Please fill the details");
      return;
    }
    const searchParams = new URLSearchParams(location.search);
    setLoading(() => true);

    await axios
      .post(`${APIURL}/customer/update_customer`, {
        customer_id: searchParams.get("customer_id"),
        customer_updates: customer,
      })
      .then((res) => {
        console.log(res);
        message.success("Updated successfully");
        setLoading(() => false);
      })
      .catch((err) => {
        console.log(err);
        message.error("Error while saving");
        setLoading(() => false);
      });
  };

  return (
    <div>
      <div
        className="signin_container"
        style={{ minHeight: "100dvh", flexDirection: "column", gap: "2rem" }}
      >
        <header>
          <h3>Modify Account Information</h3>
        </header>
        <form
          style={{ gap: "2rem", maxWidth: "400px", width: "100%" }}
          onSubmit={handleSave}
        >
          <APTextField
            label={"First Name"}
            size={"medium"}
            name={"first_name"}
            disabled={valid}
            handleChange={(e) => {
              setCustomer({
                ...customer,
                first_name: e.target.value,
              });
            }}
          />
          <APTextField
            label={"Last Name"}
            size={"medium"}
            name={"last_name"}
            disabled={valid}
            handleChange={(e) => {
              setCustomer({
                ...customer,
                last_name: e.target.value,
              });
            }}
          />

          <div className="signin_btn">
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: PRIMARY_COLOR,
                  // colorPrimaryHover: WARNING_COLOR,
                },
              }}
            >
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </ConfigProvider>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCustomer;
