import { Result } from "antd";
import React from "react";

const ServerError = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100dvh",
      }}
    >
      <Result
        status="500"
        title="Internal server error"
        subTitle="Sorry, something went wrong."
        // extra={<Button type="primary">Back Home</Button>}
      />
    </div>
  );
};

export default ServerError;
