import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const LoginPage = ({
  authState,
  setAuthState,
  userDetails,
  setUserDetails,
}) => {
  useEffect(() => {
    const config = {
      css_url: "/app/embeddediframe.css",
      is_customize_forgot_password: false,
      forgot_password_id: "login",
      forgot_password_css_url: "/app/fpwd.css",
    };

    window.catalyst.auth.signIn("login", config);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        minHeight: "100vh",
        width: "100dvw",
      }}
    >
      <div id="login"></div>
      <p className="homepage">
        <b>
          Don't have an account?{" "}
          <Link
            to="/signup"
            style={{ color: "blue", textDecorationLine: "underline" }}
          >
            Sign-up
          </Link>{" "}
          now!
        </b>
      </p>
    </div>
  );
};
export default LoginPage;
