import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "../../../api";
import Nav from "../../../components/AdminPanel/Nav";
import APTextField from "../../../components/AdminPanel/APTextField";

const Nuvei = () => {
  const [loading, setLoading] = useState(true);
  const [editState, setEditState] = useState(false);
  const [cancelState, setCancelState] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    async function fetch() {
      await axios
        .post(`${APIURL}/organization/get_organization`, {
          organization_id: ORGANIZATION_ID,
          required_keys: [
            "nuvei_payment_base_url",
            "nuvei_merchant_id",
            "nuvei_merchant_site_id",
            "nuvei_merchant_secret",
            "nuvei_paymentpage_success_url",
            "nuvei_paymentpage_base_url",
            "nuvei_payment_session_base_url",
            "payment_expired_base_url",
            "payment_url_encryption_key",
            "payment_url_expiry",
          ],
        })
        .then((res) => {
          if (!isCancelled) {
            console.log(res);
            setData(res?.data?.data?.organization);
            setLoading(() => false);
            setCancelState(() => false);
            setEditState(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetch();

    return () => {
      isCancelled = true;
    };
  }, [cancelState]);

  const handleEdit = async () => {
    setLoading(() => true);

    await new Promise((resolve) => setTimeout(resolve, 1500));

    setLoading(() => false);
    setEditState(true);
  };

  const handleCancel = async () => {
    setLoading(() => true);

    setCancelState(true);
  };

  const handleSave = async () => {
    let body = {
      organization_id: ORGANIZATION_ID,
      organization_updates: {
        nuvei_payment_base_url: data?.nuvei_payment_base_url,
        nuvei_merchant_id: data?.nuvei_merchant_id,
        nuvei_merchant_site_id: data?.nuvei_merchant_site_id,
        nuvei_merchant_secret: data?.nuvei_merchant_secret,
        nuvei_paymentpage_success_url: data?.nuvei_paymentpage_success_url,
        nuvei_paymentpage_base_url: data?.nuvei_paymentpage_base_url,
        nuvei_payment_session_base_url: data?.nuvei_payment_session_base_url,
        payment_url_expiry: parseFloat(data?.payment_url_expiry),
        payment_expired_base_url: data?.payment_expired_base_url,
        payment_url_encryption_key: data?.payment_url_encryption_key,
      },
    };

    console.log(body);

    setLoading(() => true);

    await axios
      .post(`${APIURL}/organization/update_organization`, body)
      .then((res) => {
        console.log(res);

        setCancelState(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  return (
    <div className="layout">
      <Nav
        header={
          editState
            ? "Edit Nuvei Payment details"
            : "View Nuvei Payment details"
        }
        layout={editState ? "save" : "edit"}
        onEditChange={handleEdit}
        onSaveChange={handleSave}
        onCancelChange={handleCancel}
      />
      <div className="layout_container">
        {loading ? (
          <div className="layout_loading">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="layout_main">
            <APTextField
              disabled={!editState && true}
              name={"nuvei_payment_base_url"}
              label="Nuvei Base URL"
              value={data?.nuvei_payment_base_url}
              handleChange={handleChange}
            />
            <APTextField
              name={"nuvei_merchant_id"}
              label="Nuvei Merchant ID"
              value={data?.nuvei_merchant_id}
              type={editState ? "text" : "password"}
              handleChange={handleChange}
              disabled={!editState && true}
            />
            <APTextField
              disabled={!editState && true}
              name={"nuvei_merchant_site_id"}
              label="Nuvei Merchant Site ID"
              value={data?.nuvei_merchant_site_id}
              type={editState ? "text" : "password"}
              handleChange={handleChange}
            />
            <APTextField
              disabled={!editState && true}
              name={"nuvei_merchant_secret"}
              label="Nuvei secret"
              value={data?.nuvei_merchant_secret}
              type={editState ? "text" : "password"}
              handleChange={handleChange}
            />{" "}
            <APTextField
              disabled={!editState && true}
              name={"nuvei_paymentpage_success_url"}
              label="Nuvei payment success url"
              value={data?.nuvei_paymentpage_success_url}
              handleChange={handleChange}
            />{" "}
            <APTextField
              disabled={!editState && true}
              name={"nuvei_paymentpage_base_url"}
              label="Payment page base url"
              value={data?.nuvei_paymentpage_base_url}
              handleChange={handleChange}
            />{" "}
            <APTextField
              disabled={!editState && true}
              name={"nuvei_payment_session_base_url"}
              label="Payment Session base url"
              value={data?.nuvei_payment_session_base_url}
              handleChange={handleChange}
            />{" "}
            <APTextField
              disabled={!editState && true}
              name={"payment_expired_base_url"}
              label="Payment Expired base url"
              value={data?.payment_expired_base_url}
              handleChange={handleChange}
            />{" "}
            <APTextField
              disabled={!editState && true}
              name={"payment_url_encryption_key"}
              label="Payment url encryption key"
              value={data?.payment_url_encryption_key}
              handleChange={handleChange}
            />{" "}
            <APTextField
              disabled={!editState && true}
              name={"payment_url_expiry"}
              label="Payment url expiry"
              value={data?.payment_url_expiry}
              handleChange={handleChange}
            />{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default Nuvei;
