import { Button, ConfigProvider, Input, message } from "antd";
import React, { useState } from "react";
import "../../styles/AdminPanel/Login.css";
import { PRIMARY_COLOR, WARNING_COLOR } from "../../utils";
import logo from "../../assets/RecLogo.png";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "../../api";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [formData, setFormData] = useState({
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    password: undefined,
    organization_id: ORGANIZATION_ID,
  });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email) ||
      !formData.password ||
      !formData?.first_name ||
      !formData?.last_name
    ) {
      message.error("Please enter valid credentials");
      return;
    }

    setLoading(() => true);

    await axios
      .post(`${APIURL}/user/create_user`, formData)
      .then((res) => {
        console.log(res);

        if (!res.data?.success && res.data?.message === "Already a user!!!") {
          message.error("Already a user!!!");
        } else if (!res.data?.success) {
          message.error("Already a user!!!");
        } else if (res.data?.success) {
          setLoading(false);
          message.success("User created !!");

          navigate("/signin");
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="signin">
      <header>
        <img src={logo} alt="" loading="lazy" />
      </header>
      <div className="signin_container">
        <form onSubmit={handleSubmit}>
          <h3>Sign UP</h3>
          <div className="signin_input">
            <label htmlFor="first_name">Enter first name</label>
            <ConfigProvider
              theme={{
                components: {
                  Input: {
                    activeBorderColor: PRIMARY_COLOR,
                    hoverBorderColor: PRIMARY_COLOR,
                    activeShadow: "none",
                    errorActiveShadow: "none",
                  },
                },
                token: {
                  colorErrorOutline: "none",
                  colorError: WARNING_COLOR,
                  colorErrorBorderHover: "none",
                },
              }}
            >
              <Input
                id="first_name"
                style={{ marginTop: "0.2rem" }}
                value={formData?.first_name}
                onChange={(e) => handleChange(e)}
                name="first_name"
                status={formData?.first_name === "" && "error"}
              />
            </ConfigProvider>
          </div>
          <div className="signin_input">
            <label htmlFor="last_name">Enter last name</label>
            <ConfigProvider
              theme={{
                components: {
                  Input: {
                    activeBorderColor: PRIMARY_COLOR,
                    hoverBorderColor: PRIMARY_COLOR,
                    activeShadow: "none",
                    errorActiveShadow: "none",
                  },
                },
                token: {
                  colorErrorOutline: "none",
                  colorError: WARNING_COLOR,
                  colorErrorBorderHover: "none",
                },
              }}
            >
              <Input
                id="last_name"
                style={{ marginTop: "0.2rem" }}
                value={formData?.last_name}
                onChange={(e) => handleChange(e)}
                name="last_name"
                status={formData?.last_name === "" && "error"}
              />
            </ConfigProvider>
          </div>
          <div className="signin_input">
            <label htmlFor="email">Enter the email</label>
            <ConfigProvider
              theme={{
                components: {
                  Input: {
                    activeBorderColor: PRIMARY_COLOR,
                    hoverBorderColor: PRIMARY_COLOR,
                    activeShadow: "none",
                    errorActiveShadow: "none",
                  },
                },
                token: {
                  colorErrorOutline: "none",
                  colorError: WARNING_COLOR,
                  colorErrorBorderHover: "none",
                },
              }}
            >
              <Input
                id="email"
                style={{ marginTop: "0.2rem" }}
                value={formData?.email}
                onChange={(e) => handleChange(e)}
                name="email"
                type="email"
                status={
                  formData.email &&
                  !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
                    ? "error"
                    : ""
                }
              />
            </ConfigProvider>
          </div>
          <div className="signin_input">
            <label htmlFor="password">Enter the password</label>
            <ConfigProvider
              theme={{
                components: {
                  Input: {
                    activeBorderColor: PRIMARY_COLOR,
                    hoverBorderColor: PRIMARY_COLOR,
                    activeShadow: "none",
                    errorActiveShadow: "none",
                  },
                },
                token: {
                  colorErrorOutline: "none",
                  colorError: WARNING_COLOR,
                  colorErrorBorderHover: "none",
                },
              }}
            >
              <Input.Password
                id="password"
                style={{ marginTop: "0.2rem" }}
                value={formData?.password}
                onChange={(e) => handleChange(e)}
                name="password"
                status={formData?.password === "" && "error"}
              />
            </ConfigProvider>
          </div>

          <a
            style={{ textAlign: "end", fontSize: "0.875rem", color: "#000" }}
            href="#/signin"
          >
            Login?
          </a>

          <div className="signin_btn">
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: PRIMARY_COLOR,
                  // colorPrimaryHover: WARNING_COLOR,
                },
              }}
            >
              <Button type="primary" loading={loading} htmlType="submit">
                Submit
              </Button>
            </ConfigProvider>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
