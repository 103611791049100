import { ConfigProvider, Input } from "antd";
import React from "react";
import { PRIMARY_COLOR, WARNING_COLOR } from "../../utils";

const APTextField = ({
  name,
  label,
  type,
  disabled,
  value,
  handleChange,
  placeholder,
  size,
  addonAfter,
}) => {
  return (
    <div className={`textfield`}>
      <label htmlFor={name}>{label}</label>
      <div>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                activeBorderColor: PRIMARY_COLOR,
                hoverBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                errorActiveShadow: "none",
              },
            },
            token: {
              colorErrorOutline: "none",
              colorError: WARNING_COLOR,
              colorErrorBorderHover: "none",
            },
          }}
        >
          <Input
            type={type ? type : "text"}
            id={name}
            size={size ? size : "large"}
            className="input"
            readOnly={disabled}
            name={name}
            value={value}
            onChange={(e) => handleChange(e)}
            placeholder={placeholder}
            addonAfter={addonAfter}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default APTextField;
