import React from "react";
import Trailer from "../components/Trailer/Trailer";
import Coverages from "../components/Trailer/Coverages";
import AdditionalInfo from "../components/Trailer/AdditionalInfo";
import TrailerSpecs from "../components/Trailer/TrailerSpecs";

const TrailerMain = ({
  formNumber,
  setIndex,
  setFormNumber,
  animateX,
  setAnimateX,
  trailerFormValues,
  setTrailerFormValues,
  trailerSpecsFormValues,
  setTrailerSpecsFormValues,
  errorTrailerSpecs,
  setErrorTrailerSpecs,
  addTrailerCount,
  number_of_trailer,
  index,
  addTrailer,
  errorTrailer,
  trailerNumber,
  setErrorTrailer,
  coverageFormValues,
  setCoverageFormValues,
  additionalInfoFormValues,
  setAdditionalInfoFormValues,
  errorCoverage,
  setErrorCoverage,
  errorAdditionalInfo,
  setErrorAdditionalInfo,
  trailerRedBoxObj,
  getStartedFormValues,
  quotesRedBoxObj,
  addInfoRedBoxObj,
  trailerSpecsRedBoxObj,
  setTrailerRedBoxObj,
  setQuoteRedBoxObj,
  setAddInfoRedBoxObj,
  setTrailerSpecsRedBoxObj,
  trailerNames,
  manufacturerOptions,
  setManufacturerOptions,
  referralFlag,
  setReferralFlag,
  organization,
  brokerReviewFlag,
  setBtnLoading,
  btnLoading,
  defaultData,
  setDefaultData,
}) => {
  const which_redbox =
    formNumber === 1
      ? trailerRedBoxObj
      : formNumber === 2
      ? quotesRedBoxObj
      : formNumber === 3
      ? addInfoRedBoxObj
      : formNumber === 4 && trailerSpecsRedBoxObj;

  const which_set_redbox =
    formNumber === 1
      ? setTrailerRedBoxObj
      : formNumber === 2
      ? setQuoteRedBoxObj
      : formNumber === 3
      ? setAddInfoRedBoxObj
      : formNumber === 4 && setTrailerSpecsRedBoxObj;

  const error =
    formNumber === 1
      ? errorTrailer[index - 1]
      : formNumber === 2
      ? errorCoverage[index - 1]
      : formNumber === 3
      ? errorAdditionalInfo[index - 1]
      : formNumber === 4 && errorTrailerSpecs[index - 1];

  let which_whole_err =
    formNumber === 1
      ? errorTrailer
      : formNumber === 2
      ? errorCoverage
      : formNumber === 3
      ? errorAdditionalInfo
      : formNumber === 4 && errorTrailerSpecs;

  const setError =
    formNumber === 1
      ? setErrorTrailer
      : formNumber === 2
      ? setErrorCoverage
      : formNumber === 3
      ? setErrorAdditionalInfo
      : formNumber === 4 && setErrorTrailerSpecs;

  const handleNext = async () => {
    // if (referralFlag?.state || brokerReviewFlag) {
    //   console.log("yes");
    //   if (formNumber === 4 && index >= addTrailerCount.length) {
    //     //   setNumber(number + 1);
    //   } else if (formNumber === 4) {
    //     setFormNumber(1);
    //     setIndex(index + 1);
    //   } else {
    //     setFormNumber(formNumber + 1);
    //   }

    //   return;
    // }

    let map = which_redbox.map((m) => {
      return {
        ...m,
        state: false,
      };
    });

    which_set_redbox(map);

    const allValuesUndefined = Object.values(error).some(
      (value) => value === undefined
    );

    console.log(allValuesUndefined);
    if (allValuesUndefined) {
      const updatedFormData = { ...error };
      for (const key in updatedFormData) {
        if (updatedFormData.hasOwnProperty(key)) {
          if (updatedFormData[key] === undefined) updatedFormData[key] = true;
        }
      }

      let id = index;

      let err = which_whole_err.map((m, index) => {
        return index === id - 1
          ? (which_whole_err[id - 1] = updatedFormData)
          : m;
      });

      let red_box = which_redbox.map((m, index) => {
        if (index === id - 1) {
          return { ...m, state: true };
        } else {
          return m;
        }
      });

      await new Promise((resolve) => setTimeout(resolve, 0));

      which_set_redbox(red_box);

      console.log(err);

      setError(err);

      return;
    }

    console.log(error);
    const anyValueIsTrue = Object.values(error).some((value) => value === true);

    console.log(anyValueIsTrue);

    if (anyValueIsTrue) {
      let id = index;

      let red_box = which_redbox.map((m, index) => {
        if (index === id - 1) {
          return { ...m, state: true };
        } else {
          return m;
        }
      });

      console.log(red_box);

      await new Promise((resolve) => setTimeout(resolve, 0));

      which_set_redbox(red_box);
      //   setRedBox(true);

      return;
    }
    setAnimateX(50);

    let id = index;

    let red_box = which_redbox.map((m, index) => {
      if (index === id - 1) {
        return { ...m, state: false };
      } else {
        return m;
      }
    });

    which_set_redbox(red_box);

    // nextPage(formNumber + 1);

    if (formNumber === 4 && index >= addTrailerCount.length) {
      //   setNumber(number + 1);
    } else if (formNumber === 4) {
      setFormNumber(1);
      setIndex(index + 1);
    } else {
      setFormNumber(formNumber + 1);
    }

    return true;
  };

  const handleBack = () => {
    setAnimateX(-50);

    if (formNumber === 1 && index === 1) {
      return;
    } else if (formNumber === 1 && index !== 1) {
      setFormNumber(4);
      setIndex(index - 1);
    } else {
      setFormNumber(formNumber - 1);
    }
  };

  const progressClick = (page) => {
    return;

    if (formNumber < page) {
      for (let i = formNumber; i < page; i++) {
        const which_redbox =
          i === 1
            ? trailerRedBoxObj
            : i === 2
            ? quotesRedBoxObj
            : i === 3
            ? addInfoRedBoxObj
            : i === 4 && trailerSpecsRedBoxObj;

        const which_set_redbox =
          i === 1
            ? setTrailerRedBoxObj
            : i === 2
            ? setQuoteRedBoxObj
            : i === 3
            ? setAddInfoRedBoxObj
            : i === 4 && setTrailerSpecsRedBoxObj;

        const error =
          i === 1
            ? errorTrailer[index - 1]
            : i === 2
            ? errorCoverage[index - 1]
            : i === 3
            ? errorAdditionalInfo[index - 1]
            : i === 4 && errorTrailerSpecs[index - 1];

        let which_whole_err =
          i === 1
            ? errorTrailer
            : i === 2
            ? errorCoverage
            : i === 3
            ? errorAdditionalInfo
            : i === 4 && errorTrailerSpecs;

        const setError =
          i === 1
            ? setErrorTrailer
            : i === 2
            ? setErrorCoverage
            : i === 3
            ? setErrorAdditionalInfo
            : i === 4 && setErrorTrailerSpecs;

        const allValuesUndefined = Object.values(error).some(
          (value) => value === undefined
        );

        console.log(allValuesUndefined);
        if (allValuesUndefined) {
          const updatedFormData = { ...error };
          for (const key in updatedFormData) {
            if (updatedFormData.hasOwnProperty(key)) {
              if (updatedFormData[key] === undefined)
                updatedFormData[key] = true;
            }
          }

          let id = index;

          let err = which_whole_err.map((m, index) => {
            return index === id - 1
              ? (which_whole_err[id - 1] = updatedFormData)
              : m;
          });

          let red_box = which_redbox.map((m, index) => {
            if (index === id - 1) {
              return { ...m, state: true };
            } else {
              return m;
            }
          });

          console.log(red_box);

          which_set_redbox(red_box);

          console.log(err);

          setError(err);

          return;
        }

        console.log(error);
        const anyValueIsTrue = Object.values(error).some(
          (value) => value === true
        );

        console.log(anyValueIsTrue);

        if (anyValueIsTrue) {
          let id = index;

          let red_box = which_redbox.map((m, index) => {
            if (index === id - 1) {
              return { ...m, state: true };
            } else {
              return m;
            }
          });

          console.log(red_box);

          which_set_redbox(red_box);
          //   setRedBox(true);

          return;
        }
        setAnimateX(50);

        let id = index;

        let red_box = which_redbox.map((m, index) => {
          if (index === id - 1) {
            return { ...m, state: false };
          } else {
            return m;
          }
        });

        which_set_redbox(red_box);
      }
    }
    setFormNumber(page);
  };

  return (
    <div>
      <div
        style={{
          padding: "10px",
          width: "100%",
          border: "1px solid rgb(166, 166, 166)",
          borderRadius: "5px",
        }}
      >
        <div
          className="inner_progress_bar"
          style={{ justifyContent: "center" }}
        >
          <div className="bar" onClick={() => progressClick(1)}>
            <div
              className={formNumber >= 1 ? `bar_line` : "bar_line_unselects"}
            ></div>
            <div
              className={
                formNumber >= 1 && formNumber === 1
                  ? `bar_content_selects`
                  : formNumber >= 1
                  ? "bar_content"
                  : "bar_content_unselects"
              }
            >
              TRAILER DETAILS
            </div>
          </div>
          <div className="bar" onClick={() => progressClick(2)}>
            <div
              className={formNumber >= 2 ? `bar_line` : "bar_line_unselects"}
            ></div>
            <div
              className={
                formNumber >= 2 && formNumber === 2
                  ? `bar_content_selects`
                  : formNumber >= 2
                  ? "bar_content"
                  : "bar_content_unselects"
              }
            >
              QUOTES & COVERAGES
            </div>
          </div>
          <div className="bar" onClick={() => progressClick(3)}>
            <div
              className={formNumber >= 3 ? `bar_line` : "bar_line_unselects"}
            ></div>
            <div
              className={
                formNumber >= 3 && formNumber === 3
                  ? `bar_content_selects`
                  : formNumber >= 3
                  ? "bar_content"
                  : "bar_content_unselects"
              }
            >
              ADDITIONAL INFORMATION
            </div>
          </div>
          <div className="bar" onClick={() => progressClick(4)}>
            <div
              className={formNumber >= 4 ? `bar_line` : "bar_line_unselects"}
            ></div>
            <div
              className={
                formNumber >= 4 && formNumber === 4
                  ? `bar_content_selects`
                  : formNumber >= 4
                  ? "bar_content"
                  : "bar_content_unselects"
              }
            >
              TRAILER SPECS
            </div>
          </div>
        </div>
        <div
          className="progress_line"
          style={{
            width: `${(formNumber / 4) * 100 - 0.75}%`,
            marginTop: "20px",
          }}
        ></div>
        {formNumber === 1 ? (
          <Trailer
            formNumber={formNumber}
            animateX={animateX}
            setAnimateX={setAnimateX}
            value={trailerFormValues[index - 1]}
            setValue={setTrailerFormValues}
            error={errorTrailer[index - 1]}
            setError={setErrorTrailer}
            wholeError={errorTrailer}
            wholevalue={trailerFormValues}
            trailerSpecsFormValues={trailerSpecsFormValues[index - 1]}
            setTrailerSpecsFormValues={setTrailerSpecsFormValues}
            errorTrailerSpecs={errorTrailerSpecs[index - 1]}
            setErrorTrailerSpecs={setErrorTrailerSpecs}
            trailerNumber={trailerNumber}
            addTrailer={addTrailer}
            addTrailerCount={addTrailerCount}
            number_of_trailer={number_of_trailer}
            id={index}
            formCoverage={coverageFormValues}
            setFormCoverage={setCoverageFormValues}
            formAddInfo={additionalInfoFormValues}
            setFormAddInfo={setAdditionalInfoFormValues}
            formTrailerSpecs={trailerSpecsFormValues}
            setFormTrailerSpecs={setTrailerSpecsFormValues}
            errCoverage={errorCoverage}
            setErrCoverage={setErrorCoverage}
            errAddinfo={errorAdditionalInfo}
            setErrAddInfo={setErrorAdditionalInfo}
            errTrailerSpecs={errorTrailerSpecs}
            setErrTrailerSpecs={setErrorTrailerSpecs}
            redBoxObj={trailerRedBoxObj[index - 1]}
            getStarted={getStartedFormValues[0]}
            trailerNames={trailerNames[index - 1]}
            wholetrailerNames={trailerNames}
            referralFlag={referralFlag}
            organization={organization}
            brokerReviewFlag={brokerReviewFlag}
          />
        ) : formNumber === 2 ? (
          <Coverages
            formNumber={formNumber}
            animateX={animateX}
            setAnimateX={setAnimateX}
            value={coverageFormValues[index - 1]}
            setValue={setCoverageFormValues}
            error={errorCoverage[index - 1]}
            setError={setErrorCoverage}
            id={index}
            wholeError={errorCoverage}
            wholevalue={coverageFormValues}
            redBoxObj={quotesRedBoxObj[index - 1]}
            setRedBoxObj={setQuoteRedBoxObj}
            trailer={trailerFormValues[index - 1]}
            wholeRedBox={quotesRedBoxObj}
            trailerNames={trailerNames[index - 1]}
            index={index}
            which_whole_err={which_whole_err}
            which_redbox={which_redbox}
            which_set_redbox={which_set_redbox}
            setFormNumber={setFormNumber}
            addTrailerCount={addTrailerCount}
            setIndex={setIndex}
            wholetrailerNames={trailerNames}
            referralFlag={referralFlag}
            setReferralFlag={setReferralFlag}
            brokerReviewFlag={brokerReviewFlag}
            defaultData={defaultData}
            setDefaultData={setDefaultData}
          />
        ) : formNumber === 3 ? (
          <AdditionalInfo
            formNumber={formNumber}
            animateX={animateX}
            setAnimateX={setAnimateX}
            value={additionalInfoFormValues[index - 1]}
            setValue={setAdditionalInfoFormValues}
            error={errorAdditionalInfo[index - 1]}
            setError={setErrorAdditionalInfo}
            id={index}
            wholeError={errorAdditionalInfo}
            wholevalue={additionalInfoFormValues}
            redBoxObj={addInfoRedBoxObj[index - 1]}
            trailerNames={trailerNames[index - 1]}
            wholetrailerNames={trailerNames}
            referralFlag={referralFlag}
            setReferralFlag={setReferralFlag}
            organization={organization}
            trailer={trailerFormValues[index - 1]}
            brokerReviewFlag={brokerReviewFlag}
          />
        ) : formNumber === 4 ? (
          <TrailerSpecs
            formNumber={formNumber}
            animateX={animateX}
            setAnimateX={setAnimateX}
            value={trailerSpecsFormValues[index - 1]}
            setValue={setTrailerSpecsFormValues}
            error={errorTrailerSpecs[index - 1]}
            setError={setErrorTrailerSpecs}
            trailerFormValues={trailerFormValues[index - 1]}
            id={index}
            wholeError={errorTrailerSpecs}
            wholevalue={trailerSpecsFormValues}
            redBoxObj={trailerSpecsRedBoxObj[index - 1]}
            trailerNames={trailerNames[index - 1]}
            wholetrailerNames={trailerNames}
            manufacturerOptions={manufacturerOptions}
            setManufacturerOptions={setManufacturerOptions}
            referralFlag={referralFlag}
            organization={organization}
            brokerReviewFlag={brokerReviewFlag}
            setBtnLoading={setBtnLoading}
            btnLoading={btnLoading}
          />
        ) : (
          ""
        )}
        <div className="inner_btns">
          {!(formNumber === 1 && index === 1) && (
            <button
              className="inner_back_btn"
              onClick={handleBack}
              disabled={btnLoading}
            >
              Back
            </button>
          )}
          {formNumber === 2 && (referralFlag?.state || brokerReviewFlag) && (
            <button className="inner_next_btn" onClick={handleNext}>
              Next
            </button>
          )}
          {!(
            (formNumber === 4 && index === addTrailerCount.length) ||
            formNumber === 2
          ) && (
            <button className="inner_next_btn" onClick={handleNext}>
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrailerMain;
