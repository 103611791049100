import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APIURL } from "../../../api";
import { Button, Collapse, ConfigProvider, Modal } from "antd";
import { getValueForView } from "../../../utils";
import { IconButton } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import JsonFormatter from "react-json-formatter";

const ViewTrailer = () => {
  const { quote_record_id } = useParams();
  const [data, setData] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addCustomers, setAddCustomers] = useState(null);

  useEffect(() => {
    async function fetch() {
      await axios
        .get(
          `${APIURL}/trailer/get_trailer_quote?quote_record_id=${quote_record_id}`
        )
        .then(async (res) => {
          console.log(res);
          setLoading(false);

          setCustomer(res.data?.data?.customer);
          setAddCustomers(res?.data?.data?.additional_names);
          setData(res.data?.data?.quote);
        });
    }

    fetch();
  }, []);

  const handleDeleteQuote = async () => {
    await axios
      .get(`${APIURL}/trailer/delete_record?quote_record_id=${quote_record_id}`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="layout">
      {loading ? (
        <div className="layout_loading">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className="nav">
            <p>View Trailer - {data?.quote_id} </p>
            <Button
              type="primary"
              danger
              size="small"
              onClick={handleDeleteQuote}
            >
              Delete
            </Button>{" "}
          </div>

          <div className="layout_main">
            <div className="view_layout_cont">
              <h2>Quote Details</h2>
              <div className="view_layout_grid">
                <View label={"Quote record id"} value={quote_record_id} />
                <View label={"Quote ID"} value={data?.quote_id} />{" "}
                <View label={"Policy number"} value={data?.policy_number} />
                <View
                  label={"Policy status"}
                  value={data?.policy_status}
                />{" "}
                <View label={"Quote status"} value={data?.quote_status} />
                <View label={"Inception date"} value={data?.inception_date} />
                <View label={"Expiry date"} value={data?.expiry_date} />
                <View
                  label={"Agree to tnc"}
                  value={data?.agreed_to_tnc ? "Yes" : "No"}
                />
                <View label={"Created source"} value={data?.created_source} />
                <View label={"Carrier"} value={data?.carrier} />
                <View
                  label={"Located province"}
                  value={data?.located_province}
                />
                <View
                  label={"No of trailers"}
                  value={data?.number_of_trailers}
                />
                <View label={"Referral reason"} value={data?.referral_reason} />{" "}
                <View
                  label={"Sign accept text"}
                  value={data?.signature_accept_text}
                />{" "}
                <View
                  label={"Creator record id"}
                  value={data?.creator_record_id}
                />{" "}
                <View label={"Total premium"} value={data?.total_premium} />{" "}
                <View label={"Total tax"} value={data?.total_tax} />{" "}
                <View label={"Total admin fee"} value={data?.total_admin_fee} />{" "}
                <View
                  label={"Total premium after tax"}
                  value={data?.total_premium_after_tax}
                />{" "}
                <View
                  label={"Auto renewal status"}
                  value={data?.auto_renewal_status}
                />{" "}
                <View label={"Created source"} value={data?.created_source} />{" "}
                <View label={"Modified source"} value={data?.modified_source} />{" "}
                <View
                  label={"Quote access url"}
                  value={data?.quote_access_url}
                  copy={true}
                />{" "}
                <View label={"Carrier"} value={data?.carrier} />{" "}
                <View
                  label={"is_renewal"}
                  value={getValueForView(data?.is_renewal)}
                />{" "}
                <View
                  label={"Master record id"}
                  value={data?.master_record_id}
                />{" "}
                <View
                  label={"Is migrated record"}
                  value={getValueForView(data?.is_migrated_record)}
                />{" "}
                <View label={"Sales date"} value={data?.sales_date} />{" "}
              </div>
            </div>

            <div className="view_layout_cont">
              <h2>Trailer(s)</h2>

              {data?.trailers ? (
                <Collapse
                  accordion
                  items={
                    data.trailers.length
                      ? data?.trailers.map((m, index) => {
                          return {
                            key: m?.quote_record_id,
                            label: `Trailer - ${index + 1}`,
                            children: (
                              <QuoteItem data={data?.trailers[index]} />
                            ),
                          };
                        })
                      : []
                  }
                />
              ) : (
                <p className="view_label">No trailers</p>
              )}
            </div>

            <div className="view_layout_cont">
              <h2>Payment</h2>
              {data?.payments ? (
                <Collapse
                  accordion
                  items={
                    data?.payments.length
                      ? data?.payments.map((m, index) => {
                          return {
                            key: m?.quote_record_id,
                            label: `Payment - ${index + 1}`,
                            children: (
                              <PaymentItem data={data?.payments[index]} />
                            ),
                          };
                        })
                      : []
                  }
                />
              ) : (
                <p className="view_label">No payments</p>
              )}
            </div>

            <div className="view_layout_cont">
              <h2>Customer</h2>

              <Customer customer={customer} />
            </div>

            <div className="view_layout_cont">
              <h2>Additional Customer(s)</h2>

              {addCustomers && addCustomers.length ? (
                <Collapse
                  accordion
                  items={
                    addCustomers.length
                      ? addCustomers.map((m, index) => {
                          return {
                            key: m?.quote_record_id,
                            label: `Customer - ${index + 1}`,
                            children: (
                              <Customer customer={addCustomers[index]} />
                            ),
                          };
                        })
                      : []
                  }
                />
              ) : (
                <p className="view_label">No additional customers</p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ViewTrailer;

export const View = ({ label, value, copy }) => {
  return (
    <div className="view">
      <p className="view_label">{label} :</p>
      <p className={`view_value`}>{value}</p>{" "}
      {/* {copy ? (
        <Typography.Paragraph copyable={{ text: value }}></Typography.Paragraph>
      ) : (
        ""
      )} */}
    </div>
  );
};

const QuoteItem = ({ data }) => {
  return (
    <div className="view_layout_grid">
      {" "}
      <View label={"Creator record id"} value={data?.creator_record_id} />
      <View label={"Type"} value={data?.type} />
      <View label={"Year"} value={data?.year} />
      <View
        label={"Original owner"}
        value={getValueForView(data?.original_owner)}
      />
      <View
        label={"Overriding RCV"}
        value={getValueForView(data?.override_for_exceeding_RCV)}
      />
      <View label={"Replacement cost"} value={`$ ${data?.replacement_cost}`} />
      <View
        label={"Overriding ACV"}
        value={getValueForView(data?.override_for_exceeding_ACV)}
      />
      <View
        label={"Actual cost value"}
        value={`$ ${data?.actual_cost_value}`}
      />
      <View
        label={"Parked within 50ft of waterbody"}
        value={getValueForView(data?.parked_within_500feet_of_waterbody)}
      />
      <View
        label={"Permanently or seasonally parked?"}
        value={getValueForView(data?.permanently_or_seasonally_parked)}
      />
      <View
        label={"Stored location while not in use"}
        value={data?.stored_location_while_not_in_use}
      />
      <View
        label={"Taken to USA gt 90 days"}
        value={getValueForView(data?.taken_to_us_gt_90_days)}
      />
      <View
        label={"How long owned trailer"}
        value={data?.how_long_owned_trailer}
      />
      <View
        label={"Removed in off season?"}
        value={getValueForView(data?.removed_in_off_season)}
      />
      <View
        label={"location moved to in off season"}
        value={data?.location_moved_to_in_off_season}
      />
      <View label={"Have deck?"} value={getValueForView(data?.have_deck)} />
      <View
        label={"Have screened or florida room"}
        value={getValueForView(data?.have_screened_or_florida_room)}
      />
      <View
        label={"Have hard awning"}
        value={getValueForView(data?.have_hard_awning)}
      />
      <View
        label={"Insure golf cart?"}
        value={getValueForView(data?.like_to_insure_golf_cart)}
      />
      <View label={"No of golf cart"} value={data?.number_of_golf_cart} />
      <View
        label={"Golf cart value1"}
        value={data?.golf_cart_value1 ? `$ ${data?.golf_cart_value1}` : ""}
      />
      <View
        label={"Golf cart value2"}
        value={data?.golf_cart_value2 ? `$ ${data?.golf_cart_value2}` : ""}
      />
      <View label={"Park name"} value={data?.park_name_or_location} />
      <View label={"Park address"} value={data?.park_address} />
      <View label={"Park city"} value={data?.park_city} />
      <View label={"Park province"} value={data?.park_province} />
      <View label={"Park postalcode"} value={data?.park_postalcode} />
      <View label={"Park site number"} value={data?.park_site_number} />
      <View label={"Coverage name"} value={data?.coverage_name} />
      <View label={"Premium per year"} value={data?.premium_per_year} />
      <View label={"Coverage"} value={data?.coverage} />
      <View label={"Coverage type"} value={data?.coverage_type} />
      <View
        label={"Detached priv struc coverage"}
        value={data?.detached_private_structure_coverage}
      />{" "}
      <View
        label={"Override detached"}
        value={getValueForView(
          data?.override_default_detached_private_structure_coverage
        )}
      />
      <View
        label={"Personal prop coverage"}
        value={data?.personal_property_coverage}
      />{" "}
      <View
        label={"Override personal prop coverage"}
        value={getValueForView(
          data?.override_default_personal_property_coverage
        )}
      />
      <View label={"Permises liability"} value={data?.permises_liability} />
      <View label={"Deductible"} value={data?.deductible} />
      <View
        label={"Overland water protection"}
        value={getValueForView(data?.overland_water_protection)}
      />
      <View
        label={"Is principal residence?"}
        value={getValueForView(data?.is_principal_residence)}
      />
      <View
        label={"Have rental or business use?"}
        value={getValueForView(data?.have_rental_or_business_use)}
      />
      <View
        label={"Have heating tha was not factory installed?"}
        value={getValueForView(
          data?.have_heating_that_was_not_factory_installed
        )}
      />
      <View
        label={"Non factory heating type"}
        value={data?.non_factory_heating_type}
      />
      <View
        label={"Is dual purpose?"}
        value={getValueForView(data?.is_dual_purpose)}
      />
      <View label={"Hauler selection"} value={data?.hauler_selection} />
      <View
        label={"items_of_purpose"}
        value={
          data?.items_of_purpose.length ? data?.items_of_purpose.join(", ") : ""
        }
      />
      <View
        label={"Have modifications?"}
        value={getValueForView(data?.have_modifications)}
      />
      <View
        label={"Is solar panels factory or dealer installed?"}
        value={getValueForView(
          data?.is_solar_panels_factory_or_dealer_installed
        )}
      />
      <View
        label={"Not cover aftermarket solarpanels installation"}
        value={getValueForView(
          data?.not_cover_aftermarket_solarpanels_installation
        )}
      />
      <View
        label={"Interior upgrades comments"}
        value={data?.interior_upgrades_comments}
      />
      <View
        label={"Any pre-existing damage?"}
        value={getValueForView(data?.any_pre_existing_damage)}
      />
      <View label={"Describe damage"} value={data?.describe_damage} />
      <View
        label={"Is motorized?"}
        value={getValueForView(data?.is_motorized)}
      />
      <View label={"Manufacturer"} value={data?.manufacturer} />
      <View label={"Model"} value={data?.model} />
      <View label={"Trailer length"} value={data?.length_in_feet} />
      <View label={"Trailer width"} value={data?.width_in_feet} />
      <View label={"VIN"} value={data?.VIN} />
      <View label={"Deck length"} value={data?.deck_length_ft} />
      <View label={"Deck width"} value={data?.deck_width_ft} />
      <View
        label={"Screened or florida room length"}
        value={data?.screened_or_florida_room_length_ft}
      />
      <View
        label={"Screened or florida room width"}
        value={data?.screened_or_florida_room_width_ft}
      />
      <View label={"Hard awning length"} value={data?.hard_awning_length} />
      <View label={"Hard awning width"} value={data?.hard_awning_width} />
      <View label={"Is financed?"} value={getValueForView(data?.is_financed)} />
      <View label={"Financier name"} value={data?.financier_name} />
      <View
        label={"Financier address line1"}
        value={data?.financier_address_line1}
      />
      <View
        label={"Financier address line2"}
        value={data?.financier_address_line2}
      />
      <View label={"Financier city"} value={data?.financier_city} />
      <View label={"Financier province"} value={data?.financier_province} />
      <View label={"Financier postalcode"} value={data?.financier_postalcode} />
      <View label={"Financier country"} value={data?.financier_country} />{" "}
      <View
        label={"Opted for snowbird endorsment"}
        value={getValueForView(data?.opted_for_snowbird_endorsment)}
      />
    </div>
  );
};

export const PaymentItem = ({ data }) => {
  return (
    <div className="view_layout_grid">
      <View
        label={"Amount payable"}
        value={data?.amount_payable ? `$${data?.amount_payable}` : ""}
      />{" "}
      <View label={"Payment for"} value={data?.payment_for} />
      <View label={"Payment id"} value={data?.payment_id} />
      <View label={"Initiated date"} value={data?.payment_initiated_date} />
      <View label={"Payment method"} value={data?.payment_method} />
      <View label={"Status"} value={data?.payment_status} />
      <View label={"Type"} value={data?.payment_type} />
      <View label={"Url"} value={data?.payment_url} />
      <View label={"Creator record id"} value={data?.creator_record_id} />
      <View label={"Stripe customer id"} value={data?.stripe_customer_id} />
      <View label={"Stripe intent id"} value={data?.stripe_payment_intent_id} />
      <View
        label={"Stripe payment intend data"}
        value={
          data?.stripe_payment_intent_data ? (
            <ModalView
              object={data?.stripe_payment_intent_data}
              title={"Stripe payment intend data"}
            />
          ) : (
            ""
          )
        }
      />{" "}
      <View
        label={"Stripe payment_link_details"}
        value={
          data?.stripe_payment_link_details ? (
            <ModalView
              object={data?.stripe_payment_link_details}
              title={"Stripe payment_link_details"}
            />
          ) : (
            ""
          )
        }
      />
      <View
        label={"Stripe payload received details"}
        value={
          data?.stripe_payload_received_details ? (
            <ModalView
              object={data?.stripe_payload_received_details}
              title={"Stripe payload received details"}
            />
          ) : (
            ""
          )
        }
      />{" "}
      <View
        label={"Stripe payment method data"}
        value={
          data?.stripe_payment_method_data ? (
            <ModalView
              object={data?.stripe_payment_method_data}
              title={"Stripe payment method data"}
            />
          ) : (
            ""
          )
        }
      />{" "}
      <View
        label={"Stripe payment method details"}
        value={
          data?.stripe_payment_method_details ? (
            <ModalView
              object={data?.stripe_payment_method_details}
              title={"Stripe payment method details"}
            />
          ) : (
            ""
          )
        }
      />{" "}
      <View
        label={"Stripe price details"}
        value={
          data?.stripe_price_details ? (
            <ModalView
              object={data?.stripe_price_details}
              title={"Stripe price details"}
            />
          ) : (
            ""
          )
        }
      />{" "}
      <View
        label={"Stripe product details"}
        value={
          data?.stripe_product_details ? (
            <ModalView
              object={data?.stripe_product_details}
              title={"Stripe product details"}
            />
          ) : (
            ""
          )
        }
      />
    </div>
  );
};

export const Customer = ({ customer }) => {
  return (
    <div className="view_layout_grid">
      <View label={"Customer id"} value={customer?.customer_id} />{" "}
      <View label={"Email"} value={customer?.email} />{" "}
      <View label={"First name"} value={customer?.first_name} />{" "}
      <View label={"Last name"} value={customer?.last_name} />{" "}
      <View label={"Dob"} value={customer?.dob} />{" "}
      <View label={"Phone"} value={customer?.phone} />{" "}
      <View label={"Address line1"} value={customer?.mailing_address_line1} />{" "}
      <View label={"Address line2"} value={customer?.mailing_address_line2} />{" "}
      <View label={"City"} value={customer?.mailing_city} />{" "}
      <View label={"Province"} value={customer?.mailing_province} />{" "}
      <View label={"Postalcode"} value={customer?.mailing_postalcode} />{" "}
      <View label={"Country"} value={customer?.mailing_country} />{" "}
      <View label={"Created source"} value={customer?.created_source} />{" "}
      <View label={"Creator record id"} value={customer?.creator_record_id} />{" "}
      <View label={"Customer type"} value={customer?.customer_type} />{" "}
    </div>
  );
};

const ModalView = ({ object, title }) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              titleColor: "#E52B12",
              titleFontSize: "1.25rem",
            },
          },
        }}
      >
        <Modal
          open={open}
          footer={false}
          maskClosable={false}
          width={700}
          style={{ overflow: "auto", maxHeight: "65vh" }}
          className="modal-antd"
          styles={{ header: { textAlign: "center" } }}
          closable={false}
        >
          <div className="modal_close">
            <p>{title}</p>
            <div>
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseOutlined style={{ fontSize: 16 }} />
              </IconButton>
            </div>
          </div>

          <JsonFormatter
            json={object}
            tabWith={4}
            jsonStyle={{
              propertyStyle: { color: "red" },
              stringStyle: { color: "green" },
              numberStyle: { color: "darkorange" },
            }}
          />
        </Modal>
      </ConfigProvider>
      <Button
        size="small"
        onClick={() => {
          setOpen(true);
        }}
      >
        view
      </Button>
    </div>
  );
};
