import React, { useEffect, useState } from "react";
import { Customer, PaymentItem, View } from "./ViewTrailer";
import { useParams } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../../api";
import { Button, Collapse } from "antd";
import { getValueForView } from "../../../utils";

const ViewBoat = () => {
  const { quote_record_id } = useParams();
  const [data, setData] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addCustomers, setAddCustomers] = useState(null);

  useEffect(() => {
    async function fetch() {
      await axios
        .get(`${APIURL}/boat/get_boat_quote?quote_record_id=${quote_record_id}`)
        .then(async (res) => {
          console.log(res);
          setLoading(false);

          setCustomer(res.data?.data?.customer);
          setAddCustomers(res?.data?.data?.additional_names);
          setData(res.data?.data?.quote);
        });
    }

    fetch();
  }, []);

  const handleDeleteQuote = async () => {
    await axios
      .get(`${APIURL}/boat/delete_record?quote_record_id=${quote_record_id}`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="layout">
      {loading ? (
        <div className="layout_loading">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className="nav">
            <p>View Boat - {data?.quote_id} </p>
            <Button
              type="primary"
              danger
              size="small"
              onClick={handleDeleteQuote}
            >
              Delete
            </Button>
          </div>

          <div className="layout_main">
            <div className="view_layout_cont">
              <h2>Quote Details</h2>
              <div className="view_layout_grid">
                <View label={"Quote record id"} value={quote_record_id} />
                <View label={"Quote ID"} value={data?.quote_id} />{" "}
                <View label={"Policy number"} value={data?.policy_number} />
                <View
                  label={"Policy status"}
                  value={data?.policy_status}
                />{" "}
                <View label={"Quote status"} value={data?.quote_status} />
                <View label={"Inception date"} value={data?.inception_date} />
                <View label={"Expiry date"} value={data?.expiry_date} />
                <View
                  label={"Agree to tnc"}
                  value={data?.agreed_to_tnc ? "Yes" : "No"}
                />
                <View label={"Created source"} value={data?.created_source} />
                <View label={"Carrier"} value={data?.carrier} />
                <View
                  label={"Located province"}
                  value={data?.located_province}
                />
                <View label={"No of boats"} value={data?.number_of_boats} />
                <View
                  label={"Referral reason"}
                  value={data?.referral_reason}
                />{" "}
                <View
                  label={"Sign accept text"}
                  value={data?.signature_accept_text}
                />{" "}
                <View
                  label={"Creator record id"}
                  value={data?.creator_record_id}
                />{" "}
                <View label={"Total premium"} value={data?.total_premium} />{" "}
                <View label={"Total tax"} value={data?.total_tax} />{" "}
                <View label={"Total admin fee"} value={data?.total_admin_fee} />{" "}
                <View
                  label={"Total premium after tax"}
                  value={data?.total_premium_after_tax}
                />{" "}
                <View
                  label={"Auto renewal status"}
                  value={data?.auto_renewal_status}
                />{" "}
                <View label={"Created source"} value={data?.created_source} />{" "}
                <View label={"Modified source"} value={data?.modified_source} />{" "}
                <View
                  label={"Quote access url"}
                  value={data?.quote_access_url}
                />{" "}
                <View label={"Carrier"} value={data?.carrier} />{" "}
                <View
                  label={"is_renewal"}
                  value={getValueForView(data?.is_renewal)}
                />{" "}
                <View
                  label={"Master record id"}
                  value={data?.master_record_id}
                />{" "}
                <View
                  label={"Is migrated record"}
                  value={getValueForView(data?.is_migrated_record)}
                />{" "}
                <View label={"Sales date"} value={data?.sales_date} />{" "}
              </div>
            </div>

            <div className="view_layout_cont">
              <h2>Boat(s)</h2>

              {data?.boats ? (
                <Collapse
                  accordion
                  items={
                    data.boats.length
                      ? data?.boats.map((m, index) => {
                          return {
                            key: m?.quote_record_id,
                            label: `Boat - ${index + 1}`,
                            children: <QuoteItem data={data?.boats[index]} />,
                          };
                        })
                      : []
                  }
                />
              ) : (
                <p className="view_label">No boats</p>
              )}
            </div>

            <div className="view_layout_cont">
              <h2>Payment</h2>

              {data?.payments ? (
                <Collapse
                  accordion
                  items={
                    data?.payments.length
                      ? data?.payments.map((m, index) => {
                          return {
                            key: m?.quote_record_id,
                            label: `Payment - ${index + 1}`,
                            children: (
                              <PaymentItem data={data?.payments[index]} />
                            ),
                          };
                        })
                      : []
                  }
                />
              ) : (
                <p className="view_label">No payments</p>
              )}
            </div>

            <div className="view_layout_cont">
              <h2>Customer</h2>

              <Customer customer={customer} />
            </div>

            <div className="view_layout_cont">
              <h2>Additional Customer(s)</h2>

              {addCustomers ? (
                <Collapse
                  accordion
                  items={
                    addCustomers.length
                      ? addCustomers.map((m, index) => {
                          return {
                            key: m?.quote_record_id,
                            label: `Customer - ${index + 1}`,
                            children: (
                              <Customer customer={addCustomers[index]} />
                            ),
                          };
                        })
                      : []
                  }
                />
              ) : (
                <p className="view_label">No additional customers</p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ViewBoat;

const QuoteItem = ({ data }) => {
  return (
    <div className="view_layout_grid">
      {" "}
      <View label={"Creator record id"} value={data?.creator_record_id} />
      <View label={"Type"} value={data?.type} />
      <View label={"Year"} value={data?.year} />{" "}
      <View label={"Manufacturer"} value={data?.manufacturer} />
      <View label={"Model"} value={data?.model} />
      <View label={"Replacement cost"} value={`$ ${data?.replacement_cost}`} />
      <View
        label={"Actual cost value"}
        value={`$ ${data?.actual_cost_value}`}
      />
      <View
        label={"Is main operator same as owner?"}
        value={getValueForView(data?.is_main_operator_same_as_owner)}
      />
      <View
        label={"main operator first name"}
        value={data?.main_operator_first_name}
      />
      <View
        label={"Main operator last name"}
        value={data?.main_operator_last_name}
      />
      <View label={"Main operator dob"} value={data?.main_operator_dob} />
      <View
        label={"Main operator boating exp"}
        value={data?.main_operator_boating_experience}
      />
      <View
        label={"Main operator hold pcoc card?"}
        value={getValueForView(data?.main_operator_hold_pcoc_card)}
      />
      <View label={"Deductible"} value={data?.deductible} />
      <View
        label={"Personal effects cov"}
        value={data?.personal_effects_coverage}
      />
      <View
        label={"nav equip cov"}
        value={data?.navigational_equipment_coverage}
      />
      <View label={"Premium per year"} value={data?.premium_per_year} />
      <View label={"Coverage"} value={data?.coverage} />
      <View label={"Coverage name"} value={data?.coverage_name} />
      <View label={"Liability coverage"} value={data?.liability_coverage} />
      <View label={"Salvage"} value={data?.salvage} />
      <View label={"Pollution"} value={data?.pollution} />
      <View label={"Removal of wreckage"} value={data?.removal_of_wreckage} />
      <View
        label={"Emergency towing limit"}
        value={data?.emergency_towing_limit}
      />
      <View
        label={"uninsured underinsured boater endorsement"}
        value={data?.uninsured_underinsured_boater_endorsement}
      />
      <View label={"Loss of use limit"} value={data?.loss_of_use_limit} />
      <View
        label={"Will boat be used in atlantic pacific questions?"}
        value={getValueForView(
          data?.will_boat_be_used_in_atlantic_pacific_questions
        )}
      />
      <View
        label={
          "Is boat rented or carry passengers or existing damages questions?"
        }
        value={getValueForView(
          data?.is_boat_rented_or_carry_passengers_or_existing_damages_questions
        )}
      />
      <View
        label={"Is boat layered up and safety questions?"}
        value={getValueForView(data?.is_boat_layered_up_and_safety_questions)}
      />
      <View
        label={"Operator have claim in last 5 years"}
        value={getValueForView(data?.operator_have_claim_in_last_5_years)}
      />
      <View label={"How many claims?"} value={data?.how_many_claims} />
      <View
        label={"Operator have motor convictions in last 3years"}
        value={getValueForView(
          data?.operator_have_motor_convictions_in_last_3_years
        )}
      />
      <View
        label={"How many convictions?"}
        value={data?.how_many_convictions}
      />{" "}
      <View
        label={"Is owner employees or lives on?"}
        value={getValueForView(data?.is_owner_employees_or_lives_on)}
      />
      <View
        label={"Operator had suspended driver license"}
        value={getValueForView(data?.operator_had_suspended_driver_license)}
      />
      <View label={"When suspended"} value={data?.when_suspended} />
      <View label={"Boat Length"} value={data?.length_in_feet} />
      <View label={"Hull serial id"} value={data?.hull_serial_id} />
      <View label={"Hull type"} value={data?.hull_type} />
      <View label={"Other hull type"} value={data?.hull_type_for_others} />
      <View label={"Max est speed"} value={data?.max_est_speed} />
      <View label={"Engine1 type"} value={data?.engine1_type} />
      <View label={"Engine1 others type"} value={data?.engine1_others_type} />
      <View label={"Engine1 fuel type"} value={data?.engine1_fuel_type} />
      <View label={"Engine1 model year"} value={data?.engine1_model_year} />
      <View label={"Engine1 manufacturer"} value={data?.engine1_manufacturer} />
      <View
        label={"Engine1 serial number"}
        value={data?.engine1_serial_number}
      />
      <View label={"Engine1 horse power"} value={data?.engine1_horse_power} />
      <View label={"Engine2 type"} value={data?.engine2_type} />
      <View label={"Engine2 others type"} value={data?.engine2_others_type} />
      <View label={"Engine2 fuel type"} value={data?.engine2_fuel_type} />
      <View label={"Engine2 model year"} value={data?.engine2_model_year} />
      <View label={"Engine2 manufacturer"} value={data?.engine2_manufacturer} />
      <View
        label={"Engine2 serial number"}
        value={data?.engine2_serial_number}
      />
      <View label={"Engine2 horse power"} value={data?.engine2_horse_power} />
      <View
        label={"Add a trailer"}
        value={getValueForView(data?.add_a_trailer)}
      />
      <View label={"Trailer value"} value={data?.trailer_value} />
      <View label={"Trailer model year"} value={data?.trailer_model_year} />
      <View label={"Trailer manufacturer"} value={data?.trailer_manufacturer} />
      <View label={"Trailer vin"} value={data?.trailer_vin} />
      <View label={"Trailer length"} value={data?.trailer_length} />
      <View
        label={"Add a tender"}
        value={getValueForView(data?.add_a_tender)}
      />
      <View label={"Tender value"} value={data?.tender_value} />
      <View label={"Tender model year"} value={data?.tender_model_year} />
      <View label={"Tender manufacturer"} value={data?.tender_manufacturer} />
      <View label={"Tender vin"} value={data?.tender_vin} />
      <View label={"Tender length"} value={data?.tender_length} />
      <View
        label={"Add aux motor"}
        value={getValueForView(data?.add_auxiliary_motor)}
      />
      <View label={"Aux value"} value={data?.aux_value} />
      <View label={"Aux model year"} value={data?.aux_model_year} />
      <View label={"Aux manufacturer"} value={data?.aux_manufacturer} />
      <View label={"Aux vin"} value={data?.aux_vin} />
      <View label={"Aux length"} value={data?.aux_length} />
      <View label={"Is financed?"} value={getValueForView(data?.is_financed)} />
      <View label={"Financier name"} value={data?.financier_name} />
      <View
        label={"Financier address line1"}
        value={data?.financier_address_line1}
      />
      <View
        label={"Financier address line2"}
        value={data?.financier_address_line2}
      />
      <View label={"Financier city"} value={data?.financier_city} />
      <View label={"Financier province"} value={data?.financier_province} />
      <View label={"Financier postalcode"} value={data?.financier_postalcode} />
      <View label={"Financier country"} value={data?.financier_country} />
    </div>
  );
};
