import { AutoComplete, ConfigProvider } from "antd";
import React from "react";
import {
  ERROR_HELPERTEXT_STYLES,
  PRIMARY_COLOR,
  WARNING_COLOR,
} from "../utils";
import { FormHelperText } from "@mui/material";

const Autocomplete = ({
  value,
  label,
  setValue,
  object,
  name,
  key,
  helperText,
  error,
  setError,
  error_state,
  error_text,
  wholeError,
  wholevalue,
  id,
  options,
  disabled,
}) => {
  const handleChange = (val) => {
    console.log(val);
    let updated_error;

    // if (name === "name") {
    //   console.log(error, wholeError);
    //   return;
    // }

    if (name === "park_name") {
      if (!val) {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: true,
              parking_city: true,
              parking_province: true,
              parking_address: true,
              parking_postal_code: true,
            };
          }

          return m;
        });
        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: "",
              parking_address: undefined,
              parking_city: undefined,
              parking_province: undefined,
              parking_postal_code: undefined,
            };
          }

          return m;
        });

        setValue(updated);

        return;
      }
      let filter = options.filter((m) => val === m.name);

      if (filter.length === 0) {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: false,
            };
          }

          return m;
        });
        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
            };
          }

          return m;
        });

        setValue(updated);

        return;
      }

      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            parking_city: false,
            parking_province: false,
            parking_address: false,
            parking_postal_code: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: filter[0]?.value,
            parking_address: filter[0].address_line1,
            parking_city: filter[0]?.city,
            parking_province: filter[0]?.province,
            parking_postal_code: filter[0]?.postalcode,
          };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "trailer_lienholder" || name === "boat_lienholder") {
      if (!val) {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: true,
              city_leinholder: true,
              province_leinholder: true,
              pc_leinholder: true,
              country_leinholder: true,
              line2_leinholder: false,
              street_leinholder: true,
            };
          }

          return m;
        });
        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: "",
              street_leinholder: "",
              city_leinholder: "",
              province_leinholder: "",
              pc_leinholder: "",
              country_leinholder: "",
              line2_leinholder: "",
            };
          }

          return m;
        });

        setValue(updated);

        return;
      }

      let filter = options.filter((m) => val === m.name);

      console.log(filter, "filter");

      // if (val === "Other") {
      //   let updated_error = wholeError.map((m, index) => {
      //     if (index === id - 1) {
      //       return {
      //         ...m,
      //         [name]: false,
      //         city_leinholder: true,
      //         province_leinholder: true,
      //         pc_leinholder: true,
      //         country_leinholder: true,
      //         line2_leinholder: true,
      //         street_leinholder: true,
      //         other_lienholder: true,
      //       };
      //     }

      //     return m;
      //   });
      //   setError(updated_error);

      //   let updated = wholevalue.map((m, index) => {
      //     if (index === id - 1) {
      //       return {
      //         ...m,
      //         [name]: val,
      //         street_leinholder: filter[0]?.financier_address_line1,
      //         line2_leinholder: filter[0]?.financier_address_line2,
      //         city_leinholder: filter[0]?.financier_city,
      //         province_leinholder: filter[0]?.financier_province,
      //         pc_leinholder: filter[0]?.financier_postalcode,
      //         country_leinholder: filter[0]?.financier_country,
      //         other_lienholder: "",
      //       };
      //     }

      //     return m;
      //   });

      //   setValue(updated);

      //   return;
      // }

      if (filter.length === 0) {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: false,
              city_leinholder: true,
              province_leinholder: true,
              pc_leinholder: true,
              country_leinholder: true,
              line2_leinholder: false,
              street_leinholder: true,
            };
          }

          return m;
        });
        setError(updated_error);

        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: val,
              street_leinholder: "",
              city_leinholder: "",
              province_leinholder: "",
              pc_leinholder: "",
              country_leinholder: "",
              line2_leinholder: "",
            };
          }

          return m;
        });

        setValue(updated);

        return;
      }

      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: false,
            city_leinholder: false,
            province_leinholder: false,
            pc_leinholder: false,
            country_leinholder: false,
            line2_leinholder: false,
            street_leinholder: false,
          };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            [name]: filter[0]?.value,
            street_leinholder: filter[0]?.address_line1,
            line2_leinholder: filter[0]?.address_line2,
            city_leinholder: filter[0]?.city,
            province_leinholder: filter[0]?.province,
            pc_leinholder: filter[0]?.postalcode,
            country_leinholder: filter[0]?.country,
          };
        }

        return m;
      });

      console.log(updated);

      setValue(updated);

      return;
    }

    if (val === "") {
      updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: true };
        }

        return m;
      });
      setError(updated_error);
    } else {
      updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false };
        }

        return m;
      });

      setError(updated_error);
    }

    let updated = wholevalue.map((m, index) => {
      if (index === id - 1) {
        return { ...m, [name]: val };
      }

      return m;
    });

    setValue(updated);
  };

  return (
    <div className={`textfield ${error_state && "error_textfield"}`}>
      <label htmlFor={label} style={{ marginBottom: error_state && "10px" }}>
        {label}
      </label>
      <div>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: PRIMARY_COLOR,
              colorPrimaryHover: PRIMARY_COLOR,
              colorErrorOutline: "none",
              colorError: WARNING_COLOR,
              colorErrorBorderHover: "none",
              controlOutline: "none",
              fontSize: 12,
            },
          }}
        >
          <AutoComplete
            style={{ width: "100%" }}
            id={label}
            size="large"
            options={options}
            filterOption
            onChange={(val) => handleChange(val)}
            value={value}
            status={error_state && "error"}
            allowClear
            disabled={disabled}
            // onSearch={(val) => {
            //   console.log(val);
            // }}
            // showSearch
          />
        </ConfigProvider>
        {error_state && (
          <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
            {error_text}
          </FormHelperText>
        )}
      </div>
    </div>
  );
};

export default Autocomplete;
