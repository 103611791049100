import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "../../../api";
import Nav from "../../../components/AdminPanel/Nav";
import { Table } from "antd";
import TableInput from "../../../components/AdminPanel/TableInput";

const BoatRateTable = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [editState, setEditState] = useState(false);
  const [cancelState, setCancelState] = useState(false);

  useEffect(() => {
    let isCancelled = false;

    async function fetch() {
      await axios
        .post(`${APIURL}/organization/get_organization`, {
          organization_id: ORGANIZATION_ID,
          required_keys: ["boat_rate_table"],
        })
        .then((res) => {
          if (!isCancelled) {
            console.log(res);
            let table_data = res?.data?.data?.organization?.boat_rate_table;
            let mapped_data = Object.entries(table_data).map(([key, value]) => {
              return {
                ...value,
                boat_rate: key,
                key,
              };
            });

            console.log(mapped_data);

            setData(mapped_data);
            setLoading(false);
            setCancelState(() => false);
            setEditState(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetch();

    return () => {
      isCancelled = true;
    };
  }, [cancelState]);

  const column = [
    {
      title: "Rate",
      dataIndex: "boat_rate",
      key: "boat_rate",
      fixed: "left",
      width: 150,
    },
    {
      title: "Bass Boat",
      dataIndex: "Bass Boat",
      key: "Bass Boat",
      width: 150,
      render: (_, record) => (
        <TableInput
          border={editState}
          disabled={!editState}
          value={record["Bass Boat"]}
          handleChange={(e) => {
            let value = e.target.value;

            console.log(/^[0-9.]+$/.test(value));

            let array = [...data];

            let updated = array.map((m) => {
              if (m["Bass Boat"] === record["Bass Boat"]) {
                return {
                  ...m,
                  "Bass Boat": /^[0-9.]+$/.test(value) ? value : "",
                };
              }

              return m;
            });

            setData(updated);
          }}
        />
      ),
    },
    {
      title: "Cabin Cruiser",
      dataIndex: "Cabin Cruiser",
      key: "Cabin Cruiser",
      width: 150,
      render: (_, record) => (
        <TableInput
          border={editState}
          disabled={!editState}
          value={record["Cabin Cruiser"]}
          handleChange={(e) => {
            let value = e.target.value;

            let array = [...data];

            let updated = array.map((m) => {
              if (m["Cabin Cruiser"] === record["Cabin Cruiser"]) {
                return {
                  ...m,
                  "Cabin Cruiser": /^[0-9.]+$/.test(value) ? value : "",
                };
              }

              return m;
            });

            setData(updated);
          }}
        />
      ),
    },
    {
      title: "Fishing Boat",
      dataIndex: "Fishing Boat",
      key: "Fishing Boat",
      width: 150,
      render: (_, record) => (
        <TableInput
          border={editState}
          disabled={!editState}
          value={record["Fishing Boat"]}
          handleChange={(e) => {
            let value = e.target.value;

            let array = [...data];

            let updated = array.map((m) => {
              if (m["Fishing Boat"] === record["Fishing Boat"]) {
                return {
                  ...m,
                  "Fishing Boat": /^[0-9.]+$/.test(value) ? value : "",
                };
              }

              return m;
            });

            setData(updated);
          }}
        />
      ),
    },
    {
      title: "Pontoon",
      dataIndex: "Pontoon",
      key: "Pontoon",
      width: 150,
      render: (_, record) => (
        <TableInput
          border={editState}
          disabled={!editState}
          value={record["Pontoon"]}
          handleChange={(e) => {
            let value = e.target.value;

            let array = [...data];

            let updated = array.map((m) => {
              if (m["Pontoon"] === record["Pontoon"]) {
                return {
                  ...m,
                  Pontoon: /^[0-9.]+$/.test(value) ? value : "",
                };
              }

              return m;
            });

            setData(updated);
          }}
        />
      ),
    },
    {
      title: "SeaDoo/JetSki",
      dataIndex: "SeaDoo/JetSki",
      key: "SeaDoo/JetSki",
      width: 150,
      render: (_, record) => (
        <TableInput
          border={editState}
          disabled={!editState}
          value={record["SeaDoo/JetSki"]}
          handleChange={(e) => {
            let value = e.target.value;

            let array = [...data];

            let updated = array.map((m) => {
              if (m["SeaDoo/JetSki"] === record["SeaDoo/JetSki"]) {
                return {
                  ...m,
                  "SeaDoo/JetSki": /^[0-9.]+$/.test(value) ? value : "",
                };
              }

              return m;
            });

            setData(updated);
          }}
        />
      ),
    },
    {
      title: "Runabout/Deck/Bowrider",
      dataIndex: "Runabout/Deck/Bowrider",
      key: "Runabout/Deck/Bowrider",
      width: 220,
      render: (_, record) => (
        <TableInput
          border={editState}
          disabled={!editState}
          value={record["Runabout/Deck/Bowrider"]}
          handleChange={(e) => {
            let value = e.target.value;

            let array = [...data];

            let updated = array.map((m) => {
              if (
                m["Runabout/Deck/Bowrider"] === record["Runabout/Deck/Bowrider"]
              ) {
                return {
                  ...m,
                  "Runabout/Deck/Bowrider": /^[0-9.]+$/.test(value)
                    ? value
                    : "",
                };
              }

              return m;
            });

            setData(updated);
          }}
        />
      ),
    },
    {
      title: "Ski/Wake Boat",
      dataIndex: "Ski/Wake Boat",
      key: "Ski/Wake Boat",
      width: 150,
      render: (_, record) => (
        <TableInput
          border={editState}
          disabled={!editState}
          value={record["Ski/Wake Boat"]}
          handleChange={(e) => {
            let value = e.target.value;

            let array = [...data];

            let updated = array.map((m) => {
              if (m["Ski/Wake Boat"] === record["Ski/Wake Boat"]) {
                return {
                  ...m,
                  "Ski/Wake Boat": /^[0-9.]+$/.test(value) ? value : "",
                };
              }

              return m;
            });

            setData(updated);
          }}
        />
      ),
    },
  ];

  const handleEdit = async () => {
    setLoading(() => true);

    await new Promise((resolve) => setTimeout(resolve, 1500));

    setLoading(() => false);
    setEditState(true);
  };

  const handleCancel = async () => {
    setLoading(() => true);

    setCancelState(true);
  };

  const handleSave = async () => {
    let boat_rate_table = Object.fromEntries(
      data.map((item) => [
        item.boat_rate,
        {
          "Bass Boat": parseFloat(item["Bass Boat"]),
          "Cabin Cruiser": parseFloat(item["Cabin Cruiser"]),
          "Fishing Boat": parseFloat(item["Fishing Boat"]),
          Pontoon: parseFloat(item["Pontoon"]),
          "Runabout/Deck/Bowrider": parseFloat(item["Runabout/Deck/Bowrider"]),
          "SeaDoo/JetSki": parseFloat(item["SeaDoo/JetSki"]),
          "Ski/Wake Boat": parseFloat(item["Ski/Wake Boat"]),
        },
      ])
    );

    let body = {
      organization_id: ORGANIZATION_ID,
      organization_updates: {
        boat_rate_table,
      },
    };

    console.log(body);

    setLoading(() => true);

    await axios
      .post(`${APIURL}/organization/update_organization`, body)
      .then((res) => {
        console.log(res);

        setCancelState(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(data);

  return (
    <div className="layout">
      <Nav
        header={editState ? "Edit Boat Rate Table" : "View Boat Rate Table"}
        layout={editState ? "save" : "edit"}
        onEditChange={handleEdit}
        onSaveChange={handleSave}
        onCancelChange={handleCancel}
      />
      <div className="layout_container">
        {loading ? (
          <div className="layout_loading">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="layout_main">
            <Table
              virtual
              columns={column}
              scroll={{ x: "auto", y: 700 }}
              dataSource={data}
              pagination={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BoatRateTable;
