import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIURL, ORGANIZATION_ID } from "../../../api";
import { Checkbox, Table } from "antd";
import Nav from "../../../components/AdminPanel/Nav";
import TableInput from "../../../components/AdminPanel/TableInput";

const SupportedPayments = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [editState, setEditState] = useState(false);
  const [cancelState, setCancelState] = useState(false);

  useEffect(() => {
    let isCancelled = false;

    async function fetch() {
      await axios
        .post(`${APIURL}/organization/get_organization`, {
          organization_id: ORGANIZATION_ID,
          required_keys: ["supported_payment_modes"],
        })
        .then((res) => {
          if (!isCancelled) {
            console.log(res);
            let table_data =
              res?.data?.data?.organization?.supported_payment_modes;
            let mapped_data = Object.entries(table_data).map(([key, value]) => {
              return {
                ...value,
                mode: key,
                key,
              };
            });

            console.log(mapped_data);

            setData(mapped_data);
            setLoading(false);
            setCancelState(() => false);
            setEditState(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetch();

    return () => {
      isCancelled = true;
    };
  }, [cancelState]);

  const column = [
    {
      title: "Payment Mode",
      dataIndex: "mode",
      key: "mode",
      fixed: "left",
      width: 150,
    },
    {
      title: "Nuvei code",
      dataIndex: "nuvei_code",
      key: "nuvei_code",
      width: 150,
      render: (_, record) => (
        <TableInput
          border={editState}
          disabled={!editState}
          value={record["nuvei_code"]}
          handleChange={(e) => {
            let value = e.target.value;

            let array = [...data];

            let updated = array.map((m) => {
              if (m["nuvei_code"] === record["nuvei_code"]) {
                return {
                  ...m,
                  nuvei_code: value,
                };
              }

              return m;
            });

            setData(updated);
          }}
        />
      ),
    },
    {
      title: "Processing fee percent",
      dataIndex: "processing_fee_percent",
      key: "processing_fee_percent",
      width: 250,
      render: (_, record) => (
        <TableInput
          border={editState}
          disabled={!editState}
          value={record["processing_fee_percent"]}
          handleChange={(e) => {
            let value = e.target.value;

            let array = [...data];

            let updated = array.map((m) => {
              if (
                m["processing_fee_percent"] === record["processing_fee_percent"]
              ) {
                return {
                  ...m,
                  processing_fee_percent: /^[0-9.]+$/.test(value) ? value : "",
                };
              }

              return m;
            });

            setData(updated);
          }}
        />
      ),
    },
    {
      title: "Processing fee standard",
      dataIndex: "processing_fee_standard",
      key: "processing_fee_standard",
      width: 250,
      render: (_, record) => (
        <TableInput
          border={editState}
          disabled={!editState}
          value={record["processing_fee_standard"]}
          handleChange={(e) => {
            let value = e.target.value;

            let array = [...data];

            let updated = array.map((m) => {
              if (
                m["processing_fee_standard"] ===
                record["processing_fee_standard"]
              ) {
                return {
                  ...m,
                  processing_fee_standard: /^[0-9.]+$/.test(value) ? value : "",
                };
              }

              return m;
            });

            setData(updated);
          }}
        />
      ),
    },
    {
      title: "Include percent",
      dataIndex: "include_percent",
      key: "include_percent",
      width: 150,
      render: (_, record) => (
        <Checkbox
          border={editState}
          disabled={!editState}
          value={record["include_percent"]}
          onChange={(e) => {
            let value = e.target.checked;

            let array = [...data];

            let updated = array.map((m) => {
              if (m["include_percent"] === record["include_percent"]) {
                return {
                  ...m,
                  include_percent: value,
                };
              }

              return m;
            });

            setData(updated);
          }}
        />
      ),
    },
    {
      title: "Include standard",
      dataIndex: "include_standard",
      key: "include_standard",
      width: 150,
      render: (_, record) => (
        <Checkbox
          border={editState}
          disabled={!editState}
          value={record["include_standard"]}
          onChange={(e) => {
            let value = e.target.checked;

            let array = [...data];

            let updated = array.map((m) => {
              if (m["include_standard"] === record["include_standard"]) {
                return {
                  ...m,
                  include_standard: value,
                };
              }

              return m;
            });

            setData(updated);
          }}
        />
      ),
    },
  ];

  const handleEdit = async () => {
    // setLoading(() => true);

    // await new Promise((resolve) => setTimeout(resolve, 1500));

    // setLoading(() => false);
    setEditState(true);
  };

  const handleCancel = async () => {
    setLoading(() => true);

    setCancelState(true);
  };

  const handleSave = async () => {
    let supported_payment_modes = Object.fromEntries(
      data.map((item) => [
        item.mode,
        {
          include_percent: item?.include_percent,
          include_standard: item?.include_standard,
          processing_fee_percent: parseFloat(item?.processing_fee_percent),
          processing_fee_standard: parseFloat(item?.processing_fee_standard),
          nuvei_code: item?.nuvei_code,
        },
      ])
    );

    let body = {
      organization_id: ORGANIZATION_ID,
      organization_updates: {
        supported_payment_modes,
      },
    };

    console.log(body);

    setLoading(() => true);

    await axios
      .post(`${APIURL}/organization/update_organization`, body)
      .then((res) => {
        console.log(res);

        setCancelState(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="layout">
      <Nav
        header={
          editState
            ? "Create supported payment modes"
            : "View supported payment modes"
        }
        layout={editState ? "save" : "edit"}
        onEditChange={handleEdit}
        onCancelChange={handleCancel}
        editText={"Edit"}
        onSaveChange={handleSave}
      />
      <div className="layout_container">
        {loading ? (
          <div className="layout_loading">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="layout_main">
            <Table
              columns={column}
              scroll={{ y: 900 }}
              virtual
              dataSource={data}
              pagination={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SupportedPayments;
