import { ConfigProvider, Input } from "antd";
import React from "react";
import { PRIMARY_COLOR, WARNING_COLOR } from "../../utils";

const DollarInput = ({ label, disabled, value, handleChange, name }) => {
  return (
    <div className={`spl_input`}>
      <label htmlFor={label}>{label}</label>
      <div className="input_container">
        <ConfigProvider
          theme={{
            components: {
              Input: {
                activeBorderColor: PRIMARY_COLOR,
                hoverBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                errorActiveShadow: "none",
              },
            },
            token: {
              colorErrorOutline: "none",
              colorError: WARNING_COLOR,
              colorErrorBorderHover: "none",
              fontSize: 12,
            },
          }}
        >
          <Input
            size="large"
            addonBefore={
              <p style={{ fontSize: ".875rem", opacity: "0.5" }}>$</p>
            }
            id={label}
            name={name}
            onChange={(e) => handleChange(e)}
            value={value}
            readOnly={disabled}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default DollarInput;
