import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIURL } from "../../../api";
import { Modal, Table, message } from "antd";
import Nav from "../../../components/AdminPanel/Nav";
import APTextField from "../../../components/AdminPanel/APTextField";
import { useNavigate } from "react-router-dom";

const Parks = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [editState, setEditState] = useState(false);
  const [cancelState, setCancelState] = useState(false);
  const [park, setPark] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    let isCancelled = false;

    async function fetch() {
      await axios
        .get(`${APIURL}/preferred_park/get_preferred_parks`)
        .then((res) => {
          if (!isCancelled) {
            console.log(res);

            let mapped_array = res?.data?.data.map((m, index) => {
              return { ...m, key: index + 1 };
            });

            setData(mapped_array);
            setLoading(false);
            setCancelState(() => false);
            setEditState(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetch();

    return () => {
      isCancelled = true;
    };
  }, [cancelState]);

  const column = [
    {
      title: "Park Name",
      dataIndex: "name",
      fixed: "left",
      key: "preferred_park_name",
      width: 200,
      // render: (_, record) => (
      //   <HoverCell data={data} setData={setData} record={record} />
      // ),
    },
    {
      title: "Address Line 1",
      dataIndex: "address_line1",
      key: "address",
      width: 100,
    },
    {
      title: "Address Line 2",
      dataIndex: "address_line2",
      key: "address",
      width: 100,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: 100,
    },
    {
      title: "Province",
      dataIndex: "province",
      key: "province",
      width: 100,
    },
    {
      title: "Postal code",
      dataIndex: "postalcode",
      key: "postalcode",
      width: 100,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 100,
    },
  ];

  const handleEdit = async () => {
    // setLoading(() => true);

    // await new Promise((resolve) => setTimeout(resolve, 1500));

    // setLoading(() => false);
    setEditState(true);
  };

  const handleCancel = async () => {
    setLoading(() => true);

    setCancelState(true);
  };

  const handleRowClick = async (record) => {
    console.log("Row Clicked:", record);

    navigate(`/admin/trailer/park/edit?id=${record?.preferred_park_id}`);
  };

  const handlePark = (e) => {
    const { value, name } = e.target;

    setPark({
      ...park,
      [name]: value,
    });
  };

  const handleCreate = async () => {
    await axios
      .post(`${APIURL}/preferred_park/create_preferred_parks`, {
        list_of_preferred_parks: [park],
      })
      .then((res) => {
        console.log(res);

        message.success("PreferredPark created");

        setEditState(false);
        setCancelState(true);

        setPark({});
      })
      .catch((err) => {
        console.log(err);

        message.error("Failed");
      });
  };

  return (
    <div className="layout">
      <Modal
        open={editState}
        onCancel={() => {
          setEditState(false);
        }}
        okText={"Create"}
        title="Create Preferred Park"
        onOk={handleCreate}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          <APTextField
            size={"medium"}
            label={"Park Name"}
            name={"name"}
            value={park?.name}
            handleChange={(e) => handlePark(e)}
          />
          <APTextField
            size={"medium"}
            label={"Address"}
            name={"address_line1"}
            value={park?.address_line1}
            handleChange={(e) => handlePark(e)}
          />
          <APTextField
            size={"medium"}
            label={"Address"}
            name={"address_line2"}
            value={park?.address_line2}
            handleChange={(e) => handlePark(e)}
          />
          <APTextField
            size={"medium"}
            label={"City"}
            name={"city"}
            value={park?.city}
            handleChange={(e) => handlePark(e)}
          />
          <APTextField
            size={"medium"}
            label={"Province"}
            name={"province"}
            value={park?.province}
            handleChange={(e) => handlePark(e)}
          />
          <APTextField
            size={"medium"}
            label={"Postal code"}
            name={"postalcode"}
            value={park?.postalcode}
            handleChange={(e) => handlePark(e)}
          />
          <APTextField
            size={"medium"}
            label={"Country"}
            name={"country"}
            value={park?.country}
            handleChange={(e) => handlePark(e)}
          />
        </div>
      </Modal>
      <Nav
        header={editState ? "Create Preferred Park" : "View Preferred Parks"}
        layout={editState ? "save" : "edit"}
        onEditChange={handleEdit}
        onCancelChange={handleCancel}
        editText={"Create"}
      />
      <div className="layout_container">
        {loading ? (
          <div className="layout_loading">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="layout_main">
            <Table
              columns={column}
              scroll={{ y: 630 }}
              virtual
              dataSource={data}
              pagination={false}
              onRow={(record, index) => ({
                onClick: () => handleRowClick(record),
              })}
              style={{ cursor: "pointer" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Parks;
