import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIURL } from "../../../api";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "../../../components/AdminPanel/Nav";
import APTextField from "../../../components/AdminPanel/APTextField";

const EditFinancier = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [financier, setFinancier] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    async function fetch() {
      await axios
        .get(
          `${APIURL}/financier/get_financier?financier_id=${searchParams.get(
            "id"
          )}`
        )
        .then((res) => {
          console.log(res);
          setFinancier(res?.data?.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetch();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFinancier({
      ...financier,
      [name]: value,
    });
  };

  const handleCancel = () => {
    navigate("/admin/org/financiers");
  };

  const handleSave = async () => {
    await axios
      .post(`${APIURL}/financier/update_financier`, {
        financier_id: financier?.financier_id,
        financier_updates: financier,
      })
      .then((res) => {
        console.log(res);
        navigate("/admin/org/financiers");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="layout">
      <Nav
        header={"Edit Preferred Park"}
        layout={"save"}
        // onEditChange={handleEdit}
        onSaveChange={handleSave}
        onCancelChange={handleCancel}
      />
      <div className="layout_container">
        {loading ? (
          <div className="layout_loading">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="layout_main">
            <APTextField
              label={"Financier name"}
              value={financier?.name}
              name={"name"}
              handleChange={(e) => handleChange(e)}
            />{" "}
            <APTextField
              label={"Address Line 1"}
              value={financier?.address_line1}
              name={"address_line1"}
              handleChange={(e) => handleChange(e)}
            />{" "}
            <APTextField
              label={"Address Line 2"}
              value={financier?.address_line2}
              name={"address_line2"}
              handleChange={(e) => handleChange(e)}
            />{" "}
            <APTextField
              label={"City"}
              value={financier?.city}
              name={"city"}
              handleChange={(e) => handleChange(e)}
            />{" "}
            <APTextField
              label={"Province"}
              value={financier?.province}
              name={"province"}
              handleChange={(e) => handleChange(e)}
            />{" "}
            <APTextField
              label={"Postal Code"}
              value={financier?.postalcode}
              name={"postalcode"}
              handleChange={(e) => handleChange(e)}
            />
            <APTextField
              label={"Country"}
              value={financier?.country}
              name={"country"}
              handleChange={(e) => handleChange(e)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditFinancier;
