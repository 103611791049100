import {
  Autocomplete,
  FormControlLabel,
  RadioGroup,
  TextField,
  styled,
} from "@mui/material";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "./api";

const CustomAutoComplete = styled(Autocomplete)({
  "& .MuiOutlinedInput-root": {
    padding: "5px",
    width: "100%",
  },
  "&.MuiAutocomplete-root": {
    width: "100%",
    fontSize: "12px",
  },
});

const CustomTextField = styled(TextField)({
  width: "100%",
  fontSize: "12px",
  "& .MuiOutlinedInput-root": {
    fontSize: "12px",

    "& fieldset": {
      borderColor: "rgb(166, 166, 166)",
    },
    "&:hover fieldset": {
      border: "1px solid #8ed1fc",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #8ed1fc",
    },
  },

  ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#e31d23",
  },

  "& .MuiFormHelperText-root.Mui-error": {
    color: "#e31d23",
    fontSize: ".75rem",
    lineHeight: "1rem",
    fontFamily: "Montserrat",
  },

  "& .MuiFormHelperText-root": {
    color: "#e31d23",
    fontSize: ".75rem",
    lineHeight: "1rem",
    fontFamily: "Montserrat",
    marginLeft: "0",
  },
});

const CustomRadioGroup = styled(RadioGroup)({
  "&.MuiFormGroup-root": {
    flexDirection: "row",
    gap: "10px",
    width: "100%",
    flexWrap: "nowrap",
  },

  "@media screen and (max-width: 767px)": {
    "&.MuiFormGroup-root": {
      flexWrap: "wrap",
    },
  },
});

const CustomFormControlLabel = styled(FormControlLabel)({
  "&.MuiFormControlLabel-root": {
    paddingRight: "10px",
    padding: ".5rem 0",
    borderRadius: "5px",
    marginRight: "0",
    marginLeft: "0",
    width: "100%",
  },

  ".MuiTypography-root": {
    fontFamily: "Montserrat",
    fontSize: "15px",
  },

  //   "&.MuiFormControlLabel-root:focus-within": {
  //     color: "rgb(0, 109, 168)",
  //     border: "1px solid rgb(0, 109, 168)",
  //   },
});

const phone_style = {
  border: "none",
  padding: "12px 14px 12px 48px",
  borderRadius: "5px",
  width: "100%",
  outline: "none",
  height: "42px",

  ":focus": {
    boxShadow: "none",
  },
};

const add_phn_styles = {
  padding: "12px 1px 12px 45px",
  borderRadius: "5px",
  width: "100%",
  outline: "none",
  height: "27.6px",
  fontSize: "12px",
  border: "1px solid rgb(166, 166, 166)",

  ":focus": {
    boxShadow: "none",
    border: "1px solid #8ed1fc",
  },
};

const getStarted = {
  insured_first_name: undefined,
  insured_last_name: undefined,
  email: undefined,
  province: undefined,
  additional_name: undefined,
  date_of_birth: undefined,
  phone: undefined,
  find_us: undefined,
  find_us_value: undefined,
};

const trailer = {
  type_of_trailer: undefined,
  trailer_model_year: undefined,
  are_you_org_owner: undefined,
  how_long_owned: undefined,
  replacement_cost: undefined,
  actual_cash_value: undefined,
  is_trailer_parked_within_500ft: undefined,
  is_trailer_parked: undefined,
  trailer_stored: undefined,
  trailer_taken_into_usa: undefined,
  is_trailer_removed_from_park: undefined,
  trailer_removed_address: undefined,
  have_deck: undefined,
  have_screened_in_room: undefined,
  have_hard_awning: undefined,
  insure_golf_cart: undefined,
  no_of_golf_carts: undefined,
  golf_cart_1: undefined,
  golf_cart_2: undefined,
  park_name: undefined,
  parking_address: undefined,
  parking_city: undefined,
  parking_province: undefined,
  parking_postal_code: undefined,
  parking_site_number: undefined,
};

const err_trailer = {
  type_of_trailer: undefined,
  trailer_model_year: undefined,
  are_you_org_owner: undefined,
  how_long_owned: undefined,
  replacement_cost: undefined,
  actual_cash_value: undefined,
  is_trailer_parked_within_500ft: undefined,
  is_trailer_parked: undefined,
  trailer_stored: undefined,
  trailer_taken_into_usa: undefined,
  is_trailer_removed_from_park: undefined,
  trailer_removed_address: false,
  have_deck: undefined,
  have_screened_in_room: undefined,
  have_hard_awning: undefined,
  insure_golf_cart: undefined,
  no_of_golf_carts: undefined,
  golf_cart_1: undefined,
  golf_cart_2: undefined,
  golf_cart_total_value: false,
  park_name: undefined,
  parking_address: undefined,
  parking_city: undefined,
  parking_province: undefined,
  parking_postal_code: undefined,
  parking_site_number: undefined,
};

const additional_information = {
  live_in_trailer_full_time: undefined,
  is_unit_strictly: undefined,
  is_any_heating: undefined,
  heating_type: undefined,
  is_dual_purpose_trailer: undefined,
  hauler_selection: undefined,
  toy_hauler_dropdown: {
    "Pedal Bike": false,
    ATV: false,
    Motorcycle: false,
    "Dirt Bike": false,
    "Cars/Trucks/SUV": false,
  },
  any_mod_to_trailer: undefined,
  mod_options: undefined,
  solar_panels_installed: undefined,
  use_of_solar_on_trailer: undefined,
  interior_upgrades: undefined,
  is_pre_existing_damage: undefined,
  damage_description: undefined,
  is_trailer_motorized: undefined,
  trailer_financed: undefined,
  trailer_lienholder: undefined,
  line2_leinholder: undefined,
  street_leinholder: undefined,
  city_leinholder: undefined,
  province_leinholder: undefined,
  pc_leinholder: undefined,
  country_leinholder: undefined,
};

const err_additional_information = {
  live_in_trailer_full_time: undefined,
  is_unit_strictly: undefined,
  is_any_heating: undefined,
  heating_type: undefined,
  is_dual_purpose_trailer: undefined,
  hauler_selection: undefined,
  toy_hauler_dropdown: undefined,
  any_mod_to_trailer: undefined,
  mod_options: undefined,
  solar_panels_installed: undefined,
  use_of_solar_on_trailer: undefined,
  interior_upgrades: undefined,
  is_pre_existing_damage: undefined,
  damage_description: undefined,
  is_trailer_motorized: undefined,
  trailer_financed: undefined,
  trailer_lienholder: undefined,
  line2_leinholder: undefined,
  street_leinholder: undefined,
  city_leinholder: undefined,
  province_leinholder: undefined,
  pc_leinholder: undefined,
  country_leinholder: undefined,
};

const trailer_specs = {
  trailer_manufacturer: undefined,
  trailer_model: undefined,
  trailer_length: undefined,
  trailer_width: undefined,
  vin: undefined,
  deck_length: undefined,
  deck_width: undefined,
  florida_room_length: undefined,
  florida_room_width: undefined,
  awning_length: undefined,
  awning_width: undefined,
};

const boat_policy_details = {
  inception_date: undefined,
  expiry_date: undefined,
  mailing_country: undefined,
  mailing_address: undefined,
  mailing_suite: undefined,
  mailing_city: undefined,
  mailing_province: undefined,
  mailing_postal_code: undefined,
  signature: undefined,
};

const boat_policy_details_error = {
  inception_date: undefined,
  expiry_date: undefined,
  mailing_country: undefined,
  mailing_address: undefined,
  mailing_suite: false,
  mailing_city: undefined,
  mailing_province: undefined,
  mailing_postal_code: undefined,
  signature: undefined,
};

const trailer_policy_details = {
  inception_date: undefined,
  expiry_date: undefined,
  mailing_country: undefined,
  mailing_address: undefined,
  mailing_suite: undefined,
  mailing_city: undefined,
  mailing_province: undefined,
  mailing_postal_code: undefined,
  signature: undefined,
};

const trailer_policy_details_error = {
  inception_date: undefined,
  expiry_date: undefined,
  mailing_country: undefined,
  mailing_address: undefined,
  mailing_suite: false,
  mailing_city: undefined,
  mailing_province: undefined,
  mailing_postal_code: undefined,
  signature: undefined,
};

const terms = {
  terms_checked: undefined,
};

const boat = {
  type_of_watercraft: undefined,
  boat_model_year: undefined,
  boat_manufacturer: undefined,
  boat_model: undefined,
  is_operator_same_as_owner: undefined,
  org_first_name: undefined,
  org_last_name: undefined,
  date_of_birth: undefined,
  replacement_cost: undefined,
  actual_cash_value: undefined,
  boating_experience: undefined,
  operator_hold_pcoc: undefined,
};

const eligiblity = {
  watercraft_used_in_atlantic: undefined,
  first_apply_to_watercraft: undefined,
  second_apply_to_watercraft: undefined,
  apply_to_applicant: undefined,
  claims_in_last_five_years: undefined,
  no_of_claims_in_last_five_years: undefined,
  claims_in_last_three_years: undefined,
  no_of_claims_in_last_three_years: undefined,
  driver_license_suspended: undefined,
  when_license_suspended: undefined,
};

const boat_specs = {
  boat_length: undefined,
  hull_id: undefined,
  hull_type: undefined,
  other_hull_type: undefined,
  est_max_speed: undefined,
  main1_engine_type: undefined,
  main1_other_engine_type: undefined,
  main1_fuel_type: undefined,
  main1_boat_engine_model_year: undefined,
  main1_boat_engine_manufacturer: undefined,
  main1_boat_engine_serail: undefined,
  main1_boat_engine_horsepower: undefined,
  main2_engine_type: undefined,
  main2_other_engine_type: undefined,
  main2_fuel_type: undefined,
  main2_boat_engine_model_year: undefined,
  main2_boat_engine_manufacturer: undefined,
  main2_boat_engine_serail: undefined,
  main2_boat_engine_horsepower: undefined,
};

const err_boat_specs = {
  boat_length: undefined,
  hull_id: undefined,
  hull_type: undefined,
  other_hull_type: undefined,
  est_max_speed: undefined,
  main1_engine_type: undefined,
  main1_other_engine_type: undefined,
  main1_fuel_type: undefined,
  main1_boat_engine_model_year: undefined,
  main1_boat_engine_manufacturer: undefined,
  main1_boat_engine_serail: undefined,
  main1_boat_engine_horsepower: undefined,
  main2_engine_type: false,
  main2_other_engine_type: false,
  main2_fuel_type: false,
  main2_boat_engine_model_year: false,
  main2_boat_engine_manufacturer: false,
  main2_boat_engine_serail: false,
  main2_boat_engine_horsepower: false,
};

const addons = {
  add_trailer: undefined,
  trailer_value: undefined,
  trailer_model_year: undefined,
  trailer_manufacturer: undefined,
  trailer_serial: undefined,
  trailer_length: undefined,
  add_tender: undefined,
  tender_value: undefined,
  tender_model_year: undefined,
  tender_manufacturer: undefined,
  tender_serial: undefined,
  tender_length: undefined,
  add_auxillary_motor: undefined,
  aux_engine_value: undefined,
  aux_engine_model_year: undefined,
  aux_engine_manufacturer: undefined,
  aux_engine_serial: undefined,
  aux_engine_hp: undefined,
  boat_financed: undefined,
  boat_lienholder: undefined,
  other_lienholder: undefined,
  street_leinholder: undefined,
  line2_leinholder: undefined,
  city_leinholder: undefined,
  province_leinholder: undefined,
  pc_leinholder: undefined,
  country_leinholder: undefined,
};

const Province_Options = [
  { value: "Alberta", label: "Alberta" },
  { value: "British Columbia", label: "British Columbia" },
  { value: "Manitoba", label: "Manitoba" },
  { value: "Ontario", label: "Ontario" },
  { value: "Saskatchewan", label: "Saskatchewan" },
];

const Social_Media_Options = [
  { value: "Facebook", label: "Facebook" },
  { value: "Instagram", label: "Instagram" },
  { value: "ORVO", label: "ORVO" },
  { value: "X", label: "X" },
  { value: "Tiktok", label: "Tiktok" },
  { value: "Other", label: "Other" },
];

function formatCurrency(number) {
  // Check if the input is a valid number
  if (isNaN(number)) {
    return "Invalid number";
  }

  // Use Number.toLocaleString() to format the number as currency
  return number.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

function formatPhoneNumber(rawPhoneNumber) {
  const cleaned = String(rawPhoneNumber).replace(/\D/g, "");

  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return rawPhoneNumber;
}

const FIELD_ERROR_TEXT = "This field is required";
const COMPONENT_ERROR_TEXT = "One or more entries has an error.";

const ERROR_HELPERTEXT_STYLES = {
  color: "#e31d23",
  fontSize: ".75rem",
  lineHeight: "1rem",
  fontFamily: "Montserrat",
};

const PRIMARY_COLOR = "#8ed1fc";
const WARNING_COLOR = "#e31d23";

const sendCustomerDetailsToRegisteredMail = async (email, setModalOpen) => {
  await axios
    .post(`${APIURL}/customer/send_customer_details_email`, {
      email,
      organization_id: ORGANIZATION_ID,
    })
    .then((res) => {
      console.log(res);
      setModalOpen(false);
    })
    .catch((err) => {
      console.log(err);
    });
};

const sendResetLinkToRegisteredMail = async (email, setModalOpen) => {
  let body = {
    email,
    reset_link: "",
    organization_id: ORGANIZATION_ID,
  };

  await axios
    .post(`${APIURL}/customer/send_reset_link_email`, body)
    .then((res) => {
      console.log(res);
      setModalOpen(false);
    })
    .catch((err) => {
      console.log(err);
    });
};

const generateTimestamp = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  const milliseconds = String(now.getMilliseconds()).padStart(3, "0");

  return `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
};

const getTimestamp = (timestamp) => {
  const year = parseInt(timestamp.substring(0, 4), 10);
  const month = parseInt(timestamp.substring(4, 6), 10) - 1; // Months are zero-based
  const day = parseInt(timestamp.substring(6, 8), 10);
  const hour = parseInt(timestamp.substring(8, 10), 10);
  const minute = parseInt(timestamp.substring(10, 12), 10);
  const second = parseInt(timestamp.substring(12, 14), 10);
  const millisecond = parseInt(timestamp.substring(14), 10);

  // Create a Date object using the extracted components
  const dateObject = new Date(
    year,
    month,
    day,
    hour,
    minute,
    second,
    millisecond
  );

  return dateObject;
};

const getValueForView = (data) => {
  if (data) {
    return "Yes";
  } else if (data === undefined) {
    return "";
  }

  return "No";
};

const errorValueCheckFn = (data) => {
  if (data) return false;
  else return undefined;
};

export {
  CustomAutoComplete,
  CustomFormControlLabel,
  CustomRadioGroup,
  CustomTextField,
  phone_style,
  add_phn_styles,
  getStarted,
  trailer,
  err_trailer,
  additional_information,
  err_additional_information,
  trailer_specs,
  boat_policy_details,
  terms,
  eligiblity,
  boat_specs,
  err_boat_specs,
  addons,
  boat,
  boat_policy_details_error,
  trailer_policy_details,
  trailer_policy_details_error,
  formatCurrency,
  FIELD_ERROR_TEXT,
  COMPONENT_ERROR_TEXT,
  ERROR_HELPERTEXT_STYLES,
  PRIMARY_COLOR,
  WARNING_COLOR,
  Province_Options,
  sendCustomerDetailsToRegisteredMail,
  sendResetLinkToRegisteredMail,
  formatPhoneNumber,
  generateTimestamp,
  getTimestamp,
  getValueForView,
  errorValueCheckFn,
  Social_Media_Options,
};
