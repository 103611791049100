import React, { useEffect, useRef, useState } from "react";
import "../../styles/Trailer/Trailer.css";
import Checkbox from "../Checkbox";
import Select from "../Select";
import SplTextField from "../SplTextField";
import TextFieldComponent from "../TextField";
import { motion } from "framer-motion";
import { Radio, FormHelperText } from "@mui/material";
import axios from "axios";
import { APIURL } from "../../api";
import {
  COMPONENT_ERROR_TEXT,
  CustomFormControlLabel,
  CustomRadioGroup,
  ERROR_HELPERTEXT_STYLES,
  FIELD_ERROR_TEXT,
  WARNING_COLOR,
} from "../../utils";
import Autocomplete from "../AutoComplete";
import AddressAutoComplete from "../AddressAutoComplete";
import TextArea from "../TextArea";

const canadaPostalRegex =
  /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ] ?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i;

const Trailer = ({
  animateX,
  value,
  setValue,
  error,
  setError,
  trailerSpecsFormValues,
  setTrailerSpecsFormValues,
  errorTrailerSpecs,
  setErrorTrailerSpecs,
  id,
  wholevalue,
  wholeError,
  formTrailerSpecs,
  errTrailerSpecs,
  setErrTrailerSpecs,
  redBoxObj,
  getStarted,
  trailerNames,
  wholetrailerNames,
  referralFlag,
  organization,
  setErrAddInfo,
  errAddinfo,
  brokerReviewFlag,
}) => {
  const years = [];
  for (
    let year = new Date().getFullYear() + 1;
    year >= new Date().getFullYear() - 35;
    year--
  ) {
    years.push({ value: year, label: year });
  }

  const how_long_owned_options = [
    { value: `Less than 1 Year`, label: `Less than 1 Year` },
  ];

  for (let i = 1; i < 21; i++) {
    if (i === 20) {
      how_long_owned_options.push({ value: `20 years+`, label: `20 years+` });
    } else if (i === 1) {
      how_long_owned_options.push({ value: `1 year`, label: `1 year` });
    } else
      how_long_owned_options.push({ value: `${i} years`, label: `${i} years` });
  }

  const divRef = useRef(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    let whole_err = [...wholeError];
    let updated = [...wholevalue];

    let set_err;
    if (
      value?.is_trailer_parked === "Yes" ||
      value?.type_of_trailer === "Park Model/Destination Trailer"
    ) {
      let park_name,
        parking_address,
        parking_city,
        parking_province,
        parking_site_number,
        parking_postal_code;

      console.log("inside");

      let map_value = wholevalue.map((m, index) => {
        if (index === id - 1) {
          if (m.park_name) {
            park_name = false;
          } else {
            park_name = true;
          }

          if (m.parking_address) {
            parking_address = false;
          } else {
            parking_address = true;
          }

          if (m.parking_city) {
            parking_city = false;
          } else {
            parking_city = true;
          }

          if (m.parking_province) {
            parking_province = false;
          } else {
            parking_province = true;
          }

          if (m.parking_postal_code) {
            parking_postal_code = false;
          } else {
            parking_postal_code = true;
          }

          if (m.parking_site_number) {
            parking_site_number = false;
          } else {
            parking_site_number = true;
          }
        }
      });

      whole_err = whole_err.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            park_name,
            parking_address,
            parking_city,
            parking_province,
            parking_postal_code,
            parking_site_number,
          };
        }

        return m;
      });

      // console.log(updated_error);

      // setError(() => updated_error);
    } else {
      whole_err = whole_err.map((m, index) => {
        if (index === id - 1) {
          for (const field in m) {
            if (field.startsWith("park")) {
              m[field] = false;
            }
          }
        }

        return m;
      });

      // setError(() => updated_error);

      updated = updated.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            park_name: undefined,
            parking_address: undefined,
            parking_city: undefined,
            parking_province: undefined,
            parking_site_number: undefined,
            parking_postal_code: undefined,
          };
        }

        return m;
      });
    }

    if (
      (value?.type_of_trailer === "Park Model/Destination Trailer" &&
        Number(value.trailer_model_year) >= new Date().getFullYear() - 15) ||
      (value?.type_of_trailer === "Travel Trailer/Fifth Wheel Trailer" &&
        Number(value.trailer_model_year) >= new Date().getFullYear() - 10)
    ) {
      let replacement_cost;

      let map = wholevalue.map((m, index) => {
        if (index === id - 1) {
          if (m.replacement_cost) {
            replacement_cost = false;
          } else {
            replacement_cost = true;
          }
        }
      });

      whole_err = whole_err.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            replacement_cost,
          };
        }

        return m;
      });

      // setError(() => updated_error);
    } else {
      whole_err = whole_err.map((m, index) => {
        if (index === id - 1)
          return {
            ...m,
            replacement_cost: false,
          };

        return m;
      });

      // setError(() => updated_error);

      updated = updated.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            replacement_cost: undefined,
          };
        }

        return m;
      });
    }

    console.log(whole_err, "whole_err");
    setValue(updated);

    setError(() => whole_err);
  }, [
    value?.type_of_trailer,
    value?.is_trailer_parked,
    value?.trailer_model_year,
  ]);

  console.log(error, "error");

  useEffect(() => {
    let cart1_val = value?.golf_cart_1 ? Number(value?.golf_cart_1) : 0;
    let cart2_val = value?.golf_cart_2 ? Number(value?.golf_cart_2) : 0;

    if (
      (wholevalue[id - 1]?.insure_golf_cart === "Yes" && cart1_val > 15000) ||
      cart2_val > 15000
    ) {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1)
          return {
            ...m,
            golf_cart_total_value: true,
          };

        return m;
      });
      setError(() => updated_error);
    } else {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1)
          return {
            ...m,
            golf_cart_total_value: false,
          };

        return m;
      });
      setError(() => updated_error);
    }
  }, [value?.golf_cart_1, value?.golf_cart_2]);

  useEffect(() => {
    if (getStarted?.province) {
      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return {
            ...m,
            parking_province: getStarted?.province,
          };
        }

        return m;
      });

      setValue(updated);

      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1)
          return {
            ...m,
            parking_province: false,
          };

        return m;
      });
      setError(() => updated_error);
    }
  }, []);

  const [preferredParksSelected, setPreferredParksSelected] = useState(false);

  useEffect(() => {
    let options = [...parkNameOptions];

    let preferred = options.filter(
      (m) => m.preferred_park_name === value?.park_name
    );

    if (preferred.length) {
      setPreferredParksSelected(true);
    } else {
      setPreferredParksSelected(false);
    }
  }, [value?.park_name]);

  const [parkNameOptions, setParkNameOptions] = useState([]);

  useEffect(() => {
    async function fetch() {
      axios.get(`${APIURL}/preferred_park/get_preferred_parks`).then((res) => {
        console.log(res);

        let array = res?.data?.data
          .filter((mm) => mm.province === getStarted?.province)
          .map((m) => {
            return {
              ...m,
              value: m.name,
              label: m.name,
            };
          });

        console.log(array);

        setParkNameOptions(array);
      });
    }

    fetch();
  }, []);

  console.log(value);

  return (
    <motion.div
      initial={{ x: animateX, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div ref={divRef}>
        <div className={`box ${redBoxObj?.state && "redbox"}`}>
          <div className="trailer_form">
            <header>
              <div className="side_line"></div>
              <div>
                <h3 className="error_h3">
                  Trailer Details{" "}
                  {redBoxObj?.state ? (
                    <p className="component_error_text">
                      {COMPONENT_ERROR_TEXT}
                    </p>
                  ) : (
                    ""
                  )}{" "}
                </h3>{" "}
                <h6 className="trailer_name">
                  {wholetrailerNames.length > 1 && `(${trailerNames?.name})`}
                </h6>
              </div>
            </header>
            <div className="container">
              <h3>
                Recreational or Fifth Wheel Trailer:{" "}
                <span>
                  Moved more frequently using a pick up truck, can also be
                  permanently parked. NOTE: This program is for non-motorized
                  trailers only. Motorhomes cannot be insured through RecProtect
                </span>
              </h3>
              <h3>
                Park Model:
                <span>
                  Permanently situated and wheel structure removed - similar to
                  a small cottage.
                </span>
              </h3>
              <Checkbox
                label={"Select the type of trailer"}
                value={value?.type_of_trailer}
                object={value}
                name={"type_of_trailer"}
                setValue={setValue}
                required={true}
                conditionalRender={true}
                firstoption="Travel Trailer/Fifth Wheel Trailer"
                secondoption={"Park Model/Destination Trailer"}
                error_state={error?.type_of_trailer}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                trailerSpecsFormValues={trailerSpecsFormValues}
                setTrailerSpecsFormValues={setTrailerSpecsFormValues}
                errorTrailerSpecs={errorTrailerSpecs}
                setErrorTrailerSpecs={setErrorTrailerSpecs}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                wholeErrorTrailerSpecs={errTrailerSpecs}
                disabled={referralFlag?.state || brokerReviewFlag}
                errAddinfo={errAddinfo}
                setErrAddInfo={setErrAddInfo}
              />
              <Select
                options={years}
                label="Trailer Model Year"
                value={value?.trailer_model_year}
                name={"trailer_model_year"}
                setValue={setValue}
                object={value}
                error_state={error?.trailer_model_year}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              <div className="checkbox">
                <label>Are you the original owner of the trailer?</label>
                <div className="input_checkbox">
                  <CustomRadioGroup>
                    <CustomFormControlLabel
                      value="Yes"
                      sx={{
                        "&.MuiFormControlLabel-root": {
                          border: error?.are_you_org_owner
                            ? `1px solid ${WARNING_COLOR}`
                            : value?.are_you_org_owner === "Yes"
                            ? "1px solid #8ed1fc"
                            : "1px solid #d9d9d9",
                          "&:hover": {
                            border: "1px solid #8ed1fc",
                          },
                        },
                      }}
                      control={
                        <Radio
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled={referralFlag?.state || brokerReviewFlag}
                          sx={{
                            "&.Mui-checked": {
                              color: "#8ed1fc",
                            },
                            color: "#d9d9d9",
                            ".MuiSvgIcon-root": { height: "1.25rem" },
                          }}
                          onChange={(e) => {
                            let val = e.target.value;

                            let updated_error = wholeError.map((m, index) => {
                              if (index === id - 1) {
                                return {
                                  ...m,
                                  are_you_org_owner: false,
                                };
                              }

                              return m;
                            });
                            setError(updated_error);

                            let updated = wholevalue.map((m, index) => {
                              if (index === id - 1) {
                                return {
                                  ...m,
                                  are_you_org_owner: val,
                                };
                              }

                              return m;
                            });

                            setValue(updated);
                          }}
                          checked={value?.are_you_org_owner === "Yes"}
                        />
                      }
                      label={"Yes"}
                    />
                    <CustomFormControlLabel
                      value={"No"}
                      sx={{
                        "&.MuiFormControlLabel-root": {
                          border: error?.are_you_org_owner
                            ? `1px solid ${WARNING_COLOR}`
                            : value?.are_you_org_owner === "No"
                            ? "1px solid #8ed1fc"
                            : "1px solid #d9d9d9",
                          "&:hover": {
                            border: "1px solid #8ed1fc",
                          },
                        },
                      }}
                      control={
                        <Radio
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled={referralFlag?.state || brokerReviewFlag}
                          sx={{
                            "&.Mui-checked": {
                              color: "#8ed1fc",
                            },
                            color: "#d9d9d9",
                            ".MuiSvgIcon-root": { height: "1.25rem" },
                          }}
                          onChange={(e) => {
                            let val = e.target.value;

                            let updated_error = wholeError.map((m, index) => {
                              if (index === id - 1) {
                                return {
                                  ...m,
                                  are_you_org_owner: false,
                                };
                              }

                              return m;
                            });
                            setError(updated_error);

                            let updated = wholevalue.map((m, index) => {
                              if (index === id - 1) {
                                return {
                                  ...m,
                                  are_you_org_owner: val,
                                };
                              }

                              return m;
                            });

                            setValue(updated);
                          }}
                          checked={value?.are_you_org_owner === "No"}
                        />
                      }
                      label={"No"}
                    />
                  </CustomRadioGroup>
                  {error?.are_you_org_owner && (
                    <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                      {FIELD_ERROR_TEXT}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <Select
                options={how_long_owned_options}
                label={"How long have you owned this trailer?"}
                value={value?.how_long_owned}
                name={"how_long_owned"}
                setValue={setValue}
                object={value}
                error_state={error?.how_long_owned}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              {((value?.type_of_trailer === "Park Model/Destination Trailer" &&
                Number(value.trailer_model_year) >=
                  new Date().getFullYear() - 15) ||
                (value?.type_of_trailer ===
                  "Travel Trailer/Fifth Wheel Trailer" &&
                  Number(value.trailer_model_year) >=
                    new Date().getFullYear() - 10)) && (
                <SplTextField
                  label="Replacement cost"
                  value={value?.replacement_cost}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  name={"replacement_cost"}
                  object={value}
                  error_state={error?.replacement_cost}
                  disabled={referralFlag?.state || brokerReviewFlag}
                  error_text={
                    error?.replacement_cost ? FIELD_ERROR_TEXT : ""
                    // : parseFloat(value?.replacement_cost) < 10000
                    // ? "Your unit's value must be greater than $10,000"
                    // : FIELD_ERROR_TEXT
                  }
                  id={id}
                  isNumber={true}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  helperText={
                    <>
                      <p style={{ marginBottom: "0.7rem" }}>
                        Your unit's value must be at least $10,000, include,
                        decks, adds a rooms, awnings, etc
                      </p>
                      <p>
                        To qualify for replacement cost, you must insure for the
                        Current List Price New of similar unit with upgrades
                        (deck etc.) plus applicable tax.
                      </p>
                    </>
                  }
                />
              )}
              <SplTextField
                label="Actual cash value"
                value={value?.actual_cash_value}
                setValue={setValue}
                error={error}
                setError={setError}
                name={"actual_cash_value"}
                object={value}
                isNumber={true}
                error_state={error?.actual_cash_value}
                disabled={referralFlag?.state || brokerReviewFlag}
                error_text={
                  error?.actual_cash_value ? FIELD_ERROR_TEXT : ""
                  // : parseFloat(value?.replacement_cost) < 10000
                  // ? "Your unit's value must be greater than $10,000"
                  // : FIELD_ERROR_TEXT
                }
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                helperText={
                  <>
                    <p style={{ marginBottom: "0.7rem" }}>
                      Your unit's value must be at least $10,000, include,
                      decks, adds a rooms etc.
                    </p>
                    <p>
                      Actual Cash Value will be the least of fair market value
                      or what you paid for it with upgrades (deck etc.) plus
                      applicable tax. We will pay for a similar unit (year,
                      make, model) to the one you just lost including
                      depreciation.
                    </p>
                  </>
                }
              />
              <Checkbox
                label={
                  "Is your trailer parked within 50 feet of a body of water?"
                }
                firstoption="Yes"
                secondoption="No"
                value={value?.is_trailer_parked_within_500ft}
                name={"is_trailer_parked_within_500ft"}
                setValue={setValue}
                error={error}
                object={value}
                setError={setError}
                error_state={error?.is_trailer_parked_within_500ft}
                error_text={FIELD_ERROR_TEXT}
                trailerSpecsFormValues={trailerSpecsFormValues}
                setTrailerSpecsFormValues={setTrailerSpecsFormValues}
                errorTrailerSpecs={errorTrailerSpecs}
                setErrorTrailerSpecs={setErrorTrailerSpecs}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                wholeErrorTrailerSpecs={errTrailerSpecs}
                disabled={referralFlag?.state || brokerReviewFlag}
                // helperText={
                //   "If your trailer is parked within 500 feet of a body of water you are not eligible for Overland Water Protection coverage."
                // }
              />
              {value?.type_of_trailer ===
                "Travel Trailer/Fifth Wheel Trailer" && (
                <div className="checkbox">
                  <label>
                    Is the trailer permanently parked at an accredited
                    campground?
                  </label>
                  <div className="input_checkbox">
                    <CustomRadioGroup>
                      <CustomFormControlLabel
                        value="Yes"
                        sx={{
                          "&.MuiFormControlLabel-root": {
                            border: error?.is_trailer_parked
                              ? `1px solid ${WARNING_COLOR}`
                              : value?.is_trailer_parked === "Yes"
                              ? "1px solid #8ed1fc"
                              : "1px solid #d9d9d9",
                            "&:hover": {
                              border: "1px solid #8ed1fc",
                            },
                          },
                        }}
                        control={
                          <Radio
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            disabled={referralFlag?.state || brokerReviewFlag}
                            sx={{
                              "&.Mui-checked": {
                                color: "#8ed1fc",
                              },
                              color: "#d9d9d9",
                              ".MuiSvgIcon-root": { height: "1.25rem" },
                            }}
                            onChange={(e) => {
                              let val = e.target.value;

                              let updated_error = wholeError.map((m, index) => {
                                if (index === id - 1) {
                                  return {
                                    ...m,
                                    is_trailer_parked: false,
                                    trailer_taken_into_usa: false,
                                    is_trailer_removed_from_park: true,
                                    trailer_stored: false,
                                    have_deck: true,
                                    have_hard_awning: true,
                                    have_screened_in_room: true,
                                    insure_golf_cart: true,
                                    trailer_removed_address: false,
                                  };
                                }

                                return m;
                              });
                              setError(updated_error);

                              let updated = wholevalue.map((m, index) => {
                                if (index === id - 1) {
                                  return {
                                    ...m,
                                    is_trailer_parked: val,
                                    is_trailer_removed_from_park: "",
                                    have_deck: "",
                                    have_hard_awning: "",
                                    have_screened_in_room: "",
                                    insure_golf_cart: "",
                                  };
                                }

                                return m;
                              });

                              setValue(updated);
                            }}
                            checked={value?.is_trailer_parked === "Yes"}
                          />
                        }
                        label={"Yes"}
                      />
                      <CustomFormControlLabel
                        value={"No"}
                        sx={{
                          "&.MuiFormControlLabel-root": {
                            border: error?.is_trailer_parked
                              ? `1px solid ${WARNING_COLOR}`
                              : value?.is_trailer_parked === "No"
                              ? "1px solid #8ed1fc"
                              : "1px solid #d9d9d9",
                            "&:hover": {
                              border: "1px solid #8ed1fc",
                            },
                          },
                        }}
                        control={
                          <Radio
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            disabled={referralFlag?.state || brokerReviewFlag}
                            sx={{
                              "&.Mui-checked": {
                                color: "#8ed1fc",
                              },
                              color: "#d9d9d9",
                              ".MuiSvgIcon-root": { height: "1.25rem" },
                            }}
                            onChange={(e) => {
                              let val = e.target.value;

                              let updated_error = wholeError.map((m, index) => {
                                if (index === id - 1) {
                                  return {
                                    ...m,
                                    is_trailer_parked: false,
                                    trailer_stored: true,
                                    trailer_taken_into_usa: true,
                                    is_trailer_removed_from_park: false,
                                    trailer_removed_address: false,
                                    have_deck: false,
                                    have_hard_awning: false,
                                    have_screened_in_room: false,
                                    insure_golf_cart: false,
                                    no_of_golf_carts: false,
                                    golf_cart_1: false,
                                    golf_cart_2: false,
                                  };
                                }

                                return m;
                              });
                              setError(updated_error);

                              let updated = wholevalue.map((m, index) => {
                                if (index === id - 1) {
                                  return {
                                    ...m,
                                    is_trailer_parked: val,
                                    trailer_stored: undefined,
                                    have_deck: undefined,
                                    trailer_taken_into_usa: undefined,
                                    is_trailer_removed_from_park: undefined,
                                    trailer_removed_address: undefined,
                                    have_hard_awning: undefined,
                                    have_screened_in_room: undefined,
                                    insure_golf_cart: undefined,
                                    no_of_golf_carts: undefined,
                                    golf_cart_1: undefined,
                                    golf_cart_2: undefined,
                                  };
                                }

                                return m;
                              });

                              setValue(updated);

                              let updated_error_trailer_specs =
                                errTrailerSpecs.map((m, index) => {
                                  if (index === id - 1) {
                                    return {
                                      ...m,
                                      awning_length: false,
                                      awning_width: false,
                                      deck_length: false,
                                      deck_width: false,
                                      florida_room_length: false,
                                      florida_room_width: false,
                                    };
                                  }

                                  return m;
                                });

                              setErrorTrailerSpecs(updated_error_trailer_specs);
                            }}
                            checked={value?.is_trailer_parked === "No"}
                          />
                        }
                        label={"No"}
                      />
                    </CustomRadioGroup>
                    {error?.is_trailer_parked && (
                      <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                        {FIELD_ERROR_TEXT}
                      </FormHelperText>
                    )}
                  </div>
                </div>
              )}
              {value?.is_trailer_parked === "No" && (
                // <AddressAutoComplete
                //   label={"Where is the trailer stored while not in use?"}
                //   value={value?.trailer_stored}
                //   setValue={setValue}
                //   error={error}
                //   setError={setError}
                //   name={"trailer_stored"}
                //   object={value}
                //   error_state={error?.trailer_stored}
                //   error_text={"Please provide address"}
                //   id={id}
                //   wholevalue={wholevalue}
                //   wholeError={wholeError}
                //   disabled={referralFlag?.state || brokerReviewFlag}
                //   POSTGRID_API_KEY={organization?.post_grid_api_key}
                // />
                <TextArea
                  label={"Where is the trailer stored while not in use?"}
                  value={value?.trailer_stored}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  name={"trailer_stored"}
                  object={value}
                  error_state={error?.trailer_stored}
                  error_text={"Please enter the value"}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  placeholder="Please start typing your address"
                  disabled={referralFlag?.state || brokerReviewFlag}
                />
              )}
              {value?.is_trailer_parked === "No" && (
                <Checkbox
                  label={
                    "Is the trailer taken into the USA for more than 180 days?"
                  }
                  firstoption="Yes"
                  secondoption="No"
                  value={value?.trailer_taken_into_usa}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  name={"trailer_taken_into_usa"}
                  object={value}
                  error_state={error?.trailer_taken_into_usa}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />
              )}
              {value?.is_trailer_parked === "Yes" &&
                value?.type_of_trailer ===
                  "Travel Trailer/Fifth Wheel Trailer" && (
                  <Checkbox
                    label={
                      "Is the trailer removed from the park in the off season?"
                    }
                    firstoption="Yes"
                    secondoption="No"
                    value={value?.is_trailer_removed_from_park}
                    setValue={setValue}
                    error={error}
                    setError={setError}
                    name={"is_trailer_removed_from_park"}
                    object={value}
                    error_state={error?.is_trailer_removed_from_park}
                    error_text={FIELD_ERROR_TEXT}
                    id={id}
                    wholevalue={wholevalue}
                    wholeError={wholeError}
                    wholeErrorTrailerSpecs={errTrailerSpecs}
                    disabled={referralFlag?.state || brokerReviewFlag}
                  />
                )}
              {value?.is_trailer_removed_from_park === "Yes" && (
                // <AddressAutoComplete
                //   label={"Please provide address of storage location"}
                //   value={value?.trailer_removed_address}
                //   setValue={setValue}
                //   error={error}
                //   setError={setError}
                //   name={"trailer_removed_address"}
                //   object={value}
                //   error_state={error?.trailer_removed_address}
                //   error_text={FIELD_ERROR_TEXT}
                //   id={id}
                //   wholevalue={wholevalue}
                //   wholeError={wholeError}
                //   disabled={referralFlag?.state || brokerReviewFlag}
                //   POSTGRID_API_KEY={organization?.post_grid_api_key}
                // />

                <TextArea
                  label={"Please provide address of storage location"}
                  value={value?.trailer_removed_address}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  name={"trailer_removed_address"}
                  object={value}
                  error_state={error?.trailer_removed_address}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                  placeholder="Please start typing your address"
                />
              )}
              {(value?.type_of_trailer === "Park Model/Destination Trailer" ||
                value?.is_trailer_parked === "Yes") && (
                <Checkbox
                  label={"Do you have a deck?"}
                  firstoption="Yes"
                  secondoption="No"
                  value={value?.have_deck}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  name={"have_deck"}
                  object={value}
                  error_state={error?.have_deck}
                  error_text={FIELD_ERROR_TEXT}
                  trailerSpecsFormValues={trailerSpecsFormValues}
                  setTrailerSpecsFormValues={setTrailerSpecsFormValues}
                  errorTrailerSpecs={errorTrailerSpecs}
                  setErrorTrailerSpecs={setErrorTrailerSpecs}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  wholeErrorTrailerSpecs={errTrailerSpecs}
                  wholeTrailerSpecs={formTrailerSpecs}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />
              )}{" "}
              {(value?.type_of_trailer === "Park Model/Destination Trailer" ||
                value?.is_trailer_parked === "Yes") && (
                <Checkbox
                  label={"Do you have a screened in room or a Florida room?"}
                  firstoption="Yes"
                  secondoption="No"
                  value={value?.have_screened_in_room}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  name={"have_screened_in_room"}
                  object={value}
                  error_state={error?.have_screened_in_room}
                  error_text={FIELD_ERROR_TEXT}
                  trailerSpecsFormValues={trailerSpecsFormValues}
                  setTrailerSpecsFormValues={setTrailerSpecsFormValues}
                  errorTrailerSpecs={errorTrailerSpecs}
                  setErrorTrailerSpecs={setErrorTrailerSpecs}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  wholeErrorTrailerSpecs={errTrailerSpecs}
                  wholeTrailerSpecs={formTrailerSpecs}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />
              )}
              {(value?.type_of_trailer === "Park Model/Destination Trailer" ||
                value?.is_trailer_parked === "Yes") && (
                <Checkbox
                  label={"Do you have a hard awning?"}
                  firstoption="Yes"
                  secondoption="No"
                  value={value?.have_hard_awning}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  name={"have_hard_awning"}
                  object={value}
                  error_state={error?.have_hard_awning}
                  error_text={FIELD_ERROR_TEXT}
                  trailerSpecsFormValues={trailerSpecsFormValues}
                  setTrailerSpecsFormValues={setTrailerSpecsFormValues}
                  errorTrailerSpecs={errorTrailerSpecs}
                  setErrorTrailerSpecs={setErrorTrailerSpecs}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  wholeErrorTrailerSpecs={errTrailerSpecs}
                  wholeTrailerSpecs={formTrailerSpecs}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />
              )}
              {(value?.type_of_trailer === "Park Model/Destination Trailer" ||
                value?.is_trailer_parked === "Yes") && (
                <div className="checkbox">
                  <label style={{ marginBottom: "0.75rem" }}>
                    Would you like to insure a golf cart?
                  </label>
                  <div className="input_checkbox">
                    <CustomRadioGroup>
                      <CustomFormControlLabel
                        value="Yes"
                        sx={{
                          "&.MuiFormControlLabel-root": {
                            border: error?.insure_golf_cart
                              ? `1px solid ${WARNING_COLOR}`
                              : value?.insure_golf_cart === "Yes"
                              ? "1px solid #8ed1fc"
                              : "1px solid #d9d9d9",
                            "&:hover": {
                              border: "1px solid #8ed1fc",
                            },
                          },
                        }}
                        control={
                          <Radio
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            disabled={referralFlag?.state || brokerReviewFlag}
                            sx={{
                              "&.Mui-checked": {
                                color: "#8ed1fc",
                              },
                              color: "#d9d9d9",
                              ".MuiSvgIcon-root": { height: "1.25rem" },
                            }}
                            onChange={(e) => {
                              let val = e.target.value;

                              let updated_error = wholeError.map((m, index) => {
                                if (index === id - 1) {
                                  return {
                                    ...m,
                                    insure_golf_cart: false,
                                    no_of_golf_carts: true,
                                  };
                                }

                                return m;
                              });
                              setError(updated_error);

                              let updated = wholevalue.map((m, index) => {
                                if (index === id - 1) {
                                  return {
                                    ...m,
                                    insure_golf_cart: val,
                                    no_of_golf_carts: "",
                                  };
                                }

                                return m;
                              });

                              setValue(updated);
                            }}
                            checked={value?.insure_golf_cart === "Yes"}
                          />
                        }
                        label={"Yes"}
                      />
                      <CustomFormControlLabel
                        value={"No"}
                        sx={{
                          "&.MuiFormControlLabel-root": {
                            border: error?.insure_golf_cart
                              ? `1px solid ${WARNING_COLOR}`
                              : value?.insure_golf_cart === "No"
                              ? "1px solid #8ed1fc"
                              : "1px solid #d9d9d9",
                            "&:hover": {
                              border: "1px solid #8ed1fc",
                            },
                          },
                        }}
                        control={
                          <Radio
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            disabled={referralFlag?.state || brokerReviewFlag}
                            sx={{
                              "&.Mui-checked": {
                                color: "#8ed1fc",
                              },
                              color: "#d9d9d9",
                              ".MuiSvgIcon-root": { height: "1.25rem" },
                            }}
                            onChange={(e) => {
                              let val = e.target.value;

                              let updated_error = wholeError.map((m, index) => {
                                if (index === id - 1) {
                                  return {
                                    ...m,
                                    insure_golf_cart: false,
                                    no_of_golf_carts: false,
                                    golf_cart_1: false,
                                    golf_cart_2: false,
                                  };
                                }

                                return m;
                              });
                              setError(updated_error);

                              let updated = wholevalue.map((m, index) => {
                                if (index === id - 1) {
                                  return {
                                    ...m,
                                    insure_golf_cart: val,
                                    no_of_golf_carts: "",
                                  };
                                }

                                return m;
                              });

                              setValue(updated);
                            }}
                            checked={value?.insure_golf_cart === "No"}
                          />
                        }
                        label={"No"}
                      />
                    </CustomRadioGroup>
                    {error?.insure_golf_cart && (
                      <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                        {FIELD_ERROR_TEXT}
                      </FormHelperText>
                    )}
                    <FormHelperText
                      sx={{
                        fontStyle: "italic",
                        fontSize: "0.75rem",
                        color: "#a0aec0",
                        margin: "8px 0",
                        lineHeight: "1rem",
                        letterSpacing: "0.7px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      Golf cart coverage is only available for permanently
                      parked units
                    </FormHelperText>
                  </div>
                </div>
              )}
              {(value?.type_of_trailer === "Park Model/Destination Trailer" ||
                value?.is_trailer_parked === "Yes") &&
                value?.insure_golf_cart === "Yes" && (
                  <Select
                    label={"Number of Golf Carts"}
                    options={[
                      { value: "1", label: "1" },
                      { value: "2", label: "2" },
                    ]}
                    value={value?.no_of_golf_carts}
                    setValue={setValue}
                    error={error}
                    setError={setError}
                    name={"no_of_golf_carts"}
                    object={value}
                    error_state={error?.no_of_golf_carts}
                    error_text={FIELD_ERROR_TEXT}
                    id={id}
                    wholevalue={wholevalue}
                    wholeError={wholeError}
                    disabled={referralFlag?.state || brokerReviewFlag}
                  />
                )}
              {(value?.no_of_golf_carts === "1" ||
                value?.no_of_golf_carts === "2" ||
                value?.no_of_golf_carts === 1 ||
                value?.no_of_golf_carts === 2) &&
                value?.insure_golf_cart === "Yes" && (
                  <SplTextField
                    label={"Value of Golf Cart #1"}
                    value={value?.golf_cart_1}
                    setValue={setValue}
                    error={error}
                    setError={setError}
                    name={"golf_cart_1"}
                    object={value}
                    error_state={error?.golf_cart_1}
                    error_text={FIELD_ERROR_TEXT}
                    id={id}
                    wholevalue={wholevalue}
                    wholeError={wholeError}
                    disabled={referralFlag?.state || brokerReviewFlag}
                    isNumber={true}
                  />
                )}
              {(value?.no_of_golf_carts === "2" ||
                value?.no_of_golf_carts === 2) &&
                value?.insure_golf_cart === "Yes" && (
                  <SplTextField
                    label={"Value of Golf Cart #2"}
                    value={value?.golf_cart_2}
                    setValue={setValue}
                    error={error}
                    setError={setError}
                    name={"golf_cart_2"}
                    object={value}
                    error_state={error?.golf_cart_2}
                    error_text={FIELD_ERROR_TEXT}
                    id={id}
                    wholevalue={wholevalue}
                    wholeError={wholeError}
                    disabled={referralFlag?.state || brokerReviewFlag}
                    isNumber={true}
                  />
                )}
              {error?.golf_cart_total_value && (
                <FormHelperText
                  style={ERROR_HELPERTEXT_STYLES}
                  sx={{ marginLeft: "34%", marginTop: "-2px" }}
                >
                  Each golf cart value should not exceed $15,000
                </FormHelperText>
              )}
            </div>
          </div>
        </div>

        {(value?.is_trailer_parked === "Yes" ||
          value?.type_of_trailer === "Park Model/Destination Trailer") && (
          <div
            className={`box ${redBoxObj?.state && "redbox"}`}
            style={{ marginTop: "2rem" }}
          >
            <div className="trailer_form">
              <header>
                <div className="side_line"></div>
                <h3>Park Details</h3>
              </header>
              <div className="container">
                {/* <div className="textfield">
                  <label htmlFor="autocomplete1">Park Name</label>
                  <div>
                    <CustomAutoComplete
                      disablePortal
                      id="autocomplete1"
                      inputValue={inputParkname}
                      onInputChange={handleParkInput}
                      options={parkNameOptions}
                      filterOptions={filterOptions}
                      value={value?.park_name}
                      onChange={handleParkChange}
                      renderInput={(params) => (
                        <CustomTextField
                          error={error?.park_name}
                          helperText={error?.park_name && FIELD_ERROR_TEXT}
                          {...params}
                        />
                      )}
                    />
                  </div>
                </div> */}

                <Autocomplete
                  label={"Park Name"}
                  options={parkNameOptions}
                  value={value?.park_name}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  name={"park_name"}
                  object={value}
                  error_state={error?.park_name}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />

                {/* <AddressAutoComplete
                  label={"Address"}
                  value={value?.parking_address}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  name={"parking_address"}
                  object={value}
                  error_state={error?.parking_address}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    preferredParksSelected ||
                    referralFlag?.state ||
                    brokerReviewFlag
                  }
                  province={getStarted?.province}
                  POSTGRID_API_KEY={organization?.post_grid_api_key}
                /> */}
                <TextFieldComponent
                  label={"Address"}
                  value={value?.parking_address}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  name={"parking_address"}
                  object={value}
                  error_state={error?.parking_address}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    preferredParksSelected ||
                    referralFlag?.state ||
                    brokerReviewFlag
                  }
                  province={getStarted?.province}
                  POSTGRID_API_KEY={organization?.post_grid_api_key}
                />
                <TextFieldComponent
                  label={"City"}
                  value={value?.parking_city}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  name={"parking_city"}
                  object={value}
                  error_state={error?.parking_city}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={
                    preferredParksSelected ||
                    referralFlag?.state ||
                    brokerReviewFlag
                  }
                />
                <TextFieldComponent
                  label={"Province"}
                  value={value?.parking_province}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  name={"parking_province"}
                  object={value}
                  error_state={error?.parking_province}
                  error_text={FIELD_ERROR_TEXT}
                  disabled={
                    preferredParksSelected ||
                    referralFlag?.state ||
                    brokerReviewFlag
                  }
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                />
                <TextFieldComponent
                  label={"Postal Code"}
                  value={value?.parking_postal_code}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  name={"parking_postal_code"}
                  object={value}
                  error_state={error?.parking_postal_code}
                  // error_text={FIELD_ERROR_TEXT}
                  error_text={
                    value?.parking_postal_code === ""
                      ? FIELD_ERROR_TEXT
                      : !canadaPostalRegex.test(value.parking_postal_code)
                      ? "Please enter valid postal code"
                      : ""
                  }
                  disabled={
                    preferredParksSelected ||
                    referralFlag?.state ||
                    brokerReviewFlag
                  }
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                />
                <TextFieldComponent
                  label={"Site Number"}
                  value={value?.parking_site_number}
                  setValue={setValue}
                  error={error}
                  setError={setError}
                  name={"parking_site_number"}
                  object={value}
                  error_state={error?.parking_site_number}
                  error_text={FIELD_ERROR_TEXT}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default Trailer;
