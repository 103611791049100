import { Button, ConfigProvider } from "antd";
import React from "react";
import { PRIMARY_COLOR } from "../../utils";
import { EditOutlined } from "@ant-design/icons";

const Nav = ({
  header,
  layout,
  onEditChange,
  onSaveChange,
  onCancelChange,
  editText,
}) => {
  return (
    <div className="nav">
      <p>{header}</p>
      {layout === "edit" ? (
        <ConfigProvider
          theme={{
            token: { colorPrimary: PRIMARY_COLOR },
            components: {
              Button: { contentFontSizeSM: 12 },
            },
          }}
        >
          <Button
            type="primary"
            size="small"
            onClick={onEditChange}
            icon={<EditOutlined />}
          >
            {editText ? editText : "Edit"}
          </Button>
        </ConfigProvider>
      ) : (
        <div className="nav_btns">
          <ConfigProvider
            theme={{
              token: { colorPrimary: PRIMARY_COLOR },
              components: {
                Button: { contentFontSizeSM: 12 },
              },
            }}
          >
            <Button onClick={onCancelChange} size="small">
              Cancel
            </Button>
          </ConfigProvider>
          <ConfigProvider
            theme={{
              token: { colorPrimary: PRIMARY_COLOR },
              components: {
                Button: { contentFontSizeSM: 12 },
              },
            }}
          >
            <Button onClick={onSaveChange} size="small" type="primary">
              Save
            </Button>
          </ConfigProvider>
        </div>
      )}
    </div>
  );
};

export default Nav;
