import { FormHelperText } from "@mui/material";
import React from "react";
import {
  ERROR_HELPERTEXT_STYLES,
  PRIMARY_COLOR,
  WARNING_COLOR,
} from "../utils";
import { ConfigProvider, Input } from "antd";

const numberPlusDecimalRegex = /^[\d.]+$/;

const FeetInput = ({
  label,
  value,
  setValue,
  object,
  disabled,
  type,
  name,
  error_state,
  error_text,
  error,
  setError,
  inputEnd,
  wholeError,
  wholevalue,
  id,
  isDecimal,
}) => {
  const handleChange = (e) => {
    if (isDecimal) {
      if (value === undefined && !numberPlusDecimalRegex.test(e.target.value)) {
        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: "",
            };
          }

          return m;
        });

        setValue(updated);
        return;
      }

      if (e.target.value === "" || parseFloat(e.target.value) === 0) {
        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return {
              ...m,
              [name]: e.target.value === "" ? "" : parseFloat(e.target.value),
            };
          }

          return m;
        });

        setValue(updated);

        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);

        return;
      }

      if (numberPlusDecimalRegex.test(e.target.value)) {
        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: parseFloat(e.target.value) };
          }

          return m;
        });

        setValue(updated);
      } else {
        // let updated = wholevalue.map((m, index) => {
        //   if (index === id - 1) {
        //     return { ...m, [name]: "" };
        //   }

        //   return m;
        // });

        // setValue(updated);

        // let updated_error = wholeError.map((m, index) => {
        //   if (index === id - 1) {
        //     return { ...m, [name]: true };
        //   }

        //   return m;
        // });
        // setError(updated_error);

        return;
      }

      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false };
        }

        return m;
      });
      setError(updated_error);

      return;
    }

    if (e.target.value === "") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: true };
        }

        return m;
      });
      setError(updated_error);
    } else {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false };
        }

        return m;
      });
      setError(updated_error);
    }

    let updated = wholevalue.map((m, index) => {
      if (index === id - 1) {
        return { ...m, [name]: e.target.value };
      }

      return m;
    });

    setValue(updated);
  };

  return (
    <div className={`spl_input`}>
      <label style={{ marginBottom: error_state && "0.75rem" }} htmlFor={name}>
        {label}
      </label>
      <div className="input_container">
        {/* <CustomTextField
          id={label}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">{inputEnd}</InputAdornment>
            ),
          }}
          value={value}
          error={error_state}
          onChange={handleChange}
        /> */}

        <ConfigProvider
          theme={{
            components: {
              Input: {
                activeBorderColor: PRIMARY_COLOR,
                hoverBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                errorActiveShadow: "none",
              },
            },
            token: {
              colorErrorOutline: "none",
              colorError: WARNING_COLOR,
              colorErrorBorderHover: "none",
            },
          }}
        >
          <Input
            size="large"
            addonAfter={<p style={{ fontSize: "0.75rem" }}>{inputEnd}</p>}
            id={name}
            value={value}
            status={error_state && "error"}
            onChange={handleChange}
            readOnly={disabled}
          />
        </ConfigProvider>
        {error_state && (
          <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
            {error_text}
          </FormHelperText>
        )}
        {/* {helperText.length &&
          helperText.map((m) => {
            return (
              <FormHelperText sx={{ fontStyle: "italic", fontSize: "13px" }}>
                {m}
              </FormHelperText>
            );
          })} */}
      </div>
    </div>
  );
};

export default FeetInput;
