import { ConfigProvider, Input } from "antd";
import React from "react";
import { PRIMARY_COLOR } from "../../utils";

const TableInput = ({
  type,
  name,
  disabled,
  handleChange,
  value,
  placeholder,
  border,
}) => {
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              activeBorderColor: PRIMARY_COLOR,
              hoverBorderColor: PRIMARY_COLOR,
              activeShadow: "none",
              errorActiveShadow: "none",
            },
          },
          token: {
            colorErrorOutline: "none",
            colorErrorBorderHover: "none",
          },
        }}
      >
        <Input
          type={type ? type : "text"}
          id={name}
          bordered={border}
          className="input"
          readOnly={disabled}
          name={name}
          value={value}
          onChange={(e) => handleChange(e)}
          placeholder={placeholder}
        />
      </ConfigProvider>
    </div>
  );
};

export default TableInput;
