import React, { useEffect, useRef, useState } from "react";
import ReferralPage from "../ReferralPage";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import SplTextField from "../SplTextField";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "../../api";
import TextField from "../TextField";
import FeetInput from "../FeetInput";
import SelectComponent from "../Select";
import { message } from "antd";

const Payment = ({
  referralFlag,
  setReferralFlag,
  boat,
  eligiblity,
  boatSpecs,
  coverage,
  addons,
  paymentId,
  setPaymentId,
  animateX,
  setAnimateX,
  globalData,
  formNumber,
  backPage,
  organization,
  getStartedFormValues,
  setPaymentFormvalues,
  paymentFormValues,
  paymentSuccess,
  setPaymentSuccess,
  responseData,
  defaultData,
  brokerReviewFlag,
  setDefaultData,
}) => {
  const [loading, setLoading] = useState(true);
  const [paymentData, setPaymentData] = useState(null);
  const iframeRef = useRef(null);

  const [paymentModes, setPaymentModes] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    async function fetch() {
      let state = false,
        reason = [];

      for (let i = 0; i < boat.length; i++) {
        let boat_gt_15yrs =
          Number(boat[i]?.boat_model_year) <= new Date().getFullYear() - 25;

        let prl_op_age_lt_20 = dayjs(boat[i]?.date_of_birth).isAfter(
          dayjs().subtract(20, "year")
        );

        let prl_op_age_lt_25_lt_3yrs_exp =
          dayjs(boat[i]?.date_of_birth).isAfter(dayjs().subtract(25, "year")) &&
          boat[i].boating_experience === "0-3 years";

        if (coverage[i]?.coverage > 350000) {
          if (
            responseData !== null &&
            (responseData[i]?.override_for_exceeding_ACV ||
              responseData[i]?.override_for_exceeding_RCV)
          ) {
          } else {
            state = true;
            reason.push("Any boat value exceeding $350,000");
          }
        }
        if (coverage[i]?.coverage < 10000) {
          if (
            responseData !== null &&
            (responseData[i]?.override_for_exceeding_ACV ||
              responseData[i]?.override_for_exceeding_RCV)
          ) {
          } else {
            state = true;
            reason.push("Boat valued below $10,000");
          }
        }
        if (boat_gt_15yrs) {
          if (
            responseData !== null &&
            responseData[i]?.override_boat_age_for_15_or_more
          ) {
          } else {
            state = true;
            reason.push("Boats 25 years or older");
          }
        }
        if (prl_op_age_lt_20) {
          state = true;
          reason.push("Principal operator is under 20 years of age");
        }
        if (prl_op_age_lt_25_lt_3yrs_exp) {
          state = true;
          reason.push(
            "Principal operator under 25 years of age with 0-3 years’ experience"
          );
        }
        if (boat[i]?.operator_hold_pcoc === "No") {
          state = true;
          reason.push(
            "operator hold a Pleasure Craft Operator's Card (PCOC) is No"
          );
        }
        if (boat[i]?.type_of_watercraft === "I'm not too sure") {
          state = true;
          reason.push("“I'm not too sure” is listed as boat type");
        }
        if (Number(eligiblity[i]?.no_of_claims_in_last_five_years) > 1) {
          if (
            responseData !== null &&
            responseData[i]?.override_for_PO_claims_in_last_5_years
          ) {
          } else {
            state = true;
            reason.push(
              "Has the principal operator had any motor vehicle claims in the last 5 years is more than one"
            );
          }
        }
        if (Number(eligiblity[i]?.no_of_claims_in_last_three_years) > 1) {
          if (
            responseData !== null &&
            responseData[i]?.override_for_PO_motor_convictions_in_last_3_years
          ) {
          } else {
            state = true;
            reason.push(
              "Has the principal operator had any motor vehicle convictions in the last 3 years more than one"
            );
          }
        }
        if (eligiblity[i]?.driver_license_suspended === "Yes") {
          if (
            responseData !== null &&
            responseData[i]?.override_for_PO_had_suspended_driver_license
          ) {
          } else {
            state = true;
            reason.push("principal operator driver's license suspended");
          }
        }
        if (boatSpecs[i]?.hull_type === "Other") {
          state = true;
          reason.push("Hull Type is Other");
        }
        if (eligiblity[i]?.watercraft_used_in_atlantic === "Yes") {
          state = true;
          reason.push(
            "The watercraft will be used in Atlantic/Pacific Coastal waters such and the Atlantic, Pacific or Artic Ocean including Hudson's Bay is Yes"
          );
        }
        if (eligiblity[i]?.first_apply_to_watercraft === "Yes") {
          state = true;
          reason.push(
            "The watercraft is chartered or rented to others (bareboat charters)"
          );
        }
        if (eligiblity[i]?.second_apply_to_watercraft === "No") {
          state = true;
          reason.push(
            "The vessel layed up out of the water from November 30 - April 1 every year is No"
          );
        }
        if (eligiblity[i]?.apply_to_applicant === "Yes") {
          state = true;
          reason.push("The applicant/owner employs paid crew is Yes");
        }
        if (Number(boatSpecs[i]?.boat_length) > 40) {
          state = true;
          reason.push("Vessels length exceeding 40 feet");
        }
        if (Number(addons[i]?.tender_length) > 40) {
          state = true;
          reason.push("Tender length exceeding 40 feet");
        }
        if (
          (Number(boatSpecs[i]?.est_max_speed) > 55 ||
            Number(boatSpecs[i]?.main1_boat_engine_horsepower) > 460) &&
          (boatSpecs[i]?.main1_engine_type === "Inboard" ||
            boatSpecs[i]?.main1_engine_type === "Jet drive" ||
            boatSpecs[i]?.main1_engine_type === "In/Outboard")
        ) {
          state = true;
          reason.push(
            "Vessels exceeding 55mph/460HP for an inboard/Jetdrive/Inboard&Outboard main motor 1"
          );
        }
        if (
          (Number(boatSpecs[i]?.est_max_speed) > 55 ||
            Number(boatSpecs[i]?.main1_boat_engine_horsepower) > 250) &&
          boatSpecs[i]?.main1_engine_type === "Outboard"
        ) {
          state = true;
          reason.push("Vessels exceeding 55mph/250HP for an outboard motor 1");
        }

        if (
          (Number(boatSpecs[i]?.est_max_speed) > 55 ||
            Number(boatSpecs[i]?.main2_boat_engine_horsepower) > 460) &&
          (boatSpecs[i]?.main2_engine_type === "Inboard" ||
            boatSpecs[i]?.main2_engine_type === "Jet drive" ||
            boatSpecs[i]?.main2_engine_type === "In/Outboard")
        ) {
          state = true;
          reason.push(
            "Vessels exceeding 55mph/460HP for an inboard/Jetdrive/Inboard&Outboard main motor 2"
          );
        }
        if (
          (Number(boatSpecs[i]?.est_max_speed) > 55 ||
            Number(boatSpecs[i]?.main2_boat_engine_horsepower) > 250) &&
          boatSpecs[i]?.main2_engine_type === "Outboard"
        ) {
          state = true;
          reason.push("Vessels exceeding 55mph/250HP for an outboard motor 2");
        }

        console.log(boatSpecs[i]);

        // if (
        //   coverage[i]?.coverage > 200000 ||
        //   coverage[i]?.coverage < 10000 ||
        //   boat_gt_15yrs ||
        //   prl_op_age_lt_20 ||
        //   prl_op_age_lt_25_lt_3yrs_exp ||
        //   boat[i]?.operator_hold_pcoc === "No" ||
        //   boat[i]?.type_of_watercraft === "I'm not too sure" ||
        //   Number(eligiblity[i]?.no_of_claims_in_last_five_years) > 1 ||
        //   Number(eligiblity[i]?.no_of_claims_in_last_three_years) > 1 ||
        //   eligiblity[i]?.driver_license_suspended === "Yes" ||
        //   boatSpecs[i]?.hull_type === "Other" ||
        //   eligiblity[i]?.watercraft_used_in_atlantic === "Yes" ||
        //   eligiblity[i]?.first_apply_to_watercraft === "Yes" ||
        //   eligiblity[i]?.second_apply_to_watercraft === "No" ||
        //   eligiblity[i]?.apply_to_applicant === "Yes" ||
        //   Number(boatSpecs[i]?.boat_length) > 40 ||
        //   Number(addons[i]?.tender_length) > 40 ||
        //   ((Number(boatSpecs[i]?.est_max_speed) > 55 ||
        //     Number(boatSpecs[i]?.main1_boat_engine_horsepower) > 460) &&
        //     (boatSpecs[i]?.main1_engine_type === "Inboard" ||
        //       boatSpecs[i]?.main1_engine_type === "Jet drive" ||
        //       boatSpecs[i]?.main1_engine_type === "In/Outboard")) ||
        //   ((Number(boatSpecs[i]?.est_max_speed) > 55 ||
        //     Number(boatSpecs[i]?.main1_boat_engine_horsepower) > 250) &&
        //     boatSpecs[i]?.main1_engine_type === "Outboard")
        // ) {
        //   state = true;
        // } else {
        // }
      }

      // console.log(state);

      console.log(reason, "reason");

      if (!state) {
        await axios
          .post(`${APIURL}/boat/get_premium_for_boat_quote`, {
            organization_id: ORGANIZATION_ID,
            boat_quote_record_id: globalData?.quote_record_id,
          })
          .then((res) => {
            console.log(res);
            setReferralFlag({ reached: true, state: false });
            setPaymentData(res.data?.data);
            setPaymentId(1);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (
        (referralFlag.reached === false || referralFlag.reached) &&
        referralFlag.state === false
      ) {
        if (defaultData?.referral_gtag_sent === false) {
          window.gtag("event", "conversion", {
            send_to: "AW-1006867181/8rxWCLeJpqIDEO2ljuAD",
            transaction_id: globalData?.quote_record_id,
          });

          setDefaultData({
            ...defaultData,
            referral_gtag_sent: true,
          });
        }

        setReferralFlag({ reached: true, state: true });

        let customer_email = {
          view_quote_link: `${window.location.origin}/#boat?quote_record_id=${globalData?.quote_record_id}`,
          quote_id: globalData?.quote_id,
          first_name: getStartedFormValues[0]?.insured_first_name,
          email: getStartedFormValues[0]?.email,
          referral_reason: reason,
          organization_id: ORGANIZATION_ID,
        };

        let boat_quote_updates = {
          referral_reason: reason.join(", "),
          quote_status: "Referral",
        };

        let body = {
          boat_quote_record_id: globalData?.quote_record_id,
          boat_quote_updates,
        };

        console.log(body);

        await axios
          .post(`${APIURL}/boat/update_boat_quote`, body)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });

        await axios
          .post(
            `${APIURL}/customer/send_referral_email_to_customer`,
            customer_email
          )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });

        await axios
          .post(`${APIURL}/customer/send_referral_email_to_recprotect`, {
            quote_id: globalData?.quote_id,
            referral_reason: reason,
            organization_id: ORGANIZATION_ID,
          })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    // const modifiedArray = Object.entries(
    //   organization.supported_payment_modes
    // ).map(([key, innerObj]) => ({
    //   key,
    //   ...Object.entries(innerObj).reduce(
    //     (innerAccumulator, [innerKey, innerValue]) => {
    //       // Your logic here
    //       // In this example, let's double each value in the inner object
    //       innerAccumulator[innerKey] = innerValue * 2;
    //       return innerAccumulator;
    //     },
    //     {}
    //   ),
    // }));

    // let paymentOptions = modifiedArray.map((m) => {
    //   return {
    //     ...m,
    //     label: m.key,
    //     value: m.key,
    //   };
    // });

    // setPaymentModes(paymentOptions);

    fetch();
  }, []);

  const handleNext = async () => {
    // if (!paymentFormValues?.autoCharge) {
    //   message.error("AutoCharge should be checked");

    //   return;
    // }

    if (defaultData?.quote_status === "Completed") {
      message.error("Payment already made!!");
      return;
    }

    setBtnLoading(() => true);

    let boat_quote_updates = {
      quote_id: globalData?.quote_id,
      organization_id: ORGANIZATION_ID,
      is_auto_charge_enabled: paymentFormValues?.autoCharge,
    };

    let body = {
      boat_quote_id: globalData.quote_id,
      boat_quote_updates,
    };

    // await axios
    //   .post(`${APIURL}/boat/boat/stripe/create_payment_url`, {
    //     organization_id: ORGANIZATION_ID,
    //     boat_quote_record_id: globalData?.quote_record_id,
    //     payment_method: paymentFormValues?.payment_mode,
    //     amount_payable: {
    //       total_premium: Number(paymentData?.total_premium_bf_tax),
    //       total_tax: Number(paymentData?.total_tax),
    //       admin_fee: Number(paymentData?.admin_fee),
    //     },
    //     unique_id: globalData?.quote_id,
    //     payment_for: "NewBusiness",
    //     payment_type: "Credit To Company",
    //     customer_id: globalData?.customer_id,
    //   })
    //   .then((res) => {
    //     console.log(res);
    //     let data = { ...paymentData };

    //     setPaymentData({ ...data, payment_url: res.data?.data.url });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    await axios
      .post(`${APIURL}/boat/stripe/create_payment_url`, {
        payment_link_for: "NEW_BUSINESS",
        quote_record_id: globalData?.quote_record_id,
        organization_id: ORGANIZATION_ID,
      })
      .then((res) => {
        console.log(res);
        let data = { ...paymentData };

        setPaymentData({ ...data, payment_url: res.data?.data.payment_link });

        window.open(res.data?.data.payment_link, "_parent");
        // window.parent.location.href = res.data?.data.payment_link;
      })
      .catch((err) => {
        console.log(err);
      });

    // await axios
    //   .post(`${APIURL}/boat/update_boat_quote`, body)
    //   .then((res) => {
    //     console.log(res);
    //     setBtnLoading(() => false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setBtnLoading(() => false);
    //   });

    setBtnLoading(() => false);

    setPaymentId(2);
    setAnimateX(50);
    setLoading(true);
  };

  console.log(loading);

  return (
    <motion.div
      initial={{ x: animateX, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div>
        {referralFlag?.state ? (
          <ReferralPage />
        ) : paymentId === 1 ? (
          <div>
            {loading ? (
              <div
                style={{
                  height: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="loader"></div>
              </div>
            ) : (
              <div className="payment">
                <div className={`box`}>
                  <div className="trailer_getstarted">
                    <header>
                      <div className="side_line"></div>
                      <h3>{paymentId === 1 ? "Payment Details" : ""}</h3>
                    </header>
                    <div className="container">
                      <SplTextField
                        label={"Total Premium (before tax)"}
                        disabled
                        value={paymentData?.total_premium_bf_tax}
                      />
                      <TextField
                        label={"Tax Province"}
                        disabled
                        value={paymentData?.tax_province}
                      />
                      <FeetInput
                        label={"Tax Percent"}
                        disabled
                        value={paymentData?.tax_percent}
                        inputEnd={"%"}
                      />
                      <SplTextField
                        label={"Total Tax"}
                        disabled
                        value={paymentData?.total_tax}
                      />
                      <SplTextField
                        label={"Admin Fee"}
                        disabled
                        value={paymentData?.admin_fee}
                      />
                      <SplTextField
                        label={"Total payable premium(after tax)"}
                        disabled
                        value={paymentData?.total_premium_af_tax}
                      />

                      {/* <SelectComponent
                        options={paymentModes}
                        label={"Payment modes"}
                        name={"payment_mode"}
                        object={paymentFormValues}
                        setValue={setPaymentFormvalues}
                        value={paymentFormValues?.payment_mode}
                      /> */}

                      {/* <div className="select">
                        <label htmlFor="select">Enable Auto Charge*</label>
                        <div>
                          <ConfigProvider
                            theme={{
                              token: { colorPrimary: PRIMARY_COLOR },
                            }}
                          >
                            <Checkbox
                              id="select"
                              checked={paymentFormValues?.autoCharge}
                              onChange={(e) => {
                                console.log(e.target.checked);
                                setPaymentFormvalues({
                                  ...paymentFormValues,
                                  autoCharge: e.target.checked,
                                });
                              }}
                            />
                          </ConfigProvider>
                        </div>
                      </div> */}

                      <div className="inner_btns" style={{ marginBottom: "0" }}>
                        <button
                          className={`inner_next_btn ${
                            btnLoading ? "loading" : ""
                          }`}
                          onClick={handleNext}
                          disabled={
                            defaultData?.quote_status !== "Return To Payment" &&
                            (referralFlag?.state ||
                              brokerReviewFlag ||
                              btnLoading)
                          }
                        >
                          Next
                          {btnLoading && <div className="btn_loading"></div>}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            {loading ? (
              <div
                style={{
                  height: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="loader"></div>
              </div>
            ) : (
              ""
            )}
            <div>
              <iframe
                src={paymentData?.payment_url}
                ref={iframeRef}
                frameBorder="0"
                title="Payment"
                className="payment_iframe"
                height={850}
                width={600}
                onLoad={() => {
                  setLoading(false);

                  // if (
                  //   iframeRef?.current?.contentWindow?.location?.pathname ===
                  //   "/payment/success"
                  // ) {
                  //   setPaymentSuccess(true);
                  //   console.log("yes");
                  // }
                }}
                id="iframe"
              ></iframe>
              {paymentSuccess ? (
                <div className="home_btn">
                  <button>Go Home</button>
                </div>
              ) : (
                <div className="inner_btns">
                  <button
                    className="inner_back_btn"
                    style={{ margin: "0 0 1rem 0.5rem" }}
                    onClick={() => {
                      setLoading(false);
                      setPaymentId(1);
                      setAnimateX(-50);
                    }}
                  >
                    Back
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {paymentId !== 2 ? (
        <div className="trailer_btns">
          <div
            className="back_btn"
            onClick={() => {
              setAnimateX(-100);

              backPage(formNumber - 1);
            }}
          >
            Back
          </div>
        </div>
      ) : (
        ""
      )}
    </motion.div>
  );
};

export default Payment;
