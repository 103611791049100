import React from "react";
import "../styles/Referral.css";

const ReferralPage = () => {
  return (
    <div className="referal">
      <div className="ref_first">
        <p>Thank you for your application.</p>
        <p>We have sent it to our Brokers and we will be in touch soon! </p>
      </div>
      {/* <div className="ref_second">
        <p>Please confirm your contact details and click Submit.</p>
        <p>We will contact you shortly.</p>
      </div> */}
    </div>
  );
};

export default ReferralPage;
