import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import FeetInput from "../FeetInput";
import TextField from "../TextField";
import Select from "../Select";
import { FormHelperText } from "@mui/material";
import {
  COMPONENT_ERROR_TEXT,
  ERROR_HELPERTEXT_STYLES,
  FIELD_ERROR_TEXT,
} from "../../utils";

const BoatSpecs = ({
  animateX,
  value,
  setValue,
  error,
  setError,
  id,
  wholevalue,
  wholeError,
  redBoxObj,
  boatForms,
  boatNames,
  wholeBoatNames,
  referralFlag,
  brokerReviewFlag,
}) => {
  const hullTypes = [
    { value: "Fiberglass", label: "Fiberglass" },
    { value: "Aluminum", label: "Aluminum" },
    {
      value: "Polytec",
      label: "Polytec",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];

  const engineTypes = [
    { value: "Inboard", label: "Inboard" },
    { value: "Outboard", label: "Outboard" },
    { value: "In/Outboard", label: "In/Outboard" },
    { value: "Jet drive", label: "Jet drive" },
    { value: "Electrical", label: "Electrical" },
    { value: "Other", label: "Other" },
  ];

  const fuelTypes = [
    { value: "Gas", label: "Gas" },
    { value: "Diesel", label: "Diesel" },
    { value: "Battery", label: "Battery" },
  ];

  const modelYears = [];

  for (
    let i = new Date().getFullYear() + 1;
    i >= new Date().getFullYear() - 30;
    i--
  ) {
    modelYears.push({
      value: i,
      label: i,
    });
  }

  console.log(value, error);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <motion.div
      initial={{ x: animateX, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div>
        <div className={`box ${redBoxObj?.state && "redbox"}`}>
          <div className="boat_form">
            <header>
              <div className="side_line"></div>
              <div>
                <h3 className="error_h3">
                  Boat Specs{" "}
                  {redBoxObj?.state ? (
                    <p className="component_error_text">
                      {COMPONENT_ERROR_TEXT}
                    </p>
                  ) : (
                    ""
                  )}{" "}
                </h3>
                <h6 className="boat_name">
                  {" "}
                  {wholeBoatNames.length > 1 && `(${boatNames?.name})`}
                </h6>
              </div>
            </header>
            <div className="container">
              <FeetInput
                label={"Length"}
                value={value?.boat_length}
                name={"boat_length"}
                setValue={setValue}
                object={value}
                error_state={error?.boat_length}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                inputEnd={"ft."}
                id={id}
                isDecimal={true}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              <TextField
                label={"Hull ID/ Serial #"}
                value={value?.hull_id}
                name={"hull_id"}
                setValue={setValue}
                object={value}
                error_state={error?.hull_id}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
                alphanumeric={true}
              />{" "}
              <Select
                label={"Hull Type"}
                options={hullTypes}
                value={value?.hull_type}
                name={"hull_type"}
                setValue={setValue}
                object={value}
                error_state={error?.hull_type}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              {value?.hull_type === "Other" && (
                <div
                  className={`interior_input ${
                    error?.other_hull_type && "error_field"
                  }`}
                >
                  <label>Enter the type?</label>
                  <input
                    type="text"
                    value={value?.other_hull_type}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        let updated_error = wholeError.map((m, index) => {
                          if (index === id - 1) {
                            return {
                              ...m,
                              other_hull_type: true,
                            };
                          }

                          return m;
                        });
                        setError(updated_error);
                      } else {
                        let updated_error = wholeError.map((m, index) => {
                          if (index === id - 1) {
                            return {
                              ...m,
                              other_hull_type: false,
                            };
                          }

                          return m;
                        });
                        setError(updated_error);
                      }
                      let updated = wholevalue.map((m, index) => {
                        if (index === id - 1) {
                          return {
                            ...m,
                            other_hull_type: e.target.value,
                          };
                        }

                        return m;
                      });

                      setValue(updated);
                    }}
                    disabled={referralFlag?.state || brokerReviewFlag}
                  />
                  {error?.other_hull_type && (
                    <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                      {FIELD_ERROR_TEXT}
                    </FormHelperText>
                  )}
                </div>
              )}
              <FeetInput
                label={"Estimated max. speed"}
                value={value?.est_max_speed}
                name={"est_max_speed"}
                setValue={setValue}
                isDecimal={true}
                object={value}
                error_state={error?.est_max_speed}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                inputEnd={"MPH"}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
            </div>
          </div>
        </div>
        <div
          className={`box ${redBoxObj?.state && "redbox"}`}
          style={{ marginTop: "2rem" }}
        >
          <div className="boat_form">
            <header>
              <div className="side_line"></div>
              <h3>Main Engine 1</h3>
            </header>
            <div className="container">
              <Select
                label={"Type"}
                options={engineTypes}
                value={value?.main1_engine_type}
                name={"main1_engine_type"}
                setValue={setValue}
                object={value}
                error_state={error?.main1_engine_type}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              {value?.main1_engine_type === "Other" && (
                <div
                  className={`interior_input ${
                    error?.main1_other_engine_type && "error_field"
                  }`}
                >
                  <label>Enter the type?</label>
                  <input
                    type="text"
                    value={value?.main1_other_engine_type}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        let updated_error = wholeError.map((m, index) => {
                          if (index === id - 1) {
                            return {
                              ...m,
                              main1_other_engine_type: true,
                            };
                          }

                          return m;
                        });
                        setError(updated_error);
                      } else {
                        let updated_error = wholeError.map((m, index) => {
                          if (index === id - 1) {
                            return {
                              ...m,
                              main1_other_engine_type: false,
                            };
                          }

                          return m;
                        });
                        setError(updated_error);
                      }
                      let updated = wholevalue.map((m, index) => {
                        if (index === id - 1) {
                          return {
                            ...m,
                            main1_other_engine_type: e.target.value,
                          };
                        }

                        return m;
                      });

                      setValue(updated);
                    }}
                    disabled={referralFlag?.state || brokerReviewFlag}
                  />
                  {error?.main1_other_engine_type && (
                    <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                      {FIELD_ERROR_TEXT}
                    </FormHelperText>
                  )}
                </div>
              )}
              <Select
                label={"Fuel Type"}
                options={fuelTypes}
                value={value?.main1_fuel_type}
                name={"main1_fuel_type"}
                setValue={setValue}
                object={value}
                error_state={error?.main1_fuel_type}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              <Select
                label={"Model Year"}
                options={modelYears}
                value={value?.main1_boat_engine_model_year}
                name={"main1_boat_engine_model_year"}
                setValue={setValue}
                object={value}
                error_state={error?.main1_boat_engine_model_year}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              <TextField
                label={"Manufacturer"}
                value={value?.main1_boat_engine_manufacturer}
                name={"main1_boat_engine_manufacturer"}
                setValue={setValue}
                object={value}
                error_state={error?.main1_boat_engine_manufacturer}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />{" "}
              <TextField
                label={"Serial #"}
                value={value?.main1_boat_engine_serail}
                name={"main1_boat_engine_serail"}
                setValue={setValue}
                object={value}
                error_state={error?.main1_boat_engine_serail}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
              />
              <FeetInput
                label={"Horsepower"}
                value={value?.main1_boat_engine_horsepower}
                name={"main1_boat_engine_horsepower"}
                setValue={setValue}
                object={value}
                error_state={error?.main1_boat_engine_horsepower}
                error_text={FIELD_ERROR_TEXT}
                error={error}
                setError={setError}
                inputEnd={"HP"}
                id={id}
                wholevalue={wholevalue}
                wholeError={wholeError}
                disabled={referralFlag?.state || brokerReviewFlag}
                isDecimal={true}
              />
            </div>
          </div>
        </div>{" "}
        {boatForms?.type_of_watercraft === "Cabin Cruiser" && (
          <div
            className={`box ${redBoxObj?.state && "redbox"}`}
            style={{ marginTop: "2rem" }}
          >
            <div className="boat_form">
              <header>
                <div className="side_line"></div>
                <h3>Main Engine 2</h3>
              </header>
              <div className="container">
                <Select
                  label={"Type"}
                  options={engineTypes}
                  value={value?.main2_engine_type}
                  name={"main2_engine_type"}
                  setValue={setValue}
                  object={value}
                  error_state={error?.main2_engine_type}
                  error_text={FIELD_ERROR_TEXT}
                  error={error}
                  setError={setError}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />
                {value?.main2_engine_type === "Other" && (
                  <div
                    className={`interior_input ${
                      error?.main2_other_engine_type && "error_field"
                    }`}
                  >
                    <label>Enter the type?</label>
                    <input
                      type="text"
                      value={value?.main2_other_engine_type}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          let updated_error = wholeError.map((m, index) => {
                            if (index === id - 1) {
                              return {
                                ...m,
                                main2_other_engine_type: true,
                              };
                            }

                            return m;
                          });
                          setError(updated_error);
                        } else {
                          let updated_error = wholeError.map((m, index) => {
                            if (index === id - 1) {
                              return {
                                ...m,
                                main2_other_engine_type: false,
                              };
                            }

                            return m;
                          });
                          setError(updated_error);
                        }
                        let updated = wholevalue.map((m, index) => {
                          if (index === id - 1) {
                            return {
                              ...m,
                              main2_other_engine_type: e.target.value,
                            };
                          }

                          return m;
                        });

                        setValue(updated);
                      }}
                      disabled={referralFlag?.state || brokerReviewFlag}
                    />
                    {error?.main2_other_engine_type && (
                      <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
                        {FIELD_ERROR_TEXT}
                      </FormHelperText>
                    )}
                  </div>
                )}
                <Select
                  label={"Fuel Type"}
                  options={fuelTypes}
                  value={value?.main2_fuel_type}
                  name={"main2_fuel_type"}
                  setValue={setValue}
                  object={value}
                  error_state={error?.main2_fuel_type}
                  error_text={FIELD_ERROR_TEXT}
                  error={error}
                  setError={setError}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />
                <Select
                  label={"Model Year"}
                  options={modelYears}
                  value={value?.main2_boat_engine_model_year}
                  name={"main2_boat_engine_model_year"}
                  setValue={setValue}
                  object={value}
                  error_state={error?.main2_boat_engine_model_year}
                  error_text={FIELD_ERROR_TEXT}
                  error={error}
                  setError={setError}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />
                <TextField
                  label={"Manufacturer"}
                  value={value?.main2_boat_engine_manufacturer}
                  name={"main2_boat_engine_manufacturer"}
                  setValue={setValue}
                  object={value}
                  error_state={error?.main2_boat_engine_manufacturer}
                  error_text={FIELD_ERROR_TEXT}
                  error={error}
                  setError={setError}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />{" "}
                <TextField
                  label={"Serial #"}
                  value={value?.main2_boat_engine_serail}
                  name={"main2_boat_engine_serail"}
                  setValue={setValue}
                  object={value}
                  error_state={error?.main2_boat_engine_serail}
                  error_text={FIELD_ERROR_TEXT}
                  error={error}
                  setError={setError}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                />
                <FeetInput
                  label={"Horsepower"}
                  value={value?.main2_boat_engine_horsepower}
                  name={"main2_boat_engine_horsepower"}
                  setValue={setValue}
                  object={value}
                  error_state={error?.main2_boat_engine_horsepower}
                  error_text={FIELD_ERROR_TEXT}
                  error={error}
                  setError={setError}
                  inputEnd={"HP"}
                  id={id}
                  wholevalue={wholevalue}
                  wholeError={wholeError}
                  disabled={referralFlag?.state || brokerReviewFlag}
                  isDecimal={true}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default BoatSpecs;
