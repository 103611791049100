import React from "react";
import "../styles/ProgressBar.css";

const ProgressBar = ({
  formNumber,
  nextPage,
  trailerCount,
  index,
  getStartedCount,
  getStartedId,
  paymentId,
  getStarted,
  trailer,
  PolicyDetails,
  terms,
  setGetStarted,
  setTrailer,
  setPolicyDetails,
  setTerms,
  referralFlag,
  brokerReviewFlag,
  paymentSuccess,
  setPaymentSuccess,
}) => {
  const handleClick = (page) => {
    return;
    if (paymentSuccess) {
      return;
    }

    if (referralFlag?.state || brokerReviewFlag) {
      nextPage(page);

      return;
    }

    if (page === 5 && referralFlag?.reached) {
      if (referralFlag?.reached) {
        nextPage(page);
      }
    } else if (formNumber < page) {
      // let obj = [];

      let errorArray = [getStarted, trailer, PolicyDetails, terms];
      let setErrArray = [setGetStarted, setTrailer, setPolicyDetails, setTerms];

      for (let i = formNumber; i < page; i++) {
        console.log(i);

        let error = errorArray[i - 1],
          setError = setErrArray[i - 1];

        console.log(error, setErrArray);

        const allValuesUndefined = error.some((obj) => {
          for (const key in obj) {
            if (obj[key] === undefined) {
              return true;
            }
          }
          return false;
        });

        let updatedArray = [...error];

        console.log(allValuesUndefined);
        if (allValuesUndefined) {
          updatedArray = error.map((obj) => {
            const updatedObj = { ...obj };

            for (const key in updatedObj) {
              if (updatedObj[key] === undefined) {
                updatedObj[key] = true;
              }
            }

            return updatedObj;
          });

          for (let i = 0; i < updatedArray.length; i++) {
            const errorItem = updatedArray[i];
            // const objItem = obj[i];

            const hasTrueValue = Object.values(errorItem).some(
              (value) => value === true
            );

            console.log(hasTrueValue);

            // if (hasTrueValue) {
            //   objItem.state = true;
            // } else {
            //   objItem.state = false;
            // }
          }

          // setRedBoxObj(obj);

          // setBtnLoading(() => false);

          setError(updatedArray);

          return;
        }

        const anyValueIsTrue = updatedArray.some((obj) => {
          for (const key in obj) {
            if (obj[key] === true) {
              return true;
            }
          }
          return false;
        });

        console.log(updatedArray);

        if (anyValueIsTrue) {
          for (let i = 0; i < updatedArray.length; i++) {
            const errorItem = updatedArray[i];
            // const objItem = obj[i];

            const hasTrueValue = Object.values(errorItem).some(
              (value) => value === true
            );

            console.log(hasTrueValue);

            // if (hasTrueValue) {
            //   objItem.state = true;
            // } else {
            //   objItem.state = false;
            // }
          }

          // setRedBoxObj(obj);
          // setBtnLoading(() => false);

          // console.log(obj);
          // setRedBox(true);
          return;
        }
      }

      // for (let i = 0; i < updatedArray.length; i++) {
      //   // const objItem = obj[i];

      //   objItem.state = false;
      // }

      // setRedBoxObj(obj);

      // setRedBoxObj(obj);
      nextPage(page);
    } else {
      nextPage(page);
    }

    //else if (Number(page) - Number(formNumber) !== 1) {
    //}
  };

  return (
    <div className="progress">
      <div className="progresss">
        <div className="progress_bar">
          <div
            className="bar"
            onClick={() => handleClick(1, getStarted, setGetStarted)}
          >
            <div
              style={{
                width: `${(getStartedId / getStartedCount.length) * 100}%`,
              }}
              className={formNumber >= 1 ? `bar_line` : "bar_line_unselects"}
            ></div>
            <div
              className={
                formNumber >= 1 && formNumber === 1
                  ? `bar_content_selects`
                  : formNumber >= 1
                  ? "bar_content"
                  : "bar_content_unselects"
              }
            >
              GET STARTED
            </div>
          </div>
          <div
            className="bar"
            style={{ width: "50%" }}
            onClick={() => handleClick(2, trailer, setTrailer)}
          >
            <div
              style={{ width: `${(index / trailerCount.length) * 100}%` }}
              className={formNumber >= 2 ? `bar_line` : "bar_line_unselects"}
            ></div>
            <div
              className={
                formNumber >= 2 && formNumber === 2
                  ? `bar_content_selects`
                  : formNumber >= 2
                  ? "bar_content"
                  : "bar_content_unselects"
              }
            >
              TRAILER
            </div>
          </div>

          <div className="bar" onClick={() => handleClick(3, PolicyDetails)}>
            <div
              className={formNumber >= 3 ? `bar_line` : "bar_line_unselects"}
            ></div>
            <div
              className={
                formNumber >= 3 && formNumber === 3
                  ? `bar_content_selects`
                  : formNumber >= 3
                  ? "bar_content"
                  : "bar_content_unselects"
              }
            >
              POLICY DETAILS
            </div>
          </div>
          <div className="bar" onClick={() => handleClick(4, terms)}>
            <div
              className={formNumber >= 4 ? `bar_line` : "bar_line_unselects"}
            ></div>
            <div
              className={
                formNumber >= 4 && formNumber === 4
                  ? `bar_content_selects`
                  : formNumber >= 4
                  ? "bar_content"
                  : "bar_content_unselects"
              }
            >
              TERMS
            </div>
          </div>
          <div className="bar" onClick={() => handleClick(5)}>
            <div
              style={{
                width: `${(paymentId / 2) * 100}%`,
              }}
              className={formNumber >= 5 ? `bar_line` : "bar_line_unselects"}
            ></div>
            <div
              className={
                formNumber >= 5 && formNumber === 5
                  ? `bar_content_selects`
                  : formNumber >= 5
                  ? "bar_content"
                  : "bar_content_unselects"
              }
            >
              PAYMENT
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
