import { useEffect, useState } from "react";
import "../styles/App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Boat from "../pages/Boat";
import Trailer from "../pages/Trailer";
import Login from "../pages/AdminPanel/Login";
import Admin from "../pages/AdminPanel/Admin";
import Nuvei from "../pages/AdminPanel/Organization/Nuvei";
import Sidebar from "../pages/AdminPanel/Sidebar";
import ZeptoMail from "../pages/AdminPanel/Organization/ZeptoMail";
import BoatRateTable from "../pages/AdminPanel/Boat/BoatRateTable";
import TrailerRateTable from "../pages/AdminPanel/Trailer/TrailerRateTable";
import BoatDeductible from "../pages/AdminPanel/Boat/BoatDeductible";
import { BoatNavEquip } from "../pages/AdminPanel/Boat/BoatNavEquip";
import BoatPersonalEffect from "../pages/AdminPanel/Boat/BoatPersonalEffect";
import BoatGeneral from "../pages/AdminPanel/Boat/BoatGeneral";
import TrailerDeductible from "../pages/AdminPanel/Trailer/TrailerDeductible";
import TrailerGeneral from "../pages/AdminPanel/Trailer/TrailerGeneral";
import GeneralSettings from "../pages/AdminPanel/Organization/GeneralSettings";
import GolfCart from "../pages/AdminPanel/Trailer/GolfCart";
import BoatManufacturer from "../pages/AdminPanel/Boat/BoatManufacturer";
import TrailerManufacturer from "../pages/AdminPanel/Trailer/TrailerManufacturer";
import Parks from "../pages/AdminPanel/Trailer/Parks";
import EditPark from "../pages/AdminPanel/Trailer/EditPark";
import Financier from "../pages/AdminPanel/Organization/Financier";
import EditFinancier from "../pages/AdminPanel/Organization/EditFinancier";
import EditCustomer from "../components/EditCustomer";
import { Button, ConfigProvider, Result } from "antd";
import { PRIMARY_COLOR } from "../utils";
import PaymentSuccessPage from "../pages/PaymentSuccessPage";
import PremiumRetainedTable from "../pages/AdminPanel/Organization/PremiumRetainedTable";
import Tax from "../pages/AdminPanel/Organization/Tax";
import SupportedPayments from "../pages/AdminPanel/Organization/SupportedPayments";
import Recprotect from "../Templates/Recprotect";
import BoatTable from "../pages/AdminPanel/Quotes/BoatTable";
import TrailerTable from "../pages/AdminPanel/Quotes/TrailerTable";
import ViewTrailer from "../pages/AdminPanel/Quotes/ViewTrailer";
import ViewBoat from "../pages/AdminPanel/Quotes/ViewBoat";
import BoatPolicies from "../pages/AdminPanel/Policy/BoatPolicies";
import TrailerPolicies from "../pages/AdminPanel/Policy/TrailerPolicies";
import Register from "../pages/AdminPanel/Register";
import QuoteView from "../pages/View/QuoteView";
import LeadTable from "../pages/AdminPanel/customers/LeadTable";
import ContactTable from "../pages/AdminPanel/customers/ContactTable";
import ViewCustomer from "../pages/AdminPanel/customers/ViewCustomer";
import LoginPage from "../zoho_auth/LoginPage";
import Layout from "../pages/Layout";
import BoatSignature from "../pages/BoatSignature";
import SyncQuote from "../pages/SyncQuote";

function App() {
  const location = useLocation();

  //if admin is present in the url, sidebar should shown
  const shouldDisplaySidebar = location.pathname.includes("admin");

  const navigate = useNavigate();

  const [authState, setAuthState] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  return (
    <div className="App" style={{ display: shouldDisplaySidebar && "flex" }}>
      {shouldDisplaySidebar && authState ? (
        <Sidebar
          authState={authState}
          setAuthState={setAuthState}
          userDetails={userDetails}
          setUserDetails={setUserDetails}
        />
      ) : (
        ""
      )}
      <Routes>
        <Route
          path="/"
          element={
            <StartPage
              authState={authState}
              setAuthState={setAuthState}
              userDetails={userDetails}
              setUserDetails={setUserDetails}
            />
          }
        />
        <Route
          path="/boat"
          element={
            new URLSearchParams(location.search).get("quote_record_id") ? (
              <Recprotect children={<Boat />} type={"Boat"} />
            ) : (
              <Boat />
            )
          }
        />
        <Route
          path="/trailer"
          element={
            new URLSearchParams(location.search).get("quote_record_id") ? (
              <Recprotect children={<Trailer />} type={"Trailer"} />
            ) : (
              <Trailer />
            )
          }
        />
        <Route path="/customer/edit" element={<EditCustomer />} />
        <Route
          path="/signin"
          element={
            // <Login
            //   authState={authState}
            //   setAuthState={setAuthState}
            //   userDetails={userDetails}
            //   setUserDetails={setUserDetails}
            // />
            <Layout
              authState={authState}
              setAuthState={setAuthState}
              userDetails={userDetails}
              setUserDetails={setUserDetails}
            />
          }
        />
        <Route path="/signup" element={<Register />} />
        <Route
          path="/template"
          element={<Recprotect children={<Boat />} type={"Boat"} />}
        />
        <Route path="/payment/success" element={<PaymentSuccessPage />} />
        <Route path="/recprotect/zero_payment" element={<QuoteView />} />
        <Route
          path="/recprotect/boat_signature_update"
          element={<BoatSignature />}
        />
        <Route path="/recprotect/sync_quote" element={<SyncQuote />} />
        {authState ? (
          <>
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/org/nuvei" element={<Nuvei />} />
            <Route path="/admin/org/zeptomail" element={<ZeptoMail />} />{" "}
            <Route path="/admin/org/general" element={<GeneralSettings />} />
            <Route path="/admin/org/financiers" element={<Financier />} />
            <Route
              path="/admin/org/financier/edit"
              element={<EditFinancier />}
            />
            <Route
              path="/admin/org/premium_retained_table"
              element={<PremiumRetainedTable />}
            />{" "}
            <Route path="/admin/org/tax" element={<Tax />} />{" "}
            <Route
              path="/admin/org/supported_payment_modes"
              element={<SupportedPayments />}
            />
            <Route path="/admin/boat/rate_table" element={<BoatRateTable />} />
            <Route
              path="/admin/trailer/liability"
              element={<TrailerRateTable />}
            />
            <Route path="/admin/boat/deductible" element={<BoatDeductible />} />
            <Route path="/admin/boat/nav_equip" element={<BoatNavEquip />} />
            <Route
              path="/admin/boat/personal_effects"
              element={<BoatPersonalEffect />}
            />
            <Route path="/admin/boat/general" element={<BoatGeneral />} />
            <Route
              path="/admin/trailer/deductible"
              element={<TrailerDeductible />}
            />{" "}
            <Route path="/admin/trailer/general" element={<TrailerGeneral />} />
            <Route path="/admin/trailer/golf_cart" element={<GolfCart />} />
            <Route
              path="/admin/boat/manufacturer"
              element={<BoatManufacturer />}
            />
            <Route
              path="/admin/trailer/manufacturer"
              element={<TrailerManufacturer />}
            />
            <Route path="/admin/trailer/parks" element={<Parks />} />
            <Route path="/admin/trailer/park/edit" element={<EditPark />} />
            <Route path="/admin/quotes/boat" element={<BoatTable />} />
            <Route path="/admin/quotes/trailer" element={<TrailerTable />} />
            <Route
              path="/admin/quotes/trailer/:quote_record_id"
              element={<ViewTrailer />}
            />
            <Route
              path="/admin/quotes/boat/:quote_record_id"
              element={<ViewBoat />}
            />{" "}
            <Route path="/admin/policies/boat" element={<BoatPolicies />} />{" "}
            <Route
              path="/admin/policies/trailer"
              element={<TrailerPolicies />}
            />
            <Route path="/admin/customers/lead" element={<LeadTable />} />
            <Route
              path="/admin/customer/:customer_id"
              element={<ViewCustomer />}
            />{" "}
            <Route path="/admin/customers/contact" element={<ContactTable />} />
          </>
        ) : (
          ""
        )}

        <Route
          path="*"
          element={
            <div
              style={{
                minHeight: "100dvh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "100dvw",
              }}
            >
              <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
                extra={
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: PRIMARY_COLOR,
                        // colorPrimaryHover: WARNING_COLOR,
                      },
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={() => {
                        navigate("/signin");
                      }}
                    >
                      Back Home
                    </Button>
                  </ConfigProvider>
                }
              />
            </div>
          }
        />
      </Routes>
    </div>
  );
}

export default App;

const StartPage = ({
  authState,
  setAuthState,
  userDetails,
  setUserDetails,
}) => {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(true);

  // useEffect(() => {
  //   console.log(loginPageVisited);
  //   if (loginPageVisited) {
  //     navigate("/signin");
  //   }
  // }, []);

  useEffect(() => {
    async function auth() {
      await window.catalyst.auth
        .isUserAuthenticated()
        .then((result) => {
          console.log(result);
          setUserDetails({
            firstName: result.content.first_name,
            lastName: result.content.last_name,
            mailid: result.content.email_id,
            timeZone: result.content.time_zone,
            createdTime: result.content.created_time,
          });
          setAuthState(true);
          navigate("/admin");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally((res) => {
          console.log(res);
          setIsFetching(false);
        });
    }

    auth();
  }, []);

  return (
    <>
      {isFetching ? (
        <div className="flex_layout">
          <div className="loader_layout"></div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            minHeight: "100vh",
            gap: "2rem",
          }}
        >
          <button
            className="next_btn"
            onClick={() => {
              navigate("/boat");
            }}
          >
            Boat Insurance
          </button>
          <p>OR</p>
          <button
            className="back_btn"
            onClick={() => {
              navigate("/trailer");
            }}
          >
            Trailer Insurance
          </button>
        </div>
      )}
    </>
  );
};
