import { ConfigProvider, Input, Select, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIURL } from "../../../api";
import { TablePagination } from "../Quotes/BoatTable";

const BoatPolicies = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState({ text: "", type: "" });

  const column = [
    {
      title: "Created time",
      dataIndex: "created_time",
      key: "created_time",
      width: 200,
      render: () => <div>{new Date().toLocaleString()}</div>,
      fixed: "left",
    },
    {
      title: "Modified time",
      dataIndex: "modified_time",
      key: "modified_time",
      width: 200,
      render: () => <div>{new Date().toLocaleString()}</div>,
    },
    {
      title: "Quote status",
      dataIndex: "quote_status",
      key: "quote_status",
      width: 150,
      render: (_, record) => (
        <div
          style={{
            backgroundColor:
              record?.quote_status === "Created"
                ? //yellow
                  "#FFFFCC"
                : record?.quote_status === "In Progress"
                ? // orange
                  "#FCC981"
                : record?.quote_status === "Referral"
                ? //red
                  "#FF7276"
                : record?.quote_status === "Return To Payment"
                ? //pink
                  "#ffe9ec"
                : record?.quote_status === "Payment Pending"
                ? "#EDE3FF"
                : record?.quote_status === "Completed"
                ? //green
                  "#90ee90"
                : "",
          }}
        >
          {record?.quote_status}
        </div>
      ),
    },
    {
      title: "Quote ID",
      dataIndex: "quote_id",
      key: "quote_id",
      width: 150,
    },
    {
      title: "Quote record ID",
      dataIndex: "quote_record_id",
      key: "quote_record_id",
      width: 260,
    },
    {
      title: "Policy number",
      dataIndex: "policy_number",
      key: "policy_number",
      width: 150,
    },
    {
      title: "Policy status",
      dataIndex: "policy_status",
      key: "policy_status",
      width: 200,
      render: (_, record) => (
        <div
          style={{
            backgroundColor:
              record?.policy_status === "ACTIVE"
                ? "#90ee90"
                : record?.policy_status === "INACTIVE - RENEWED"
                ? "#FFFFCC"
                : record?.policy_status === "INACTIVE - EXPIRED"
                ? "#ffe9ec"
                : record?.policy_status === "INACTIVE - FUTURE"
                ? "#FCC981"
                : record?.policy_status === "INACTIVE - CANCELLED"
                ? "#90ee90"
                : record?.policy_status === "INACTIVE - REVEWAL PENDING"
                ? "#EDE3FF"
                : record?.policy_status === "INACTIVE - RENEWAL CANCELLED"
                ? "#f9e8d8"
                : "",
          }}
        >
          {record?.policy_status}
        </div>
      ),
    },
    {
      title: "Deal type",
      dataIndex: "deal_type",
      key: "deal_type",
      width: 150,
      render: (_, record) => (
        <div
          style={{
            backgroundColor:
              record?.deal_type === "Quote"
                ? "#FCC981"
                : record?.deal_type === "Policy"
                ? "#90ee90"
                : "",
          }}
        >
          {record?.deal_type}
        </div>
      ),
    },
    {
      title: "Total premium after tax",
      dataIndex: "total_premium_after_tax",
      key: "total_premium_after_tax",
      width: 210,
    },
    {
      title: "Auto renewal status",
      dataIndex: "auto_renewal_status",
      key: "value",
      width: 200,
    },
    {
      title: "Created Source",
      dataIndex: "created_source",
      key: "created_source",
      width: 150,
    },
    {
      title: "Modified Source",
      dataIndex: "modified_source",
      key: "modified_sourcevalue",
      width: 150,
    },
    {
      title: "Quote access url",
      dataIndex: "quote_access_url",
      key: "quote_access_url",
      width: 200,
      ellipsis: true,
    },
    {
      title: "Customer ID",
      dataIndex: "customer_id",
      key: "customer_id",
      width: 150,
    },
    {
      title: "No of Boats",
      dataIndex: "number_of_boats",
      key: "number_of_boats",
      width: 150,
    },
    {
      title: "Inception date",
      dataIndex: "inception_date",
      key: "inception_date",
      width: 150,
    },
    {
      title: "Expiry date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      width: 150,
    },
  ];

  async function fetch(page) {
    setLoading(() => true);

    await axios
      .get(`${APIURL}/boat/policies?page=${page}`)
      .then((res) => {
        console.log(res);
        setData(res?.data?.data?.quote_list);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetch(1);
  }, []);

  const handleSearch = (value) => {};

  return (
    <div className="layout">
      <div className="nav">
        <p>Boat Policies</p>
        <div>
          <Select
            style={{ minWidth: "200px", marginRight: "1rem" }}
            options={[
              { label: "Policy number", value: "policy_number" },
              { label: "Quote id", value: "quote_id" },
              { label: "Quote record id", value: "quote_record_id" },
            ]}
            value={searchData.type}
            onChange={(val) => {
              setSearchData({ ...searchData, type: val });
            }}
          />
          <Input.Search
            enterButton
            style={{ maxWidth: "200px" }}
            value={searchData.text}
            onChange={(e) => {
              setSearchData({ ...searchData, text: e.target.value });
            }}
            onSearch={handleSearch}
          />
        </div>
      </div>

      <div className="layout_main">
        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellPaddingBlock: 8,
                cellPaddingInline: 8,
                // headerBg: "#d7d7d7",
              },
            },
            // token: { colorBgContainer: "red" },
          }}
        >
          <Table
            columns={column}
            virtual
            loading={loading}
            // dataSource={[
            //   {
            //     quote_record_id: "Q_65d586803b34bd54fb31ca6d",
            //     quote_status: "Created",
            //     policy_status: "ACTIVE",
            //   },
            //   {
            //     quote_record_id: "Q_65d7858469f75f7996c4e222",
            //     quote_status: "Created",
            //   },
            // ]}
            dataSource={data}
            onRow={(record) => ({
              onClick: () => {
                navigate(`/admin/quotes/boat/${record.quote_record_id}`);
              },
            })}
            scroll={{ y: 630 }}
            pagination={false}
          />
        </ConfigProvider>
        <TablePagination
          page={page}
          action={fetch}
          data={data}
          setPage={setPage}
        />
      </div>
    </div>
  );
};

export default BoatPolicies;
