import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "../../../api";
import Nav from "../../../components/AdminPanel/Nav";
import APTextField from "../../../components/AdminPanel/APTextField";
import { PercentageOutlined } from "@ant-design/icons";

const GeneralSettings = () => {
  const [loading, setLoading] = useState(true);
  const [editState, setEditState] = useState(false);
  const [cancelState, setCancelState] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    async function fetch() {
      await axios
        .post(`${APIURL}/organization/get_organization`, {
          organization_id: ORGANIZATION_ID,
          required_keys: [
            "init_quote",
            "init_policy",
            "quote_number_tracker",
            "post_grid_api_key",
            "organization_id",
            "name",
            "email",
            "admin_fee_percent",
            "recprotect_referral_team_email",
            "recprotect_referral_team_name",
            "sales_email",
            "webapp_base_url",
            "webapp_backend_base_url",
          ],
        })
        .then((res) => {
          if (!isCancelled) {
            console.log(res);
            let response = res?.data?.data?.organization;
            response.total_quotes =
              res?.data?.data?.organization?.quote_number_tracker?.total_quotes;
            response.last_quote_number =
              res?.data?.data?.organization?.quote_number_tracker?.last_quote_number;

            console.log(response);
            setData(response);
            setLoading(() => false);
            setCancelState(() => false);
            setEditState(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetch();

    return () => {
      isCancelled = true;
    };
  }, [cancelState]);

  const handleEdit = async () => {
    setLoading(() => true);

    await new Promise((resolve) => setTimeout(resolve, 1500));

    setLoading(() => false);
    setEditState(true);
  };

  const handleCancel = async () => {
    setLoading(() => true);

    setCancelState(true);
  };

  const handleSave = async () => {
    let body = {
      organization_id: ORGANIZATION_ID,
      organization_updates: {
        init_quote: data?.init_quote,
        init_policy: data?.init_policy,
        quote_number_tracker: {
          total_quotes: data?.total_quotes,
          last_quote_number: data?.last_quote_number,
        },
        post_grid_api_key: data?.post_grid_api_key,
        email: data?.email,
        admin_fee_percent: parseFloat(data?.admin_fee_percent),
        recprotect_referral_team_email: data?.recprotect_referral_team_email,
        recprotect_referral_team_name: data?.recprotect_referral_team_name,
        sales_email: data?.sales_email,
        webapp_base_url: data?.webapp_base_url,
        webapp_backend_base_url: data?.webapp_backend_base_url,
      },
    };

    console.log(body);

    setLoading(() => true);

    await axios
      .post(`${APIURL}/organization/update_organization`, body)
      .then((res) => {
        console.log(res);

        setCancelState(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(name);

    setData({ ...data, [name]: value });
  };

  return (
    <div className="layout">
      <Nav
        header={editState ? "Edit General Settings" : "View General Settings"}
        layout={editState ? "save" : "edit"}
        onEditChange={handleEdit}
        onSaveChange={handleSave}
        onCancelChange={handleCancel}
      />
      <div className="layout_container">
        {loading ? (
          <div className="layout_loading">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="layout_main">
            <APTextField
              disabled={true}
              label="Organization ID"
              value={data?.organization_id}
            />{" "}
            <APTextField
              disabled={true}
              name={"name"}
              label="Organization Name"
              value={data?.name}
            />
            <APTextField
              disabled={!editState && true}
              name={"email"}
              label="Organization Email"
              value={data?.email}
              handleChange={handleChange}
            />
            <APTextField
              disabled={!editState && true}
              name={"init_quote"}
              label="Initial Quote"
              value={data?.init_quote}
              handleChange={handleChange}
            />
            <APTextField
              name={"init_policy"}
              label="Initial Policy"
              value={data?.init_policy}
              handleChange={handleChange}
              disabled={!editState && true}
            />
            <APTextField
              disabled={!editState && true}
              name={"total_quotes"}
              label="Total Quotes"
              value={data?.total_quotes}
              handleChange={handleChange}
            />
            <APTextField
              disabled={!editState && true}
              name={"last_quote_number"}
              label="Last Quote Number"
              value={data?.last_quote_number}
              handleChange={handleChange}
            />
            <APTextField
              disabled={!editState && true}
              name={"post_grid_api_key"}
              label="Post Grid Api Key"
              value={data?.post_grid_api_key}
              handleChange={handleChange}
              type={editState ? "text" : "password"}
            />
            <APTextField
              disabled={!editState && true}
              name={"admin_fee_percent"}
              label="Admin fee %"
              value={data?.admin_fee_percent}
              handleChange={handleChange}
              addonAfter={<PercentageOutlined />}
            />{" "}
            <APTextField
              disabled={!editState && true}
              name={"recprotect_referral_team_email"}
              label="RecP referral team email"
              value={data?.recprotect_referral_team_email}
              handleChange={handleChange}
            />{" "}
            <APTextField
              disabled={!editState && true}
              name={"recprotect_referral_team_name"}
              label="RecP referral team name"
              value={data?.recprotect_referral_team_name}
              handleChange={handleChange}
            />{" "}
            <APTextField
              disabled={!editState && true}
              name={"sales_email"}
              label="Sales email"
              value={data?.sales_email}
              handleChange={handleChange}
            />{" "}
            <APTextField
              disabled={!editState && true}
              name={"webapp_base_url"}
              label="Frontend base url"
              value={data?.webapp_base_url}
              handleChange={handleChange}
            />{" "}
            <APTextField
              disabled={!editState && true}
              name={"webapp_backend_base_url"}
              label="Backend base url"
              value={data?.webapp_backend_base_url}
              handleChange={handleChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralSettings;
