import { ConfigProvider, Input } from "antd";
import React from "react";
import {
  ERROR_HELPERTEXT_STYLES,
  PRIMARY_COLOR,
  WARNING_COLOR,
} from "../utils";
import { FormHelperText } from "@mui/material";

const TextArea = ({
  label,
  value,
  setValue,
  object,
  disabled,
  type,
  name,
  error_state,
  error_text,
  error,
  setError,
  wholeError,
  wholevalue,
  id,
  policy_details,
  policy_details_error,
  setPolicyDetailFormValues,
  setErrorPolicyDetails,
  placeholder,
  helperText,
  isDecimal,
  vin,
  alphanumeric,
}) => {
  const handleChange = (e) => {
    if (e.target.value === "") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: true };
        }

        return m;
      });
      setError(updated_error);
    } else {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false };
        }

        return m;
      });
      setError(updated_error);
    }

    let updated = wholevalue.map((m, index) => {
      if (index === id - 1) {
        return { ...m, [name]: e.target.value };
      }

      return m;
    });

    setValue(updated);
  };

  return (
    <div
      className={`textfield ${error_state && "error_textfield"}`}
      style={{
        alignItems: helperText && "flex-start",
      }}
    >
      <label
        htmlFor={name}
        style={{ marginBottom: (helperText || error_state) && "10px" }}
      >
        {label}
      </label>
      <div>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                activeBorderColor: PRIMARY_COLOR,
                hoverBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                errorActiveShadow: "none",
              },
            },
            token: {
              colorErrorOutline: "none",
              colorError: WARNING_COLOR,
              colorErrorBorderHover: "none",
            },
          }}
        >
          <Input.TextArea
            type={type ? type : "text"}
            id={name}
            size="large"
            className="input"
            readOnly={disabled}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            maxLength={vin && 17}
            status={error_state ? "error" : ""}
          />
        </ConfigProvider>
        {helperText ? (
          <FormHelperText
            sx={{
              fontStyle: "italic",
              fontSize: "0.75rem",
              color: "#a0aec0",
              margin: "8px 0",
              lineHeight: "1rem",
              letterSpacing: "0.7px",
              fontFamily: "Montserrat",
            }}
          >
            {helperText}
          </FormHelperText>
        ) : (
          ""
        )}
        {error_state && (
          <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
            {error_text}
          </FormHelperText>
        )}
      </div>
    </div>
  );
};

export default TextArea;
