import React, { useEffect, useState } from "react";
import GetStarted from "../components/Trailer/GetStarted";
import ProgressBar from "../components/ProgressBar";
import PolicyDetails from "../components/Trailer/PolicyDetails";
import Terms from "../components/Trailer/Terms";
import TrailerMain from "./TrailerMain";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "../api";
import {
  additional_information,
  err_additional_information,
  err_trailer,
  errorValueCheckFn,
  getStarted,
  terms,
  trailer,
  trailer_policy_details,
  trailer_policy_details_error,
  trailer_specs,
} from "../utils";
import Payment from "../components/Trailer/Payment";
import { Footer } from "../components/Footer";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import ServerError from "./ServerError";
import { Result } from "antd";

const coverage = {
  premises_liability: "2,000,000",
  deductible_ac: 1000,
  deductible_rc: 1000,
  coverage_name: undefined,
  overrided_preferred_park_discount: false,
};

const coverage_err = {
  premises_liability: false,
  deductible_ac: false,
  deductible_rc: false,
  coverage_name: undefined,
};

const Trailer = () => {
  const location = useLocation();
  const [formNumber, setFormNumber] = useState(1);
  const [animateX, setAnimateX] = useState(0);

  const [getStartedFormValues, setGetStartedFormValues] = useState([
    getStarted,
  ]);
  const [errorGetStarted, setErrorGetStarted] = useState([getStarted]);

  const [additionalNames, setAdditionalNames] = useState([
    {
      id: 1,
      add_first_name: "",
      add_last_name: "",
      add_email: "",
      add_phone: "",
      add_dob: "",
    },
  ]);
  const [errorAdditionalNames, setErrorAdditionalNames] = useState(false);

  console.log(errorAdditionalNames, "adddddd");

  const [isAddNamesSent, setIsAddNamesSent] = useState(false);

  const [trailerFormValues, setTrailerFormValues] = useState([trailer]);
  const [errorTrailer, setErrorTrailer] = useState([err_trailer]);

  const [coverageFormValues, setCoverageFormValues] = useState([coverage]);
  const [errorCoverage, setErrorCoverage] = useState([coverage_err]);

  const [additionalInfoFormValues, setAdditionalInfoFormValues] = useState([
    additional_information,
  ]);
  const [errorAdditionalInfo, setErrorAdditionalInfo] = useState([
    err_additional_information,
  ]);

  const [trailerSpecsFormValues, setTrailerSpecsFormValues] = useState([
    trailer_specs,
  ]);
  const [errorTrailerSpecs, setErrorTrailerSpecs] = useState([trailer_specs]);

  const [policyDetailFormValues, setPolicyDetailFormValues] = useState([
    trailer_policy_details,
  ]);
  const [errorPolicyDetails, setErrorPolicyDetails] = useState([
    trailer_policy_details_error,
  ]);

  const [termsValues, setTermsValues] = useState([terms]);
  const [errorTerms, setErrorTerms] = useState([terms]);

  const nextPage = (page) => {
    setFormNumber(page);
  };

  const backPage = (page) => {
    setFormNumber(page);
  };

  const [addTrailerCount, setAddTrailerCount] = useState([
    { id: 1, name: "trailer 1" },
  ]);

  const number_of_trailer = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
  ];

  const addTrailer = (val) => {
    let array = [...addTrailerCount];
    let redTrailer = [...trailerRedBoxObj];
    let redQuote = [...quotesRedBoxObj];
    let redAddInfo = [...addInfoRedBoxObj];
    let redTrailerSpecs = [...trailerSpecsRedBoxObj];

    console.log(array.length - val);

    if (array.length > val) {
      for (let i = array.length; i > val; i--) {
        array.pop();
        redTrailer.pop();
        redQuote.pop();
        redAddInfo.pop();
        redTrailerSpecs.pop();
      }
    } else {
      for (let i = array.length + 1; i <= val; i++) {
        array.push({ id: i, name: `trailer ${i}` });
        redTrailer.push({ id: i, state: false });
        redQuote.push({ id: i, state: false });
        redAddInfo.push({ id: i, state: false });
        redTrailerSpecs.push({ id: i, state: false });
      }
    }

    console.log(array);
    setAddTrailerCount(array);
    setTrailerRedBoxObj(redTrailer);
    setQuoteRedBoxObj(redQuote);
    setAddInfoRedBoxObj(redAddInfo);
    setTrailerSpecsRedBoxObj(redTrailerSpecs);

    setBoatNumber(val);
  };

  const [boatNumber, setBoatNumber] = useState(1);

  const [trailerRedBoxObj, setTrailerRedBoxObj] = useState([
    { id: 1, state: false },
  ]);
  const [quotesRedBoxObj, setQuoteRedBoxObj] = useState([
    { id: 1, state: false },
  ]);
  const [addInfoRedBoxObj, setAddInfoRedBoxObj] = useState([
    { id: 1, state: false },
  ]);
  const [trailerSpecsRedBoxObj, setTrailerSpecsRedBoxObj] = useState([
    { id: 1, state: false },
  ]);

  const [trailerNames, setTrailerNames] = useState([{ name: undefined }]);
  const [errorTrailerNames, setErrorTrailerNames] = useState([
    { name: undefined },
  ]);

  const [paymentFormValues, setPaymentFormvalues] = useState({
    autoCharge: false,
    payment_mode: "",
  });

  const [trailerNumberId, setTrailerNumberId] = useState(1);
  const [internalTrailerState, setInternalTrailerState] = useState(1);

  const [getStartedCount, setGetStartedCount] = useState([1, 2]);
  const [getStartedId, setGetStartedId] = useState(1);
  const [paymentId, setPaymentId] = useState(1);

  const [signatureObj, setSignatureObj] = useState({ text: "", font: "" });
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const [globalData, setGlobalData] = useState({
    quote_id: "",
    customer_id: "",
    quote_record_id: "",
  });

  const [organization, setOrganization] = useState(null);

  //saves the response for existing quote
  const [responseData, setResponseData] = useState(null);
  const [defaultData, setDefaultData] = useState({
    quote_status: "",
    coverage_gtag_sent: false,
    referral_gtag_sent: false,
  });

  function ReturnFalseObj(arrayOfObjects) {
    const newArray = arrayOfObjects.map((obj) => {
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        newObj[key] = false;
      });
      return newObj;
    });

    return newArray;
  }

  useEffect(() => {
    async function fun() {
      await axios
        .post(`${APIURL}/organization/get_organization`, {
          organization_id: ORGANIZATION_ID,
          required_keys: [
            "organization_id",
            "name",
            "email",
            "webhook_url",
            "post_grid_api_key",
            "supported_payment_modes",
            "sales_email",
          ],
        })
        .then((res) => {
          console.log(res);

          setOrganization(res?.data?.data?.organization);
        })
        .catch((err) => {
          console.log(err);
        });

      let sessionQuoteRecordId =
        window.sessionStorage.getItem("trailer_record_id");

      let sessionQuoteId = window.sessionStorage.getItem("trailer_quote_id");
      let data = { ...globalData };

      const searchParams = new URLSearchParams(location.search);

      let quote_record_id = searchParams.get("quote_record_id");

      if (sessionQuoteRecordId || quote_record_id) {
        await axios
          .get(
            `${APIURL}/trailer/get_trailer_quote?quote_record_id=${
              quote_record_id
                ? quote_record_id
                : sessionQuoteRecordId
                ? sessionQuoteRecordId
                : ""
            }`
          )
          .then(async (res) => {
            console.log(res);

            if (res?.data?.success === false) {
              if (
                res.data?.message ===
                `TrailerQuote Not Found with given id ${quote_record_id}`
              ) {
                setLoading("id_not_found");
                return;
              }

              let data = { ...globalData };

              data.quote_id = res?.data?.data?.quote?.quote_id
                ? res?.data?.data?.quote?.quote_id
                : sessionQuoteId
                ? sessionQuoteId
                : "";
              data.quote_record_id = quote_record_id
                ? quote_record_id
                : sessionQuoteRecordId
                ? sessionQuoteRecordId
                : "";

              console.log(data, "data");
              setGlobalData(data);
              setLoading("success");

              if (!data.quote_id || !data.quote_record_id) {
                setLoading("error");
              }

              return;
            }

            let trailer_obj = [],
              coverage_obj = [],
              addInfo_obj = [],
              trailerSpecs_obj = [],
              error_trailer = [],
              error_coverage = [],
              error_addInfo = [],
              error_trailerSpecs = [],
              customer = [{}],
              error_customer = [{}],
              policy_details = [{}],
              error_policy_details = [{}],
              terms = [{}],
              error_terms = [{}],
              payment = {},
              additional_names = [];

            let trailer = res?.data?.data?.quote?.trailers;
            let customer_data = res?.data?.data?.customer;
            let add_names_data = res?.data?.data?.additional_names;

            if (trailer.length) {
              setResponseData(trailer);
            }

            data.quote_id = res?.data?.data?.quote?.quote_id
              ? res?.data?.data?.quote?.quote_id
              : sessionQuoteId
              ? sessionQuoteId
              : "";
            data.quote_record_id = quote_record_id
              ? quote_record_id
              : sessionQuoteRecordId
              ? sessionQuoteRecordId
              : "";

            if (!data.quote_id || !data.quote_record_id) {
              setLoading("error");
            }

            data.customer_id = customer_data?.customer_id;

            console.log(customer_data, "customer");

            customer[0].date_of_birth = customer_data?.dob
              ? dayjs(customer_data?.dob, "DD/MM/YYYY")
              : undefined;
            customer[0].email = customer_data?.email;
            customer[0].insured_first_name = customer_data?.first_name;
            customer[0].insured_last_name = customer_data?.last_name;
            customer[0].phone = customer_data?.phone;
            customer[0].find_us = customer_data?.where_you_find_us;
            customer[0].additional_name = res?.data?.data?.quote
              ?.any_additional_names
              ? "Yes"
              : res?.data?.data?.quote?.any_additional_names === false
              ? "No"
              : undefined;

            customer[0].province = res?.data?.data?.quote?.located_province;

            customer[0].find_us_value =
              customer_data?.where_you_find_us === "Social Media"
                ? customer_data?.social_media_option_name
                : customer_data?.where_you_find_us === "Friend or Family"
                ? customer_data?.friend_option_name
                : customer_data?.where_you_find_us === "Dealership"
                ? customer_data?.dealership_option_name
                : customer_data?.where_you_find_us === "Campground"
                ? customer_data?.campground_option_name
                : customer_data?.where_you_find_us === "Marina"
                ? customer_data?.marina_option_name
                : customer_data?.where_you_find_us === "Other"
                ? customer_data?.others_option_name
                : undefined;

            setGlobalData(data);
            setGetStartedFormValues(customer);
            error_customer[0].insured_first_name = customer_data?.first_name
              ? false
              : undefined;
            error_customer[0].insured_last_name = customer_data?.last_name
              ? false
              : undefined;
            error_customer[0].email = customer_data?.email ? false : undefined;
            error_customer[0].date_of_birth = customer_data?.dob
              ? false
              : undefined;
            error_customer[0].phone = customer_data?.phone ? false : undefined;
            error_customer[0].find_us = customer_data?.where_you_find_us
              ? false
              : undefined;
            error_customer[0].find_us_value =
              customer_data?.where_you_find_us === "Google"
                ? false
                : !customer[0].find_us_value
                ? undefined
                : false;

            error_customer[0].province = res?.data?.data?.quote
              ?.located_province
              ? false
              : undefined;
            error_customer[0].additional_name =
              res?.data?.data?.quote?.any_additional_names ||
              res?.data?.data?.quote?.any_additional_names === false
                ? false
                : undefined;

            // error_customer = ReturnFalseObj(errorGetStarted);

            setErrorGetStarted(error_customer);

            if (add_names_data.length) {
              for (let i = 0; i < add_names_data.length; i++) {
                additional_names.push({
                  id: i + 1,
                  add_first_name: add_names_data[i].first_name,
                  add_last_name: add_names_data[i].last_name,
                  add_email: add_names_data[i].email,
                  add_phone: add_names_data[i].phone,
                  add_dob: dayjs(add_names_data[i].dob, "DD/MM/YYYY"),
                });
              }

              console.log(additional_names);

              setAdditionalNames(additional_names);

              setIsAddNamesSent(true);
            }

            let trailer_name_obj = [];

            if (trailer.length) {
              for (let i = 0; i < trailer.length; i++) {
                trailer_obj.push({});
                coverage_obj.push({});
                addInfo_obj.push({ toy_hauler_dropdown: {} });
                trailerSpecs_obj.push({});
                error_trailer.push({});
                error_coverage.push({});
                error_addInfo.push({});
                error_trailerSpecs.push({});
                trailer_name_obj.push({});
              }
            } else {
              setLoading("success");

              return;
            }

            setLoading("success");

            policy_details[0].mailing_country = customer_data?.mailing_country;
            policy_details[0].mailing_address =
              customer_data?.mailing_address_line1;
            policy_details[0].mailing_suite =
              customer_data?.mailing_address_line2;
            policy_details[0].mailing_city = customer_data?.mailing_city;
            policy_details[0].mailing_province =
              customer_data?.mailing_province;
            policy_details[0].mailing_postal_code =
              customer_data?.mailing_postalcode;
            policy_details[0].inception_date = res?.data?.data?.quote
              ?.inception_date
              ? dayjs(res?.data?.data?.quote?.inception_date, "DD/MM/YYYY")
              : undefined;
            policy_details[0].expiry_date = res?.data?.data?.quote?.expiry_date
              ? dayjs(res?.data?.data?.quote?.expiry_date, "DD/MM/YYYY")
              : undefined;
            policy_details[0].signature = undefined;

            error_policy_details[0].inception_date = res?.data?.data?.quote
              .inception_date
              ? false
              : undefined;
            error_policy_details[0].expiry_date = res?.data?.data?.quote
              .expiry_date
              ? false
              : undefined;
            error_policy_details[0].mailing_country =
              customer_data?.mailing_country ? false : undefined;
            error_policy_details[0].mailing_address =
              customer_data?.mailing_address_line1 ? false : undefined;
            error_policy_details[0].mailing_suite = false;
            error_policy_details[0].mailing_city = customer_data?.mailing_city
              ? false
              : undefined;
            error_policy_details[0].mailing_province =
              customer_data?.mailing_province ? false : undefined;
            error_policy_details[0].mailing_postal_code =
              customer_data?.mailing_postalcode ? false : undefined;
            error_policy_details[0].signature = res?.data?.data?.quote
              .signature_accept_text
              ? false
              : undefined;

            setSignatureObj({
              text: res?.data?.data?.quote.signature_accept_text,
              font: "",
            });

            terms[0].terms_checked = res?.data?.data?.quote?.agreed_to_tnc;
            error_terms[0].terms_checked = res?.data?.data?.quote?.agreed_to_tnc
              ? false
              : undefined;

            payment.autoCharge = res?.data?.data?.quote?.is_auto_charge_enabled
              ? true
              : false;

            setPaymentFormvalues({
              autoCharge: payment.autoCharge,
              payment_mode: "",
            });

            // if (res?.data?.data?.quote?.referral_reason) {
            //   setReferralFlag({ state: true, reached: true });
            // }

            // setErrorAdditionalNames(true);

            setTermsValues(terms);
            setErrorTerms(error_terms);

            setPolicyDetailFormValues(policy_details);
            setErrorPolicyDetails(error_policy_details);

            setBoatNumber(res?.data?.data?.quote?.number_of_trailers);

            if (res?.data?.data?.quote?.number_of_trailers > 1) {
              addTrailer(res?.data?.data?.quote?.number_of_trailers);

              for (let i = 0; i < trailer.length; i++) {
                trailer_name_obj[i].name = trailer[i].manufacturer;
              }

              setAddTrailerCount([
                { id: 1, name: "Trailer 1" },
                { id: 2, name: "Trailer 2" },
              ]);
              setErrorTrailerNames([{ name: false }, { name: false }]);

              setTrailerNames(trailer_name_obj);
            }

            for (let i = 0; i < trailer.length; i++) {
              trailer_obj[i].type_of_trailer = trailer[i].type;
              trailer_obj[i].trailer_model_year = trailer[i].year;
              trailer_obj[i].are_you_org_owner = trailer[i].original_owner
                ? "Yes"
                : trailer[i].original_owner === false
                ? "No"
                : undefined;
              trailer_obj[i].replacement_cost = trailer[i].replacement_cost;
              trailer_obj[i].actual_cash_value = trailer[i].actual_cost_value;
              trailer_obj[i].is_trailer_parked_within_500ft = trailer[i]
                .parked_within_500feet_of_waterbody
                ? "Yes"
                : trailer[i].parked_within_500feet_of_waterbody === false
                ? "No"
                : undefined;
              trailer_obj[i].is_trailer_parked = trailer[i]
                .permanently_or_seasonally_parked
                ? "Yes"
                : trailer[i].permanently_or_seasonally_parked === false
                ? "No"
                : undefined;
              trailer_obj[i].trailer_stored =
                trailer[i].stored_location_while_not_in_use;
              trailer_obj[i].trailer_taken_into_usa = trailer[i]
                .taken_to_us_gt_180_days
                ? "Yes"
                : trailer[i].taken_to_us_gt_180_days === false
                ? "No"
                : undefined;
              trailer_obj[i].how_long_owned = trailer[i].how_long_owned_trailer;
              trailer_obj[i].is_trailer_removed_from_park = trailer[i]
                .removed_in_off_season
                ? "Yes"
                : trailer[i].removed_in_off_season === false
                ? "No"
                : undefined;
              trailer_obj[i].trailer_removed_address =
                trailer[i].location_moved_to_in_off_season;
              trailer_obj[i].have_deck = trailer[i].have_deck
                ? "Yes"
                : trailer[i].have_deck === false
                ? "No"
                : undefined;
              trailer_obj[i].have_screened_in_room = trailer[i]
                .have_screened_or_florida_room
                ? "Yes"
                : trailer[i].have_screened_or_florida_room === false
                ? "No"
                : undefined;
              trailer_obj[i].have_hard_awning = trailer[i].have_hard_awning
                ? "Yes"
                : trailer[i].have_hard_awning === false
                ? "No"
                : undefined;
              trailer_obj[i].insure_golf_cart = trailer[i]
                .like_to_insure_golf_cart
                ? "Yes"
                : trailer[i].like_to_insure_golf_cart === false
                ? "No"
                : undefined;
              trailer_obj[i].no_of_golf_carts = trailer[i].number_of_golf_cart;
              trailer_obj[i].golf_cart_1 = trailer[i].golf_cart_value1;
              trailer_obj[i].golf_cart_2 = trailer[i].golf_cart_value2;

              trailer_obj[i].park_name = trailer[i].park_name_or_location;
              trailer_obj[i].parking_address = trailer[i].park_address;
              trailer_obj[i].parking_city = trailer[i].park_city;
              trailer_obj[i].parking_province = trailer[i].park_province;
              trailer_obj[i].parking_postal_code = trailer[i].park_postalcode;
              trailer_obj[i].parking_site_number = trailer[i].park_site_number;

              coverage_obj[i].coverage_name = trailer[i].coverage_name;
              coverage_obj[i].premium_per_year = trailer[i].premium_per_year;
              coverage_obj[i].coverage = trailer[i].coverage;
              coverage_obj[i].coverage_type = trailer[i].coverage_type;
              coverage_obj[i].detached_private_structure_coverage =
                trailer[i].detached_private_structure_coverage;
              coverage_obj[i].personal_property_coverage =
                trailer[i].personal_property_coverage;
              coverage_obj[i].permises_liability =
                trailer[i].permises_liability;
              coverage_obj[i].deductible = trailer[i].deductible;

              coverage_obj[i].deductible_ac =
                trailer[i]?.coverage_name === "Current Market Value Coverage" &&
                trailer[i].deductible
                  ? trailer[i].deductible
                  : 1000;
              coverage_obj[i].deductible_rc =
                trailer[i]?.coverage_name === "Replacement Value Coverage" &&
                trailer[i].deductible
                  ? trailer[i].deductible
                  : 1000;
              coverage_obj[i].premises_liability = "2,000,000";
              coverage_obj[i].opted_for_snowbird_endorsment =
                trailer[i]?.opted_for_snowbird_endorsment;

              coverage_obj[i].overrided_preferred_park_discount = trailer[i]
                ?.overrided_preferred_park_discount
                ? trailer[i]?.overrided_preferred_park_discount
                : false;

              console.log(
                trailer[i]?.overrided_preferred_park_discount,
                "opted_snowbird_endorsement"
              );

              // premises_liability: "2,000,000",
              coverage_obj[i].overland_water_protection =
                trailer[i].overland_water_protection;

              addInfo_obj[i].live_in_trailer_full_time = trailer[i]
                .is_principal_residence
                ? "Yes"
                : trailer[i].is_principal_residence === false
                ? "No"
                : undefined;
              addInfo_obj[i].is_unit_strictly = trailer[i]
                .have_rental_or_business_use
                ? "Yes"
                : trailer[i].have_rental_or_business_use === false
                ? "No"
                : undefined;
              addInfo_obj[i].is_any_heating = trailer[i]
                .have_heating_that_was_not_factory_installed
                ? "Yes"
                : trailer[i].have_heating_that_was_not_factory_installed ===
                  false
                ? "No"
                : undefined;
              addInfo_obj[i].heating_type = trailer[i].non_factory_heating_type;
              addInfo_obj[i].is_dual_purpose_trailer = trailer[i]
                .is_dual_purpose
                ? "Yes"
                : trailer[i].is_dual_purpose === false
                ? "No"
                : undefined;
              addInfo_obj[i].hauler_selection = trailer[i].purpose_type;
              //items_of_purpose
              addInfo_obj[i].toy_hauler_dropdown =
                trailer[i].purpose_type === "Toy Hauler" &&
                trailer[i].items_of_purpose
                  ? trailer[i]?.items_of_purpose.reduce((acc, item) => {
                      acc[item] = true;
                      return acc;
                    }, {})
                  : {
                      "Pedal Bike": false,
                      ATV: false,
                      Motorcycle: false,
                      "Dirt Bike": false,
                      "Cars/Trucks/SUV": false,
                    };
              addInfo_obj[i].any_mod_to_trailer = trailer[i].have_modifications
                ? "Yes"
                : trailer[i].have_modifications === false
                ? "No"
                : undefined;
              addInfo_obj[i].mod_options = trailer[i].modification_type;
              addInfo_obj[i].solar_panels_installed = trailer[i]
                .is_solar_panels_factory_or_dealer_installed
                ? "Yes"
                : trailer[i].is_solar_panels_factory_or_dealer_installed ===
                  false
                ? "No"
                : undefined;
              addInfo_obj[i].use_of_solar_on_trailer = trailer[i]
                .not_cover_aftermarket_solarpanels_installation
                ? "Yes"
                : trailer[i].not_cover_aftermarket_solarpanels_installation ===
                  false
                ? "No"
                : undefined;
              addInfo_obj[i].interior_upgrades =
                trailer[i].interior_upgrades_comments;
              addInfo_obj[i].is_pre_existing_damage = trailer[i]
                .any_pre_existing_damage
                ? "Yes"
                : trailer[i].any_pre_existing_damage === false
                ? "No"
                : undefined;
              addInfo_obj[i].damage_description = trailer[i]?.describe_damage;
              addInfo_obj[i].is_trailer_motorized = trailer[i].is_motorized
                ? "Yes"
                : trailer[i].is_motorized === false
                ? "No"
                : undefined;
              trailerSpecs_obj[i].trailer_manufacturer =
                trailer[i].manufacturer;
              trailerSpecs_obj[i].trailer_model = trailer[i].model;
              trailerSpecs_obj[i].trailer_length = trailer[i].length_in_feet;
              trailerSpecs_obj[i].trailer_width = trailer[i].width_in_feet;
              trailerSpecs_obj[i].vin = trailer[i].VIN;
              trailerSpecs_obj[i].deck_length = trailer[i].deck_length_ft;
              trailerSpecs_obj[i].deck_width = trailer[i].deck_width_ft;
              trailerSpecs_obj[i].florida_room_length =
                trailer[i].screened_or_florida_room_length_ft;
              trailerSpecs_obj[i].florida_room_width =
                trailer[i].screened_or_florida_room_width_ft;
              trailerSpecs_obj[i].awning_length = trailer[i].hard_awning_length;
              trailerSpecs_obj[i].awning_width = trailer[i].hard_awning_width;
              addInfo_obj[i].trailer_financed = trailer[i].is_financed
                ? "Yes"
                : trailer[i].is_financed === false
                ? "No"
                : undefined;
              addInfo_obj[i].trailer_lienholder = trailer[i].financier_name;
              addInfo_obj[i].other_lienholder = trailer[i].financier_name;

              addInfo_obj[i].street_leinholder =
                trailer[i].financier_address_line1;
              addInfo_obj[i].line2_leinholder =
                trailer[i].financier_address_line2;
              addInfo_obj[i].city_leinholder = trailer[i].financier_city;
              addInfo_obj[i].province_leinholder =
                trailer[i].financier_province;
              addInfo_obj[i].pc_leinholder = trailer[i].financier_postalcode;
              addInfo_obj[i].country_leinholder = trailer[i].financier_country;

              error_trailer[i].type_of_trailer = errorValueCheckFn(
                trailer[i].type
              );
              error_trailer[i].trailer_model_year = errorValueCheckFn(
                trailer[i].year
              );
              error_trailer[i].are_you_org_owner =
                trailer[i].original_owner || trailer[i].original_owner === false
                  ? false
                  : undefined;
              error_trailer[i].replacement_cost =
                ((trailer[i].type === "Park Model/Destination Trailer" &&
                  Number(trailer[i].year) >= new Date().getFullYear() - 15) ||
                  (trailer[i].type === "Travel Trailer/Fifth Wheel Trailer" &&
                    Number(trailer[i].year) >=
                      new Date().getFullYear() - 10)) &&
                !trailer[i].replacement_cost
                  ? undefined
                  : false;
              error_trailer[i].actual_cash_value = errorValueCheckFn(
                trailer[i].actual_cost_value
              );

              error_trailer[i].is_trailer_parked_within_500ft =
                trailer[i].parked_within_500feet_of_waterbody ||
                trailer[i].parked_within_500feet_of_waterbody === false
                  ? false
                  : undefined;

              error_trailer[i].is_trailer_parked =
                trailer[i].type === "Travel Trailer/Fifth Wheel Trailer" &&
                !(
                  trailer[i].permanently_or_seasonally_parked ||
                  trailer[i].permanently_or_seasonally_parked === false
                )
                  ? undefined
                  : false;

              error_trailer[i].trailer_stored =
                trailer[i].permanently_or_seasonally_parked === false &&
                !trailer[i].stored_location_while_not_in_use
                  ? undefined
                  : false;
              error_trailer[i].trailer_taken_into_usa =
                trailer[i].permanently_or_seasonally_parked === false &&
                !(
                  trailer[i].taken_to_us_gt_180_days ||
                  trailer[i].taken_to_us_gt_180_days === false
                )
                  ? undefined
                  : false;

              error_trailer[i].how_long_owned = errorValueCheckFn(
                trailer[i].how_long_owned_trailer
              );

              error_trailer[i].is_trailer_removed_from_park =
                trailer[i].permanently_or_seasonally_parked &&
                trailer[i].type === "Travel Trailer/Fifth Wheel Trailer" &&
                !(
                  trailer[i].removed_in_off_season ||
                  trailer[i].removed_in_off_season === false
                )
                  ? undefined
                  : false;

              error_trailer[i].trailer_removed_address =
                trailer[i].removed_in_off_season &&
                !trailer[i].location_moved_to_in_off_season
                  ? undefined
                  : false;

              error_trailer[i].have_deck =
                (trailer[i].type === "Park Model/Destination Trailer" ||
                  trailer[i].permanently_or_seasonally_parked) &&
                !(trailer[i].have_deck || trailer[i].have_deck === false)
                  ? undefined
                  : false;

              error_trailer[i].have_screened_in_room =
                (trailer[i].type === "Park Model/Destination Trailer" ||
                  trailer[i].permanently_or_seasonally_parked) &&
                !(
                  trailer[i].have_screened_or_florida_room ||
                  trailer[i].have_screened_or_florida_room === false
                )
                  ? undefined
                  : false;

              error_trailer[i].have_hard_awning =
                (trailer[i].type === "Park Model/Destination Trailer" ||
                  trailer[i].permanently_or_seasonally_parked) &&
                !(
                  trailer[i].have_hard_awning ||
                  trailer[i].have_hard_awning === false
                )
                  ? undefined
                  : false;

              error_trailer[i].insure_golf_cart =
                (trailer[i].type === "Park Model/Destination Trailer" ||
                  trailer[i].permanently_or_seasonally_parked) &&
                !(
                  trailer[i].like_to_insure_golf_cart ||
                  trailer[i].like_to_insure_golf_cart === false
                )
                  ? undefined
                  : false;

              error_trailer[i].no_of_golf_carts =
                (trailer[i].type === "Park Model/Destination Trailer" ||
                  trailer[i].permanently_or_seasonally_parked) &&
                trailer[i].like_to_insure_golf_cart &&
                !trailer[i].number_of_golf_cart
                  ? undefined
                  : false;
              error_trailer[i].golf_cart_1 =
                (trailer[i].type === "Park Model/Destination Trailer" ||
                  trailer[i].permanently_or_seasonally_parked) &&
                trailer[i].like_to_insure_golf_cart &&
                trailer[i].number_of_golf_cart &&
                !trailer[i].golf_cart_value1
                  ? undefined
                  : false;
              error_trailer[i].golf_cart_2 =
                (trailer[i].type === "Park Model/Destination Trailer" ||
                  trailer[i].permanently_or_seasonally_parked) &&
                trailer[i].like_to_insure_golf_cart &&
                Number(trailer[i].number_of_golf_cart) === 2 &&
                !trailer[i].golf_cart_value2
                  ? undefined
                  : false;

              error_trailer[i].park_name =
                (trailer[i].type === "Park Model/Destination Trailer" ||
                  trailer[i].permanently_or_seasonally_parked) &&
                !trailer[i].park_name_or_location
                  ? undefined
                  : false;
              error_trailer[i].parking_address =
                (trailer[i].type === "Park Model/Destination Trailer" ||
                  trailer[i].permanently_or_seasonally_parked) &&
                !trailer[i].park_address
                  ? undefined
                  : false;
              error_trailer[i].parking_city =
                (trailer[i].type === "Park Model/Destination Trailer" ||
                  trailer[i].permanently_or_seasonally_parked) &&
                !trailer[i].park_city
                  ? undefined
                  : false;
              error_trailer[i].parking_province =
                (trailer[i].type === "Park Model/Destination Trailer" ||
                  trailer[i].permanently_or_seasonally_parked) &&
                !trailer[i].park_province
                  ? undefined
                  : false;
              error_trailer[i].parking_postal_code =
                (trailer[i].type === "Park Model/Destination Trailer" ||
                  trailer[i].permanently_or_seasonally_parked) &&
                !trailer[i].park_postalcode
                  ? undefined
                  : false;
              error_trailer[i].parking_site_number =
                (trailer[i].type === "Park Model/Destination Trailer" ||
                  trailer[i].permanently_or_seasonally_parked) &&
                !trailer[i].park_site_number
                  ? undefined
                  : false;

              //pending

              error_coverage[i].coverage_name = errorValueCheckFn(
                trailer[i].coverage_name
              );
              error_coverage[i].premium_per_year = false;
              error_coverage[i].coverage = false;
              error_coverage[i].coverage_type = false;
              error_coverage[i].detached_private_structure_coverage = false;
              error_coverage[i].personal_property_coverage = false;
              error_coverage[i].permises_liability = false;
              error_coverage[i].deductible = false;
              error_coverage[i].overland_water_protection = false;

              error_addInfo[i].live_in_trailer_full_time =
                trailer[i].is_principal_residence ||
                trailer[i].is_principal_residence === false
                  ? false
                  : undefined;

              error_addInfo[i].is_unit_strictly =
                trailer[i].have_rental_or_business_use ||
                trailer[i].have_rental_or_business_use === false
                  ? false
                  : undefined;

              error_addInfo[i].is_any_heating =
                trailer[i].have_heating_that_was_not_factory_installed ||
                trailer[i].have_heating_that_was_not_factory_installed === false
                  ? false
                  : undefined;

              error_addInfo[i].heating_type =
                trailer[i].have_heating_that_was_not_factory_installed &&
                !trailer[i].non_factory_heating_type
                  ? undefined
                  : false;

              //TODO and type of trailer

              error_addInfo[i].is_dual_purpose_trailer =
                trailer[i].type === "Travel Trailer/Fifth Wheel Trailer" &&
                !(
                  trailer[i].is_dual_purpose ||
                  trailer[i].is_dual_purpose === false
                )
                  ? undefined
                  : false;

              error_addInfo[i].hauler_selection =
                trailer[i].type === "Travel Trailer/Fifth Wheel Trailer" &&
                trailer[i].is_dual_purpose &&
                !trailer[i].purpose_type
                  ? undefined
                  : false;
              error_addInfo[i].toy_hauler_dropdown =
                trailer[i].purpose_type === "Toy Hauler" &&
                trailer[i].items_of_purpose.length === 0
                  ? undefined
                  : false;
              error_addInfo[i].any_mod_to_trailer =
                trailer[i].have_modifications ||
                trailer[i].have_modifications === false
                  ? false
                  : undefined;
              error_addInfo[i].mod_options =
                trailer[i].have_modifications && !trailer[i].modification_type
                  ? undefined
                  : false;
              error_addInfo[i].solar_panels_installed =
                trailer[i].modification_type === "Solar panels" &&
                (trailer[i].is_solar_panels_factory_or_dealer_installed ||
                  trailer[i].is_solar_panels_factory_or_dealer_installed ===
                    false)
                  ? false
                  : trailer[i].modification_type === "Solar panels" &&
                    !(
                      trailer[i].is_solar_panels_factory_or_dealer_installed ||
                      trailer[i].is_solar_panels_factory_or_dealer_installed ===
                        false
                    )
                  ? undefined
                  : false;

              error_addInfo[i].use_of_solar_on_trailer =
                trailer[i].is_solar_panels_factory_or_dealer_installed ===
                  false &&
                (trailer[i].not_cover_aftermarket_solarpanels_installation ||
                  trailer[i].not_cover_aftermarket_solarpanels_installation ===
                    false)
                  ? false
                  : trailer[i].is_solar_panels_factory_or_dealer_installed ===
                      false &&
                    !(
                      trailer[i]
                        .not_cover_aftermarket_solarpanels_installation ||
                      trailer[i]
                        .not_cover_aftermarket_solarpanels_installation ===
                        false
                    )
                  ? undefined
                  : false;
              error_addInfo[i].interior_upgrades =
                trailer[i].modification_type === "Interior upgrades" &&
                !trailer[i].interior_upgrades_comments
                  ? undefined
                  : false;

              error_addInfo[i].is_pre_existing_damage =
                trailer[i].any_pre_existing_damage ||
                trailer[i].any_pre_existing_damage === false
                  ? false
                  : undefined;
              error_addInfo[i].damage_description =
                trailer[i].any_pre_existing_damage &&
                !trailer[i]?.describe_damage
                  ? undefined
                  : false;

              error_addInfo[i].is_trailer_motorized =
                trailer[i].is_motorized || trailer[i].is_motorized === false
                  ? false
                  : undefined;

              error_trailerSpecs[i].trailer_manufacturer = errorValueCheckFn(
                trailer[i].manufacturer
              );
              error_trailerSpecs[i].trailer_model = errorValueCheckFn(
                trailer[i].model
              );
              error_trailerSpecs[i].trailer_length = errorValueCheckFn(
                trailer[i].length_in_feet
              );
              error_trailerSpecs[i].trailer_width =
                trailer[i].type === "Park Model/Destination Trailer" &&
                !trailer[i].width_in_feet
                  ? undefined
                  : false;
              error_trailerSpecs[i].vin =
                trailer[i].type === "Travel Trailer/Fifth Wheel Trailer" &&
                !trailer[i].VIN
                  ? undefined
                  : false;
              error_trailerSpecs[i].deck_length =
                trailer[i].have_deck && !trailer[i].deck_length_ft
                  ? undefined
                  : false;
              error_trailerSpecs[i].deck_width =
                trailer[i].have_deck && !trailer[i].deck_width_ft
                  ? undefined
                  : false;
              error_trailerSpecs[i].florida_room_length =
                trailer[i].have_screened_or_florida_room &&
                !trailer[i].screened_or_florida_room_length_ft
                  ? undefined
                  : false;
              error_trailerSpecs[i].florida_room_width =
                trailer[i].have_screened_or_florida_room &&
                !trailer[i].screened_or_florida_room_width_ft
                  ? undefined
                  : false;
              error_trailerSpecs[i].awning_length =
                trailer[i].have_hard_awning && !trailer[i].hard_awning_length
                  ? undefined
                  : false;
              error_trailerSpecs[i].awning_width =
                trailer[i].have_hard_awning && !trailer[i].hard_awning_width
                  ? undefined
                  : false;

              error_addInfo[i].trailer_financed =
                trailer[i].is_financed || trailer[i].is_financed === false
                  ? false
                  : undefined;
              error_addInfo[i].trailer_lienholder =
                trailer[i].is_financed && !trailer[i].financier_name
                  ? undefined
                  : false;
              error_addInfo[i].other_lienholder = false;

              error_addInfo[i].street_leinholder =
                trailer[i].is_financed && !trailer[i].financier_address_line1
                  ? undefined
                  : false;
              error_addInfo[i].line2_leinholder = false;
              error_addInfo[i].city_leinholder =
                trailer[i].is_financed && !trailer[i].financier_city
                  ? undefined
                  : false;
              error_addInfo[i].province_leinholder =
                trailer[i].is_financed && !trailer[i].financier_province
                  ? undefined
                  : false;
              error_addInfo[i].pc_leinholder =
                trailer[i].is_financed && !trailer[i].financier_postalcode
                  ? undefined
                  : false;
              error_addInfo[i].country_leinholder =
                trailer[i].is_financed && !trailer[i].financier_country
                  ? undefined
                  : false;
            }

            setTrailerFormValues(trailer_obj);
            setCoverageFormValues(coverage_obj);
            setAdditionalInfoFormValues(addInfo_obj);
            setTrailerSpecsFormValues(trailerSpecs_obj);

            setErrorTrailer(error_trailer);
            setErrorCoverage(error_coverage);
            setErrorAdditionalInfo(error_addInfo);
            setErrorTrailerSpecs(error_trailerSpecs);

            setDefaultData({
              ...defaultData,
              quote_status: res?.data?.data?.quote?.quote_status,
            });

            if (res?.data?.data?.quote?.quote_status === "Referral") {
              setReferralFlag({ state: true, reached: true });
            } else if (res?.data?.data?.quote?.quote_status === "Completed") {
              setBrokerReviewFlag(true);
            } else if (
              res?.data?.data?.quote?.quote_status === "Return To Payment"
            ) {
              setBrokerReviewFlag(true);
              setFormNumber(3);
            } else if (
              res?.data?.data?.quote?.quote_status === "Payment Pending"
            ) {
              setFormNumber(3);
            } else if (
              res?.data?.data?.quote?.quote_status === "Return To Broker"
            ) {
              setBrokerReviewFlag(true);
            }

            if (res?.data?.data?.quote?.is_uneditable_by_rtp) {
              setBrokerReviewFlag(true);
            }
          });
      } else {
        await axios
          .post(`${APIURL}/trailer/create_trailer_quote`, {
            organization_id: ORGANIZATION_ID,
            created_source: "WEBAPP",
          })
          .then((res) => {
            console.log(res);
            if (res?.data?.data?.quote.quote_id) {
              setLoading("success");

              let data = { ...globalData };

              data.quote_id = res?.data?.data?.quote?.quote_id;
              data.quote_record_id = res?.data?.data?.quote?.quote_record_id;

              if (!data.quote_id || !data.quote_record_id) {
                setLoading("error");
              }

              setGlobalData(data);
              window.sessionStorage.setItem(
                "trailer_quote_id",
                res?.data?.data?.quote?.quote_id
              );
              window.sessionStorage.setItem(
                "trailer_record_id",
                res?.data?.data?.quote?.quote_record_id
              );
            } else {
              setLoading("error");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    fun();
  }, []);

  const [loading, setLoading] = useState("loading");
  const [manufacturerOptions, setManufacturerOptions] = useState([]);

  console.log(errorAdditionalInfo, "error_addinfo");

  useEffect(() => {
    async function fun() {
      await axios
        .get(`${APIURL}/manufacturer/get_manufacturers?type=Trailer`)
        .then((res) => {
          console.log(res);
          let arr = res.data?.data
            .map((m) => {
              return { value: m.name, label: m.name };
            })
            .sort();

          console.log(arr);
          setManufacturerOptions(() => arr);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    fun();
  }, []);

  const [btnLoading, setBtnLoading] = useState(false);

  const [referralFlag, setReferralFlag] = useState({
    state: false,
    reached: false,
  });
  const [brokerReviewFlag, setBrokerReviewFlag] = useState(false);

  return (
    <div>
      {loading === "loading" ? (
        <div className="flex_layout" style={{ minHeight: "30vh" }}>
          <div className="loader_layout"></div>
        </div>
      ) : loading === "success" ? (
        <div className="trailer">
          <h1>Trailer</h1>
          <h2>Quote # {globalData?.quote_id}</h2>
          <ProgressBar
            formNumber={formNumber}
            nextPage={nextPage}
            trailerCount={addTrailerCount}
            index={trailerNumberId}
            getStartedCount={getStartedCount}
            getStartedId={getStartedId}
            paymentId={paymentId}
            getStarted={errorGetStarted}
            setGetStarted={setErrorGetStarted}
            trailer={errorTrailerSpecs}
            setTrailer={setErrorTrailerSpecs}
            PolicyDetails={errorPolicyDetails}
            setPolicyDetails={setErrorPolicyDetails}
            terms={errorTerms}
            setTerms={setErrorTerms}
            setTrailerSpecsRedBoxObj={setTrailerSpecsRedBoxObj}
            referralFlag={referralFlag}
            brokerReviewFlag={brokerReviewFlag}
            paymentSuccess={paymentSuccess}
            setPaymentSuccess={setPaymentSuccess}
          />
          <div className="trailer_forms_container">
            {formNumber === 1 ? (
              <GetStarted
                formNumber={formNumber}
                nextPage={nextPage}
                backPage={backPage}
                animateX={animateX}
                setAnimateX={setAnimateX}
                value={getStartedFormValues[0]}
                setValue={setGetStartedFormValues}
                error={errorGetStarted[0]}
                setError={setErrorGetStarted}
                additionalNames={additionalNames}
                setAdditionalNames={setAdditionalNames}
                id={1}
                wholeError={errorGetStarted}
                wholevalue={getStartedFormValues}
                trailerFormValues={trailerFormValues}
                setTrailerFormValues={setTrailerFormValues}
                errorTrailer={errorTrailer}
                setErrorTrailer={setErrorTrailer}
                trailerSpecsFormValues={trailerSpecsFormValues}
                setTrailerSpecsFormValues={setTrailerSpecsFormValues}
                errorTrailerSpecs={errorTrailerSpecs}
                setErrorTrailerSpecs={setErrorTrailerSpecs}
                trailerNumber={boatNumber}
                addTrailer={addTrailer}
                addTrailerCount={addTrailerCount}
                number_of_trailer={number_of_trailer}
                index={trailerNumberId}
                setIndex={setTrailerNumberId}
                coverageFormValues={coverageFormValues}
                setCoverageFormValues={setCoverageFormValues}
                additionalInfoFormValues={additionalInfoFormValues}
                setAdditionalInfoFormValues={setAdditionalInfoFormValues}
                errorCoverage={errorCoverage}
                setErrorCoverage={setErrorCoverage}
                errorAdditionalInfo={errorAdditionalInfo}
                setErrorAdditionalInfo={setErrorAdditionalInfo}
                trailerRedBoxObj={trailerRedBoxObj}
                quotesRedBoxObj={quotesRedBoxObj}
                trailerSpecsRedBoxObj={trailerSpecsRedBoxObj}
                addInfoRedBoxObj={addInfoRedBoxObj}
                setTrailerRedBoxObj={setTrailerRedBoxObj}
                setQuoteRedBoxObj={setQuoteRedBoxObj}
                setAddInfoRedBoxObj={setAddInfoRedBoxObj}
                setTrailerSpecsRedBoxObj={setTrailerSpecsRedBoxObj}
                trailerNames={trailerNames}
                errorTrailerNames={errorTrailerNames}
                setTrailerNames={setTrailerNames}
                setErrorTrailerNames={setErrorTrailerNames}
                getStartedCount={getStartedCount}
                getStartedId={getStartedId}
                setGetStartedId={setGetStartedId}
                errorAdditionalNames={errorAdditionalNames}
                setErrorAdditionalNames={setErrorAdditionalNames}
                globalData={globalData}
                setGlobalData={setGlobalData}
                isAddNamesSent={isAddNamesSent}
                setIsAddNamesSent={setIsAddNamesSent}
                manufacturerOptions={manufacturerOptions}
                setManufacturerOptions={setManufacturerOptions}
                referralFlag={referralFlag}
                organization={organization}
                brokerReviewFlag={brokerReviewFlag}
                policyDetailFormValues={policyDetailFormValues}
                setPolicyDetailFormValues={setPolicyDetailFormValues}
                errorPolicyDetails={errorPolicyDetails}
                setErrorPolicyDetails={setErrorPolicyDetails}
              />
            ) : formNumber === 2 ? (
              <div>
                <TrailerMain
                  number={formNumber}
                  setNumber={setFormNumber}
                  formNumber={internalTrailerState}
                  setFormNumber={setInternalTrailerState}
                  animateX={animateX}
                  setAnimateX={setAnimateX}
                  trailerFormValues={trailerFormValues}
                  setTrailerFormValues={setTrailerFormValues}
                  errorTrailer={errorTrailer}
                  setErrorTrailer={setErrorTrailer}
                  trailerSpecsFormValues={trailerSpecsFormValues}
                  setTrailerSpecsFormValues={setTrailerSpecsFormValues}
                  errorTrailerSpecs={errorTrailerSpecs}
                  setErrorTrailerSpecs={setErrorTrailerSpecs}
                  trailerNumber={boatNumber}
                  addTrailer={addTrailer}
                  addTrailerCount={addTrailerCount}
                  number_of_trailer={number_of_trailer}
                  index={trailerNumberId}
                  setIndex={setTrailerNumberId}
                  coverageFormValues={coverageFormValues}
                  setCoverageFormValues={setCoverageFormValues}
                  additionalInfoFormValues={additionalInfoFormValues}
                  setAdditionalInfoFormValues={setAdditionalInfoFormValues}
                  errorCoverage={errorCoverage}
                  setErrorCoverage={setErrorCoverage}
                  errorAdditionalInfo={errorAdditionalInfo}
                  setErrorAdditionalInfo={setErrorAdditionalInfo}
                  errTrailerSpecs={errorTrailerSpecs}
                  setErrTrailerSpecs={setErrorTrailerSpecs}
                  trailerRedBoxObj={trailerRedBoxObj}
                  getStartedFormValues={getStartedFormValues}
                  quotesRedBoxObj={quotesRedBoxObj}
                  trailerSpecsRedBoxObj={trailerSpecsRedBoxObj}
                  addInfoRedBoxObj={addInfoRedBoxObj}
                  setTrailerRedBoxObj={setTrailerRedBoxObj}
                  setQuoteRedBoxObj={setQuoteRedBoxObj}
                  setAddInfoRedBoxObj={setAddInfoRedBoxObj}
                  setTrailerSpecsRedBoxObj={setTrailerSpecsRedBoxObj}
                  trailerNames={trailerNames}
                  manufacturerOptions={manufacturerOptions}
                  setManufacturerOptions={setManufacturerOptions}
                  referralFlag={referralFlag}
                  setReferralFlag={setReferralFlag}
                  organization={organization}
                  brokerReviewFlag={brokerReviewFlag}
                  setBtnLoading={setBtnLoading}
                  btnLoading={btnLoading}
                  defaultData={defaultData}
                  setDefaultData={setDefaultData}
                />
                <TrailerButtons
                  error={errorTrailerSpecs}
                  setError={setErrorTrailerSpecs}
                  setAnimateX={setAnimateX}
                  formNumber={formNumber}
                  nextPage={nextPage}
                  backPage={backPage}
                  redBoxObj={trailerSpecsRedBoxObj}
                  setRedBoxObj={setTrailerSpecsRedBoxObj}
                  index={trailerNumberId}
                  addTrailerCount={addTrailerCount}
                  internalTrailerState={internalTrailerState}
                  globalData={globalData}
                  trailerFormValues={trailerFormValues}
                  coverageFormValues={coverageFormValues}
                  additionalInfoFormValues={additionalInfoFormValues}
                  trailerSpecsFormValues={trailerSpecsFormValues}
                  btnLoading={btnLoading}
                  setBtnLoading={setBtnLoading}
                  referralFlag={referralFlag}
                  brokerReviewFlag={brokerReviewFlag}
                />
              </div>
            ) : formNumber === 3 ? (
              <PolicyDetails
                formNumber={formNumber}
                nextPage={nextPage}
                backPage={backPage}
                animateX={animateX}
                setAnimateX={setAnimateX}
                value={policyDetailFormValues[0]}
                setValue={setPolicyDetailFormValues}
                getStartedFormValues={getStartedFormValues[0]}
                error={errorPolicyDetails[0]}
                setError={setErrorPolicyDetails}
                id={1}
                wholeError={errorPolicyDetails}
                wholevalue={policyDetailFormValues}
                signatureObj={signatureObj}
                setSignatureObj={setSignatureObj}
                globalData={globalData}
                referralFlag={referralFlag}
                organization={organization}
                brokerReviewFlag={brokerReviewFlag}
                defaultData={defaultData}
                btnLoading={btnLoading}
              />
            ) : formNumber === 4 ? (
              <Terms
                formNumber={formNumber}
                nextPage={nextPage}
                backPage={backPage}
                animateX={animateX}
                setAnimateX={setAnimateX}
                value={termsValues[0]}
                setValue={setTermsValues}
                error={errorTerms[0]}
                id={1}
                setError={setErrorTerms}
                globalData={globalData}
                type="Trailer"
                referralFlag={referralFlag}
                wholeError={errorTerms}
                wholevalue={termsValues}
                brokerReviewFlag={brokerReviewFlag}
                defaultData={defaultData}
              />
            ) : formNumber === 5 ? (
              <Payment
                referralFlag={referralFlag}
                setReferralFlag={setReferralFlag}
                trailer={trailerFormValues}
                addDetails={additionalInfoFormValues}
                coverage={coverageFormValues}
                trailerCount={addTrailerCount}
                paymentId={paymentId}
                setPaymentId={setPaymentId}
                animateX={animateX}
                setAnimateX={setAnimateX}
                globalData={globalData}
                formNumber={formNumber}
                nextPage={nextPage}
                backPage={backPage}
                organization={organization}
                getStartedFormValues={getStartedFormValues}
                paymentFormValues={paymentFormValues}
                setPaymentFormvalues={setPaymentFormvalues}
                paymentSuccess={paymentSuccess}
                setPaymentSuccess={setPaymentSuccess}
                responseData={responseData}
                defaultData={defaultData}
                brokerReviewFlag={brokerReviewFlag}
                setDefaultData={setDefaultData}
              />
            ) : (
              ""
            )}
          </div>

          {(formNumber === 1 && getStartedId === 1) ||
          (formNumber === 5 && paymentId === 2) ? (
            ""
          ) : (
            <Footer
              brokerReviewFlag={brokerReviewFlag}
              setBrokerReviewFlag={setBrokerReviewFlag}
              type={"Trailer"}
              globalData={globalData}
              trailerFormValues={trailerFormValues}
              coverageFormValues={coverageFormValues}
              additionalInfoFormValues={additionalInfoFormValues}
              trailerSpecsFormValues={trailerSpecsFormValues}
              addTrailerCount={addTrailerCount}
              getStartedFormValues={getStartedFormValues}
              referralFlag={referralFlag}
              formNumber={formNumber}
              policyDetailFormValues={policyDetailFormValues}
              signatureObj={signatureObj}
              trailerNames={trailerNames}
            />
          )}
          {/* {(formNumber !== 1 && formNumber !== 5) ||
          (getStartedId !== 1 && paymentId !== 2) ? (
            <Footer
              brokerReviewFlag={brokerReviewFlag}
              setBrokerReviewFlag={setBrokerReviewFlag}
              type={"Trailer"}
              globalData={globalData}
              trailerFormValues={trailerFormValues}
              coverageFormValues={coverageFormValues}
              additionalInfoFormValues={additionalInfoFormValues}
              trailerSpecsFormValues={trailerSpecsFormValues}
              addTrailerCount={addTrailerCount}
            />
          ) : (
            ""
          )} */}
        </div>
      ) : loading === "error" ? (
        <ServerError />
      ) : loading === "id_not_found" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100dvh",
            minWidth: "100vdw",
          }}
        >
          <Result
            status={"error"}
            title="Invalid Quote"
            subTitle="Please check your url"
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Trailer;

const TrailerButtons = ({
  error,
  setError,
  setAnimateX,
  formNumber,
  nextPage,
  backPage,
  redBoxObj,
  setRedBoxObj,
  addTrailerCount,
  index,
  internalTrailerState,
  globalData,
  trailerFormValues,
  coverageFormValues,
  additionalInfoFormValues,
  trailerSpecsFormValues,
  btnLoading,
  setBtnLoading,
  referralFlag,
  brokerReviewFlag,
}) => {
  return (
    <div className="trailer_btns">
      {internalTrailerState === 4 && index === addTrailerCount.length && (
        <button
          className={`next_btn ${btnLoading ? "loading" : ""}`}
          onClick={async () => {
            if (referralFlag?.state || brokerReviewFlag) {
              setAnimateX(100);
              nextPage(formNumber + 1);

              return;
            }

            console.log(error);
            setBtnLoading(() => true);

            let obj = [...redBoxObj];

            const allValuesUndefined = error.some((obj) => {
              for (const key in obj) {
                if (obj[key] === undefined) {
                  return true;
                }
              }
              return false;
            });

            let updatedArray = [...error];

            console.log(allValuesUndefined);
            if (allValuesUndefined) {
              updatedArray = error.map((obj) => {
                const updatedObj = { ...obj };

                for (const key in updatedObj) {
                  if (updatedObj[key] === undefined) {
                    updatedObj[key] = true;
                  }
                }

                return updatedObj;
              });

              for (let i = 0; i < updatedArray.length; i++) {
                const errorItem = updatedArray[i];
                const objItem = obj[i];

                const hasTrueValue = Object.values(errorItem).some(
                  (value) => value === true
                );

                console.log(hasTrueValue);

                if (hasTrueValue) {
                  objItem.state = true;
                } else {
                  objItem.state = false;
                }
              }

              setRedBoxObj(obj);

              setBtnLoading(() => false);

              setError(updatedArray);

              return;
            }

            const anyValueIsTrue = updatedArray.some((obj) => {
              for (const key in obj) {
                if (obj[key] === true) {
                  return true;
                }
              }
              return false;
            });

            console.log(updatedArray);

            if (anyValueIsTrue) {
              for (let i = 0; i < updatedArray.length; i++) {
                const errorItem = updatedArray[i];
                const objItem = obj[i];

                const hasTrueValue = Object.values(errorItem).some(
                  (value) => value === true
                );

                console.log(hasTrueValue);

                if (hasTrueValue) {
                  objItem.state = true;
                } else {
                  objItem.state = false;
                }
              }

              setRedBoxObj(obj);
              setBtnLoading(() => false);

              console.log(obj);
              // setRedBox(true);
              return;
            }

            for (let i = 0; i < updatedArray.length; i++) {
              const objItem = obj[i];

              console.log(objItem);

              objItem.state = false;
            }

            setRedBoxObj(obj);

            let trailer_data_obj = [];

            for (let i = 0; i < addTrailerCount.length; i++) {
              trailer_data_obj.push({});
            }

            console.log(trailerFormValues);

            for (let i = 0; i < addTrailerCount.length; i++) {
              trailer_data_obj[i].type = trailerFormValues[i].type_of_trailer;

              trailer_data_obj[i].year =
                trailerFormValues[i].trailer_model_year;

              trailer_data_obj[i].original_owner =
                trailerFormValues[i].are_you_org_owner === "Yes"
                  ? true
                  : trailerFormValues[i].are_you_org_owner === "No"
                  ? false
                  : undefined;

              trailer_data_obj[i].replacement_cost =
                trailerFormValues[i].replacement_cost;

              trailer_data_obj[i].actual_cost_value =
                trailerFormValues[i].actual_cash_value;

              trailer_data_obj[i].parked_within_500feet_of_waterbody =
                trailerFormValues[i].is_trailer_parked_within_500ft === "Yes"
                  ? true
                  : trailerFormValues[i].is_trailer_parked_within_500ft === "No"
                  ? false
                  : undefined;

              trailer_data_obj[i].permanently_or_seasonally_parked =
                trailerFormValues[i].is_trailer_parked === "Yes"
                  ? true
                  : trailerFormValues[i].is_trailer_parked === "No"
                  ? false
                  : undefined;
              trailer_data_obj[i].stored_location_while_not_in_use =
                trailerFormValues[i].trailer_stored;
              trailer_data_obj[i].taken_to_us_gt_180_days =
                trailerFormValues[i].trailer_taken_into_usa === "Yes"
                  ? true
                  : trailerFormValues[i].trailer_taken_into_usa === "No"
                  ? false
                  : undefined;
              trailer_data_obj[i].how_long_owned_trailer =
                trailerFormValues[i].how_long_owned;
              trailer_data_obj[i].removed_in_off_season =
                trailerFormValues[i].is_trailer_removed_from_park === "Yes"
                  ? true
                  : trailerFormValues[i].is_trailer_removed_from_park === "No"
                  ? false
                  : undefined;
              trailer_data_obj[i].location_moved_to_in_off_season =
                trailerFormValues[i].trailer_removed_address;
              trailer_data_obj[i].have_deck =
                trailerFormValues[i]?.have_deck === "Yes"
                  ? true
                  : trailerFormValues[i]?.have_deck === "No"
                  ? false
                  : undefined;
              trailer_data_obj[i].have_screened_or_florida_room =
                trailerFormValues[i].have_screened_in_room === "Yes"
                  ? true
                  : trailerFormValues[i].have_screened_in_room === "No"
                  ? false
                  : undefined;
              trailer_data_obj[i].have_hard_awning =
                trailerFormValues[i].have_hard_awning === "Yes"
                  ? true
                  : trailerFormValues[i].have_hard_awning === "No"
                  ? false
                  : undefined;
              trailer_data_obj[i].like_to_insure_golf_cart =
                trailerFormValues[i].insure_golf_cart === "Yes"
                  ? true
                  : trailerFormValues[i].insure_golf_cart === "No"
                  ? false
                  : undefined;
              trailer_data_obj[i].number_of_golf_cart =
                trailerFormValues[i].no_of_golf_carts;
              trailer_data_obj[i].golf_cart_value1 =
                trailerFormValues[i].golf_cart_1;
              trailer_data_obj[i].golf_cart_value2 =
                trailerFormValues[i].golf_cart_2;
              trailer_data_obj[i].park_name_or_location =
                trailerFormValues[i].park_name;
              trailer_data_obj[i].park_address =
                trailerFormValues[i].parking_address;
              trailer_data_obj[i].park_city = trailerFormValues[i].parking_city;
              trailer_data_obj[i].park_province =
                trailerFormValues[i].parking_province;
              trailer_data_obj[i].park_postalcode =
                trailerFormValues[i].parking_postal_code;
              trailer_data_obj[i].park_site_number =
                trailerFormValues[i].parking_site_number;

              trailer_data_obj[i].coverage_name =
                coverageFormValues[i].coverage_name;
              trailer_data_obj[i].premium_per_year =
                coverageFormValues[i].premium_per_year;
              trailer_data_obj[i].coverage = coverageFormValues[i].coverage;
              trailer_data_obj[i].coverage_type =
                coverageFormValues[i].coverage_type;
              trailer_data_obj[i].detached_private_structure_coverage =
                coverageFormValues[i].detached_private_structure_coverage;
              trailer_data_obj[i].personal_property_coverage =
                coverageFormValues[i].personal_property_coverage;
              trailer_data_obj[i].permises_liability =
                coverageFormValues[i].permises_liability;
              trailer_data_obj[i].deductible = coverageFormValues[i].deductible;
              trailer_data_obj[i].overland_water_protection =
                coverageFormValues[i].overland_water_protection;

              trailer_data_obj[i].is_principal_residence =
                additionalInfoFormValues[i].live_in_trailer_full_time === "Yes"
                  ? true
                  : additionalInfoFormValues[i].live_in_trailer_full_time ===
                    "No"
                  ? false
                  : undefined;
              trailer_data_obj[i].have_rental_or_business_use =
                additionalInfoFormValues[i].is_unit_strictly === "Yes"
                  ? true
                  : additionalInfoFormValues[i].is_unit_strictly === "No"
                  ? false
                  : undefined;
              trailer_data_obj[i].have_heating_that_was_not_factory_installed =
                additionalInfoFormValues[i].is_any_heating === "Yes"
                  ? true
                  : additionalInfoFormValues[i].is_any_heating === "No"
                  ? false
                  : undefined;
              trailer_data_obj[i].non_factory_heating_type =
                additionalInfoFormValues[i].heating_type;
              trailer_data_obj[i].is_dual_purpose =
                additionalInfoFormValues[i].is_dual_purpose_trailer === "Yes"
                  ? true
                  : additionalInfoFormValues[i].is_dual_purpose_trailer === "No"
                  ? false
                  : undefined;
              trailer_data_obj[i].purpose_type =
                additionalInfoFormValues[i].hauler_selection;
              trailer_data_obj[i].items_of_purpose =
                additionalInfoFormValues[i].hauler_selection === "Toy Hauler"
                  ? Object.entries(
                      additionalInfoFormValues[i].toy_hauler_dropdown
                    )
                      .filter(([key, value]) => value === true)
                      .map(([key]) => key)
                  : undefined;
              trailer_data_obj[i].have_modifications =
                additionalInfoFormValues[i].any_mod_to_trailer === "Yes"
                  ? true
                  : additionalInfoFormValues[i].any_mod_to_trailer === "No"
                  ? false
                  : undefined;
              trailer_data_obj[i].modification_type =
                additionalInfoFormValues[i].mod_options;
              trailer_data_obj[i].is_solar_panels_factory_or_dealer_installed =
                additionalInfoFormValues[i].solar_panels_installed === "Yes"
                  ? true
                  : additionalInfoFormValues[i].solar_panels_installed === "No"
                  ? false
                  : undefined;
              trailer_data_obj[
                i
              ].not_cover_aftermarket_solarpanels_installation =
                additionalInfoFormValues[i].use_of_solar_on_trailer === "Yes"
                  ? true
                  : additionalInfoFormValues[i].use_of_solar_on_trailer === "No"
                  ? false
                  : undefined;
              trailer_data_obj[i].interior_upgrades_comments =
                additionalInfoFormValues[i].interior_upgrades;
              trailer_data_obj[i].any_pre_existing_damage =
                additionalInfoFormValues[i].is_pre_existing_damage === "Yes"
                  ? true
                  : additionalInfoFormValues[i].is_pre_existing_damage === "No"
                  ? false
                  : undefined;
              trailer_data_obj[i].describe_damage =
                additionalInfoFormValues[i].damage_description;
              trailer_data_obj[i].is_motorized =
                additionalInfoFormValues[i].is_trailer_motorized === "Yes"
                  ? true
                  : additionalInfoFormValues[i].is_trailer_motorized === "No"
                  ? false
                  : undefined;
              trailer_data_obj[i].manufacturer =
                trailerSpecsFormValues[i].trailer_manufacturer;
              trailer_data_obj[i].model =
                trailerSpecsFormValues[i].trailer_model;
              trailer_data_obj[i].length_in_feet =
                trailerSpecsFormValues[i].trailer_length;
              trailer_data_obj[i].width_in_feet =
                trailerSpecsFormValues[i].trailer_width;
              trailer_data_obj[i].VIN = trailerSpecsFormValues[i].vin;
              trailer_data_obj[i].deck_length_ft =
                trailerSpecsFormValues[i].deck_length;
              trailer_data_obj[i].deck_width_ft =
                trailerSpecsFormValues[i].deck_width;
              trailer_data_obj[i].screened_or_florida_room_length_ft =
                trailerSpecsFormValues[i].florida_room_length;
              trailer_data_obj[i].screened_or_florida_room_width_ft =
                trailerSpecsFormValues[i].florida_room_width;
              trailer_data_obj[i].hard_awning_length =
                trailerSpecsFormValues[i].awning_length;
              trailer_data_obj[i].hard_awning_width =
                trailerSpecsFormValues[i].awning_width;
              trailer_data_obj[i].is_financed =
                additionalInfoFormValues[i].trailer_financed === "Yes"
                  ? true
                  : additionalInfoFormValues[i].trailer_financed === "No"
                  ? false
                  : undefined;

              trailer_data_obj[i].financier_name =
                additionalInfoFormValues[i].trailer_lienholder;

              trailer_data_obj[i].financier_address_line1 =
                additionalInfoFormValues[i].street_leinholder;
              trailer_data_obj[i].financier_address_line2 =
                additionalInfoFormValues[i].line2_leinholder;
              trailer_data_obj[i].financier_city =
                additionalInfoFormValues[i].city_leinholder;
              trailer_data_obj[i].financier_province =
                additionalInfoFormValues[i].province_leinholder;
              trailer_data_obj[i].financier_postalcode =
                additionalInfoFormValues[i].pc_leinholder;
              trailer_data_obj[i].financier_country =
                additionalInfoFormValues[i].country_leinholder;
            }

            console.log(trailer_data_obj);

            let trailer_quote_updates = {
              trailer_quote_record_id: globalData?.quote_record_id,
              quote_status: "In Progress",
              trailers: trailer_data_obj,
              organization_id: ORGANIZATION_ID,
            };

            let body = {
              trailer_quote_record_id: globalData.quote_record_id,
              trailer_quote_updates,
            };

            console.log(body);

            setAnimateX(100);

            nextPage(formNumber + 1);
            setBtnLoading(() => false);

            await axios
              .post(`${APIURL}/trailer/update_trailer_quote`, body)
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                setBtnLoading(() => false);
              });
          }}
          disabled={btnLoading}
        >
          Next {btnLoading && <div className="btn_loading"></div>}
        </button>
      )}
      {formNumber === 1 ? (
        ""
      ) : (
        <button
          className="back_btn"
          onClick={() => {
            setAnimateX(-100);

            backPage(formNumber - 1);
          }}
          disabled={btnLoading}
        >
          Back
        </button>
      )}
    </div>
  );
};

export const Button = (
  redBoxObj,
  wholeError,
  setRedBoxObj,
  setError,
  nextPage,
  formNumber,
  setAnimateX
) => {
  let obj = [...redBoxObj];

  const allValuesUndefined = wholeError.some((obj) => {
    for (const key in obj) {
      if (obj[key] === undefined) {
        return true;
      }
    }
    return false;
  });

  let updatedArray = [...wholeError];

  console.log(allValuesUndefined);
  if (allValuesUndefined) {
    updatedArray = wholeError.map((obj) => {
      const updatedObj = { ...obj };

      for (const key in updatedObj) {
        if (updatedObj[key] === undefined) {
          updatedObj[key] = true;
        }
      }

      return updatedObj;
    });

    for (let i = 0; i < updatedArray.length; i++) {
      const errorItem = updatedArray[i];
      const objItem = obj[i];

      const hasTrueValue = Object.values(errorItem).some(
        (value) => value === true
      );

      console.log(hasTrueValue);

      if (hasTrueValue) {
        objItem.state = true;
      } else {
        objItem.state = false;
      }
    }

    setRedBoxObj(obj);

    setError(updatedArray);

    return;
  }

  const anyValueIsTrue = updatedArray.some((obj) => {
    for (const key in obj) {
      if (obj[key] === true) {
        return true;
      }
    }
    return false;
  });

  console.log(updatedArray);

  if (anyValueIsTrue) {
    for (let i = 0; i < updatedArray.length; i++) {
      const errorItem = updatedArray[i];
      const objItem = obj[i];

      const hasTrueValue = Object.values(errorItem).some(
        (value) => value === true
      );

      console.log(hasTrueValue);

      if (hasTrueValue) {
        objItem.state = true;
      } else {
        objItem.state = false;
      }
    }

    setRedBoxObj(obj);

    console.log(obj);
    // setRedBox(true);
    return;
  }

  for (let i = 0; i < updatedArray.length; i++) {
    const objItem = obj[i];

    objItem.state = false;
  }

  console.log(obj);

  setRedBoxObj(obj);

  setAnimateX(100);

  nextPage(formNumber + 1);
};
