import React, { useRef } from "react";
import { ConfigProvider, DatePicker, Tooltip } from "antd";
import { FormHelperText } from "@mui/material";
import dayjs from "dayjs";
import { ERROR_HELPERTEXT_STYLES, WARNING_COLOR } from "../utils";
import { QuestionCircleTwoTone } from "@ant-design/icons";

const DatePickerComponent = ({
  label,
  value,
  setValue,
  object,
  disabled,
  type,
  name,
  error_state,
  error_text,
  error,
  setError,
  wholeError,
  wholevalue,
  id,
  placeholder,
  max,
  info,
}) => {
  const inputRef = useRef(null);

  const handleChange = (date, dateString) => {
    console.log(date);
    // if (/^\d+$/.test(dateString)) {
    //   // Automatically add hyphens for date and month
    //   if (dateString.length === 2) {
    //     dateString = dateString + "-";
    //   } else if (dateString.length === 5) {
    //     dateString = dateString + "-";
    //   }
    // }

    if (name === "inception_date") {
      let expiry_date = dayjs(date).add(1, "year");

      console.log(dayjs(expiry_date), dateString);
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false, expiry_date: false };
        }

        return m;
      });

      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: date, expiry_date: dayjs(expiry_date) };
        }

        return m;
      });

      setValue(updated);

      return;
    }
    
    let updated_error = wholeError.map((m, index) => {
      if (index === id - 1) {
        return { ...m, [name]: false };
      }

      return m;
    });
    setError(updated_error);

    let updated = wholevalue.map((m, index) => {
      if (index === id - 1) {
        return { ...m, [name]: date };
      }

      return m;
    });

    setValue(updated);
  };

  return (
    <div className={`textfield ${error_state && "error_textfield"}`}>
      <label htmlFor={label} id="label">
        {label}{" "}
        {info ? (
          <Tooltip
            title={
              <p style={{ fontSize: "0.75rem" }}>
                Date Format should be{" "}
                <b style={{ letterSpacing: "0.5px" }}>
                  {dayjs().format("DD/MM/YYYY")}
                </b>{" "}
              </p>
            }
          >
            <QuestionCircleTwoTone twoToneColor={"#8ed1fc"} />
          </Tooltip>
        ) : (
          ""
        )}
      </label>
      <div>
        <ConfigProvider
          theme={{
            components: {
              DatePicker: {
                activeBorderColor: "#8ed1fc",
                cellHoverWithRangeBg: "#fff",
                hoverBorderColor: "#8ed1fc",
                cellRangeBorderColor: "#fff",
                cellActiveWithRangeBg: "#fff",
                activeShadow: "0 0 0 0",
              },
            },
            token: {
              colorPrimary: "#8ed1fc",
              colorErrorOutline: "none",
              colorError: WARNING_COLOR,
              colorErrorBorderHover: "none",
              fontSize: 12,
            },
          }}
        >
          <DatePicker
            size="large"
            style={{ width: "100%" }}
            // onKeyDown={(e) => e.preventDefault()}
            ref={inputRef}
            id={name !== "expiry_date" && label}
            allowClear={false}
            value={value}
            onChange={handleChange}
            format={"DD/MM/YYYY"}
            status={error_state && "error"}
            disabled={disabled}
            placeholder={placeholder}
            disabledDate={max}
          />
        </ConfigProvider>
        {error_state && (
          <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
            {error_text}
          </FormHelperText>
        )}
      </div>
    </div>
  );
};

export default DatePickerComponent;
