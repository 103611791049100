import { FormHelperText } from "@mui/material";
import React from "react";
import {
  ERROR_HELPERTEXT_STYLES,
  PRIMARY_COLOR,
  WARNING_COLOR,
} from "../utils";
import { ConfigProvider, Input } from "antd";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const numberPlusDecimalRegex = /^[\d.]+$/;
var alphanumericRegex = /^[a-zA-Z0-9]+$/;
const usaZipRegex = /^\d{5}(?:-\d{4})?$/;
const canadaPostalRegex =
  /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ] ?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i;

const TextField = ({
  label,
  value,
  setValue,
  object,
  disabled,
  type,
  name,
  error_state,
  error_text,
  error,
  setError,
  wholeError,
  wholevalue,
  id,
  policy_details,
  policy_details_error,
  setPolicyDetailFormValues,
  setErrorPolicyDetails,
  placeholder,
  helperText,
  isDecimal,
  vin,
  alphanumeric,
}) => {
  const handleChange = (e) => {
    if (isDecimal) {
      if (e.target.value === "") {
        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: e.target.value };
          }

          return m;
        });

        setValue(updated);
      }
      if (numberPlusDecimalRegex.test(e.target.value)) {
        console.log("yes");
        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: e.target.value };
          }

          return m;
        });

        setValue(updated);
      } else {
        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: "" };
          }

          return m;
        });

        setValue(updated);

        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);

        return;
      }

      if (e.target.value === "") {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);
      } else {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false };
          }

          return m;
        });
        setError(updated_error);
      }

      return;
    }

    if (alphanumeric) {
      if (e.target.value === "") {
        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: e.target.value };
          }

          return m;
        });

        setValue(updated);
      }
      if (alphanumericRegex.test(e.target.value)) {
        console.log("yes");
        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: e.target.value };
          }

          return m;
        });

        setValue(updated);
      } else {
        let updated = wholevalue.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: "" };
          }

          return m;
        });

        setValue(updated);

        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);

        return;
      }

      if (e.target.value === "") {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);
      } else {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false };
          }

          return m;
        });
        setError(updated_error);
      }

      return;
    }

    if (name === "inception_date") {
      let val = e.target.value;
      const date = new Date(val);

      let expiry_date = `${date.getDate().toString().padStart(2, "0")}-${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${date.getFullYear() + 1}`;

      if (e.target.value === "") {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true, expiry_date: false };
          }

          return m;
        });
        setError(updated_error);
      } else {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false, expiry_date: false };
          }

          return m;
        });
        setError(updated_error);
      }

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.value, expiry_date };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "postal_code") {
      if (e.target.value === "") {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);

        let updated_error_policy = policy_details_error.map((m, index) => {
          if (index === id - 1) {
            return { ...m, mailing_postal_code: true };
          }

          return m;
        });
        setErrorPolicyDetails(updated_error_policy);
      } else {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false };
          }

          return m;
        });
        setError(updated_error);

        let updated_error_policy = policy_details_error.map((m, index) => {
          if (index === id - 1) {
            return { ...m, mailing_postal_code: false };
          }

          return m;
        });
        setErrorPolicyDetails(updated_error_policy);
      }

      let updated_policy = policy_details.map((m, index) => {
        if (index === id - 1) {
          return { ...m, mailing_postal_code: e.target.value };
        }

        return m;
      });

      setPolicyDetailFormValues(updated_policy);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.value };
        }

        return m;
      });

      setValue(updated);
    }

    if (name === "name") {
      if (e.target.value === "") {
        let updated_error = error.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);
      } else {
        let updated_error = error.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false };
          }

          return m;
        });
        setError(updated_error);
      }
      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.value };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "signature_text") {
      setValue({ ...wholevalue, text: e.target.value });
      let updated_error = error.map((m, index) => {
        if (1) {
          return { ...m, signature: undefined };
        }

        return m;
      });
      setError(updated_error);

      console.log(error, wholevalue);
      return;
    }

    if (name === "mailing_suite" || name === "line2_leinholder") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false };
        }

        return m;
      });
      setError(updated_error);

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.value };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "email") {
      if (e.target.value === "" || !emailRegex.test(e.target.value)) {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);
      } else {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false };
          }

          return m;
        });
        setError(updated_error);
      }
      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.value };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "pc_leinholder" || name === "parking_postal_code") {
      if (e.target.value === "" || !canadaPostalRegex.test(e.target.value)) {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);
      } else {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false };
          }

          return m;
        });
        setError(updated_error);
      }

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.value };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (name === "mailing_postal_code") {
      if (e.target.value === "") {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);
      } else if (
        wholevalue[0].mailing_country === "Canada" &&
        !canadaPostalRegex.test(e.target.value)
      ) {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);
      } else if (
        wholevalue[0].mailing_country === "US" &&
        !usaZipRegex.test(e.target.value)
      ) {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: true };
          }

          return m;
        });
        setError(updated_error);
      } else {
        let updated_error = wholeError.map((m, index) => {
          if (index === id - 1) {
            return { ...m, [name]: false };
          }

          return m;
        });
        setError(updated_error);
      }

      let updated = wholevalue.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: e.target.value };
        }

        return m;
      });

      setValue(updated);

      return;
    }

    if (e.target.value === "") {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: true };
        }

        return m;
      });
      setError(updated_error);
    } else {
      let updated_error = wholeError.map((m, index) => {
        if (index === id - 1) {
          return { ...m, [name]: false };
        }

        return m;
      });
      setError(updated_error);
    }
    let updated = wholevalue.map((m, index) => {
      if (index === id - 1) {
        return { ...m, [name]: e.target.value };
      }

      return m;
    });

    setValue(updated);
  };

  return (
    <div
      className={`textfield ${error_state && "error_textfield"}`}
      style={{
        alignItems: helperText && "flex-start",
      }}
    >
      <label
        htmlFor={name}
        style={{ marginBottom: (helperText || error_state) && "10px" }}
      >
        {label}
      </label>
      <div>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                activeBorderColor: PRIMARY_COLOR,
                hoverBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                errorActiveShadow: "none",
              },
            },
            token: {
              colorErrorOutline: "none",
              colorError: WARNING_COLOR,
              colorErrorBorderHover: "none",
            },
          }}
        >
          <Input
            type={type ? type : "text"}
            id={name}
            size="large"
            className="input"
            readOnly={disabled}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            maxLength={vin && 17}
          />
        </ConfigProvider>
        {helperText ? (
          <FormHelperText
            sx={{
              fontStyle: "italic",
              fontSize: "0.75rem",
              color: "#a0aec0",
              margin: "8px 0",
              lineHeight: "1rem",
              letterSpacing: "0.7px",
              fontFamily: "Montserrat",
            }}
          >
            {helperText}
          </FormHelperText>
        ) : (
          ""
        )}
        {error_state && (
          <FormHelperText sx={ERROR_HELPERTEXT_STYLES}>
            {error_text}
          </FormHelperText>
        )}
      </div>
    </div>
  );
};

export default TextField;
