import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIURL, ORGANIZATION_ID } from "../../../api";
import Nav from "../../../components/AdminPanel/Nav";
import DollarInput from "../../../components/AdminPanel/DollarInput";
import APTextField from "../../../components/AdminPanel/APTextField";
import { PercentageOutlined } from "@ant-design/icons";

const TrailerGeneral = () => {
  const [loading, setLoading] = useState(true);
  const [editState, setEditState] = useState(false);
  const [cancelState, setCancelState] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    async function fetch() {
      await axios
        .post(`${APIURL}/organization/get_organization`, {
          organization_id: ORGANIZATION_ID,
          required_keys: [
            "trailer_detached_priv_stru_cov",
            "trailer_min_retn_prem",
            "trailer_ol_water_protection",
            "trailer_over_25years_surcharge",
            "trailer_personal_prop_cov",
            "trailer_preferred_parks_discount",
            "trailer_seasonally_parked_discount",
            "trailer_snowbird_endorsement_prem",
            "trailer_personal_prop_cov_excess_prem",
          ],
        })
        .then((res) => {
          if (!isCancelled) {
            console.log(res);
            let response = res?.data?.data?.organization;
            response.trailer_personal_prop_cov_excess_prem_charge =
              res?.data?.data?.organization?.trailer_personal_prop_cov_excess_prem?.charge;
            response.trailer_personal_prop_cov_excess_prem_per_amt =
              res?.data?.data?.organization?.trailer_personal_prop_cov_excess_prem?.per_amt;

            console.log(response);
            console.log(response);
            setData(response);
            setLoading(() => false);
            setCancelState(() => false);
            setEditState(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetch();

    return () => {
      isCancelled = true;
    };
  }, [cancelState]);

  const handleEdit = async () => {
    setLoading(() => true);

    await new Promise((resolve) => setTimeout(resolve, 1500));

    setLoading(() => false);
    setEditState(true);
  };

  const handleCancel = async () => {
    setLoading(() => true);

    setCancelState(true);
  };

  const handleSave = async () => {
    let body = {
      organization_id: ORGANIZATION_ID,
      organization_updates: {
        trailer_detached_priv_stru_cov: parseFloat(
          data?.trailer_detached_priv_stru_cov
        ),
        trailer_min_retn_prem: parseFloat(data?.trailer_min_retn_prem),
        trailer_ol_water_protection: parseFloat(
          data?.trailer_ol_water_protection
        ),
        trailer_over_25years_surcharge: parseFloat(
          data?.trailer_over_25years_surcharge
        ),
        trailer_personal_prop_cov: parseFloat(data?.trailer_personal_prop_cov),
        trailer_preferred_parks_discount: parseFloat(
          data?.trailer_preferred_parks_discount
        ),
        trailer_seasonally_parked_discount: parseFloat(
          data?.trailer_seasonally_parked_discount
        ),
        trailer_snowbird_endorsement_prem: parseFloat(
          data?.trailer_snowbird_endorsement_prem
        ),
        trailer_personal_prop_cov_excess_prem: {
          charge: parseFloat(
            data?.trailer_personal_prop_cov_excess_prem_charge
          ),
          per_amt: parseFloat(
            data?.trailer_personal_prop_cov_excess_prem_per_amt
          ),
        },
      },
    };

    console.log(body);

    setLoading(() => true);

    await axios
      .post(`${APIURL}/organization/update_organization`, body)
      .then((res) => {
        console.log(res);

        setCancelState(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  return (
    <div className="layout">
      <Nav
        header={editState ? "Edit Trailer Settings" : "View Trailer Settings"}
        layout={editState ? "save" : "edit"}
        onEditChange={handleEdit}
        onSaveChange={handleSave}
        onCancelChange={handleCancel}
      />
      <div className="layout_container">
        {loading ? (
          <div className="layout_loading">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="layout_main"> 
            <APTextField
              addonAfter={<PercentageOutlined />}
              handleChange={handleChange}
              disabled={!editState && true}
              name={"trailer_detached_priv_stru_cov"}
              label="Trailer Detached Private Structure Coverage"
              value={data?.trailer_detached_priv_stru_cov}
            />
            <DollarInput
              handleChange={handleChange}
              disabled={!editState && true}
              name={"trailer_min_retn_prem"}
              label="Trailer Min.retn Premium"
              value={data?.trailer_min_retn_prem}
            />
            <APTextField
              addonAfter={<PercentageOutlined />}
              handleChange={handleChange}
              disabled={!editState && true}
              name={"trailer_personal_prop_cov"}
              label="Personal Property Coverage"
              value={data?.trailer_personal_prop_cov}
            />
            <DollarInput
              handleChange={handleChange}
              disabled={!editState && true}
              name={"trailer_ol_water_protection"}
              label="OverLand Water Protection"
              value={data?.trailer_ol_water_protection}
            />
            <APTextField
              addonAfter={<PercentageOutlined />}
              handleChange={handleChange}
              disabled={!editState && true}
              name={"trailer_over_25years_surcharge"}
              label="Trailer Over 25years Surcharge"
              value={data?.trailer_over_25years_surcharge}
            />
            <APTextField
              addonAfter={<PercentageOutlined />}
              handleChange={handleChange}
              disabled={!editState && true}
              name={"trailer_preferred_parks_discount"}
              label="Trailer Preferred Parks Discount"
              value={data?.trailer_preferred_parks_discount}
            />
            <APTextField
              addonAfter={<PercentageOutlined />}
              handleChange={handleChange}
              disabled={!editState && true}
              name={"trailer_seasonally_parked_discount"}
              label="Trailer Seasonally Parked Discount"
              value={data?.trailer_seasonally_parked_discount}
            />
            <DollarInput
              handleChange={handleChange}
              disabled={!editState && true}
              name={"trailer_snowbird_endorsement_prem"}
              label="Snowbird endorsement premium"
              value={data?.trailer_snowbird_endorsement_prem}
            />
            <DollarInput
              handleChange={handleChange}
              disabled={!editState && true}
              name={"trailer_personal_prop_cov_excess_prem_charge"}
              label="trailer personal prop cov excess prem charge"
              value={data?.trailer_personal_prop_cov_excess_prem_charge}
            />
            <DollarInput
              handleChange={handleChange}
              disabled={!editState && true}
              name={"trailer_personal_prop_cov_excess_prem_per_amt"}
              label="trailer personal prop cov excess prem per amt"
              value={data?.trailer_personal_prop_cov_excess_prem_per_amt}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TrailerGeneral;
