import React from "react";
import { Customer } from "../Quotes/ViewTrailer";

const ViewCustomer = () => {
  return (
    <div>
      <Customer customer={{}} />
    </div>
  );
};

export default ViewCustomer;
