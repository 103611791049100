import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIURL } from "../../../api";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "../../../components/AdminPanel/Nav";
import APTextField from "../../../components/AdminPanel/APTextField";

const EditPark = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [parkDetails, setParkDetails] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    async function fetch() {
      await axios
        .get(
          `${APIURL}/preferred_park/get_preferred_park?preferred_park_id=${searchParams.get(
            "id"
          )}`
        )
        .then((res) => {
          console.log(res);
          setParkDetails(res?.data?.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetch();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setParkDetails({
      ...parkDetails,
      [name]: value,
    });
  };

  const handleCancel = () => {
    navigate("/admin/trailer/parks");
  };

  const handleSave = async () => {
    await axios
      .post(`${APIURL}/preferred_park/update_preferred_park`, {
        preferred_park_id: parkDetails?.preferred_park_id,
        preferred_park_updates: parkDetails,
      })
      .then((res) => {
        console.log(res);
        navigate("/admin/trailer/parks");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="layout">
      <Nav
        header={"Edit Preferred Park"}
        layout={"save"}
        // onEditChange={handleEdit}
        onSaveChange={handleSave}
        onCancelChange={handleCancel}
      />
      <div className="layout_container">
        {loading ? (
          <div className="layout_loading">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="layout_main">
            <APTextField
              label={"Park name"}
              value={parkDetails?.name}
              name={"name"}
              handleChange={(e) => handleChange(e)}
            />{" "}
            <APTextField
              label={"Address line 1"}
              value={parkDetails?.address_line1}
              name={"address_line1"}
              handleChange={(e) => handleChange(e)}
            />{" "}
            <APTextField
              label={"Address line 2"}
              value={parkDetails?.address_line2}
              name={"address_line2"}
              handleChange={(e) => handleChange(e)}
            />{" "}
            <APTextField
              label={"City"}
              value={parkDetails?.city}
              name={"city"}
              handleChange={(e) => handleChange(e)}
            />{" "}
            <APTextField
              label={"Province"}
              value={parkDetails?.province}
              name={"province"}
              handleChange={(e) => handleChange(e)}
            />{" "}
            <APTextField
              label={"Postal Code"}
              value={parkDetails?.postalcode}
              name={"postalcode"}
              handleChange={(e) => handleChange(e)}
            />
            <APTextField
              label={"Country"}
              value={parkDetails?.country}
              name={"country"}
              handleChange={(e) => handleChange(e)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditPark;
