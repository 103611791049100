import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIURL, ORGANIZATION_ID } from "../../../api";
import TableInput from "../../../components/AdminPanel/TableInput";
import { Modal, Table, message } from "antd";
import Nav from "../../../components/AdminPanel/Nav";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import APTextField from "../../../components/AdminPanel/APTextField";

const BoatManufacturer = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [editState, setEditState] = useState(false);
  const [cancelState, setCancelState] = useState(false);
  const [manufacturerName, setManufacturerName] = useState("");

  useEffect(() => {
    let isCancelled = false;

    async function fetch() {
      await axios
        .get(`${APIURL}/manufacturer/get_manufacturers?type=Boat`)
        .then((res) => {
          if (!isCancelled) {
            console.log(res);

            let mapped_array = res?.data?.data.map((m, index) => {
              return { ...m, key: index + 1 };
            });

            setData(mapped_array);
            setLoading(false);
            setCancelState(() => false);
            setEditState(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetch();

    return () => {
      isCancelled = true;
    };
  }, [cancelState]);

  const column = [
    {
      title: "S.No",
      dataIndex: "key",
      key: "key",
      fixed: "left",
      width: 100,
    },
    {
      title: "Manufacturer Name",
      dataIndex: "manufacturer_name",
      key: "manufacturer_name",
      width: 200,
      render: (_, record) => (
        <HoverCell data={data} setData={setData} record={record} />
      ),
    },
  ];

  const handleEdit = async () => {
    // setLoading(() => true);

    // await new Promise((resolve) => setTimeout(resolve, 1500));

    // setLoading(() => false);
    setEditState(true);
  };

  const handleCreateManufacturer = async () => {
    await axios
      .post(`${APIURL}/manufacturer/create_manufacturers`, {
        list_of_manufacturers: [{ name: manufacturerName, type: "Boat" }],
      })
      .then((res) => {
        console.log(res);
        setManufacturerName("");
        message.success("Manufacturer created");
        setEditState(false);
        setCancelState(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="layout">
      <Modal
        open={editState}
        onCancel={() => {
          setEditState(false);
        }}
        okText={"Create"}
        title="Create Boat Manufacturer"
        onOk={handleCreateManufacturer}
      >
        <div>
          <APTextField
            size={"medium"}
            label={"Manufacturer Name"}
            value={manufacturerName}
            handleChange={(e) => {
              setManufacturerName(e.target.value);
            }}
          />
        </div>
      </Modal>
      <Nav
        header={
          editState ? "Create Boat Manufacturer" : "View Boat Manufacturers"
        }
        layout={editState ? "save" : "edit"}
        onEditChange={handleEdit}
        editText={"Create"}
      />
      <div className="layout_container">
        {loading ? (
          <div className="layout_loading">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="layout_main">
            <Table
              size="small"
              columns={column}
              scroll={{ y: 630 }}
              dataSource={data}
              pagination={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BoatManufacturer;

export const HoverCell = ({ record, data, setData }) => {
  const [edit, setEdit] = useState(false);
  const [editState, setEditState] = useState(false);

  const handleSave = async () => {
    console.log(record);

    await axios
      .post(`${APIURL}/manufacturer/update_manufacturer`, {
        manufacturer_id: record?.manufacturer_id,
        manufacturer_updates: {
          name: record?.name,
          type: record?.type,
        },
      })
      .then((res) => {
        console.log(res);

        setEditState(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      onMouseEnter={() => {
        setEdit(true);
      }}
      onMouseLeave={() => {
        setEdit(false);
      }}
      style={{ display: "flex", gap: "5px" }}
    >
      <TableInput
        border={editState}
        disabled={!editState}
        value={record?.name}
        handleChange={(e) => {
          let value = e.target.value;

          let array = [...data];

          let updated = array.map((m) => {
            if (m.manufacturer_id === record.manufacturer_id) {
              return {
                ...m,
                name: value,
              };
            }

            return m;
          });

          setData(updated);
        }}
      />
      {edit && !editState ? (
        <EditOutlined
          onClick={() => {
            setEditState(true);
          }}
        />
      ) : (
        ""
      )}
      {edit && editState ? (
        <div style={{ display: "flex", gap: "3px" }}>
          <CheckCircleTwoTone twoToneColor={"#78d6c6"} onClick={handleSave} />
          <CloseCircleTwoTone
            twoToneColor={"#F5222D"}
            onClick={() => {
              setEditState(false);
            }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
